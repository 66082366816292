import { CONFIG_OPTION_TOAST_ERROR } from 'common/toast';
import DropdownCategory from 'components/Common/DropdownCategory';
import LabelWrapper from 'components/Common/LabelWrapper';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import { Col, Input, Label, Row, Spinner } from 'reactstrap';
import { getImageSearchByCategory, postConvertToCdn } from 'store/thunks';

interface BoxImageSearchProps {
    keyword: string,
    news_id?: number | string,
    categories: any,
    onSetImageInsert: (image: any) => void,
    resetCursorToStart?: () => void,
}

const BoxImageSearch: React.ForwardRefRenderFunction<HTMLInputElement, BoxImageSearchProps> = ({ keyword, news_id, categories = [], onSetImageInsert, resetCursorToStart }, ref) => {
    const { t } = useTranslation();
    const refBoxViewGenerate: any = ref;
    const refBoxViewImages = useRef<any>([]);

    const [params, setParams] = useState<any>({ is_apply: 1, news_id: news_id, category_id: '', keyword: keyword, page: 1, limit: 10 });
    const [categorySearch, setCategorySearch] = useState<any>({ value: '', label: t('All') });

    const [keywordSearch, setKeywordSearch] = useState<string>(keyword);
    const [applySearch, setApplySearch] = useState<number>(1);
    const [images, setImage] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextPage, setNextPage] = useState<boolean>(false);
    const [isItemLoading, setIsItemLoading] = useState<number | null>(null);

    const handleSearch = async (_params: any = {}) => {
        try {
            if (isLoading) {
                return;
            }
            setIsLoading((_prev) => true);

            let _clone_params: any = structuredClone({ ...params, ..._params });

            if (!!_clone_params?.is_apply) {
                if (_clone_params?.category_id === '') {
                    _clone_params.is_all = 1;
                }
                delete _clone_params.is_apply;
            } else {
                delete _clone_params.category_id;
                delete _clone_params.is_apply;
            }
            const res: any = await getImageSearchByCategory(_clone_params);
            if (res?.code === 200) {
                setImage((_prev: any) => (_params?.page === 1 ? [...res?.data?.list] : [..._prev, ...res?.data?.list]));
                setNextPage((_prev: any) => !!res?.data?.next_page);
            }
            setIsLoading((_prev) => false);
        } catch (error: any) {
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            setIsLoading((_prev) => false);
            return error;
        }
    };

    const handleNextPage = () => {
        const _page = params?.page + 1;
        handleSearch({ page: _page });
        setParams((_prev: any) => ({ ..._prev, page: _page }));
    };

    const handleSetImageInsert = async (item: any, index: number) => {
        try {
            if (isItemLoading !== null) {
                return;
            }
            setIsItemLoading((_prev) => index);
            const res: any = await postConvertToCdn({
                source_url: item?.context_link,
                image_url: item?.image_url,
                category_id: categorySearch?.value ? Number(categorySearch?.value) : null
            });
            if (res?.code === 200) {
                const _image = {
                    ...item,
                    image_url: res?.data?.convert_url || '',
                    keyword: res?.data?.keyword || '',
                    source_title: res?.data?.source_title || '',
                    source_url: res?.data?.source_url || '',
                    image_category_id: categorySearch?.value || '',
                    category_name: String(categorySearch?.label || '')?.split('>')[categorySearch?.depth === 2 ? 1 : 0],
                };
                onSetImageInsert && onSetImageInsert(_image);
            } else {
                toast(`${t('The original image does not exist!')}`, CONFIG_OPTION_TOAST_ERROR);
            }

            // const _image = {
            //     ...item,
            //     image_url: item?.image_url || '',
            //     image_category_id: categorySearch?.value || '',
            //     category_name: String(categorySearch?.label || '')?.split('>')[categorySearch?.depth === 2 ? 1 : 0]
            // };
            // onSetImageInsert && onSetImageInsert(_image);


            setIsItemLoading((_prev) => null);
        } catch (error: any) {
            setIsItemLoading((_prev) => null);
            toast(`${t('The original image does not exist!')}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }

    };

    // useEffect(() => {
    //     setKeywordSearch((_prev) => keyword);
    // }, [keyword, news_id]);

    const handleSearchClick = () => {
        setImage((_prev: any) => []);
        setParams((_prev: any) => ({ ..._prev, is_apply: applySearch, category_id: categorySearch?.value || '', keyword: keywordSearch || '', news_id: news_id, page: 1 }));
        handleSearch({ is_apply: applySearch, category_id: categorySearch?.value || '', keyword: keywordSearch || '', news_id: news_id, page: 1 });
    }

    useEffect(() => {
        if (news_id) {
            setImage((_prev: any) => []);
            setParams((_prev: any) => ({ ..._prev, is_apply: applySearch, category_id: categorySearch?.value, keyword: keyword || '', news_id: news_id, page: 1 }));
            handleSearch({ is_apply: applySearch, category_id: categorySearch?.value || '', keyword: keyword || '', news_id: news_id, page: 1 });
            setKeywordSearch((_prev) => keyword);
        }
    }, [news_id, keyword]);


    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const target = event.target as HTMLElement;

            // Check if the click is outside all elements in refBoxViewImages
            const clickedOutsideImages = refBoxViewImages.current.every(
                (ref: any) => ref && !ref.contains(target)
            );

            // Check if the click is outside the element in refBoxViewGenerate
            const clickedOutsideGenerate =
                refBoxViewGenerate.current && !refBoxViewGenerate.current.contains(target);

            // Check if the click is inside an element with the class 'tox-dialog' (TinyMCE modal)
            const clickedInsideEditorModal = !!document.querySelector('.tox-dialog')?.contains(target);

            // If the click is outside both refBoxViewImages and refBoxViewGenerate, and not inside the editor modal
            if (clickedOutsideImages && clickedOutsideGenerate && !clickedInsideEditorModal) {
                resetCursorToStart && resetCursorToStart();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [resetCursorToStart, refBoxViewGenerate, refBoxViewImages]);



    return (
        <div className="color-scrollbar-os overflow-y-auto overflow-x-hidden h-100 scrollable-div-google-search" id="scrollableDiv">
            <InfiniteScroll
                dataLength={images?.length || 0}
                next={handleNextPage}
                scrollableTarget='scrollableDiv'
                hasMore={nextPage}
                loader={''} // loader={<LoadingListNotify />}
                scrollThreshold={'50%'}
            >
                <Row className="box-google-image">
                    <Col sm={12}>
                        <Row>
                            <Col sm={12} className='mt-3'>
                                <div className="position-relative mx-2 mb-2">
                                    <div className="form-check form-switch mb-0">
                                        <Input className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="allow_reuse_source_article"
                                            name="allow_reuse_source_article"
                                            checked={!!applySearch}
                                            onChange={(event: any) => {
                                                const input = Number(event?.target.checked);
                                                setApplySearch((_prev: any) => input)
                                            }}
                                        />
                                        <Label className="form-check-label" for="allow_reuse_source_article">{t('Apply Category Filter')}</Label>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} className='mt-1'>
                                <div className="position-relative mx-2 mb-2">
                                    <LabelWrapper label={t('Category')} isShow={true}>
                                        <DropdownCategory
                                            disabled={!applySearch}
                                            name="category"
                                            placeholder={t('Category')}
                                            isClearable={false}
                                            dataList={[{ id: '', name: t('All'), subs: [] }, ...categories]}
                                            initialValue={categorySearch ?? undefined}
                                            onChangeCategory={(event) => {
                                                setCategorySearch(event)
                                            }}
                                        />
                                    </LabelWrapper>
                                </div>
                            </Col>
                            <Col col={8} sm={9} className='mt-0'>
                                <div className="position-relative my-2 ms-2">
                                    <LabelWrapper label={t('Keyword')} isShow={true}>
                                        <Input
                                            name="text"
                                            type="text"
                                            className="form-control mb-3"
                                            placeholder="Keyword"
                                            style={{ borderRadius: '5px' }}
                                            value={keywordSearch || ''}
                                            onChange={(event: any) => {
                                                const input = event.target.value;
                                                setKeywordSearch((_prev: any) => input)
                                            }}
                                            autoComplete="off"
                                        />
                                    </LabelWrapper>
                                </div>
                            </Col>
                            <Col col={4} sm={3} className='mt-0'>
                                <div className="text-center my-2 me-2">
                                    <button className="btn-md btn btn-soft-primary w-100" style={{ borderRadius: '5px' }} disabled={isLoading} onClick={() => handleSearchClick()}>
                                        {t('Button Search')}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12}>
                        <div className="row gallery-wrapper">
                            {images?.map((item: any, index: number) => (
                                <Col sm={6} key={index}>
                                    <div className="my-masonry-grid_row ps-0 cursor-pointer">
                                        <div className="element-item project designing development">
                                            <div className="gallery-box card">
                                                <div ref={(el: any) => (refBoxViewImages.current[index] = el)} className="gallery-container d-flex justify-content-center align-items-center" style={{ height: '150px', textAlign: 'center', backgroundColor: '#eaeaea' }} onClick={() => handleSetImageInsert(item, index)}>
                                                    <img className="gallery-img img-fluid mx-auto" style={{ minHeight: '150px' }} src={item?.thumbnail_link || ''} alt={t('No Image')} />
                                                    {(isItemLoading === index) && <span className="position-absolute fs-24">
                                                        <Spinner size="sm" className="text-white fs-20 align-middle" style={{ width: '40px', height: '40px' }}></Spinner>
                                                        {/* <i className="text-primary mdi mdi-loading mdi-spin align-middle" style={{ width: '50px', height: '50px' }}></i> */}
                                                    </span>}
                                                </div>
                                                <div className="box-content">
                                                    <div className="d-flex align-items-center mt-1">
                                                        <div className="flex-grow-1 text-muted text-over-one-line">{t('Source')}:
                                                            <a className="text-primary ms-2 hover-underline-primary" target="_blank" href={item?.context_link || ''}>
                                                                {item?.title || ''}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </div>
                        <div className="text-center my-2">
                            {isLoading ? <Spinner size="sm" className="text-primary fs-20 align-middle" style={{ width: '30px', height: '30px' }}></Spinner> : <button className="btn-sm btn btn-primary" disabled={isLoading || !nextPage} style={{ width: '80px' }} onClick={() => handleNextPage()}>
                                {nextPage ? t('More') : t('No data')}
                            </button>}
                        </div>
                    </Col>
                </Row>
            </InfiniteScroll>
        </div>
    );
};

export default React.memo(React.forwardRef(BoxImageSearch));
