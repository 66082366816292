import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IActivityLog } from "./types/_logging";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/logs/admin-activities';
const loggingApi = {
  activityLogs(params: any): Promise<AxiosResponse<ResponseData<IActivityLog[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
}
export default loggingApi
