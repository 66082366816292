import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IEnable2FA, IOperator } from "./types/_operator";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/admins';
const operatorApi = {
  operators(params: any): Promise<AxiosResponse<ResponseData<IOperator[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getOperator(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IOperator>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  allOperators(params: any = {}): Promise<AxiosResponse<ResponseData<IOperator[]>, any>> {
    const url = `masters/admins`
    return api.get(url, params)
  },
  postOperator(dataForm: any): Promise<AxiosResponse<ResponseData<IOperator>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putOperator(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IOperator>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteOperator(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IOperator>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  putChangePasswordProfile(dataForm: any): Promise<AxiosResponse<ResponseData<IOperator>, any>> {
    const url = `${path}/change-password`;
    return api.put(url, dataForm)
  },
  putProfile(dataForm: any): Promise<AxiosResponse<ResponseData<IOperator>, any>> {
    const url = `${path}/profile`;
    return api.put(url, dataForm)
  },
  putProfiles(dataForm: any): Promise<AxiosResponse<ResponseData<IOperator>, any>> {
    const url = `${path}/profiles`;
    return api.put(url, dataForm)
  },
  putEnable2FA(dataForm: any): Promise<AxiosResponse<ResponseData<IEnable2FA>, any>> {
    const url = `/auth/enable-2fa`;
    return api.put(url, dataForm)
  },

}
export default operatorApi
