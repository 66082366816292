import { createAsyncThunk } from "@reduxjs/toolkit";
import trackingApi from 'api/trackingApi';
import { formatQueryParams } from "helpers/format";

export const getArticleTrackings = createAsyncThunk("News Monitors", async (params: any = {}) => {
  try {
    const response = await trackingApi.articleTrackings(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getArticleTrackingsByNormal = async (params: any = {}) => {
  try {
    const response = await trackingApi.articleTrackings(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};