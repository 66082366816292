import BreadCrumb from 'components/Common/BreadCrumb';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import LabelWrapper from 'components/Common/LabelWrapper';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { formatNumberWithCommas } from 'helpers/format';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import TableContainer from "../../../components/Common/TableContainer";
import { getMailStatistics as onGetMailStatistics } from "../../../store/thunks";
import ChartLine from './ChartLine';
import { ARR_INDEX_TEXT_DAY_OF_WEEK } from 'helpers/constans';
export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'date';

registerLocale('en', en);
registerLocale('ko', ko);

const MailStatistic = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const START_DATE = `${moment().subtract(30, 'days').format("Y-MM-DD")}`;
  const END_DATE = `${moment().format("Y-MM-DD")}`;

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, 'DESC'),
    start_date: withDefault(StringParam, START_DATE),
    end_date: withDefault(StringParam, END_DATE),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
  const [startDate, endDate] = dateSearch;

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Mail;

  const MailProperties = createSelector(
    selectLayoutState,
    (state) => ({
      mailStatistics: state.mailStatistics,
      isMailStatisticSuccess: state.isMailStatisticSuccess,
      isMailStatisticLoading: state.isMailStatisticLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { mailStatistics, isMailStatisticLoading } = useSelector(MailProperties);

  useEffect(() => {
    dispatch(onGetMailStatistics(query));
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    const queryNew = {
      ...query,
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    };
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      order_by: 'DESC',
      sort_by: TYPE_SELECT_DEFAULT,
      start_date: `${moment().subtract(7, 'days').format("Y-MM-DD")}`,
      end_date: `${moment().format("Y-MM-DD")}`,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Date'),
        accessor: "date",
        filterable: true,
        sortable: true,
        thWidth: 150,
        thClass: 'align-middle text-start',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const arrDate = String(cell?.value || '').split(' ');
          const num = moment(cell?.value).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
          const classText = num === 0 ? 'text-danger row-bg-danger' : (num === 6 ? 'text-primary row-bg-primary' : '');
          const textNote = t(`STANDS_${ARR_INDEX_TEXT_DAY_OF_WEEK[num]}`);
          return (
            !!item?.text_row ? <strong>{item?.text_row} </strong> :
              <div className="text-start" style={{ minWidth: '120px' }}>
                <span className={classText}>{moment(arrDate?.[0] || '', 'Y-MM-DD').format("MM-DD")} ({textNote})</span>
              </div>

          )
        },
      },
      {
        Header: t('Sent'),
        accessor: "sent",
        filterable: true,
        sortable: false,
        width: 170,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end" style={{ minWidth: '150px' }}>
              {formatNumberWithCommas(item?.total_sent)}
            </div>
          </>)
        },
      },
      {
        Header: t('Opened'),
        accessor: "opened",
        filterable: true,
        sortable: false,
        width: 170,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end" style={{ minWidth: '150px' }}>
              {formatNumberWithCommas(item?.total_opened)}
            </div>
          </>)
        },
      },
      {
        Header: t('CTR'),
        accessor: "ctr",
        filterable: true,
        sortable: false,
        width: 170,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end" style={{ minWidth: '150px' }}>
              {formatNumberWithCommas(item?.ctr)}
            </div>
          </>)
        },
      },
      {
        Header: '',
        accessor: "__",
        filterable: true,
        sortable: false,
        width: 50,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end" style={{ minWidth: '50px' }}>

            </div>
          </>)
        },
      },
    ],
    [i18n?.language, query, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {

    } catch (error: any) {
      return error;
    }
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    document.title = `${t('Sending Email')} - ${t('Email Statistics')} | PRESS Ai`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  const categories = useMemo(() => {
    return mailStatistics?.list?.map((item: any) => item?.date) || [];
  }, [mailStatistics?.list, i18n?.language]);

  const series = useMemo(() => {
    const obChart = mailStatistics?.list?.reduce((ob: any, item: any) => {
      ob.sent?.push(Number(String(item?.total_sent).replace(/,/g, '')));
      ob.opened?.push(Number(String(item?.total_opened).replace(/,/g, '')));
      ob.ctr?.push(Number(String(item?.ctr).replace(/,/g, '')));
      return ob;
    }, { sent: [], opened: [], ctr: [] }) || [];
    return (
      [
        {
          name: t('Sent'),
          type: 'column',
          data: obChart?.sent,
        },
        {
          name: t('Opened'),
          type: 'column',
          data: obChart?.opened,
        },
        {
          name: t('CTR'),
          type: 'line',
          data: obChart?.ctr,
        },
      ]
    )
  }, [mailStatistics?.list, i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Email Statistics')} pageTitle={t('Sending Email')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.MAIL_LOG, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm-12">
                        <div>
                          <h5 className="card-title mb-0">
                            <span>
                              <span className="me-2">{t('Total')}:</span>
                              <CountUp
                                start={0}
                                end={mailStatistics?.pagination?.total || 0}
                                duration={1}
                                className="text-primary"
                              />
                            </span>
                          </h5>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={6} md={6} lg={3} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-xxl-2'>
                        <LabelWrapper label={t('Date')} isShow={true}>
                          <DatePickerCustom
                            placeholder={`${t('Start Date')} - ${t('End Date')}`}
                            startDate={startDate || null}
                            endDate={endDate || null}
                            onChangePicker={handleChangePicker}
                            showOptions={[
                              'today', 'yesterday', 'two_day_ago',
                              'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                              'this_week', 'last_week',
                              'this_month', 'last_month',
                            ]}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={9} xl={9} xxl={9} className="hstack gap-1 justify-content-center justify-content-sm-start mt-3 mt-xxl-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isMailStatisticLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <ChartLine
                    className="my-3"
                    titles={[t('Sent'), t('Opened'), t('CTR')]}
                    categories={categories}
                    series={series}
                  />
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card "
                      tableClass="align-middle table-have-first-row-is-total"
                      theadClass="table-light"
                      columns={columns}
                      data={mailStatistics?.list?.length ? [{
                        ...mailStatistics?.total,
                        text_row: t('Sum')
                      }, ...mailStatistics?.list] : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={mailStatistics?.pagination?.total}
                      customPageCount={Math.ceil(Number(mailStatistics?.pagination?.total) / Number(mailStatistics?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isMailStatisticLoading}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />
      </div >
    </React.Fragment >
  );
};

export default MailStatistic;