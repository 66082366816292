import BreadCrumb from "components/Common/BreadCrumb";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import { useQueryParams } from "use-query-params";
import TabCampaign from "./Campaign";
import TabData from "./Data";

const Car = () => {
    const { t, i18n } = useTranslation();

    const [activeTab, setActiveTab] = useState("CAMPAIGN");

    const [query, setQuery]: any = useQueryParams({});

    const tabChange = (tab: any) => {
        if (activeTab !== tab) {
            setQuery({}, "push");
            setActiveTab((_prev) => tab);
        }
    };

    useEffect(() => {
        document.title = `${t('Car')} - ${t('Boosting Content')} | PRESS Ai`;
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t("Car")} pageTitle={t("Boosting Content")} />
                    <Row>
                        <Col lg={12}>
                            <Card
                                id="CAMPAIGN"
                                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
                            >
                                <div
                                    style={{
                                        background: "#f3f6f9",
                                        borderTopLeftRadius: "0.6rem",
                                        borderTopRightRadius: "0.6rem",
                                    }}
                                >
                                    <div
                                        className="d-block"
                                        style={{
                                            background: "#f3f6f9",
                                            borderRadius: "0.6rem",
                                            width: "fit-content",
                                        }}
                                    >
                                        <Nav tabs className="nav nav-tabs nav-link-none-hover nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    style={{
                                                        cursor: "pointer",
                                                        background: `${activeTab === "CAMPAIGN" ? "var(--vz-primary)" : "unset"}`,
                                                        color: `${activeTab === "CAMPAIGN" ? "white" : "unset"}`,
                                                        borderTopRightRadius: 0
                                                    }}
                                                    onClick={() => tabChange("CAMPAIGN")}
                                                >
                                                    {t("Campaign Car")}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{
                                                        cursor: "pointer",
                                                        background: `${activeTab === "DATA" ? "var(--vz-primary)" : "unset"}`,
                                                        color: `${activeTab === "DATA" ? "white" : "unset"}`,
                                                        borderTopLeftRadius: 0,
                                                        borderTopRightRadius: 0,
                                                    }}
                                                    onClick={() => tabChange("DATA")}
                                                >
                                                    {t("Data Car")}
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </div>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="CAMPAIGN">
                                        {activeTab === "CAMPAIGN" && (
                                            <TabCampaign activeTab={activeTab} />
                                        )}
                                    </TabPane>
                                    <TabPane
                                        tabId="DATA"
                                    >
                                        {activeTab === "DATA" && (
                                            <TabData
                                                activeTab={activeTab}
                                            />
                                        )}
                                    </TabPane>
                                </TabContent>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Car;
