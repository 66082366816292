import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IArticleTracking } from "./types/_tracking";
const api = new APIClient();

const path = '/news-trackings';
const trackingApi = {
  articleTrackings(params: any): Promise<AxiosResponse<ResponseData<IArticleTracking[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
}
export default trackingApi
