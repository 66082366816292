import React, { ReactNode } from "react";
import TooltipCustom from "./TooltipCustom";
interface LabelWrapperProps {
    children: ReactNode;
    label?: string,
    isShow?: boolean,
    disabled?: boolean,
    isRequired?: boolean
    description?: string
}

const LabelWrapper: React.FC<LabelWrapperProps> = ({
    children,
    label = '',
    isShow = false,
    disabled = false,
    isRequired = false,
    description = ''
}) => {
    return (
        <div className="position-relative">
            <>{children}</>
            {isShow && (<label style={{
                position: 'absolute',
                left: '5px',
                top: '0px',
                marginBottom: '0px',
                transform: 'translateY(-50%)',
                padding: `0px 5px`,
                color: '#c2c2c2',
                fontSize: '12px',
                fontWeight: '400',
                borderRadius: '3px',
                zIndex: 2,
                backgroundColor: disabled ? 'hsl(0, 0%, 95%)' : 'var(--vz-secondary-bg)',
            }}>
                {label} {isRequired && <span className="text-danger"> *</span>}
                {!!description && <TooltipCustom
                    title={description || ''}
                    className="d-inline-block vertical-align-middle"
                    style={{ transform: 'translateY(1px)' }}
                >
                    <i className="ri-question-line align-bottom text-secondary fs-14" ></i>
                </TooltipCustom>}
            </label>)}
        </div>
    );
};

export default LabelWrapper;
