import { createSlice } from "@reduxjs/toolkit";
import { ResponseData } from "api/types/_public";
import { IConfig } from "api/types/_system";
import { getConfigurations } from "./thunk";

export interface IState {

  configs: ResponseData<IConfig[]> | null,
  isConfigLoading: boolean,
  isConfigSuccess: boolean,

  error: any,
};

export const initialState: IState = {

  configs: null,
  isConfigLoading: false,
  isConfigSuccess: false,

  error: {},
};

const SystemSlice = createSlice({
  name: "System",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Configs
    builder.addCase(getConfigurations.pending, (state: IState, action: any) => {
      state.isConfigLoading = true
    });
    builder.addCase(getConfigurations.fulfilled, (state: IState, action: any) => {
      state.configs = action.payload.data;
      state.isConfigSuccess = true;
      state.isConfigLoading = false;
    });
    builder.addCase(getConfigurations.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isConfigSuccess = false;
      state.isConfigLoading = false;
    });
  },
});

export default SystemSlice.reducer;