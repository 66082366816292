import { INews } from 'api/types/_news';
import { CONFIG_OPTION_TOAST_INFO } from 'common/toast';
import EditorCustom from 'components/Common/EditorCustom';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Input } from 'reactstrap';

interface BoxGeneratedNewsProps {
    newsDetail: INews | null;
    imageInsert?: any,
    isEdit?: boolean
}

const BoxGeneratedNews: React.ForwardRefRenderFunction<HTMLInputElement, BoxGeneratedNewsProps> = ({ newsDetail, imageInsert = null, isEdit = false, ...props }, ref) => {
    const { t } = useTranslation();

    const [title, setTitle] = useState<any>('');
    const [content, setContent] = useState<any>('');

    const copyTextToClipboard = useCallback(async (text: string) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);
        } else {
            document.execCommand('copy', true, text);
        }
        toast(`${t('Copied to the clipboard.')}`, CONFIG_OPTION_TOAST_INFO);
    }, []);

    const resetCursorToStart = () => {
        const editor = (ref as any)?.current;
        if (editor) {
            // Find the first <br> tag or &nbsp; in the content after insertion
            const body = editor?.getBody();
            if (!body) {
                return;
            }
            const brNode = body.querySelector('br'); // Find the first <br> tag
            let targetNode = null;

            if (brNode) {
                targetNode = brNode; // Prioritize the <br> tag
            } else {
                // If no <br> is found, search for &nbsp; in text nodes
                const textNodes = Array.from(body.childNodes).filter(
                    (node: any) => node.nodeType === Node.TEXT_NODE && node.nodeValue?.includes('\u00A0')
                );
                if (textNodes.length > 0) {
                    targetNode = textNodes[0];
                }
            }

            if (targetNode) {
                // Set the cursor immediately after the <br> tag or &nbsp;
                const parentNode = targetNode.parentNode;
                const offset = Array.from(parentNode.childNodes).indexOf(targetNode) + 1;
                editor.selection.setCursorLocation(parentNode, offset);
            }

            // Update the bookmark state at the new cursor location
            const newBookmark = editor.selection.getBookmark();
            // editor?.selection.setCursorLocation(null, 0);
        }

    };

    const validateURL = (input: string) => {
        const regex = /^(https?:\/\/)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=]*)?$/;
        return regex.test(input);
    };
    const insertImageAfterFirstBreak = async (link_image: string = '') => {
        if (!link_image || !(ref as any)?.current) return;
        const editor = (ref as any).current;

        const selection = editor.selection.getRng();
        const start = selection?.startOffset;
        const __content = `
             <div style="text-align: center; margin: 1rem 0;">
                <p style="text-align: center;">
                <img src="${link_image}" alt=${t('Source')} style="max-width:70%;"/>
                </p>
                <p style="text-align: center;">
                ${t('Source')}: ${validateURL(imageInsert?.source_title) ? `<a href="${imageInsert?.source_title}" target="_blank" style="font-style: italic; color: #555;">${imageInsert?.source_title}</a>` : `<span style="font-style: italic; color: #555;">${imageInsert?.source_title}</span>`} 
                </p>
            </div>`;
        const bookmark = editor.selection.getBookmark();
        if (bookmark) {
            // If there is focus (cursor in the editor), insert at that position
            editor.selection.moveToBookmark(bookmark);
            editor.insertContent(__content);
            // const bookmarks = editor.getBody().querySelectorAll('span[data-mce-type="bookmark"]');
            // bookmarks.forEach((bm:any) => bm.remove());
            // editor.selection.setCursorLocation(null, 0);
        } else {
            // If there is no focus (no cursor), insert after the first `<p>` tag
            const content = editor.getContent(); // Get the current content of the editor
            const updatedContent = content.replace(
                /(<br\b[^>]*>|&nbsp;)/i, // Regex to find the first `<br>` tag
                `$1${__content}`
            );
            editor.setContent(updatedContent); // Update the content of the editor
            // editor.selection.setCursorLocation(null, 0);

        }

        resetCursorToStart();
    }


    useEffect(() => {
        const _title: any = `${(newsDetail?.title || '')}`;
        const _content: any = `${(newsDetail?.body || '')}`;
        setContent((_prev: any) => _content);
        setTitle((_prev: any) => _title);
        setTimeout(() => {
            (ref as any).current.getTitle = () => {
                return `${_title}`;
            };
        }, 1000);
        setTimeout(() => {
            resetCursorToStart();
        }, 3000);
        return () => {
            setContent((_prev: any) => '');
            setTitle((_prev: any) => '');
        }
    }, [JSON.stringify(newsDetail?.body)]);

    useEffect(() => {
        if (imageInsert?.image_url) {
            insertImageAfterFirstBreak(imageInsert?.image_url);
        }
    }, [JSON.stringify(imageInsert?.image_url)]);

    useEffect(() => {
        if ((ref as any).current) {
            (ref as any).current.getTitle = () => {
                return `${title}`;
            };
        }
    }, [title, ref]);

    return (
        <>
            <span className="d-flex align-items-center text-success position-absolute fs-13" style={{ top: 5, right: 10, zIndex: 9 }}>
                {!!newsDetail?.title && <span className="text-primary d-flex align-items-center cursor-pointer me-3" onClick={() => copyTextToClipboard(`${newsDetail?.title || ''}\n\n${newsDetail?.body}`)}><i className="ri ri-file-copy-line" style={{ transform: 'translateY(1px)' }} /> {t('Copy')}</span>}
            </span>
            <div>
                <div className="d-flex flex-wrap align-items-start gap-2 mt-0 pt-2 mb-1 px-3 " style={{ borderBottom: '1px dashed #ececec', paddingBottom: '4px' }}>
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 fs-14 fs-md-16">
                            {t('Main Keyword Frequency')}:
                        </div>
                        <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                            <span className="fw-medium">{Number(newsDetail?.title_count || 0) + Number(newsDetail?.body_count || 0)}</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 fs-14 fs-md-16">
                            {t('Sub Keyword Frequency')}:
                        </div>
                        <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                            <span className="fw-medium">{Number(newsDetail?.title_keyword_sub_count || 0) + Number(newsDetail?.body_keyword_sub_count || 0)}</span>
                        </div>
                    </div>
                </div>
                <Input
                    name="text"
                    type="textarea"
                    className={`form-control h3 fs-24  px-3 mb-0 py-1`}
                    placeholder=""
                    rows={1}
                    style={{ width: '100%', border: '0px solid #fff', minHeight: '38px', fontWeight: '500', color: 'var(--vz-heading-color)' }}
                    value={title || ''}
                    onChange={(event: any) => {
                        const input = event.target.value;
                        setTitle((_prev: any) => input);
                    }}
                    autoComplete="off"
                />
            </div>
            <EditorCustom
                initialValue={`${content}`}
                ref={ref}
                toolbar={isEdit ? undefined : false}
                height={'calc(100% - 85px)'}
                fontSize={17}
                isAutoCenterImageUpload={true}
            />
        </>
    );
};

export default React.memo(React.forwardRef(BoxGeneratedNews));
