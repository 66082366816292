import { formatNumberWithCommas } from 'helpers/format';
import moment from 'moment';
import React from 'react';
import ReactApexChart from "react-apexcharts";

const RankingChart = ({ data = [], name = '', height = 70, isShowReversedY = false, showAxisBorder = false, fillColor = '#fb3335', fillOpacity = 0.5, isRotate = false, markersSize = 0, style = { transform: 'translateY(-20px)' }, isShowXaxis = false, isShowYaxis = false, isShowTooltip = false, tickAmountXaxis = 5 }) => {

    const getDateFormat = (date: string) => {
        return moment(date, 'YYYY-MM-DD').format('MM-DD');
    };
    const categories = data?.map((item: any) => getDateFormat(item?.date));

    const values = data?.map((item: any) => item?.index);
    const isDark = document.body.getAttribute('data-theme-version') === 'dark';

    const series = [
        {
            name: name,
            data: isShowReversedY ? values?.map((v: number) => (v === 0 ? 0 : (11 - v))) : values,
        },
    ]
    const options = {
        chart: {
            type: 'area',
            height: '100%',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            animations: {
                enabled: isShowYaxis, // Disable animations
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded'
            },

        },
        colors: ['#fb3335'],
        dataLabels: {
            enabled: false,
            offsetY: -10,
            style: {
                fontSize: '12px',
                colors: ["var(--text-dark)"]
            },
        },

        legend: {
            show: false,
        },
        stroke: {
            show: true,
            width: 2,
            curve: 'straight',
            lineCap: 'butt',
            colors: ['#fb3335'],
        },
        markers: {
            size: [markersSize],
            colors: '#fff',
            strokeColors: '#fb3335',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            hover: {
                size: undefined,
                sizeOffset: 3
            }
        },
        grid: {
            borderColor: 'var(--border)',
            show: showAxisBorder,
            xaxis: {
                lines: {
                    show: false,
                }
            },
            yaxis: {
                lines: {
                    show: showAxisBorder,
                }
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        xaxis: {
            categories: categories,
            labels: {
                show: isShowXaxis,
                style: {
                    colors: '#030012',
                    fontSize: '11px',
                    fontFamily: "Open Sans",
                    fontWeight: 100,
                    cssClass: 'apexcharts-xaxis-label',
                },
                ...(isRotate ? {} : { rotate: 0 }),

            },
            crosshairs: {
                show: false,
            },
            axisBorder: {
                show: isShowXaxis,
                color: 'var(--border)'
            },
            axisTicks: {
                show: false,
            },
            tickAmount: tickAmountXaxis,
            forceNiceScale: false,
            opposite: false,


        },
        yaxis: {
            ...(isShowReversedY ? { min: 0, max: 10 } : {}),
            show: isShowYaxis,
            // axisTicks: {
            //     show: true,
            // },
            axisBorder: {
                show: showAxisBorder,
                color: '#fb3335'
            },
            // tickAmount: 5,
            // forceNiceScale: true,
            // opposite: false,
            tooltip: {
                enabled: false
            },
            labels: {
                show: isShowYaxis,
                offsetX: -15,
                style: {
                    colors: '#fb3335',
                    fontSize: '11px',
                    fontFamily: "Open Sans",
                    fontWeight: 100,
                    cssClass: 'apexcharts-yaxis-label',
                },
                formatter: function (y: any, ind: any) {
                    return isShowReversedY ? (y > 11 ? '^' : (y === 0 ? '~' : 11 - y)) : formatNumberWithCommas(y);
                }
            },
        },
        fill: {
            opacity: fillOpacity,
            colors: fillColor,
            ...(fillOpacity === 0 ? { type: 'solid' } : {})
        },
        tooltip: {
            enabled: isShowTooltip,
            theme: isDark ? 'dark' : 'light',
            x: {
                show: false
            },
            y: {
                // show: false,
                formatter: function (val: any, ind: any) {
                    return isShowReversedY ? (val === 0 ? '~' : (values[ind?.dataPointIndex])) : formatNumberWithCommas(val);
                }
            },
            fillSeriesColor: false,
            marker: {
                show: true,
            },
            style: {
                fontSize: '0.7rem',
                fontFamily: "NanumSquare",
                fontWeight: 100,
            },
        },

    }
    return (
        <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={height}
            style={style}
        />
    );
};

export default React.memo(RankingChart);