import { IUser } from 'api/types/_user';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { STATUS_USER_OPTIONS } from 'helpers/constans';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { deleteUser, getUsers as onGetUsers, putUser } from "../../../store/thunks";
import UserForm from './UserForm';
import { Option } from 'api/types/_public';
import DropdownStatus from 'components/Common/DropdownStatus';
import { formatNumberWithCommas } from 'helpers/format';
export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);


const UserList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const STATUS_USER_OPTIONS_LANG: any = STATUS_USER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    name: withDefault(StringParam, ''),
    email: withDefault(StringParam, ''),
    company: withDefault(StringParam, ''),
    is_active: withDefault(StringParam, ''),
  });
  const [nameSearch, setNameSearch] = useState<string>(query?.name || "");
  const [emailSearch, setEmailSearch] = useState<string>(query?.email || "");
  const [companySearch, setCompanySearch] = useState<string>(query?.company || "");
  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_USER_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(query?.is_active)) || null);


  const [userEdit, setUserEdit] = useState<IUser & { isCopy?: boolean } | null>(null);

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<IUser | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.User;

  const UserProperties = createSelector(
    selectLayoutState,
    (state) => ({
      users: state.users,
      isUserSuccess: state.isUserSuccess,
      isUserLoading: state.isUserLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { users, error, isUserLoading } = useSelector(UserProperties);


  useEffect(() => {
    dispatch(onGetUsers(query));
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      name: nameSearch || '',
      email: emailSearch || '',
      company: companySearch || '',
      is_active: statusSearch?.value,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      name: '',
      email: '',
      company: '',
      is_active: '',
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date(),
      page: 1,
    }, "push")
    setNameSearch((_prev) => "");
    setEmailSearch((_prev) => "");
    setCompanySearch((_prev) => "");
    setStatusSearch(null);
  };


  // Begin::Update User
  const onCloseClick = () => {
    setUserEdit((_prev) => null);
    setIsOpen((_prev) => false)
  }

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(onGetUsers(query));
  }
  //End::Update User

  // Begin::Delete
  const handleConfirmDelete = (item: IUser) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteUser(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetUsers(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('ID'),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span style={{ minWidth: '50px' }}>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Status'),
        accessor: "is_active",
        filterable: false,
        sortable: false,
        thClass: 'text-center',
        thWidth: 100,
        Cell: (cell: any) => {
          return (
            <div className="text-center" style={{ minWidth: '80px' }}>
              <span className={`rounded-pill badge bg-${['danger', 'success'][cell?.value] || 'secondary'}`}>{STATUS_USER_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
            </div>
          );
        }
      },
      {
        Header: t('Name'),
        accessor: "name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '100px' }}>
              {item?.name || '-'}
            </div>
          </>)
        },
      },
      {
        Header: t('Email'),
        accessor: "email",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '100px' }}>{cell?.value || '-'}</div>
          </>)
        },
      },
      {
        Header: t('Company'),
        accessor: "company",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '100px' }}>{cell?.value || '-'}</div>
          </>)
        },
      },
      {
        Header: t('Phone'),
        accessor: "phone_number",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '100px' }}>{cell?.value || '-'}</div>
          </>)
        },
      },
      {
        Header: t('Domain'),
        accessor: "domain",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '100px' }}>{cell?.value || '-'}</div>
          </>)
        },
      },
      {
        Header: t('Usage restrictions'),
        accessor: "news_daily_limit",
        filterable: true,
        sortable: false,
        description: t('Daily article limit'),
        thClass: 'text-center',
        thWidth: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '140px' }} className="text-center">{formatNumberWithCommas(item?.configs?.news_daily_limit)}</div>
          </>)
        },
      },
      {
        Header: t('Number Of Login'),
        accessor: "login_count",
        filterable: true,
        sortable: true,
        thWidth: 160,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-center" style={{ minWidth: '140px' }}>
              {formatNumberWithCommas(cell?.value)}
            </div>
          )
        },
      },
      {
        Header: t('Last Login Time'),
        accessor: "last_login_at",
        filterable: true,
        sortable: true,
        thWidth: 150,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end pe-3" style={{ minWidth: '130px' }}>
              <span><span>{arrDate?.[0] || '-'}</span> <br /> <span className="text-secondary">{arrDate?.[1] || '-'}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 110,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 110,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Action'),
        thClass: 'text-center',
        thWidth: 100,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0" style={{ minWidth: '70px' }}>
              {isHavePermissionRole(ROLES_FOR_APP.USER_UPDATE, userPermissions) && <TooltipCustom
                title={t('Button Update User')}
                id={`update-us-${item?.id}`}
              >
                <li className=" list-inline-item me-0" id={`update-us-${item?.id}`}>
                  <Link className="btn btn-sm btn-soft-primary edit-item-btn" to="#"
                    onClick={(e) => { e.preventDefault(); setUserEdit((prev) => item) }}
                  >
                    <i className="ri-pencil-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>}
              {isHavePermissionRole(ROLES_FOR_APP.USER_DELETE, userPermissions) && (
                <TooltipCustom
                  title={t('Button Delete User')}
                  id={`detail-cp-${item?.id}`}
                >
                  <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                    >
                      <i className="ri-delete-bin-3-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, query, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {

    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t('Home')} - ${t('User Management')} | PRESS Ai`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('User Management')} pageTitle={t('Home')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.USER_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-5 con-sm-6">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            <CountUp
                              start={0}
                              end={users?.pagination?.total || 0}
                              duration={1}
                              className="text-primary"
                            />
                          </h5>
                        </div>
                      </div>
                      <div className="col-7 con-sm-6 text-end">
                        {isHavePermissionRole(ROLES_FOR_APP.USER_CREATE, userPermissions) && (<div className="col-sm-auto">
                          <div>
                            <button
                              type="button"
                              className="btn btn-success add-btn rounded-pill"
                              id="create-btn"
                              onClick={() => setIsOpen((_prev) => true)}
                            >
                              <i className="ri-add-line align-bottom me-1"></i>
                              {t('Button Create User')}
                            </button>
                          </div>
                        </div>)}
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={6} md={6} xl={2} xxl={2} className='mt-3 mt-md-2'>
                        <LabelWrapper label={t('Name')} isShow={!!nameSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Name')}...`}
                            value={nameSearch}
                            onChange={(e) => setNameSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} xl={2} xxl={2} className='mt-3 mt-md-2'>
                        <LabelWrapper label={t('Email')} isShow={!!emailSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Email')}...`}
                            value={emailSearch}
                            onChange={(e) => setEmailSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={12} xl={2} xxl={2} className='mt-3 mt-md-2'>
                        <LabelWrapper label={t('Company')} isShow={!!companySearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Company')}...`}
                            value={companySearch}
                            onChange={(e) => setCompanySearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={12} xl={2} xxl={2} className='mt-3 mt-md-2'>
                        <LabelWrapper label={t('Status')} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_USER_OPTIONS_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded z-hight"
                            classNamePrefix="name-prefix"
                            initialValue={statusSearch || null}
                            onChangeSelect={(e: any) => setStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All'), value: '' }}
                            colors={['danger', 'success']}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={4} xxl={4} className="hstack gap-1 justify-content-center justify-content-sm-end justify-content-md-between mt-3 mt-md-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isUserLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={users?.list?.length ? users?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={users?.pagination?.total}
                      customPageCount={Math.ceil(Number(users?.pagination?.total) / Number(users?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isUserLoading}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}</Row>
        </Container>
        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />
        <Modal
          isOpen={!!userEdit?.id || isOpen}
          centered={true}
          size="lg"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseClick}>
            <>{!!(userEdit?.id) ? t("Button Update User") : t("Button Create User")}</>  <>{(userEdit?.id) && (' - ' + (userEdit?.name || '') || '')}</>
          </ModalHeader>
          <ModalBody className="pt-2">
            <div style={{ minHeight: "calc(100dvh - 300px)" }}>
              <UserForm
                isModal={true}
                id={String(userEdit?.id || '')}
                isCopy={!!(userEdit?.isCopy)}
                triggerRefresh={triggerRefresh}
              />
            </div>
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t('Button Delete User')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div >
    </React.Fragment >
  );
};

export default UserList;