import { ISection } from "api/types/_master";
import { ICondition, INewsGenerate } from "api/types/_news";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_INFO, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import { AI_MODEL_OPTIONS, NEWS_GENERATE_REQUEST_SOURCE_TYPE, NEWS_GENERATE_REQUEST_STATUS, SYSTEM_CONFIG_KEY } from "helpers/constans";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Badge, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { getKeywordGenerateNews, postKeywordGenerateNews, putConfiguration, putKeywordReGenerateNews, putSectionPrompt } from "store/thunks";
import HighlightText from "../Components/HighlightText";
import SpinnerScreen from "../Components/SpinnerScreen";
import Hashtag from "components/Common/Hashtag";
import TooltipCustom from "components/Common/TooltipCustom";

export interface Props {
    isOpen?: boolean;
    detail?: INewsGenerate | null,
    sections?: ISection[],
    sourceType: NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI | NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS | NEWS_GENERATE_REQUEST_SOURCE_TYPE.GENERAL,
    onClose?: () => void
    triggerRefresh?: (type: string) => void;
}

const ModalGenerateNews = ({
    isOpen = false,
    detail = null,
    sections = [],
    sourceType = NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS,
    onClose,
    triggerRefresh
}: Props) => {
    const { t, i18n } = useTranslation();

    const AI_MODEL_OPTIONS_LANG: any = AI_MODEL_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [sectionOptions, setSectionOptions] = useState<Option[]>([]);

    const [newsDetail, setNewsDetail] = useState<INewsGenerate | null>(null);
    const [condition, setCondition] = useState<ICondition | null>(null);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [isInterval, setIsInterval] = useState<boolean>(false);

    const [isReGenerating, setIsReGenerating] = useState<boolean>(false);

    const [isSavePrompt, setIsSavePrompt] = useState<boolean>(false);

    const [typeShowInfoRight, setTypeShowInfoRight] = useState<'SOURCE' | 'PROMPT'>((sourceType === NEWS_GENERATE_REQUEST_SOURCE_TYPE.GENERAL ? 'PROMPT' : 'SOURCE'));

    const onCloseClick = () => {
        setIsInterval((_prev) => false);
        setIsGenerating((_prev) => false);
        onClose && onClose();
    }

    const handleSetNewsDetail = (detail: INewsGenerate | null) => {
        setNewsDetail((_prev) => detail);
        setCondition((_prev: any) => ({ ..._prev, ...detail?.condition }));
    }

    const handleGenerating = async () => {
        try {
            setIsGenerating((_prev) => true);

            const request = {
                keyword: condition?.keyword || '',
                ...(
                    [NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS, NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI]?.includes(sourceType) ? {
                        keyword_sub: condition?.keyword_sub || '',

                        count_title: String(condition?.count_title) !== '' ? Number(condition?.count_title || 0) : null,
                        count_body: String(condition?.count_body) !== '' ? Number(condition?.count_body || 0) : null,
                        count_content: String(condition?.count_content) !== '' ? Number(condition?.count_content || 0) : null,

                        sub_count_title: String(condition?.sub_count_title) !== '' ? Number(condition?.sub_count_title || 0) : null,
                        sub_count_body: String(condition?.sub_count_body) !== '' ? Number(condition?.sub_count_body || 0) : null,
                        sub_count_content: String(condition?.sub_count_content) !== '' ? Number(condition?.sub_count_content || 0) : null,

                        source_count: Number(condition?.source_count || 0),
                        total_news: Number(condition?.total_news || 0),

                        source_max: sourceType === NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS ? Number(condition?.source_max || 0) : null,

                        source_date_from: `${moment().subtract(Number(condition?.source_date || 0) <= 0 ? 0 : (Number(condition?.source_date || 0)), 'days').format("Y-MM-DD")}`,
                        source_date_to: `${moment().format("Y-MM-DD")}`,

                    } : {}
                ),

                prompt_format: condition?.prompt_format || '',

                section_prompt_format: condition?.section_prompt_format || '',

                section_prompt_config_type: condition?.section ? Number(String(condition?.section)?.split('=')[0]) : '',
                section_prompt_config_key: condition?.section ? String(condition?.section)?.split('=')[1] : '',

                source_type: sourceType,

                ai_model: condition?.ai_model || AI_MODEL_OPTIONS_LANG[1]?.value,

                exclude_keywords: condition?.exclude_keywords || []
            };

            const response: any = await postKeywordGenerateNews(request);
            if (response?.code === 200) {
                setCondition((_prev: any) => ({ ..._prev, id: response?.data?.id }));
                setIsInterval((_prev) => true);
                triggerRefresh && triggerRefresh('ONLY_LIST_NEWS');
            } else {
                setIsGenerating((_prev) => false);
                toast(`${response || ""}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsGenerating((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const formatResultDetail = (item: any, option: any = { id: '' }) => {
        const _condition = {
            id: item?.id,
            keyword: item?.keyword,
            keyword_sub: item?.keyword_sub,
            count_title: item?.count_title,
            count_body: item?.count_body,
            count_content: item?.count_content,
            sub_count_title: item?.sub_count_title,
            sub_count_body: item?.sub_count_body,
            sub_count_content: item?.sub_count_content,
            source_count: item?.source_count,
            source_max: item?.source_max,
            source_date: 1,
            source_date_from: item?.source_date_from,
            source_date_to: item?.source_date_to,
            source_type: sourceType,
            total_news: item?.total_news,
            // prompt_format: item?.prompt_format || '',
            // section_prompt_format: item?.section_prompt_format || '',
            // section: `${item?.section_prompt_config?.type}=${item?.section_prompt_config?.key}`,
            // exclude_keywords: item?.exclude_keywords || [],
            ai_model: item?.ai_model || AI_MODEL_OPTIONS_LANG[0]?.value,
        };
        return ({ ...item?.results[0], condition: _condition, ...option });
    };

    const handleReGenerating = async () => {
        try {
            if (!condition?.id) {
                return;
            }

            setIsReGenerating((_prev) => true);

            const request = {
                prompt_format: condition?.prompt_format || '',
                section_prompt_format: condition?.section_prompt_format || '',
                ai_model: condition?.ai_model || AI_MODEL_OPTIONS_LANG[1]?.value,
            };

            const response: any = await putKeywordReGenerateNews(`${condition?.id}`, request);
            setIsReGenerating((_prev) => false);
            if (response?.code === 200) {
                const item = formatResultDetail(response?.data, { id: newsDetail?.id || '' });
                handleSetNewsDetail(item);
                toast(t("The process has been completed."), CONFIG_OPTION_TOAST_NORMAL);
                triggerRefresh && triggerRefresh('ONLY_LIST_NEWS');
            } else {
                toast(`${response || ""}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsReGenerating((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const handleSavePrompt = async () => {
        try {
            setIsSavePrompt((_prev) => true);

            let _key_config: string = SYSTEM_CONFIG_KEY.SIMULATOR_GENERAL_COMMON_PROMPT;
            if (sourceType === NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS) {
                _key_config = SYSTEM_CONFIG_KEY.SIMULATOR_NEWS_COMMON_PROMPT;
            }
            if (sourceType === NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI) {
                _key_config = SYSTEM_CONFIG_KEY.SIMULATOR_NAMUWIKI_COMMON_PROMPT;
            }

            const _config_common_prompt = {
                configs: [
                    {
                        key: _key_config,
                        value: condition?.prompt_format || '',
                    }
                ]
            };

            const _config_section_prompt = {
                source_type: sourceType,
                type: condition?.section ? Number(String(condition?.section)?.split('=')[0]) : '',
                key: condition?.section ? String(condition?.section)?.split('=')[1] : '',
                prompt: condition?.section_prompt_format || '',
            };

            const [res1, res2]: any = await Promise.all([putConfiguration(_config_common_prompt), putSectionPrompt(_config_section_prompt)]);

            setIsSavePrompt((_prev) => false);
            if (res1?.code === 200 && res2?.code === 200) {
                toast(t("The process has been completed."), CONFIG_OPTION_TOAST_NORMAL);
                triggerRefresh && triggerRefresh('ONLY_LIST_NEWS');
            } else {
                toast(`${res1 || ""}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsSavePrompt((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };


    useEffect(() => {
        let intervalId: any;
        const fetchDetail = async () => {
            try {
                const response: any = await getKeywordGenerateNews(condition?.id || '');
                // Check if the polling should stop
                if ([
                    NEWS_GENERATE_REQUEST_STATUS?.COMPLETED,
                    NEWS_GENERATE_REQUEST_STATUS.COLLECT_ERROR,
                    NEWS_GENERATE_REQUEST_STATUS.GENERATE_ERROR,
                    NEWS_GENERATE_REQUEST_STATUS.ERROR_NOT_ENOUGH_NEWS,
                    NEWS_GENERATE_REQUEST_STATUS.OTHER_ERROR
                ]?.includes(response.data?.status)) {
                    clearInterval(intervalId);
                    setIsInterval((_prev) => false);
                    setIsGenerating((_prev) => false);
                    const _mess = `${[NEWS_GENERATE_REQUEST_STATUS?.COMPLETED]?.includes(response.data?.status) ? t("The process has been completed.") : t("Failed to collect news matching the conditions.")}`;
                    const _type = [NEWS_GENERATE_REQUEST_STATUS?.COMPLETED]?.includes(response.data?.status) ? CONFIG_OPTION_TOAST_NORMAL : CONFIG_OPTION_TOAST_ERROR;
                    toast(_mess, _type);
                    if (response?.data) {
                        const item = formatResultDetail(response?.data, { id: '' });
                        handleSetNewsDetail(item);
                        triggerRefresh && triggerRefresh('ONLY_LIST_NEWS');
                    }
                }
            } catch (error: any) {
                toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
                clearInterval(intervalId); // Stop polling on error
                setIsInterval((_prev) => false);
            }
        };

        if (!!condition && !!isInterval) {
            intervalId = setInterval(fetchDetail, 3000); // Poll every 3 seconds
            fetchDetail(); // Immediate call on mount
        }

        return () => {
            clearInterval(intervalId);
        };

    }, [JSON.stringify(condition), isInterval]);


    const copyTextToClipboard = useCallback(async (text: string) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);
        } else {
            document.execCommand('copy', true, text);
        }
        toast(`${t('Copied to the clipboard.')}`, CONFIG_OPTION_TOAST_INFO);
    }, []);

    const clickShowInfoRight = () => {
        setTypeShowInfoRight((_prev) => _prev === 'SOURCE' ? 'PROMPT' : 'SOURCE');
    }

    const formatNameSection = (_name: string = '', _type: number) => {
        const _replace_type: string = String(_name).replace('AI News', t('AI News')).replace('Boosting', t('Boosting'));
        const _replace_name: string = String(_replace_type).split(' - ')?.map((n: string) => t(n)).join(' - ');
        return _replace_name;
    };

    useEffect(() => {
        if (!newsDetail?.id && sections?.length) {
            const _section_prompt = sections?.find((s) => (`${s?.type}=${s?.key}` === condition?.section))?.prompt || '';
            setCondition((_prev: any) => ({ ..._prev, section_prompt_format: _section_prompt }));
        }
    }, [condition?.section, JSON.stringify(sections)]);

    const initialValueAIModel = useMemo(() => {
        const initialValue = AI_MODEL_OPTIONS_LANG?.find((md: any) => (md?.value === condition?.ai_model)) || AI_MODEL_OPTIONS_LANG[1];
        return initialValue;
    }, [AI_MODEL_OPTIONS_LANG, condition?.ai_model]);

    const initialValueSection = useMemo(() => {
        const initialValue = sectionOptions?.find((md: any) => (md?.value === condition?.section)) || sectionOptions[0];
        return initialValue;
    }, [sectionOptions, condition?.section]);

    const initialValueKeywords = useMemo(() => {
        const initialValue = (condition?.exclude_keywords || detail?.condition?.exclude_keywords || [])?.map((s: string) => ({ id: s, text: s }));
        return initialValue;
    }, [detail?.condition?.exclude_keywords, condition?.exclude_keywords]);

    const titleModal = useMemo(() => {
        if (sourceType === NEWS_GENERATE_REQUEST_SOURCE_TYPE.GENERAL) {
            return t('General Simulator');
        }

        if (sourceType === NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI) {
            return t('Namuwiki Simulator');
        }

        if (sourceType === NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS) {
            return t('News Simulator');
        }

        return '';
    }, [sourceType]);

    const titleSource = useMemo(() => {
        if (sourceType === NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI) {
            return t('Source Contents');
        }
        return t('Source Articles');
    }, [sourceType]);


    useEffect(() => {
        if (!!detail) {
            handleSetNewsDetail(detail);
        }
        return () => {
            setCondition((_prev) => null);
            setTypeShowInfoRight((_prev: any) => (sourceType === NEWS_GENERATE_REQUEST_SOURCE_TYPE.GENERAL ? 'PROMPT' : 'SOURCE'));
        }
    }, [detail]);

    useEffect(() => {
        const options = sections?.map((item) => ({ label: formatNameSection(item?.name, item?.type), value: `${item?.type}=${item?.key}` }));
        setSectionOptions((_prev) => options);
    }, [JSON.stringify(sections), i18n?.language]);

    const refLeft = useRef<any>(null);
    const refRight = useRef<any>(null);
    const [position, setPosition] = useState<any>({ left1: 20, left2: 30, top1: 20, top2: 20 });

    useEffect(() => {
        const getPosition = () => {
            if (refLeft.current && refRight.current) {
                const leftRect = refLeft.current.getBoundingClientRect();
                const rightRect = refRight.current.getBoundingClientRect();
                setPosition((_prev: any) => ({ left1: leftRect.left, left2: rightRect.left, top1: leftRect.top, top2: rightRect.top }));
            }
        };

        // Timeout to ensure UI has rendered
        const timeoutId = setTimeout(() => {
            // Get position on mount
            getPosition();
        }, 0);

        // Optionally, track changes on window resize
        window.addEventListener("resize", getPosition);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener("resize", getPosition);
        };
    }, [JSON.stringify(newsDetail)]);

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                centered={true}
                size="xl"
                className={`border-header-footer modal-fullscreen`}
                scrollable={false}
                toggle={onCloseClick}
                keyboard={true}
            >
                <ModalHeader toggle={onCloseClick} className="modal-title-flex-grow-1">
                    <div className="d-flex flex-column gap-0 gap-sm-2 fs-18 fs-md-20">
                        <div className="flex-grow-1">
                            {titleModal} <span className="mx-1">{`>`}</span>  {!!newsDetail?.id ? t('AI News') : (
                                <>{t('Button Create Generate News')}</>)} : <span style={{ backgroundColor: '#ffff00' }}>{newsDetail?.keyword || ''}</span> {!!newsDetail?.keyword_sub && <> - <span style={{ backgroundColor: '#a8d2fb' }}>{newsDetail?.keyword_sub || ''}</span></>}
                        </div>
                        {!!newsDetail?.id ? (
                            <div className="d-flex flex-wrap fs-14">
                                <div className="me-2">
                                    <span style={{ backgroundColor: '#ffff00' }}>{newsDetail?.keyword || ''}</span>
                                </div>
                                {[NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS, NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI]?.includes(sourceType) && <>
                                    <div className="me-2 fw-normal">
                                        {t('Title')}: <span className="fw-bold">{newsDetail?.condition?.count_title ?? '-'}</span>
                                    </div>
                                    <div className="me-2 fw-normal">
                                        {t('Body')}: <span className="fw-bold">{newsDetail?.condition?.count_body ?? '-'}</span>
                                    </div>
                                    <div className="fw-normal">
                                        {t('Title + Body')}: <span className="fw-bold">{newsDetail?.condition?.count_content ?? '-'}</span>
                                    </div>
                                    <div className="mx-2 text-danger">|</div>
                                    <div className="me-2">
                                        <span style={{ backgroundColor: '#a8d2fb' }}>{newsDetail?.keyword_sub || ''}</span>
                                    </div>

                                    <div className="me-2 fw-normal">
                                        {t('Title')}: <span className="fw-bold">{newsDetail?.condition?.sub_count_title ?? '-'}</span>
                                    </div>
                                    <div className="me-2 fw-normal">
                                        {t('Body')}: <span className="fw-bold">{newsDetail?.condition?.sub_count_body ?? '-'}</span>
                                    </div>
                                    <div className="fw-normal">
                                        {t('Title + Body')}: <span className="fw-bold">{newsDetail?.condition?.sub_count_content ?? '-'}</span>
                                    </div>
                                </>}
                                {[NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS, NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI]?.includes(sourceType) && <>
                                    <div className="mx-2 text-danger">|</div>
                                    <div className="me-2 fw-normal">
                                        {titleSource}: <span className="fw-bold">{newsDetail?.condition?.source_count ?? '-'}</span>
                                    </div>
                                </>}

                                {[NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS]?.includes(sourceType) && <>
                                    <div className="me-2 fw-normal">
                                        {t('News Articles')}: <span className="fw-bold">{newsDetail?.condition?.total_news ?? '-'}</span>
                                    </div>
                                    <div className="me-2 fw-normal">
                                        {t('Max Articles')}: <span className="fw-bold">{newsDetail?.condition?.source_max ?? '-'}</span>
                                    </div>
                                </>}
                                <div className="mx-2 ms-0 text-danger">|</div>
                                <div className="me-2 fw-normal">
                                    {t('AI Model')}: <span className="fw-bold">{(AI_MODEL_OPTIONS_LANG?.find((md: any) => (md?.value === newsDetail?.condition?.ai_model)) || AI_MODEL_OPTIONS_LANG[1])?.label || '-'}</span>
                                </div>
                                <div className="mx-2 ms-0 text-danger">|</div>
                                <div className="me-2 fw-normal">
                                    {t('Section')}: <span className="fw-bold">{sectionOptions?.find((md: any) => (md?.value === newsDetail?.condition?.section))?.label || '-'}</span>
                                </div>
                            </div>
                        ) : (<div className="d-flex flex-wrap fs-14">
                            <div className="me-2">
                                <span style={{ backgroundColor: '#ffff00' }}>
                                    <LabelWrapper label={t('Keyword')} isRequired={true} isShow={true}>
                                        <Input
                                            name="text"
                                            type="text"
                                            className={`form-control mb-3 mb-lg-2 mb-xxl-1 ${condition?.keyword === '' ? 'border-danger' : ''}`}
                                            placeholder=""
                                            style={{ width: [NEWS_GENERATE_REQUEST_SOURCE_TYPE.GENERAL]?.includes(sourceType) ? '180px' : '100px' }}
                                            value={condition?.keyword}
                                            onChange={(event: any) => {
                                                const text = event.target.value;
                                                setCondition((_prev: any) => ({ ..._prev, keyword: text }))
                                            }}
                                            autoComplete="off"
                                        />
                                    </LabelWrapper>
                                </span>
                            </div>
                            {[NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS, NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI]?.includes(sourceType) && <>
                                <div className="me-2 fw-normal">
                                    <LabelWrapper label={t('Title')} isShow={true}>
                                        <Input
                                            name="text"
                                            type="text"
                                            className="form-control mb-3 mb-lg-2 mb-xxl-1"
                                            placeholder=""
                                            style={{ width: '80px' }}
                                            value={condition?.count_title}
                                            onChange={(event: any) => {
                                                const input = event.target.value;
                                                const onlyNumbers = String(input).replace(/\D/g, '');
                                                setCondition((_prev: any) => ({ ..._prev, count_title: onlyNumbers }))
                                            }}
                                            autoComplete="off"
                                        />
                                    </LabelWrapper>
                                </div>
                                <div className="me-2 fw-normal">
                                    <LabelWrapper label={t('Body')} isShow={true}>
                                        <Input
                                            name="text"
                                            type="text"
                                            className="form-control mb-3 mb-lg-2 mb-xxl-1"
                                            placeholder=""
                                            style={{ width: '80px' }}
                                            value={condition?.count_body}
                                            onChange={(event: any) => {
                                                const input = event.target.value;
                                                const onlyNumbers = String(input).replace(/\D/g, '');
                                                setCondition((_prev: any) => ({ ..._prev, count_body: onlyNumbers }))
                                            }}
                                            autoComplete="off"
                                        />
                                    </LabelWrapper>
                                </div>
                                <div className="fw-normal">
                                    <LabelWrapper label={t('Title + Body')} isShow={true}>
                                        <Input
                                            name="text"
                                            type="text"
                                            className="form-control mb-3 mb-lg-2 mb-xxl-1"
                                            placeholder=""
                                            style={{ width: '90px' }}
                                            value={condition?.count_content}
                                            onChange={(event: any) => {
                                                const input = event.target.value;
                                                const onlyNumbers = String(input).replace(/\D/g, '');
                                                setCondition((_prev: any) => ({ ..._prev, count_content: onlyNumbers }))
                                            }}
                                            autoComplete="off"
                                        />
                                    </LabelWrapper>
                                </div>
                                <div className="mx-2 text-danger align-self-center mb-3 mb-lg-2 mb-xxl-1">|</div>
                                <div className="me-2">
                                    <span style={{ backgroundColor: '#a8d2fb' }}>
                                        <LabelWrapper label={t('Sub Keyword')} isRequired={false} isShow={true}>
                                            <Input
                                                name="text"
                                                type="text"
                                                className={`form-control mb-3 mb-lg-2 mb-xxl-1`}
                                                placeholder=""
                                                style={{ width: '110px' }}
                                                value={condition?.keyword_sub}
                                                onChange={(event: any) => {
                                                    const text = event.target.value;
                                                    setCondition((_prev: any) => ({ ..._prev, keyword_sub: text }))
                                                }}
                                                autoComplete="off"
                                            />
                                        </LabelWrapper>
                                    </span>
                                </div>
                                <div className="me-2 fw-normal">
                                    <LabelWrapper label={t('Title')} isShow={true}>
                                        <Input
                                            name="text"
                                            type="text"
                                            className="form-control mb-3 mb-lg-2 mb-xxl-1"
                                            placeholder=""
                                            style={{ width: '80px' }}
                                            value={condition?.sub_count_title}
                                            onChange={(event: any) => {
                                                const input = event.target.value;
                                                const onlyNumbers = String(input).replace(/\D/g, '');
                                                setCondition((_prev: any) => ({ ..._prev, sub_count_title: onlyNumbers }))
                                            }}
                                            autoComplete="off"
                                        />
                                    </LabelWrapper>
                                </div>
                                <div className="me-2 fw-normal">
                                    <LabelWrapper label={t('Body')} isShow={true}>
                                        <Input
                                            name="text"
                                            type="text"
                                            className="form-control mb-3 mb-lg-2 mb-xxl-1"
                                            placeholder=""
                                            style={{ width: '80px' }}
                                            value={condition?.sub_count_body}
                                            onChange={(event: any) => {
                                                const input = event.target.value;
                                                const onlyNumbers = String(input).replace(/\D/g, '');
                                                setCondition((_prev: any) => ({ ..._prev, sub_count_body: onlyNumbers }))
                                            }}
                                            autoComplete="off"
                                        />
                                    </LabelWrapper>
                                </div>
                                <div className="fw-normal">
                                    <LabelWrapper label={t('Title + Body')} isShow={true}>
                                        <Input
                                            name="text"
                                            type="text"
                                            className="form-control mb-3 mb-lg-2 mb-xxl-1"
                                            placeholder=""
                                            style={{ width: '90px' }}
                                            value={condition?.sub_count_content}
                                            onChange={(event: any) => {
                                                const input = event.target.value;
                                                const onlyNumbers = String(input).replace(/\D/g, '');
                                                setCondition((_prev: any) => ({ ..._prev, sub_count_content: onlyNumbers }))
                                            }}
                                            autoComplete="off"
                                        />
                                    </LabelWrapper>
                                </div>
                            </>}
                            {[NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS, NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI]?.includes(sourceType) && <>
                                <div className="mx-2 text-danger align-self-center mb-3 mb-lg-2 mb-xxl-1">|</div>
                                <div className="me-2 fw-normal">
                                    <LabelWrapper label={sourceType === NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI ? t('Source Contents') : t('Source Articles')} isShow={true}>
                                        <Input
                                            name="text"
                                            type="text"
                                            className="form-control mb-3 mb-lg-2 mb-xxl-1"
                                            placeholder=""
                                            style={{ width: '110px' }}
                                            value={condition?.source_count}
                                            onChange={(event: any) => {
                                                const input = event.target.value;
                                                const onlyNumbers = String(input).replace(/\D/g, '');
                                                setCondition((_prev: any) => ({ ..._prev, source_count: onlyNumbers }))
                                            }}
                                            autoComplete="off"
                                        />
                                    </LabelWrapper>
                                </div>
                            </>}
                            {[NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS]?.includes(sourceType) && <>
                                <div className="me-2 fw-normal">
                                    <LabelWrapper label={t('Max Articles')} isShow={true}>
                                        <Input
                                            name="text"
                                            type="text"
                                            className="form-control mb-3 mb-lg-2 mb-xxl-1"
                                            placeholder=""
                                            style={{ width: '110px' }}
                                            value={condition?.source_max}
                                            onChange={(event: any) => {
                                                const input = event.target.value;
                                                const onlyNumbers = String(input).replace(/\D/g, '');
                                                setCondition((_prev: any) => ({ ..._prev, source_max: onlyNumbers }))
                                            }}
                                            autoComplete="off"
                                        />
                                    </LabelWrapper>
                                </div>
                            </>}
                            <div className="mx-2 ms-0 text-danger align-self-center mb-3 mb-lg-2 mb-xxl-1">|</div>
                            <div className="me-2 fw-normal">
                                <LabelWrapper label={t('AI Model')} isShow={true}>
                                    <DropdownOption
                                        name="ai_model"
                                        dataList={AI_MODEL_OPTIONS_LANG || []}
                                        placeholder={`${t("AI Model")}...`}
                                        className={`dropdown-status-rounded z-hight mb-3 mb-lg-2 mb-xxl-1 ${condition?.ai_model}`}
                                        classNamePrefix="name-prefix"
                                        style={{ width: '150px' }}
                                        initialValue={initialValueAIModel}
                                        onChangeSelect={(e: any) => {
                                            setCondition((_prev: any) => ({ ..._prev, ai_model: e?.value }))
                                        }}
                                    />
                                </LabelWrapper>
                            </div>
                            <div className="mx-2 ms-0 text-danger align-self-center mb-3 mb-lg-2 mb-xxl-1">|</div>
                            <div className="me-2 fw-normal">
                                <LabelWrapper label={t('Section')} isShow={true}>
                                    <DropdownOption
                                        name="ai_model"
                                        dataList={sectionOptions || []}
                                        placeholder={`${t("Section")}...`}
                                        className={`dropdown-status-rounded z-hight-100 mb-3 mb-lg-2 mb-xxl-1 ${condition?.section}`}
                                        classNamePrefix="name-prefix"
                                        style={{ width: '200px' }}
                                        initialValue={initialValueSection}
                                        onChangeSelect={(e: any) => {
                                            setCondition((_prev: any) => ({ ..._prev, section: e?.value }))
                                        }}
                                    />
                                </LabelWrapper>
                            </div>
                            <div className="ms-2 fw-normal">
                                <button
                                    className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center mb-3 mb-lg-2 mb-xxl-1"
                                    type="button"
                                    color="light"
                                    onClick={handleGenerating}
                                    disabled={!!isGenerating || !!isReGenerating || !!isSavePrompt}
                                    style={{ width: '150px' }}
                                >
                                    {isGenerating ? <span style={{ transform: 'translateY(1px)' }}><Spinner size="sm"></Spinner></span> : <i className="ri-refresh-line" style={{ lineHeight: '22px' }} />}
                                    <span className="ms-1">{t("Button Create Generate News")}</span>
                                </button>
                            </div>
                        </div>)}
                        <div className="me-2 fw-normal w-100 mt-2 d-flex flex-wrap gap-3 align-items-start">
                            <div className="flex-grow-1" style={{ minWidth: '240px', maxWidth: '250px' }}>
                                <div className="me-2 fw-normal">
                                    <LabelWrapper label={t('Exclusion Keywords for Collection')} description={t("When collecting source contents, articles with titles containing the specified keywords will not be collected.")} isShow={true}>
                                        {/* <TooltipCustom
                                            title={t("When collecting source contents, articles with titles containing the specified keywords will not be collected.")}
                                            className="d-inline-block vertical-align-middle ms-1"
                                            style={{ transform: 'translateY(1px)' }}
                                        >
                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                        </TooltipCustom> */}
                                        {/* {!!newsDetail?.id ?
                                            <div className="live-preview p-2" style={{ maxHeight: "150px", minHeight: '88px', borderRadius: 'var(--vz-border-radius)', border: 'var(--vz-border-width) solid var(--vz-input-border-custom)' }}>
                                                {condition?.exclude_keywords?.map((k: string) => (
                                                    <span className="badge bg-primary-subtle text-primary fs-13" key={k}>{k}</span>
                                                ))}
                                            </div> :
                                            */}
                                        <Hashtag
                                            name="keywords"
                                            placeholder=""
                                            suggestions={[]}
                                            initialValue={initialValueKeywords}
                                            onChangeTag={(event: any) => {
                                                const _k = (event || [])?.map((k: any) => (k?.id));
                                                setCondition((_prev: any) => ({ ..._prev, exclude_keywords: _k }))
                                            }}
                                            idSelected="hashtags-CampaignFormMulti fs-14"
                                            maxHashtags={2000}
                                            maxHeight="150px"
                                            allowDeleteFromEmptyInput={!newsDetail?.id}
                                        />

                                        {/* <Input
                                            name="text"
                                            type="textarea"
                                            className={`form-control`}
                                            placeholder=""
                                            rows={3}
                                            style={{ width: '100%', minHeight: '88px' }}
                                            value={condition?.exclude_keywords || ''}
                                            onChange={(event: any) => {
                                                const input = event.target.value;
                                                setCondition((_prev: any) => ({ ..._prev, exclude_keywords: input }))
                                            }}
                                            autoComplete="off"
                                        /> */}
                                    </LabelWrapper>
                                </div>
                            </div>
                            <div className="flex-grow-1" style={{ minWidth: '200px' }}>
                                <LabelWrapper label={t('Common Prompt')} isShow={true}>
                                    <Input
                                        name="text"
                                        type="textarea"
                                        className={`form-control`}
                                        placeholder=""
                                        rows={3}
                                        style={{ width: '100%', minHeight: '88px' }}
                                        value={condition?.prompt_format || ''}
                                        onChange={(event: any) => {
                                            const input = event.target.value;
                                            setCondition((_prev: any) => ({ ..._prev, prompt_format: input }))
                                        }}
                                        autoComplete="off"
                                    />
                                </LabelWrapper>
                                <div className={`w-100 mb-1 mb-xl-0 fs-12 text-muted ${[NEWS_GENERATE_REQUEST_SOURCE_TYPE.GENERAL]?.includes(sourceType) ? 'pt-1' : ''}`}>
                                    {[NEWS_GENERATE_REQUEST_SOURCE_TYPE.GENERAL]?.includes(sourceType) ? <>{t('{keyword} is automatically converted into the entered keyword.')}</> : ''}
                                </div>
                            </div>
                            <div className="flex-grow-1" style={{ minWidth: '200px' }}>
                                <LabelWrapper label={t('Section Prompt')} isShow={true}>
                                    <Input
                                        name="text"
                                        type="textarea"
                                        className={`form-control mb-2 mb-xl-0`}
                                        placeholder=""
                                        rows={3}
                                        style={{ width: '100%', minHeight: '88px' }}
                                        value={condition?.section_prompt_format || ''}
                                        onChange={(event: any) => {
                                            const input = event.target.value;
                                            setCondition((_prev: any) => ({ ..._prev, section_prompt_format: input }))
                                        }}
                                        autoComplete="off"
                                    />
                                </LabelWrapper>
                            </div>

                            <ul className={`d-flex flex-row flex-md-column gap-0 gap-xl-2 mb-0 ps-0 align-self-end box-button-render-news ${[NEWS_GENERATE_REQUEST_SOURCE_TYPE.GENERAL]?.includes(sourceType) ? 'pb-22' : ''}`}>
                                {(!!condition?.id && !isGenerating) && <li className="list-inline-item" id={`re-generate-button`}>
                                    <button
                                        className="btn btn-soft-primary fs-14 px-1 d-flex justify-content-center align-item-center mb-2 mb-xl-0"
                                        type="button"
                                        color="light"
                                        onClick={handleReGenerating}
                                        disabled={(!!isGenerating || !!isReGenerating || !!isSavePrompt)}
                                        style={{ width: '150px' }}
                                    >
                                        {(!!isGenerating || !!isReGenerating) ? <span style={{ transform: 'translateY(1px)' }}><Spinner size="sm"></Spinner></span> : <i className="ri-refresh-line" style={{ lineHeight: '22px' }} />}
                                        <span className="ms-1">{t('Button Create Re Generate News')}</span>
                                    </button>
                                </li>}
                                <li className="list-inline-item" id={`save-prompt-button`}>
                                    <button
                                        className="btn btn-soft-primary fs-14 px-1 d-flex justify-content-center align-item-center mb-2 mb-xl-0"
                                        type="button"
                                        color="light"
                                        onClick={handleSavePrompt}
                                        disabled={(!!isGenerating || !!isReGenerating || !!isSavePrompt)}
                                        style={{ width: '150px' }}
                                    >
                                        {(!!isSavePrompt) ? <span style={{ transform: 'translateY(1px)' }}><Spinner size="sm"></Spinner></span> : <i className="ri-login-circle-line" style={{ lineHeight: '22px' }} />}
                                        <span className="ms-1">{t('Button Save Prompt')}</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className="position-relative">
                    <span className="position-absolute text-success fs-13 text-end px-2 d-none d-lg-block" style={{
                        top: position?.top1,
                        left: position?.left1 + 10,
                        transform: `translateY(-${position?.top1 - 10}px)`,
                        zIndex: 10,
                        backgroundColor: 'var(--vz-secondary-bg)'
                    }}>
                        {t('Generated News')}
                    </span>
                    {(!!newsDetail?.condition?.id) && <span className="position-absolute text-primary fs-13 text-end px-2 d-none d-lg-block" style={{
                        top: position?.top2,
                        left: position?.left2 + 10,
                        transform: `translateY(-${position?.top2 - 10}px)`,
                        zIndex: 10,
                        backgroundColor: 'var(--vz-secondary-bg)'
                    }}>
                        {typeShowInfoRight === 'SOURCE' ? t('Source') : t('Prompt')}
                    </span>}
                    {[NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS, NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI]?.includes(sourceType) && <span className="position-absolute text-primary fs-13 text-end px-2 d-none d-lg-block" style={{
                        top: position?.top2,
                        left: position?.left2 + 10,
                        transform: `translateY(-${position?.top2 - 10}px)`,
                        zIndex: 10,
                        backgroundColor: 'var(--vz-secondary-bg)'
                    }}>
                        {typeShowInfoRight === 'SOURCE' ? t('Source') : t('Prompt')}
                    </span>}
                    <div className="h-100-pc w-100 overflow-x-hidden">
                        <Row className="w-100-pc px-0 h-100-pc">
                            <Col lg={([NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS, NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI]?.includes(sourceType) || !!newsDetail?.condition?.id) ? 8 : 12} className={`h-100-pc mt-2 mt-md-0 ${([NEWS_GENERATE_REQUEST_SOURCE_TYPE.GENERAL]?.includes(sourceType) && !newsDetail?.condition?.id) ? 'pe-0' : ''}`}>
                                <span className="position-absolute text-success fs-13 text-end px-2 d-block d-lg-none" style={{
                                    top: 0,
                                    left: 5,
                                    transform: `translateY(-${10}px)`,
                                    zIndex: 10,
                                    backgroundColor: 'var(--vz-secondary-bg)'
                                }}>
                                    {t('Generated News')}
                                </span>
                                <div ref={refLeft} className="inner-modal-body color-scrollbar-os position-relative " style={{ border: '1.5px dashed var(--vz-success)', minHeight: '50dvh' }}>
                                    <span className="d-flex align-items-center text-success position-absolute fs-13" style={{ top: 5, right: 10 }}>
                                        {!!newsDetail?.title && <span className="text-primary d-flex align-items-center cursor-pointer me-3" onClick={() => copyTextToClipboard(`${newsDetail?.title || ''}\n\n${newsDetail?.body}`)}><i className="ri ri-file-copy-line" style={{ transform: 'translateY(1px)' }} /> {t('Copy')}</span>}
                                    </span>
                                    <div className="p-2">
                                        {(!!isGenerating || !!isReGenerating) ? <SpinnerScreen zIndex={9} isShow={true} /> : (
                                            <><p className="mb-3 fs-22 fs-md-23" style={{ lineHeight: '170%', fontWeight: 600 }}>
                                                <HighlightText text={newsDetail?.title || ''} keyword={newsDetail?.keyword || ''} sub_keyword={newsDetail?.keyword_sub} />
                                            </p>
                                                <p className="mb-3 mt-3 fs-17 fs-md-18" style={{ whiteSpace: 'pre-line', lineHeight: '170%' }}>
                                                    <HighlightText text={newsDetail?.body || ''} keyword={newsDetail?.keyword || ''} sub_keyword={newsDetail?.keyword_sub} />
                                                </p></>)}
                                    </div>
                                </div>
                            </Col>
                            {([NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS, NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI]?.includes(sourceType) || !!newsDetail?.condition?.id) &&
                                <Col lg={4} className="h-100-pc ms-1 ms-lg-0 pe-3 me-lg-0 mt-4  mt-md-0">
                                    <span className="position-absolute text-primary fs-13 text-end px-2 d-block d-lg-none" style={{
                                        top: 0,
                                        left: 5,
                                        transform: `translateY(-${10}px)`,
                                        zIndex: 10,
                                        backgroundColor: 'var(--vz-secondary-bg)'
                                    }}>
                                        {typeShowInfoRight === 'SOURCE' ? t('Source') : t('Prompt')}
                                    </span>
                                    <div className="row h-100-pc align-content-between gap-3" ref={refRight} >
                                        {(!!isGenerating) ? <SpinnerScreen zIndex={9} isShow={true} isShowIcon={!!isGenerating} style={{ border: '1.5px dashed var(--vz-primary)' }} /> : (
                                            (typeShowInfoRight === 'SOURCE') ? <>
                                                {newsDetail?.sources?.map((item, index) => {
                                                    return (
                                                        <div key={index} className={`color-scrollbar-os position-relative box-source-news chill-${Number(newsDetail?.sources?.length || 0)}`} >
                                                            <span className="d-flex align-items-center text-primary position-absolute fs-13" style={{ top: 5, right: 10 }}>
                                                                {!!item?.title && <span className="text-success d-flex align-items-center cursor-pointer me-3" onClick={() => copyTextToClipboard(`${item?.title || ''}\n\n${item?.body}`)}><i className="ri ri-file-copy-line" style={{ transform: 'translateY(1px)' }} />{t('Copy')}</span>}
                                                                <Link
                                                                    className="nav-link cursor-pointer hover-underline-primary me-3"
                                                                    target="_blank"
                                                                    to={item?.url}
                                                                >
                                                                    <i className="ri ri-links-line" style={{ transform: 'translateY(-1px)' }} />{(sourceType === NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI) ? t('Namuwiki Link') : t('Source Link')}
                                                                </Link>
                                                                {sourceType === NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI ? t('Source News Contents') : t('Source News')} {index + 1}
                                                            </span>
                                                            <div className="p-2 px-0">
                                                                <div className="d-flex flex-wrap align-items-start gap-2 mt-2 pt-2 mb-1" style={{ borderBottom: '1px dashed #d0caca', paddingBottom: '4px' }}>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 fs-14 fs-md-16">
                                                                            {t('Main Keyword Frequency')}:
                                                                        </div>
                                                                        <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                                                                            <span className="fw-medium">{Number(item?.count_title || 0) + Number(item?.count_body || 0)}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 fs-14 fs-md-16">
                                                                            {t('Sub Keyword Frequency')}:
                                                                        </div>
                                                                        <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                                                                            <span className="fw-medium">{Number(item?.sub_count_title || 0) + Number(item?.sub_count_body || 0)}</span>
                                                                        </div>
                                                                    </div>
                                                                    {!!item?.publish_time && <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 fs-14 fs-md-16">
                                                                            {t('Date')}:
                                                                        </div>
                                                                        <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                                                                            <span className="fw-medium">{item?.publish_time || ''}</span>
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                                <p className="mb-3 fs-20 fs-md-18" style={{ lineHeight: '170%', fontWeight: 600 }}>
                                                                    <HighlightText text={item?.title || ''} keyword={newsDetail?.keyword || ''} sub_keyword={newsDetail?.keyword_sub} />
                                                                </p>
                                                                <p className="mb-3 mt-3 fs-17 fs-md-16" style={{ whiteSpace: 'pre-line', lineHeight: '170%' }}>
                                                                    <HighlightText text={item?.body || ''} keyword={newsDetail?.keyword || ''} sub_keyword={newsDetail?.keyword_sub} />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </> : <>
                                                <div className={`color-scrollbar-os position-relative box-source-news chill-1 py-3 h-100`} >
                                                    <div className="">
                                                        <div className="p-2 fs-17 fs-md-18" style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: newsDetail?.prompt || '' }}></div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Col>
                            }
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter className="position-relative footer-button-new" lang={i18n?.language || 'en'}>
                    <div className="w-100 d-flex gap-2 justify-content-between align-items-center flex-wrap ">
                        <strong>
                            <div className="d-flex flex-column align-items-start">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 fs-14 fs-md-16">
                                        {t('Main Keyword Frequency')}:
                                    </div>
                                    <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                                        <span className="fw-medium">{(!!isGenerating || !!isReGenerating) ? 0 : (Number(newsDetail?.count_title || 0) + Number(newsDetail?.count_body || 0))}</span>
                                    </div>
                                </div>
                                {[NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS, NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI]?.includes(sourceType) && (
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 fs-14 fs-md-16">
                                            {t('Sub Keyword Frequency')}:
                                        </div>
                                        <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                                            <span className="fw-medium">{(!!isGenerating || !!isReGenerating) ? 0 : (Number(newsDetail?.sub_count_title || 0) + Number(newsDetail?.sub_count_body || 0))}</span>
                                        </div>
                                    </div>)}
                            </div>
                        </strong>
                        <div className="d-flex flex-grow-1 gap-2 gap-sm-3 justify-content-end justify-content-sm-end mt-2 mt-sm-0">
                            <div className="d-flex gap-2 gap-sm-3">
                                {[NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAVER_NEWS, NEWS_GENERATE_REQUEST_SOURCE_TYPE.NAMUWIKI]?.includes(sourceType) && <button
                                    className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center"
                                    type="button"
                                    color="light"
                                    onClick={clickShowInfoRight}
                                    style={{ width: '120px' }}
                                >
                                    <span className="ms-1">{typeShowInfoRight === 'SOURCE' ? t('Prompt') : t('Source')}</span>
                                </button>}
                                <button
                                    className="btn btn-secondary fs-14 px-1 d-flex justify-content-center align-item-center"
                                    type="button"
                                    color="light"
                                    onClick={onCloseClick}
                                    style={{ width: '75px' }}
                                >
                                    <i className="ri-close-fill" style={{ lineHeight: '22px' }} /> <span className="ms-1">{t("Button Close")}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </React.Fragment >
    );
};

export default ModalGenerateNews;

