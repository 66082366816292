import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IDailyStatistic, IKeywordStatistic, IMediaStatistic } from "./types/_statistic";
const api = new APIClient();

const path = '/';
const statisticApi = {
  dailyStatistics(params: any): Promise<AxiosResponse<ResponseData<IDailyStatistic[]> & PaginationResponse, any>> {
    const url = `/news-trackings/statistics`
    return api.get(url, params)
  },
  keywordStatistics(params: any): Promise<AxiosResponse<ResponseData<IKeywordStatistic[]> & PaginationResponse, any>> {
    const url = `/keyword-statistics`
    return api.get(url, params)
  },
  mediaStatistics(params: any): Promise<AxiosResponse<ResponseData<IMediaStatistic[]> & PaginationResponse, any>> {
    const url = `/partner-statistics`
    return api.get(url, params)
  },
}
export default statisticApi
