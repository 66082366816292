const ROLES_FOR_APP = {
    DASHBOARD_VIEW: "view",

    ROLE_LIST: "role_list",
    ROLE_CREATE: "role_create",
    ROLE_UPDATE: "role_update",
    ROLE_DELETE: "role_remove",

    USER_LIST: "user_list",
    USER_CREATE: "user_create",
    USER_UPDATE: "user_update",
    USER_DELETE: "user_remove",

    OPERATOR_LIST: "user_list",
    OPERATOR_CREATE: "user_create",
    OPERATOR_UPDATE: "user_update",
    OPERATOR_DELETE: "user_remove",

    KEYWORD_BOOST_LIST: "keyword_boost_list",
    KEYWORD_BOOST_CREATE: "keyword_boost_create",
    KEYWORD_BOOST_UPDATE: "keyword_boost_update",
    KEYWORD_BOOST_DELETE: "keyword_boost_remove",

    KEYWORD_REAL_TIME_LIST: "keyword_real_time_list",
    KEYWORD_REAL_TIME_CREATE: "keyword_real_time_create",
    KEYWORD_REAL_TIME_UPDATE: "keyword_real_time_update",
    KEYWORD_REAL_TIME_DELETE: "keyword_real_time_remove",

    JOURNALIST_LIST: "journalist_list",
    JOURNALIST_CREATE: "journalist_create",
    JOURNALIST_UPDATE: "journalist_update",
    JOURNALIST_DELETE: "journalist_remove",

    GENERAL_NEWS_LIST: "general_news_list",
    GENERAL_NEWS_CREATE: "general_news_create",
    GENERAL_NEWS_UPDATE: "general_news_update",
    GENERAL_NEWS_DELETE: "general_news_remove",

    GENERATE_NEWS_LIST: "generate_news_list",
    GENERATE_NEWS_CREATE: "generate_news_create",
    GENERATE_NEWS_UPDATE: "generate_news_update",
    GENERATE_NEWS_DELETE: "generate_news_remove",

    CATEGORY_LIST: "category_list",
    CATEGORY_CREATE: "category_create",
    CATEGORY_UPDATE: "category_update",
    CATEGORY_DELETE: "category_remove",

    MAIL_LIST: "mail_list",
    MAIL_CREATE: "mail_create",
    MAIL_UPDATE: "mail_update",
    MAIL_DELETE: "mail_remove",
    MAIL_LOG: "mail_log",
    MAIL_STATISTICS: "mail_statistics",

    ACTIVITY_LOG_LIST: 'activity_logs', // 

    AI_NEWS: "ai_news",
    BOOSTING_NEWS: "boosting_news",
    EXTRACT_DAILY_SEARCH_VOLUME: 'extract_daily_search_volume',

    REAL_TIME_NEWS: 'real_time_news',

    BOOSTING_CONTENT_NEWS: 'boosting-content',

    CAMPAIGN_CAR_LIST: "campaign_car_list",
    CAMPAIGN_CAR_CREATE: "campaign_car_create",
    CAMPAIGN_CAR_UPDATE: "campaign_car_update",
    CAMPAIGN_CAR_DELETE: "campaign_car_remove",

    DATA_CAR_LIST: "data_car_list",
    DATA_CAR_CREATE: "data_car_create",
    DATA_CAR_UPDATE: "data_car_update",
    DATA_CAR_DELETE: "data_car_remove",

    ARTICLE_TRACKING: 'article_tracking',

    DAILY_STATISTICS: 'daily-statistics',
    KEYWORD_STATISTICS: 'keyword-statistics',
    MEDIA_STATISTICS: 'media-statistics',

    CONFIGURATION_AI_NEWS: 'configuration_ai_news',
    CONFIGURATION_BOOSTING_NEWS: 'configuration_boosting_news',
    CONFIGURATION_REAL_TIME_NEWS: 'configuration_real_time_news',

    GOOGLE_IMAGE_LIST: "google_image_list",
    GOOGLE_IMAGE_CREATE: "google_image_create",
    GOOGLE_IMAGE_UPDATE: "google_image_update",
    GOOGLE_IMAGE_DELETE: "google_image_remove",

};

const isHavePermissionRole = (keyRoles: string | string[], permissions: { id: string | number, name: string }[] = []): boolean => {
    // const roles = typeof keyRoles === 'string' ? [keyRoles] : keyRoles;
    // return permissions?.some(item => roles?.includes(item.name));
    return true;
};

export { ROLES_FOR_APP, isHavePermissionRole };