import { createSlice } from "@reduxjs/toolkit";
import { IImageCategory } from "api/types/_image";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getImageCategories } from "./thunk";


export interface IState {
  imageCategories: ResponseData<IImageCategory[]> & PaginationResponse | null,
  isImageCategoryLoading: boolean,
  isImageCategorySuccess: boolean,

  allImageCategory: ResponseData<IImageCategory[]> | null,

  error: any,
};

export const initialState: IState = {
  imageCategories: null,
  isImageCategoryLoading: false,
  isImageCategorySuccess: false,

  allImageCategory: null,

  error: {},
};

const ImageSlice = createSlice({
  name: "Image",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get ImageCategories
    builder.addCase(getImageCategories.pending, (state: IState, action: any) => {
      state.isImageCategoryLoading = true
    });
    builder.addCase(getImageCategories.fulfilled, (state: IState, action: any) => {
      state.imageCategories = action.payload.data;
      state.isImageCategorySuccess = true;
      state.isImageCategoryLoading = false;
    });
    builder.addCase(getImageCategories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isImageCategorySuccess = false;
      state.isImageCategoryLoading = false;
    });
  },
});

export default ImageSlice.reducer;