import { createSlice } from "@reduxjs/toolkit";
import { ICategory } from "api/types/_category";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getCategories } from "./thunk";


export interface IState {
  categories: ResponseData<ICategory[]> & PaginationResponse | null,
  isCategoryLoading: boolean,
  isCategorySuccess: boolean,

  allCategory: ResponseData<ICategory[]> | null,

  error: any,
};

export const initialState: IState = {
  categories: null,
  isCategoryLoading: false,
  isCategorySuccess: false,

  allCategory: null,

  error: {},
};

const CategorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Categories
    builder.addCase(getCategories.pending, (state: IState, action: any) => {
      state.isCategoryLoading = true
    });
    builder.addCase(getCategories.fulfilled, (state: IState, action: any) => {
      state.categories = action.payload.data;
      state.isCategorySuccess = true;
      state.isCategoryLoading = false;
    });
    builder.addCase(getCategories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategorySuccess = false;
      state.isCategoryLoading = false;
    });
  },
});

export default CategorySlice.reducer;