import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Option {
    filename: string,
    filepath: string,
    mail_id: number
}

export interface Props {
    files?: Option[];
}


const PreviewAttachFile = ({
    files = [],
}: Props) => {

    const { t, i18n } = useTranslation();

    const getFileExtension = (url: string = '') => {
        const extension = (`${url || ''}`).split('.')?.pop()?.toLowerCase() || '';
        return extension;
    };

    const renderFileContent = (file: Option) => {
        switch (getFileExtension(file?.filepath || '')) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'bmp':
            case 'svg':
                return (<i className="ri-image-fill text-success align-bottom"></i>);
            case 'mp4':
            case 'webm':
            case 'avi':
            case 'mov':
            case 'mkv':
                return (<i className="ri-movie-fill text-success align-bottom"></i>);
            case 'pdf':
                return (<i className="ri-file-pdf-fill text-danger align-bottom"></i>);
            case 'txt':
                return (<i className="ri-file-text-fill text-secondary align-bottom"></i>);
            case 'csv':
                return (<i className="ri-file-list-2-fill text-warning align-bottom"></i>);
            case 'docx':
                return (<i className="ri-file-word-fill text-primary align-bottom"></i>);
            case 'excel':
                return (<i className="ri-file-excel-fill text-success align-bottom"></i>);
            case 'ppt':
                return (<i className="ri-file-ppt-fill text-danger align-bottom"></i>);
            case 'hwp':
                return (<i className="ri-file-hwp-fill text-danger align-bottom"></i>);
            default:
                return (<i className=" ri-links-fill text-secondary align-bottom"></i>);
        }
    };

    return (
        <React.Fragment>
            <div>
                {files?.map((item, index) => (
                    <div key={index} className="mb-2">
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 fs-17 me-2">
                                {renderFileContent(item)}
                            </div>
                            <div className="flex-grow-1">
                                <Link
                                    className="nav-link hover-underline cursor-pointer "
                                    target="_blank"
                                    to={item?.filepath}
                                >
                                    {item?.filename || ''}
                                </Link>
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment >
    );
};

export default React.memo(PreviewAttachFile);
