import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import DropdownOption from 'components/Common/DropdownOption';
import Hashtag from 'components/Common/Hashtag';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import { useFormik } from "formik";
import { AI_MODEL_OPTIONS, NEWS_TYPE, SYSTEM_CONFIG_KEY } from 'helpers/constans';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from 'reselect';
import { getConfiguration, getKeywordsConfiguration, getConfigurations as onGetConfigurations, postKeywordsConfiguration, putConfiguration } from 'store/thunks';
import * as Yup from "yup";

const Configuration = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const AI_MODEL_OPTIONS_LANG: any = AI_MODEL_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.System;

  const SystemProperties = createSelector(
    selectLayoutState,
    (state) => ({
      configs: state.configs,
      isConfigSuccess: state.isConfigSuccess,
      isConfigLoading: state.isConfigLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { configs } = useSelector(SystemProperties);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }
    setIsOpen((_prev) => true);
  };

  const handleSubmitConfiguration = async (values: any) => {
    try {
      if (isLoading) {
        return;
      }

      setIsLoading((_prev) => true);

      const _data_configs = {
        configs: [
          {
            key: SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_KEYWORD_TITLE_COUNT,
            value: String(values?.title_count || ''),
            is_active: 1,
          },
          {
            key: SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_KEYWORD_BODY_COUNT,
            value: String(values?.body_count || ''),
            is_active: 1,
          },
          {
            key: SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_KEYWORD_CONTENT_COUNT,
            value: String(values?.content_count || ''),
            is_active: 1,
          },
          {
            key: SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_SOURCE_COUNT,
            value: String(values?.source_count || ''),
            is_active: 1,
          },
          {
            key: SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_SOURCE_MAX,
            value: String(values?.source_max || ''),
            is_active: 1,
          },
          {
            key: SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_SOURCE_START_MINUS_DAY,
            value: String(values?.source_start_minus_day || ''),
            is_active: 1,
          },
          {
            key: SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_TOTAL_NEWS_PER_DAY,
            value: String(values?.total_news_per_day || ''),
            is_active: 1,
          },
          {
            key: SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_PROMPT_FORMAT,
            value: values?.prompt_format || '',
            is_active: 1,
          },
          {
            key: SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_MODEL,
            value: values?.ai_model?.value || '',
            is_active: 1,
          },
          {
            key: SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_ALLOW_REUSE_SOURCE_ARTICLE,
            value: String(values?.allow_reuse_source_article || 0),
            is_active: 1,
          }
        ]
      };

      const _data_keywords = {
        news_type: NEWS_TYPE.REALTIME_NEWS,
        keywords: (values?.keywords || [])?.map((k: any) => (k?.text || ''))
      };

      const [res1, res2]: any = await Promise.all([putConfiguration(_data_configs), postKeywordsConfiguration(_data_keywords)]);
      setIsOpen((_prev) => false);
      setIsLoading((_prev) => false);
      if (res1?.code === 200 && res2?.code === 200) {
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${res1?.message || res1}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsOpen((_prev) => false);
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    prompt_format: Yup.string().nullable(),
    total_news_per_day: Yup.string().nullable(),
    title_count: Yup.string().nullable(),
    body_count: Yup.string().nullable(),
    content_count: Yup.string().nullable(),
    source_count: Yup.string().nullable(),
    source_max: Yup.string().nullable(),
    source_start_minus_day: Yup.string().nullable(),
    ai_model: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).nullable(),
    allow_reuse_source_article: Yup.number().nullable(),
    keywords: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        text: Yup.string(),
      })
    ).nullable(),
  });

  const formik = useFormik({
    initialValues: {
      prompt_format: '',
      total_news_per_day: '',
      title_count: '',
      body_count: '',
      content_count: '',
      source_count: '',
      source_max: '',
      source_start_minus_day: '',
      ai_model: null,
      allow_reuse_source_article: 0,
      keywords: []
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    dispatch(onGetConfigurations({}));
  }, [dispatch]);

  const handleCallAllOption = async () => {
    try {
      const [res1, res2]: any = await Promise.all([getConfiguration({}), getKeywordsConfiguration({ news_type: NEWS_TYPE.REALTIME_NEWS })]);

      const _configs = res1?.data?.list?.filter((item: any) => item?.group === 'news_realtime') || [];

      const _config_prompt_format = _configs?.find((item: any) => item?.key === SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_PROMPT_FORMAT) || {};
      const _config_total_news_per_day = _configs?.find((item: any) => item?.key === SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_TOTAL_NEWS_PER_DAY) || {};
      const _config_title_count = _configs?.find((item: any) => item?.key === SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_KEYWORD_TITLE_COUNT) || {};
      const _config_body_count = _configs?.find((item: any) => item?.key === SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_KEYWORD_BODY_COUNT) || {};
      const _config_content_count = _configs?.find((item: any) => item?.key === SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_KEYWORD_CONTENT_COUNT) || {};
      const _config_source_count = _configs?.find((item: any) => item?.key === SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_SOURCE_COUNT) || {};
      const _config_source_max = _configs?.find((item: any) => item?.key === SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_SOURCE_MAX) || {};
      const _config_source_start_minus_day = _configs?.find((item: any) => item?.key === SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_SOURCE_START_MINUS_DAY) || {};

      const _ai_model = _configs?.find((item: any) => item?.key === SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_MODEL) || {};
      const _config_ai_model = AI_MODEL_OPTIONS_LANG?.find((v: any) => String(v?.value) === String(_ai_model?.value)) || AI_MODEL_OPTIONS_LANG[0];

      const _config_allow_reuse_source_article = _configs?.find((item: any) => item?.key === SYSTEM_CONFIG_KEY.NEWS_REALTIME_CREATE_ALLOW_REUSE_SOURCE_ARTICLE) || {};

      const _keywords = res2?.data?.map((k: any) => ({
        id: k?.keyword || '',
        text: k?.keyword || '',
      })) || [];

      formik?.setFieldValue('prompt_format', _config_prompt_format?.value);
      formik?.setFieldValue('total_news_per_day', _config_total_news_per_day?.value);
      formik?.setFieldValue('title_count', _config_title_count?.value);
      formik?.setFieldValue('body_count', _config_body_count?.value);
      formik?.setFieldValue('content_count', _config_content_count?.value);
      formik?.setFieldValue('source_count', _config_source_count?.value);
      formik?.setFieldValue('source_max', _config_source_max?.value);
      formik?.setFieldValue('source_start_minus_day', _config_source_start_minus_day?.value);
      formik?.setFieldValue('ai_model', _config_ai_model);
      formik?.setFieldValue('allow_reuse_source_article', Number(_config_allow_reuse_source_article?.value));
      formik?.setFieldValue('keywords', _keywords);
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    if (!!configs) {
      handleCallAllOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  useEffect(() => {
    document.title = `${t('Configuration')} - ${t('Boosting News')} | PRESS Ai`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Boosting News')} pageTitle={t('Configuration')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.CONFIGURATION_AI_NEWS, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)', minHeight: '75dvh' }}>
                  <CardBody className="pt-3 mx-3">
                    <form onSubmit={formik.handleSubmit}>
                      <div style={{ maxWidth: '800px', margin: '0 auto' }}>
                        <Row>
                          {/* <Col sm={12} md={12} xl={12} className='mt-3 text-start'>
                            <div className="text-start form-check form-switch form-switch-sm" style={{ minWidth: '80px' }}>
                              <Label htmlFor="customSwitchsizelg" className="form-label">{t('Status')} <span className="text-danger"> *</span></Label>
                              <Input
                                className="form-check-input"
                                id="customSwitchsizelg"
                                type="checkbox"
                                name="status"
                                role="switch"
                                checked={Boolean(formik?.values?.status)}
                                onChange={(e: any) => {
                                  formik?.setFieldValue('status', e.target.checked);
                                }}
                              />
                            </div>
                            {formik.touched.status && formik.errors.status ? (
                              <div className="text-danger mt-2">{formik.errors.status}</div>
                            ) : null}
                          </Col> */}
                          <div className="position-relative mb-3 mt-1 mt-md-0" style={{ border: '1px dashed var(--vz-primary)', borderRadius: '4px', padding: '10px' }}>
                            <span className="text-primary position-absolute fs-13 px-1" style={{ backgroundColor: "var(--vz-secondary-bg)", top: '0px', transform: 'translateY(-50%)' }}>{t('Keyword')}</span>

                            <Col sm={12} md={12} xl={12} className='mt-1 '>
                              <Label htmlFor="title_count" className="form-label">{t('Title')} <span className="text-danger"> *</span>
                                <TooltipCustom
                                  title={t("Sets the number of keyword matches in the title when collecting articles.")}
                                  className="d-inline-block vertical-align-middle ms-1"
                                  style={{ transform: 'translateY(1px)' }}
                                >
                                  <i className="ri-question-line align-bottom text-secondary" ></i>
                                </TooltipCustom>
                              </Label>
                              <Input
                                className="form-control"
                                id="title_count"
                                name="title_count"
                                type="text"
                                value={formik?.values?.title_count || ''}
                                placeholder={t('Title')}
                                autoComplete='off'
                                onChange={(event: any) => {
                                  const input = event.target.value;
                                  const onlyNumbers = input.replace(/\D/g, "");
                                  const s = onlyNumbers;
                                  formik.setFieldValue("title_count", s);
                                }}
                              />
                              {formik.touched.title_count && formik.errors.title_count ? (
                                <div className="text-danger mt-2">{formik.errors.title_count}</div>
                              ) : null}
                            </Col>

                            <Col sm={12} md={12} xl={12} className='mt-2 '>
                              <Label htmlFor="body_count" className="form-label">{t('Body')} <span className="text-danger"> *</span>
                                <TooltipCustom
                                  title={t("Sets the number of keyword matches in the body when collecting articles.")}
                                  className="d-inline-block vertical-align-middle ms-1"
                                  style={{ transform: 'translateY(1px)' }}
                                >
                                  <i className="ri-question-line align-bottom text-secondary" ></i>
                                </TooltipCustom>
                              </Label>
                              <Input
                                className="form-control"
                                id="body_count"
                                name="body_count"
                                type="text"
                                value={formik?.values?.body_count || ''}
                                placeholder={t('Body')}
                                autoComplete='off'
                                onChange={(event: any) => {
                                  const input = event.target.value;
                                  const onlyNumbers = input.replace(/\D/g, "");
                                  const s = onlyNumbers;
                                  formik.setFieldValue("body_count", s);
                                }}
                              />
                              {formik.touched.body_count && formik.errors.body_count ? (
                                <div className="text-danger mt-2">{formik.errors.body_count}</div>
                              ) : null}
                            </Col>

                            <Col sm={12} md={12} xl={12} className='mt-2 pb-1'>
                              <Label htmlFor="content_count" className="form-label">{t('Title + Body')} <span className="text-danger"> *</span>
                                <TooltipCustom
                                  title={t("Sets the number of keyword matches in either the title or the body when collecting articles.")}
                                  className="d-inline-block vertical-align-middle ms-1"
                                  style={{ transform: 'translateY(1px)' }}
                                >
                                  <i className="ri-question-line align-bottom text-secondary" ></i>
                                </TooltipCustom>
                              </Label>
                              <Input
                                className="form-control"
                                id="content_count"
                                name="content_count"
                                type="text"
                                autoComplete='off'
                                value={formik?.values?.content_count || ''}
                                placeholder={t('Title + Body')}
                                onChange={(event: any) => {
                                  const input = event.target.value;
                                  const onlyNumbers = input.replace(/\D/g, "");
                                  const s = onlyNumbers;
                                  formik.setFieldValue("content_count", s);
                                }}
                              />
                              {formik.touched.content_count && formik.errors.content_count ? (
                                <div className="text-danger mt-2">{formik.errors.content_count}</div>
                              ) : null}
                            </Col>
                          </div>
                          <Col sm={12} md={12} xl={12} className='mt-2 '>
                            <Label htmlFor="source_start_minus_day" className="form-label">{t('Source Dates')} <span className="text-danger"> *</span>
                              <TooltipCustom
                                title={t("Sets the past date range for collecting articles.")}
                                className="d-inline-block vertical-align-middle ms-1"
                                style={{ transform: 'translateY(1px)' }}
                              >
                                <i className="ri-question-line align-bottom text-secondary" ></i>
                              </TooltipCustom>
                            </Label>
                            <Input
                              className="form-control"
                              id="source_start_minus_day"
                              name="source_start_minus_day"
                              type="text"
                              autoComplete='off'
                              value={formik?.values?.source_start_minus_day || ''}
                              placeholder={t('Source Dates')}
                              onChange={(event: any) => {
                                const input = event.target.value;
                                const onlyNumbers = input.replace(/\D/g, "");
                                const s = onlyNumbers;
                                formik.setFieldValue("source_start_minus_day", s);
                              }}
                            />
                            {formik.touched.source_start_minus_day && formik.errors.source_start_minus_day ? (
                              <div className="text-danger mt-2">{formik.errors.source_start_minus_day}</div>
                            ) : null}
                          </Col>

                          <Col sm={12} md={12} xl={12} className='mt-2 '>
                            <Label htmlFor="source_max" className="form-label">{t('Max Articles')} <span className="text-danger"> *</span>
                              <TooltipCustom
                                title={t("Sets the maximum number of articles to be collected.")}
                                className="d-inline-block vertical-align-middle ms-1"
                                style={{ transform: 'translateY(1px)' }}
                              >
                                <i className="ri-question-line align-bottom text-secondary" ></i>
                              </TooltipCustom>
                            </Label>
                            <Input
                              className="form-control"
                              id="source_max"
                              name="source_max"
                              type="text"
                              autoComplete='off'
                              value={formik?.values?.source_max || ''}
                              placeholder={t('Max Articles')}
                              onChange={(event: any) => {
                                const input = event.target.value;
                                const onlyNumbers = input.replace(/\D/g, "");
                                const s = onlyNumbers;
                                formik.setFieldValue("source_max", s);
                              }}
                            />
                            {formik.touched.source_max && formik.errors.source_max ? (
                              <div className="text-danger mt-2">{formik.errors.source_max}</div>
                            ) : null}
                          </Col>
                          <Col sm={12} md={12} xl={12} className='mt-2'>
                            <div style={{ minHeight: '80px' }}>
                              <label className="form-label">
                                {t("Exclusion Keywords for Collection")}
                              </label>
                              <TooltipCustom
                                title={t("When collecting source contents, articles with titles containing the specified keywords will not be collected.")}
                                className="d-inline-block vertical-align-middle ms-1"
                                style={{ transform: 'translateY(1px)' }}
                              >
                                <i className="ri-question-line align-bottom text-secondary" ></i>
                              </TooltipCustom>
                              <Hashtag
                                name="keywords"
                                placeholder={`${t("Exclusion Keywords for Collection")}...`}
                                suggestions={[]}
                                initialValue={formik?.values?.keywords || []}
                                onChangeTag={(event: any) => {
                                  formik.setFieldValue("keywords", event);
                                }}
                                idSelected="hashtags-CampaignFormMulti"
                                maxHashtags={2000}
                                maxHeight="150px"
                              />
                            </div>
                          </Col>
                          <Col sm={12} md={12} xl={12} className='mt-2 '>
                            <Label htmlFor="source_count" className="form-label">{t('Source Articles')} <span className="text-danger"> *</span>
                              <TooltipCustom
                                title={t("Sets the number of articles to be used for generating new content.")}
                                className="d-inline-block vertical-align-middle ms-1"
                                style={{ transform: 'translateY(1px)' }}
                              >
                                <i className="ri-question-line align-bottom text-secondary" ></i>
                              </TooltipCustom>
                            </Label>
                            <Input
                              className="form-control"
                              id="source_count"
                              name="source_count"
                              type="text"
                              autoComplete='off'
                              value={formik?.values?.source_count || ''}
                              placeholder={t('Source Articles')}
                              onChange={(event: any) => {
                                const input = event.target.value;
                                const onlyNumbers = input.replace(/\D/g, "");
                                const s = onlyNumbers;
                                formik.setFieldValue("source_count", s);
                              }}
                            />
                            {formik.touched.source_count && formik.errors.source_count ? (
                              <div className="text-danger mt-2">{formik.errors.source_count}</div>
                            ) : null}
                          </Col>

                          <Col sm={12} md={12} xl={12} className='mt-2 '>
                            <Label htmlFor="total_news_per_day" className="form-label">{t('Total News Per Day')} <span className="text-danger"> *</span>
                              <TooltipCustom
                                title={t("Sets the maximum number of articles to be generated per day for each keyword.")}
                                className="d-inline-block vertical-align-middle ms-1"
                                style={{ transform: 'translateY(1px)' }}
                              >
                                <i className="ri-question-line align-bottom text-secondary" ></i>
                              </TooltipCustom>
                            </Label>
                            <Input
                              className="form-control"
                              id="total_news_per_day"
                              name="total_news_per_day"
                              type="text"
                              autoComplete='off'
                              value={formik?.values?.total_news_per_day || ''}
                              placeholder={t('Total News Per Day')}
                              onChange={(event: any) => {
                                const input = event.target.value;
                                const onlyNumbers = input.replace(/\D/g, "");
                                const s = onlyNumbers;
                                formik.setFieldValue("total_news_per_day", s);
                              }}
                            />
                            {formik.touched.total_news_per_day && formik.errors.total_news_per_day ? (
                              <div className="text-danger mt-2">{formik.errors.total_news_per_day}</div>
                            ) : null}
                          </Col>
                          <Col sm={12} md={12} xl={12} className='mt-2'>
                            <Label htmlFor="ai_model" className="form-label">{t('AI Model')}</Label> <span className="text-danger"> *</span>
                            <DropdownOption
                              name="ai_model"
                              dataList={AI_MODEL_OPTIONS_LANG || []}
                              placeholder={`${t("AI Model")}...`}
                              className={`dropdown-status-rounded z-hight mb-3 mb-lg-2 mb-xxl-1`}
                              classNamePrefix="name-prefix"
                              style={{ width: '100%' }}
                              initialValue={formik?.values?.ai_model}
                              onChangeSelect={(e: any) => {
                                formik.setFieldValue("ai_model", e);
                              }}
                            />
                          </Col>

                          <Col sm={12} md={12} xl={12} className='mt-2 '>
                            <Label htmlFor="prompt_format" className="form-label">{t('Prompt')} <span className="text-danger"> *</span>
                              <TooltipCustom
                                title={t("Specifies the SLM prompt to be used for generating articles.")}
                                className="d-inline-block vertical-align-middle ms-1"
                                style={{ transform: 'translateY(1px)' }}
                              >
                                <i className="ri-question-line align-bottom text-secondary" ></i>
                              </TooltipCustom>
                            </Label>
                            <Input
                              className="form-control"
                              id="prompt_format"
                              name="prompt_format"
                              type="textarea"
                              autoComplete='off'
                              rows={8}
                              value={formik?.values?.prompt_format || ''}
                              placeholder={t('Prompt')}
                              onChange={(e: any) => {
                                formik?.setFieldValue('prompt_format', e?.target?.value || '');
                              }}
                            />
                            {formik.touched.prompt_format && formik.errors.prompt_format ? (
                              <div className="text-danger mt-2">{formik.errors.prompt_format}</div>
                            ) : null}
                          </Col>

                          <Col sm={12} md={12} xl={12} className='mt-3 '>
                            <div className="form-check form-switch mb-0">
                              <Input className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="allow_reuse_source_article"
                                name="allow_reuse_source_article"
                                checked={!!formik?.values?.allow_reuse_source_article}
                                onChange={(event: any) => formik.setFieldValue("allow_reuse_source_article", Number(event?.target.checked))}
                              />
                              <Label className="form-check-label" for="allow_reuse_source_article">{t('Allow reusing the source article for each session generated.')}</Label>
                            </div>
                          </Col>

                          <Col sm={12} md={12} xl={12} className='mt-4 pt-1 text-end'>
                            <button
                              style={{ width: '150px' }}
                              type="submit"
                              disabled={(isLoading)}
                              className="btn btn-primary fs-14 rounded-pill">
                              {<>{t('Button Update')}</>}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>

        <Modal
          isOpen={!!isOpen}
          centered={true}
          // size="lg"
          toggle={() => setIsOpen(false)}
          keyboard={true}
        >
          <ModalHeader>
            {t('Configuration')}
          </ModalHeader>

          <ModalBody className="">
            <div className="row g-4">
              <Col sm={12}>
                <div className="row g-3">
                  <div className="mt-3 text-center">
                    <h6 className="mb-2 fs-19 text-center mt-1">
                      {t("Are you sure you want to update this?")}
                    </h6>
                    <p className="fs-15 mb-4 d-flex justify-content-center align-items-start align-items-sm-center text-center ">
                      {t('After confirming the update action, the data will be updated. Do you want to proceed with the update action.')}
                    </p>
                    <div className="d-flex gap-3 mt-2 justify-content-center">
                      <button
                        className="btn btn-primary rounded-pill fs-14"
                        type="button"
                        color="light"
                        onClick={() => handleSubmitConfiguration(formik?.values)}
                        style={{ width: '120px' }}
                      >
                        {(isLoading) ? <Spinner size="sm me-2" ></Spinner> : <>{t('Button Update')}</>}
                      </button>
                      <button
                        className="btn btn-secondary rounded-pill fs-14 ms-2"
                        type="button"
                        color="light"
                        onClick={() => setIsOpen(false)}
                        style={{ width: '120px' }}
                      >
                        {t("Button Close")}
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal >

        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />

      </div >
    </React.Fragment >
  );
};

export default Configuration;