import { INewsSource } from 'api/types/_news';
import { CONFIG_OPTION_TOAST_INFO } from 'common/toast';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import HighlightText from './HighlightText';

interface BoxSourceNewsProps {
    keyword: string,
    keyword_sub: string,
    item: INewsSource | null;
    index: number
}

const BoxSourceNews: React.FC<BoxSourceNewsProps> = ({ item, index, keyword, keyword_sub }) => {
    const { t } = useTranslation();

    const copyTextToClipboard = useCallback(async (text: string) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);
        } else {
            document.execCommand('copy', true, text);
        }
        toast(`${t('Copied to the clipboard.')}`, CONFIG_OPTION_TOAST_INFO);
    }, []);

    return (
        <>
            <span className="d-flex align-items-center text-primary position-absolute fs-13" style={{ top: 5, right: 10 }}>
                {!!item?.title && <span className="text-success d-flex align-items-center cursor-pointer me-3" onClick={() => copyTextToClipboard(`${item?.title || ''}\n\n${item?.body}`)}><i className="ri ri-file-copy-line" style={{ transform: 'translateY(1px)' }} /> {t('Copy')}</span>}
                {!!item?.title && <Link
                    className="nav-link cursor-pointer hover-underline-primary me-3"
                    target="_blank"
                    to={item?.url}
                >
                    <i className="ri ri-links-line" style={{ transform: 'translateY(-1px)' }} />{t('Source Link')}
                </Link>}
                {t('Source News')} {index + 1}
            </span>
            <div className="p-2 px-0">
                <div className="d-flex flex-wrap align-items-start gap-2 mt-2 pt-2 mb-1" style={{ borderBottom: '1px dashed #d0caca', paddingBottom: '4px' }}>
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 fs-14 fs-md-16">
                            {t('Main Keyword Frequency')}:
                        </div>
                        <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                            <span className="fw-medium">{Number(item?.title_count || 0) + Number(item?.body_count || 0)}</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 fs-14 fs-md-16">
                            {t('Sub Keyword Frequency')}:
                        </div>
                        <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                            <span className="fw-medium">{Number(item?.title_keyword_sub_count || 0) + Number(item?.body_keyword_sub_count || 0)}</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 fs-14 fs-md-16">
                            {t('Date')}:
                        </div>
                        <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                            <span className="fw-medium">{item?.publish_time || ''}</span>
                        </div>
                    </div>
                </div>
                <p className="mb-3 fs-20 fs-md-18" style={{ lineHeight: '170%', fontWeight: 600 }}>
                    <HighlightText text={item?.title || ''} keyword={keyword || ''} sub_keyword={keyword_sub} />
                </p>
                <p className="mb-3 mt-3 fs-17 fs-md-16" style={{ whiteSpace: 'pre-line', lineHeight: '170%' }}>
                    <HighlightText text={item?.body || ''} keyword={keyword || ''} sub_keyword={keyword_sub} />
                </p>
            </div>
        </>
    );
};

export default React.memo(BoxSourceNews);
