import { ICategory } from "api/types/_category";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatus from "components/Common/DropdownStatus";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { useFormik } from "formik";
import { STATUS_KEYWORD_BOOST_OPTIONS } from "helpers/constans";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import { getKeywordBoostV2, importKeywordBoostV2ByExcel, putKeywordBoostV2 } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: string;
    categories: ICategory[],
    isCopy?: boolean,
    triggerRefresh?: (is_refresh_categories: boolean) => void;
}

const FILE_SIZE = 10 * 1024 * 1024; // 10MB
const SUPPORTED_FORMATS = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const ImportBoostKeywordForm = ({
    id = '',
    categories,
    triggerRefresh
}: Props) => {
    const { t, i18n } = useTranslation();

    const { userPermissions } = useRole();

    const STATUS_KEYWORD_BOOST_OPTIONS_LANG: Option[] = STATUS_KEYWORD_BOOST_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                files: values?.file || null,
                ...(values?.category_name ? { category_name: values?.category_name || '' } : {}),
                ...(values?.category_id?.value ? { category_id: Number(values?.category_id?.value) } : {}),
                status: Number(values?.status ? values?.status?.value : STATUS_KEYWORD_BOOST_OPTIONS_LANG[0]?.value), // 0 | 1,
            };
            const response: any = await importKeywordBoostV2ByExcel(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh(!!values?.category_name);
            } else {
                setIsLoading((_prev) => false);
                toast(`${response?.message || response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        // keyword: Yup.string().required(`${t("This is required")}`),
        category_name: Yup.string()
            .nullable()
            .test(
                'category-name-required',
                `${t("Category name is required when creating a new category")}`,
                function (value) {
                    const { is_create_new_category } = this.parent;
                    if (is_create_new_category) {
                        return !!value; // If creating a new category, category_name must not be empty
                    }
                    return true; // Otherwise, category_name is optional
                }
            ),
        is_create_new_category: Yup.boolean().nullable(),
        file: Yup.mixed()
            .test(
                'fileSize',
                t('File too large. Max size is 10MB.'),
                (value: any) => !value || (value?.size <= FILE_SIZE)
            )
            .test(
                'fileFormat',
                t('Unsupported Format. Please upload an Excel file.'),
                (value: any) => !value || SUPPORTED_FORMATS.includes(value?.type)
            ).required(`${t("File is required")}`),
        category_id: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            }).nullable()
            .test(
                'category-id-required',
                `${t("Category ID is required when not creating a new category")}`,
                function (value) {
                    const { is_create_new_category } = this.parent;
                    if (!is_create_new_category) {
                        return !!(value && value.label && value.value); // Convert to boolean with !!
                    }
                    return true;
                }
            ),
        status: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
    });

    const formik = useFormik({
        initialValues: {
            // keyword: '',
            category_name: '',
            file: '',
            category_id: null,
            is_create_new_category: false,
            status: null
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleChangeFile = (value: any | null) => {
        formik?.setFieldValue('file', value?.target?.files[0] || null);
    }

    const handleSetValueForm = (valueDefault: any) => {
        // const vKeyword = valueDefault?.keyword || '';
        // const vKeywordSub = valueDefault?.keyword_sub || '';

        // const vCategory = categories?.find(
        //     (item) => String(item?.id) === String(valueDefault?.category_id)
        // );

        // setTimeout(() => {
        //     formik.setFieldValue("keyword", vKeyword);
        //     formik.setFieldValue("category_name", vKeywordSub);
        //     formik.setFieldValue("category_id", vCategory);
        // }, 300);
    };

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            const [resDetail]: any = await Promise.all([idItem ? getKeywordBoostV2(idItem) : {},]);
            if (resDetail?.data) {
                handleSetValueForm(resDetail?.data);
                setIsLoadingDetail((_prev) => false);
            }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        // handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        return () => {
            formik?.setFieldValue('file', null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {isLoadingDetail && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(164 164 164 / 36%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Spinner size="sm" color="primary"></Spinner>
            </div>}
            <div>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <label className="form-label">
                                                {t("Status")}
                                                <span className="text-danger"> *</span>
                                            </label>
                                            <DropdownStatus
                                                name="status"
                                                dataList={STATUS_KEYWORD_BOOST_OPTIONS_LANG || []}
                                                placeholder={`${t("Status")}...`}
                                                className="dropdown-status-rounded"
                                                classNamePrefix="name-prefix"
                                                initialValue={formik?.values?.status || STATUS_KEYWORD_BOOST_OPTIONS_LANG[0]}
                                                onChangeSelect={(event: any) =>
                                                    formik.setFieldValue("status", event)
                                                }
                                                colors={["secondary", "primary"]}
                                            />
                                            {formik.touched.status && formik.errors.status ? (
                                                <div className="text-danger mt-2">
                                                    {formik.errors.status}
                                                </div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    {/* <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="keyword" className="form-label">{t('Keywords')} <span className="text-danger">*</span>
                                                <TooltipCustom
                                                    title={t("You can register up to 1,000 items at a time")}
                                                    className="d-inline-block vertical-align-middle ms-1 cursor-pointer"
                                                    style={{ transform: 'translateY(1px)' }}
                                                >
                                                    <i className="ri-question-line align-bottom text-secondary" ></i>
                                                </TooltipCustom>
                                            </Label>
                                            <Input
                                                name="keyword"
                                                type="textarea"
                                                rows={8}
                                                className="form-control"
                                                id="keyword"
                                                placeholder={`${t('Keyword')},${t('Sub Keyword')}\n${t('Keyword')},${t('Sub Keyword')}\n${t('Keyword')},${t('Sub Keyword')}\n${t('Keyword')},${t('Sub Keyword')}\n${t('Keyword')},${t('Sub Keyword')}\n...`}
                                                value={formik?.values?.keyword}
                                                onChange={(event: any) => formik.setFieldValue('keyword', event?.target?.value)}
                                            />
                                            {formik.touched.keyword && formik.errors.keyword ? (
                                                <div className="text-danger mt-2">{formik.errors.keyword}</div>
                                            ) : null}
                                        </div>
                                    </Col> */}
                                    

                                    <Col sm={12} md={12} xl={12} className='mb-3'>
                                        <Label htmlFor="formSizeDefault" className="form-label">{t('Keywords') + ' (' + t('Excel File') + ')'} <span className="text-danger"> *</span></Label>
                                        <div className="custom-file-button">
                                            <Input className="form-control" accept=".xls,.xlsx"
                                                label="Upload Your File"
                                                data-title="Upload Your File"
                                                id="formSizeDefault"
                                                type="file"
                                                onChange={handleChangeFile}
                                            />
                                            {!formik?.values?.file && <div className="text-upload">{t('No file chosen')}</div>}
                                            <span className="icon-upload"> <i className="ri-upload-line" /></span>
                                        </div>
                                        {formik.touched.file && formik.errors.file ? (
                                            <div className="text-danger mt-2">{formik.errors.file}</div>
                                        ) : null}
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="keyword" className="form-label">{t('Category')} {!formik?.values?.is_create_new_category && <span className="text-danger">*</span>}</Label>
                                            <DropdownOption
                                                name="category_id"
                                                dataList={categories?.map((item) => ({ value: `${item?.id}`, label: item?.name }))}
                                                placeholder={`${t("Category")}...`}
                                                className="search-filter-category-type dropdown-status-rounded "
                                                classNamePrefix="name-prefix"
                                                initialValue={formik?.values?.category_id || null}
                                                onChangeSelect={(e: any) => formik.setFieldValue('category_id', e)}
                                                isHasOptionAll={false}
                                                disabled={!!formik?.values?.is_create_new_category}
                                            />
                                            {formik.touched.category_id && formik.errors.category_id ? (
                                                <div className="text-danger mt-2">{formik.errors.category_id}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <div className="form-check notification-check mb-3 mt-2">
                                                <input className="form-check-input" type="checkbox" checked={!!formik?.values?.is_create_new_category} id={`is_create_new_category`}
                                                    onChange={(event: any) => {
                                                        formik.setFieldValue(`is_create_new_category`, !!event.target.checked);
                                                        if (!!event.target.checked) {
                                                            formik.setFieldValue(`category_id`, null);
                                                        } else {
                                                            formik.setFieldValue(`category_name`, '');
                                                        }
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor={`is_create_new_category`}>{t('Create New Category')} {!!formik?.values?.is_create_new_category && <span className="text-danger">*</span>}</label>
                                            </div>
                                            {!!formik?.values?.is_create_new_category && <Input
                                                name="category_name"
                                                type="text"
                                                className="form-control"
                                                id="category_name"
                                                placeholder={t('Category Name')}
                                                value={formik?.values?.category_name}
                                                onChange={(event: any) => formik.setFieldValue('category_name', event?.target?.value)}
                                            />}
                                            {formik.touched.category_name && formik.errors.category_name ? (
                                                <div className="text-danger mt-2">{formik.errors.category_name}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={12} className="mt-2">
                                        <div className="hstack gap-2 justify-content-end">
                                            {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_UPDATE, userPermissions) && (
                                                <button
                                                    style={{ width: '250px' }}
                                                    type="submit"
                                                    className="btn btn-primary fs-14 rounded-pill">
                                                    {isLoading ? <Spinner size="sm me-2" ></Spinner> : <></>}
                                                    {t('Button Import Bulk Boost Keywords')}
                                                </button>)}
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </Col >
                    </Row >
                </Container >
            </div>
        </React.Fragment >
    );
};

export default ImportBoostKeywordForm;
