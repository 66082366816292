import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IGenerateNews, INews, IPartner } from "./types/_news";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/news';
const path_generate_news = '/generate-news';
const newsApi = {
  aiNews(params: any): Promise<AxiosResponse<ResponseData<INews[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  news(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<INews>>> {
    const url = `${path}/${id}`
    return api.get(url, params)
  },
  putNews(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IGenerateNews>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteNewsMulti(dataForm: any): Promise<AxiosResponse<ResponseData<INews>, any>> {
    const url = `${path}`;
    return api.delete(url, {
      data: dataForm
    })
  },
  downloadNews(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<INews[]> & PaginationResponse, any>> {
    const url = `${path}/${id}/download`;
    const TYPE_DOWNLOAD: any = {
      COPY: 1,
      WORD: 2,
      PDF: 3,
      ZIP: 4
    };
    return api.post(url, {
      // news_id: id,
      download_type: TYPE_DOWNLOAD?.[`${params?.type}`] || '',
      language_code: params?.language_code || 'ko',
    })
  },
  downloadExcelNews(params: any): Promise<AxiosResponse<ResponseData<INews[]> & PaginationResponse, any>> {
    const url = `${path}/export`
    return api.get(url, params)
  },
  importMediaMewsByExcel(dataForm: any): Promise<AxiosResponse<ResponseData<INews>, any>> {
    const url = `/news-trackings/import`;
    const formData = new FormData();
    if (dataForm.files) {
      formData.append('files', dataForm.files);
    }
    return api.post(url, formData, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  postNewsTracking(dataForm: any): Promise<AxiosResponse<ResponseData<INews>, any>> {
    const url = `/news-trackings`;
    return api.post(url, dataForm)
  },
  publishNews(id: string | number, data: any = {}): Promise<AxiosResponse<ResponseData<INews[]> & PaginationResponse, any>> {
    const url = `${path}/${id}/publish`;
    return api.post(url, data);
  },
  unPublishNews(id: string | number, data: any = {}): Promise<AxiosResponse<ResponseData<INews[]> & PaginationResponse, any>> {
    const url = `${path}/${id}/unpublish`;
    return api.post(url, data);
  },
  postPublishUrlNews(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<INews>, any>> {
    const url = `${path}/${id}/publish-url`;
    return api.post(url, dataForm)
  },
  cancelMyNews(id: string | number, params: any): Promise<AxiosResponse<ResponseData<INews[]> & PaginationResponse, any>> {
    const url = `${path}/${id}/cancel-used`
    return api.put(url, params)
  },
  generateNews(params: any): Promise<AxiosResponse<ResponseData<IGenerateNews[]> & PaginationResponse, any>> {
    const url = `${path_generate_news}`
    return api.get(url, params)
  },
  keywordGenerateNews(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<INews>>> {
    const url = `${path_generate_news}/${id}`
    return api.get(url, params)
  },
  postKeywordGenerateNews(dataForm: any): Promise<AxiosResponse<ResponseData<IGenerateNews>, any>> {
    const url = `${path_generate_news}`;
    return api.post(url, dataForm)
  },
  putKeywordGenerateNews(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IGenerateNews>, any>> {
    const url = `${path_generate_news}/${id}`;
    return api.put(url, dataForm)
  },
  putKeywordReGenerateNews(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IGenerateNews>, any>> {
    const url = `${path_generate_news}/${id}/re-generate`;
    return api.put(url, dataForm)
  },
  deleteKeywordGenerateNews(dataForm: any): Promise<AxiosResponse<ResponseData<IGenerateNews>, any>> {
    const url = `${path_generate_news}`;
    return api.delete(url, {
      data: dataForm
    })
  },
  deleteGenerateNewsMulti(dataForm: any): Promise<AxiosResponse<ResponseData<IGenerateNews>, any>> {
    const url = `/generate-news`;
    return api.delete(url, {
      data: dataForm
    })
  },
  allPartners(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IPartner>>> {
    const url = `/masters/partners`
    return api.get(url, params)
  },

  getTemplateSampleNewsExcel(params: any = {}): Promise<AxiosResponse<ResponseData<any>>> {
    const url = `/masters/news-monitor/templates`
    return api.get(url, params)
  },

  getGenerateBoostingNews(params: any): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `/systems/jobs/status`
    return api.get(url, params)
  },

  postGenerateBoostingNews(dataForm: any): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `/systems/jobs`;
    return api.post(url, dataForm)
  },

  putGenerateBoostingNews(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `/systems/jobs/${id}/status`;
    return api.post(url, dataForm)
  },
}
export default newsApi
