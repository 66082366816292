import { createSlice } from "@reduxjs/toolkit";
import { ICampaignCar } from "api/types/_campaign";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getCampaignCars } from "./thunk";


export interface IState {
  campaignCars: ResponseData<ICampaignCar[]> & PaginationResponse | null,
  isCampaignCarLoading: boolean,
  isCampaignCarSuccess: boolean,

  allCategory: ResponseData<ICampaignCar[]> | null,

  error: any,
};

export const initialState: IState = {
  campaignCars: null,
  isCampaignCarLoading: false,
  isCampaignCarSuccess: false,

  allCategory: null,

  error: {},
};

const CampaignSlice = createSlice({
  name: "Campaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Campaign Car
    builder.addCase(getCampaignCars.pending, (state: IState, action: any) => {
      state.isCampaignCarLoading = true
    });
    builder.addCase(getCampaignCars.fulfilled, (state: IState, action: any) => {
      state.campaignCars = action.payload.data;
      state.isCampaignCarSuccess = true;
      state.isCampaignCarLoading = false;
    });
    builder.addCase(getCampaignCars.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCampaignCarSuccess = false;
      state.isCampaignCarLoading = false;
    });
  },
});

export default CampaignSlice.reducer;