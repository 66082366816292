import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import DropdownStatus from "components/Common/DropdownStatus";
import { useRole } from "components/Hooks/UserHooks";
import { useFormik } from "formik";
import { STATUS_KEYWORD_BOOST_OPTIONS } from "helpers/constans";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import { getKeywordBoost, postKeywordBoost, putKeywordBoost } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    triggerRefresh?: () => void;
}

const KeywordBoostForm = ({
    id = '',
    triggerRefresh
}: Props) => {
    const { t, i18n } = useTranslation();

    const { userPermissions } = useRole();

    const STATUS_KEYWORD_BOOST_OPTIONS_LANG: Option[] = STATUS_KEYWORD_BOOST_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                keyword: values?.keyword || '',
                keyword_sub: values?.keyword_sub || '',
                // media: values?.media || '',
                // daily_search_volume: Number(values?.daily_search_volume || 0),
                // monthly_search_volume: Number(values?.monthly_search_volume || 0),
                // exposure_rank: Number(values?.exposure_rank || 0),
                status: Number(values?.status ? values?.status?.value : STATUS_KEYWORD_BOOST_OPTIONS_LANG[0]?.value), // 0 | 1,
            };
            const response: any = id ? await putKeywordBoost(id, data) : await postKeywordBoost(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response?.message || response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        keyword: Yup.string().required(`${t("This is required")}`),
        keyword_sub: Yup.string().nullable(),
        daily_search_volume: Yup.string().nullable(),
        monthly_search_volume: Yup.string().nullable(),
        media: Yup.string().nullable(),
        exposure_rank: Yup.string().nullable(),
        status: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            keyword: '',
            keyword_sub: '',
            // daily_search_volume: '0',
            // monthly_search_volume: '0',
            // media: '',
            // exposure_rank: '0',
            status: null
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const vKeyword = valueDefault?.keyword || '';
        const vKeywordSub = valueDefault?.keyword_sub || '';

        // const vMedia = valueDefault?.media || '';

        // const vDailySearchVolume = Number(valueDefault?.configs?.daily_search_volume || 0);
        // const vMonthlySearchVolume = Number(valueDefault?.configs?.monthly_search_volume || 0);
        // const vExposureRank = Number(valueDefault?.configs?.exposure_rank || 0);

        const vStatus = STATUS_KEYWORD_BOOST_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.status)
        );

        setTimeout(() => {
            formik.setFieldValue("keyword", vKeyword);
            formik.setFieldValue("keyword_sub", vKeywordSub);
            // formik.setFieldValue("media", vMedia);
            // formik.setFieldValue("daily_search_volume", vDailySearchVolume);
            // formik.setFieldValue("monthly_search_volume", vMonthlySearchVolume);
            // formik.setFieldValue("exposure_rank", vExposureRank);
            formik.setFieldValue("status", vStatus);
        }, 300);
    };

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            const [resDetail]: any = await Promise.all([idItem ? getKeywordBoost(idItem) : {},]);
            if (resDetail?.data) {
                handleSetValueForm(resDetail?.data);
                setIsLoadingDetail((_prev) => false);
            }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <React.Fragment>
            {isLoadingDetail && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(164 164 164 / 36%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Spinner size="sm" color="primary"></Spinner>
            </div>}
            <div>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <label className="form-label">
                                                {t("Status")}
                                                <span className="text-danger"> *</span>
                                            </label>
                                            <DropdownStatus
                                                name="status"
                                                dataList={STATUS_KEYWORD_BOOST_OPTIONS_LANG || []}
                                                placeholder={`${t("Status")}...`}
                                                className="dropdown-status-rounded"
                                                classNamePrefix="name-prefix"
                                                initialValue={formik?.values?.status || STATUS_KEYWORD_BOOST_OPTIONS_LANG[0]}
                                                onChangeSelect={(event: any) =>
                                                    formik.setFieldValue("status", event)
                                                }
                                                colors={["secondary", "primary"]}
                                            />
                                            {formik.touched.status && formik.errors.status ? (
                                                <div className="text-danger mt-2">
                                                    {formik.errors.status}
                                                </div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="keyword" className="form-label">{t('Keyword')} <span className="text-danger">*</span></Label>
                                            <Input
                                                name="keyword"
                                                type="text"
                                                className="form-control"
                                                id="keyword"
                                                placeholder={t('Keyword')}
                                                value={formik?.values?.keyword}
                                                onChange={(event: any) => formik.setFieldValue('keyword', event?.target?.value)}
                                            />
                                            {formik.touched.keyword && formik.errors.keyword ? (
                                                <div className="text-danger mt-2">{formik.errors.keyword}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="keyword_sub" className="form-label">{t('Sub Keyword')}</Label>
                                            <Input
                                                name="keyword_sub"
                                                type="text"
                                                className="form-control"
                                                id="keyword_sub"
                                                placeholder={t('Sub Keyword')}
                                                value={formik?.values?.keyword_sub}
                                                onChange={(event: any) => formik.setFieldValue('keyword_sub', event?.target?.value)}
                                            />
                                            {formik.touched.keyword_sub && formik.errors.keyword_sub ? (
                                                <div className="text-danger mt-2">{formik.errors.keyword_sub}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    {/* <Col lg={12}>
                                        <div className="mb-4">
                                            <Label htmlFor="daily_search_volume" className="form-label">
                                                {t('Daily Search Volume')}
                                            </Label>
                                            <Input
                                                name="daily_search_volume"
                                                type="text"
                                                className="form-control"
                                                id="daily_search_volume"
                                                placeholder={t('Daily Search Volume')}
                                                value={formik?.values?.daily_search_volume}
                                                onChange={(event: any) => {
                                                    const input = event.target.value;
                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                    const s = Number(onlyNumbers);
                                                    formik.setFieldValue("daily_search_volume", s);
                                                }}
                                            />
                                            {formik.touched.daily_search_volume && formik.errors.daily_search_volume ? (
                                                <div className="text-danger mt-2">{formik.errors.daily_search_volume}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-4">
                                            <Label htmlFor="monthly_search_volume" className="form-label">
                                                {t('Monthly Search Volume')}
                                            </Label>
                                            <Input
                                                name="monthly_search_volume"
                                                type="text"
                                                className="form-control"
                                                id="monthly_search_volume"
                                                placeholder={t('Monthly Search Volume')}
                                                value={formik?.values?.monthly_search_volume}
                                                onChange={(event: any) => {
                                                    const input = event.target.value;
                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                    const s = Number(onlyNumbers);
                                                    formik.setFieldValue("monthly_search_volume", s);
                                                }}
                                            />
                                            {formik.touched.monthly_search_volume && formik.errors.monthly_search_volume ? (
                                                <div className="text-danger mt-2">{formik.errors.monthly_search_volume}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="media" className="form-label">{t('Partner Media')}</Label>
                                            <Input
                                                name="media"
                                                type="text"
                                                className="form-control"
                                                id="media"
                                                placeholder={t('Partner Media')}
                                                value={formik?.values?.media}
                                                onChange={(event: any) => formik.setFieldValue('media', event?.target?.value)}
                                            />
                                            {formik.touched.media && formik.errors.media ? (
                                                <div className="text-danger mt-2">{formik.errors.media}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-4">
                                            <Label htmlFor="exposure_rank" className="form-label">
                                                {t('Exposure Rank')}
                                            </Label>
                                            <Input
                                                name="exposure_rank"
                                                type="text"
                                                className="form-control"
                                                id="exposure_rank"
                                                placeholder={t('Exposure Rank')}
                                                value={formik?.values?.exposure_rank}
                                                onChange={(event: any) => {
                                                    const input = event.target.value;
                                                    const onlyNumbers = input.replace(/\D/g, "");
                                                    const s = Number(onlyNumbers);
                                                    formik.setFieldValue("exposure_rank", s);
                                                }}
                                            />
                                            {formik.touched.exposure_rank && formik.errors.exposure_rank ? (
                                                <div className="text-danger mt-2">{formik.errors.exposure_rank}</div>
                                            ) : null}
                                        </div>
                                    </Col> */}
                                    <Col lg={12} className="mt-2">
                                        <div className="hstack gap-2 justify-content-end">
                                            {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_UPDATE, userPermissions) && (
                                                <button
                                                    style={{ width: '210px' }}
                                                    type="submit"
                                                    className="btn btn-primary fs-14 rounded-pill">
                                                    {isLoading ? <Spinner size="sm me-2" ></Spinner> : <></>}
                                                    {id ? t('Button Update Boost Keyword') : t('Button Create Boost Keyword')}
                                                </button>)}
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </Col >
                    </Row >
                </Container >
            </div>
        </React.Fragment >
    );
};

export default KeywordBoostForm;
