import { IJournalist } from "api/types/_journalist";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input, Label, Row, Spinner } from 'reactstrap';
import { getJournalist, postJournalist, putJournalist } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    triggerRefresh?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const JournalistForm = ({
    id = '',
    triggerRefresh,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { userPermissions } = useRole();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const [initialValuesDefault, setInitialValuesDefault] = useState<IJournalist | null>(null);

    const handleSubmit = async (values: any) => {

        try {
            setIsLoading((_prev) => true);
            const data = {
                first_name: values?.first_name ?? "",
                last_name: values?.last_name ?? "",
                email: values?.email ?? "",
                is_active: values?.is_active ?? 1,
            };

            const response: any = id ? await putJournalist(id, data) : await postJournalist(data);

            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        first_name: Yup.string().required(`${t("First Name is required")}`),
        last_name: Yup.string().required(`${t("Last Name is required")}`),
        email: Yup.string().email(`${t('Please enter a valid email address')}`).required(`${t('Email is required')}`),
        password: !!id ? Yup.string().nullable() : Yup.string().min(8, `${t('Password must be at least 8 characters')}`).required(`${t("Password is required")}`),
        is_active: Yup.number(),
        joining_date: Yup.string().nullable(),
        role: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(), //.required(`${t("Role is required")}`),
    });

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            joining_date: "",
            is_active: 1,
            role: undefined
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const vFirstName = valueDefault?.first_name || '';
        const vLastName = valueDefault?.last_name || '';
        const vEmail = valueDefault?.email || '';
        const vPassword = valueDefault?.password || '';
        const vIsActive = valueDefault?.is_active;
        const vJoiningDate = valueDefault?.created_at || '';

        setTimeout(() => {
            formik.setFieldValue("first_name", vFirstName);
            formik.setFieldValue("last_name", vLastName);
            formik.setFieldValue("email", vEmail);
            formik.setFieldValue("password", vPassword);
            formik.setFieldValue("is_active", vIsActive);
            formik.setFieldValue("joining_date", vJoiningDate);
        }, 300);
    };

    const handleSubmitChangePassword = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                password: values?.new_password ?? "",
                is_active: initialValuesDefault?.is_active ?? 1,
            };
            const response: any = await putJournalist(id, data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchemaChangePassword = Yup.object({
        new_password: Yup.string().min(8, `${t('Password must be at least 8 characters')}`).required(`${t("New Password is required")}`),
        confirm_password: Yup.string().oneOf([Yup.ref('new_password')], `${t("Passwords must match")}`),
    });

    const formikChangePassword = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: "",
        },
        validationSchema: validationSchemaChangePassword,
        onSubmit: handleSubmitChangePassword,
    });

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            const [resDetail]: any = await Promise.all([idItem ? getJournalist(idItem) : {},]);
            if (resDetail?.data) {
                setInitialValuesDefault((_prev) => resDetail?.data);
                handleSetValueForm(resDetail?.data);
                setIsLoadingDetail((_prev) => false);
            }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <React.Fragment>
            {isLoadingDetail && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(164 164 164 / 36%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Spinner size="sm" color="primary"></Spinner>
            </div>}
            <div>
                <Row>
                    <form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="firstnameInput" className="form-label">{t('First Name')} <span className="text-danger">*</span></Label>
                                    <Input
                                        name="first_name"
                                        type="text"
                                        className="form-control"
                                        id="firstnameInput"
                                        placeholder={t('Enter your firstname')}
                                        value={formik?.values?.first_name}
                                        onChange={(event: any) => formik.setFieldValue('first_name', event?.target?.value)}
                                    />
                                    {formik.touched.first_name && formik.errors.first_name ? (
                                        <div className="text-danger mt-2">{formik.errors.first_name}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="lastnameInput" className="form-label">{t('Last Name')} <span className="text-danger"> *</span></Label>
                                    <Input
                                        name="last_name"
                                        type="text"
                                        className="form-control"
                                        id="lastnameInput"
                                        placeholder={t('Enter your lastname')}
                                        value={formik?.values?.last_name}
                                        onChange={(event: any) => formik.setFieldValue('last_name', event?.target?.value)}
                                    />
                                    {formik.touched.last_name && formik.errors.last_name ? (
                                        <div className="text-danger mt-2">{formik.errors.last_name}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="emailInput" className="form-label">{t('Email Address')} <span className="text-danger"> *</span></Label>
                                    <Input
                                        name="email"
                                        type="text"
                                        className="form-control"
                                        id="emailInput"
                                        placeholder={t('Enter your email')}
                                        value={formik?.values?.email}
                                        onChange={(event: any) => formik.setFieldValue('email', event?.target?.value)}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="text-danger mt-2">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="JoiningdatInput" className="form-label">{t('Joining Date')}</Label>
                                    <Input
                                        name="joining_date"
                                        className="form-control"
                                        disabled
                                        value={formik?.values?.joining_date || ''}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="hstack gap-2 justify-content-end">
                                    {isHavePermissionRole(ROLES_FOR_APP.JOURNALIST_UPDATE, userPermissions) && (
                                        <button
                                            style={{ width: '170px' }}
                                            type="submit"
                                            className="btn btn-primary fs-14 rounded-pill">
                                            {isLoading ? <Spinner size="sm me-2" ></Spinner> : <></>}
                                            {id ? t('Button Update Journalist') : t('Button Create Journalist')}
                                        </button>)}
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Row >
            </div>
            <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />
        </React.Fragment >
    );
};

export default JournalistForm;
