import { createSlice } from "@reduxjs/toolkit";
import { getOperators } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IOperator } from "api/types/_operator";


export interface IState {
  operators: ResponseData<IOperator[]> & PaginationResponse | null,
  isOperatorLoading: boolean,
  isOperatorSuccess: boolean,

  allOperator: ResponseData<IOperator[]> | null,

  error: any,
};

export const initialState: IState = {
  operators: null,
  isOperatorLoading: false,
  isOperatorSuccess: false,

  allOperator: null,

  error: {},
};

const OperatorSlice = createSlice({
  name: "Operator",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Operators
    builder.addCase(getOperators.pending, (state: IState, action: any) => {
      state.isOperatorLoading = true
    });
    builder.addCase(getOperators.fulfilled, (state: IState, action: any) => {
      state.operators = action.payload.data;
      state.isOperatorSuccess = true;
      state.isOperatorLoading = false;
    });
    builder.addCase(getOperators.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isOperatorSuccess = false;
      state.isOperatorLoading = false;
    });
  },
});

export default OperatorSlice.reducer;