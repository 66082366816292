import { formatNumberWithCommas } from 'helpers/format';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactApexChart from "react-apexcharts";
import chartRanking from "../../../../assets/images/chart_rank.png";

interface ChartToBase64Props {
    data?: { date: string; index: number }[];
    name?: string;
    height?: number;
    showAxisBorder?: boolean;
    fillColor?: string;
    fillOpacity?: number;
    isRotate?: boolean;
    markersSize?: number;
    style?: React.CSSProperties;
    isShowXaxis?: boolean;
    isShowYaxis?: boolean;
    isShowTooltip?: boolean;
    tickAmountXaxis?: number;
    isShowReversedY?: boolean;
}

const ChartToBase64: React.FC<ChartToBase64Props> = ({
    data = [],
    name = '',
    height = 60,
    showAxisBorder = false,
    fillColor = '#fb3335',
    fillOpacity = 0.5,
    isRotate = false,
    markersSize = 0,
    style = { transform: 'translateY(-20px)' },
    isShowXaxis = false,
    isShowYaxis = false,
    isShowTooltip = false,
    tickAmountXaxis = 5,
    isShowReversedY = false
}) => {
    const chartContainerRef = useRef<HTMLDivElement | null>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [base64Image, setBase64Image] = useState<string | null>(null);

    const categories = useMemo(() => {
        return data.map(item => item.date);
    }, [data]);

    const values = useMemo(() => {
        return data.map(item => item.index);
    }, [data]);

    const series = React.useMemo(() => [{
        name: name,
        data: isShowReversedY ? values?.map((v: number) => (v === 0 ? 0 : (11 - v))) : values,
    }], [name, values]);

    const options = React.useMemo(() => ({
        chart: {
            type: 'area',
            height: '100%',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            animations: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
            },
        },
        grid: {
            show: false, // Hide the grid completely
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }
        },
        stroke: {
            show: true,
            width: 2,
            curve: 'straight',
            lineCap: 'butt',
            colors: [fillColor],
        },
        markers: {
            size: [markersSize],
            colors: 'var(--card)',
            strokeColors: fillColor,
            strokeWidth: 2,
        },
        xaxis: {
            categories: categories,
            labels: {
                show: isShowXaxis,
                style: {
                    colors: '#030012',
                    fontSize: '11px',
                    fontFamily: "Open Sans",
                    fontWeight: 100,
                },
                ...(isRotate ? {} : { rotate: 0 }),
            },
            crosshairs: {
                show: false, // Ensure crosshairs are disabled on x-axis
            },
            axisBorder: {
                show: false, // Remove the axis border
            },
            axisTicks: {
                show: false, // Remove the axis ticks
            },
            tickAmount: tickAmountXaxis,
        },
        yaxis: {
            ...(isShowReversedY ? { min: 0, max: 10 } : {}),
            show: isShowYaxis,
            axisBorder: {
                show: false, // Remove the axis border
            },
            axisTicks: {
                show: false, // Remove the axis ticks
            },
            crosshairs: {
                show: false, // Ensure crosshairs are disabled on x-axis
            },
            labels: {
                show: isShowYaxis,
                offsetX: -15,
                style: {
                    colors: fillColor,
                    fontSize: '11px',
                    fontFamily: "Open Sans",
                    fontWeight: 100,
                },
                formatter: (y: number) => {
                    return formatNumberWithCommas(y);
                },
            },
        },
        legend: {
            show: false, // Hide the legend
        },
        dataLabels: {
            enabled: false, // Disable data labels
        },
        fill: {
            opacity: fillOpacity,
            colors: fillColor,
            ...(fillOpacity === 0 ? { type: 'solid' } : {}),
        },
        tooltip: {
            enabled: isShowTooltip,
            theme: 'light',
            y: {
                formatter: (val: number) => formatNumberWithCommas(val),
            },
            x: {
                show: false // Disable tooltip crosshairs on x-axis
            },
            marker: {
                show: false // Disable the marker in the tooltip
            },
        },
        // Remove crosshair settings
        crosshairs: {
            show: false // Disable crosshairs globally if supported
        },
    }), [
        fillColor,
        fillOpacity,
        isRotate,
        markersSize,
        categories,
        isShowXaxis,
        isShowYaxis,
        isShowTooltip,
        tickAmountXaxis
    ]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.boundingClientRect.top - window.innerHeight <= 200) {
                    setIsVisible(true);
                }
            },
            {
                root: null,
                threshold: 0,
            }
        );

        const currentRef = chartContainerRef.current;
        if (currentRef) observer.observe(currentRef);

        return () => {
            if (currentRef) observer.unobserve(currentRef);
        };
    }, []);

    const convertSvgToBase64 = useCallback(() => {
        const svgElement = chartContainerRef.current?.querySelector('svg');
        if (svgElement) {
            const svgData = new XMLSerializer().serializeToString(svgElement);
            const base64 = window.btoa(unescape(encodeURIComponent(svgData)));
            setBase64Image((_prev) => `data:image/svg+xml;base64,${base64}`);
        }
    }, []);

    useEffect(() => {
        if (isVisible) {
            // Reset base64Image when data changes
            setBase64Image((_prev) => null);

            // Trigger re-conversion after data changes
            const timeoutId = setTimeout(convertSvgToBase64, 1000);
            return () => clearTimeout(timeoutId);
        }
    }, [isVisible, JSON.stringify(data)]);

    return (
        <div ref={chartContainerRef}>
            {(isVisible) ? (
                <>
                    {base64Image ? (
                        <img
                            src={base64Image}
                            alt="Base64 Chart"
                            style={style}
                        />
                    ) : (<ReactApexChart
                        options={options}
                        series={series}
                        type="area"
                        height={height}
                        style={style}
                    />)}
                </>
            ) : <img
                src={chartRanking}
                alt="Example Chart"
                loading="lazy"
                style={style}
            />}
        </div>
    );
};

export default React.memo(ChartToBase64);
