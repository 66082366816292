import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import { getTemplateSampleNewsExcel, importMediaMewsByExcel } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    categories?: { value: string, label: string }[],
    isShowLevel1Dropdown?: boolean
    triggerRefresh?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FILE_SIZE = 10 * 1024 * 1024; // 10MB
const SUPPORTED_FORMATS = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const ImportMediaArticleForm = ({
    triggerRefresh,
}: Props) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isLoadingDownloadFile, setIsLoadingDownloadFile] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                files: values?.file || null,
            };
            const response: any = await importMediaMewsByExcel(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        file: Yup.mixed()
            .test(
                'fileSize',
                t('File too large. Max size is 10MB.'),
                (value: any) => !value || (value?.size <= FILE_SIZE)
            )
            .test(
                'fileFormat',
                t('Unsupported Format. Please upload an Excel file.'),
                (value: any) => !value || SUPPORTED_FORMATS.includes(value?.type)
            ).required(`${t("File is required")}`),
    });

    const formik = useFormik({
        initialValues: {
            file: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleChangeFile = (value: any | null) => {
        formik?.setFieldValue('file', value?.target?.files[0] || null);
    }

    const handleDownloadExcel = async (e: any) => {
        try {
            e.preventDefault();
            if (isLoadingDownloadFile) {
                return;
            }
            setIsLoadingDownloadFile((_prev) => true);
            const response: any = await getTemplateSampleNewsExcel({});
            if (response?.data) {
                setIsLoadingDownloadFile((_prev) => false);
                const link = document.createElement('a');
                link.href = response?.data?.import_file_url;
                link.download = 'Download Sample Excel';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                setIsLoadingDownloadFile((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoadingDownloadFile((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }

    useEffect(() => {
        return () => {
            formik?.setFieldValue('file', null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <form onSubmit={formik.handleSubmit}>
                            <div style={{ maxWidth: '500px', margin: '0 auto' }}>
                                <Row>
                                    <Col sm={12} md={12} xl={12} className='mt-3 '>
                                        <Label htmlFor="formSizeDefault" className="form-label">{t('Bulk Articles') + ' (' + t('Excel File') + ')'} <span className="text-danger"> *</span></Label>
                                        <div className="custom-file-button">
                                            <Input className="form-control" accept=".xls,.xlsx"
                                                label="Upload Your File"
                                                data-title="Upload Your File"
                                                id="formSizeDefault"
                                                type="file"
                                                onChange={handleChangeFile}
                                            />
                                            {!formik?.values?.file && <div className="text-upload">{t('No file chosen')}</div>}
                                            <span className="icon-upload"> <i className="ri-upload-line" /></span>
                                        </div>
                                        {formik.touched.file && formik.errors.file ? (
                                            <div className="text-danger mt-2">{formik.errors.file}</div>
                                        ) : null}
                                    </Col>
                                    <Col sm={6} md={6} xl={6} className='mt-0 mt-sm-4 text-start ' style={{ paddingTop: '26px' }}>
                                        <a href={'#'} onClick={handleDownloadExcel} download="기사_등록_샘플_파일.xlsx" className="stretched-link hover-underline">
                                            {isLoadingDownloadFile && <Spinner size="sm me-1" ></Spinner>} {t('Download Sample Excel')}
                                        </a>
                                    </Col>
                                    <Col sm={6} md={6} xl={6} className='mt-4 pt-1 text-end'>
                                        <button
                                            style={{ width: '190px' }}
                                            type="submit"
                                            disabled={isLoading}
                                            className="btn btn-primary fs-14 rounded-pill">
                                            {isLoading ? <Spinner size="sm me-1" ></Spinner> : <></>}
                                            {t('Button Import Bulk Articles')}
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    </Row >
                </Container >
            </div>
            <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />
        </React.Fragment >
    );
};

export default ImportMediaArticleForm;
