import { createAsyncThunk } from "@reduxjs/toolkit";
import campaignApi from 'api/campaignApi';
import { formatQueryParams } from "helpers/format";

export const getCampaignCars = createAsyncThunk("Campaign Cars", async (params: any = {}) => {
  try {
    const response = await campaignApi.campaignCars(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllCampaignCar = async (params: any = {}) => {
  try {
    const response = await campaignApi.allCampaignCar(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getCampaignCar = async (id: string | number, params: any = {}) => {
  try {
    const response = await campaignApi.getCampaignCar(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postCampaignCar = async (data: any = {}) => {
  try {
    const response = await campaignApi.postCampaignCar(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putCampaignCar = async (id: string | number, data: any = {}) => {
  try {
    const response = await campaignApi.putCampaignCar(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteMultiCampaignCar = async (data: any = {}) => {
  try {
    const response = await campaignApi.deleteMultiCampaignCar(data);
    return response;
  } catch (error) {
    return error;
  }
};


