import { ICondition, INewsGenerate } from "api/types/_news";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_INFO, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import DropdownMultiOption from "components/Common/DropdownMultiOption";
import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import { AI_MODEL_OPTIONS, NEWS_GENERATE_REQUEST_SOURCE_TYPE, NEWS_GENERATE_REQUEST_STATUS, SYSTEM_CONFIG_KEY } from "helpers/constans";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { getKeywordGenerateNews, getModelCar, postKeywordGenerateNews, postTemplateCar, putConfiguration, putKeywordReGenerateNews, putTemplateCar } from "store/thunks";
import HighlightText from "../Components/HighlightText";
import SpinnerScreen from "../Components/SpinnerScreen";

export interface Props {
    isOpen?: boolean;
    detail?: INewsGenerate | null,
    modelCars?: { label: string, value: string, name: string, id: string }[],
    templateCars?: { label: string, value: string, content: string }[],
    sourceType: NEWS_GENERATE_REQUEST_SOURCE_TYPE.CAR,
    onClose?: () => void
    triggerRefresh?: (type?: string) => void;
}

const ModalGenerateCarNews = ({
    isOpen = false,
    detail = null,
    modelCars = [],
    templateCars = [],
    sourceType = NEWS_GENERATE_REQUEST_SOURCE_TYPE.CAR,
    onClose,
    triggerRefresh
}: Props) => {
    const { t, i18n } = useTranslation();

    const AI_MODEL_OPTIONS_LANG: any = AI_MODEL_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const CAR_MODEL_OPTIONS_LANG: any = modelCars;

    const [lineUpOptions, setLineUpOptions] = useState<Option[]>([]);

    const [groupOptions, setGroupOptions] = useState<Option[]>([]);

    const [detailCarModel, setDetailCarModel] = useState<any>(null);

    const [typeShowInfoRight, setTypeShowInfoRight] = useState<'JSON_TEMPLATE' | 'PROMPT'>('JSON_TEMPLATE');

    const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false);

    const [jsonTemplate, setJsonTemplate] = useState<any>('');

    const [templateOptions, setTemplateOptions] = useState<Option[]>([]);

    const timeoutCallJsonTemplateRef = useRef<NodeJS.Timeout | null>(null);
    const [isLoadingJsonTemplate, setIsLoadingJsonTemplate] = useState<boolean>(false);

    const [newsDetail, setNewsDetail] = useState<INewsGenerate | null>(null);
    const [condition, setCondition] = useState<ICondition | null>(null);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [isInterval, setIsInterval] = useState<boolean>(false);

    const [isReGenerating, setIsReGenerating] = useState<boolean>(false);

    const [isSavePrompt, setIsSavePrompt] = useState<boolean>(false);

    const [isTemplating, setIsTemplating] = useState<boolean>(false);

    const [addTemplate, setAddTemplate] = useState<any>(null);

    const onCloseClick = () => {
        setIsInterval((_prev) => false);
        setIsGenerating((_prev) => false);
        onClose && onClose();
    }

    const handleSetNewsDetail = (detail: INewsGenerate | null) => {
        setNewsDetail((_prev) => detail);
        setCondition((_prev: any) => ({ ..._prev, ...detail?.condition }));
        setJsonTemplate((_prev: any) => detail?.condition?.template_content);
    }

    const handleGenerating = async () => {
        try {
            setIsGenerating((_prev) => true);

            const request = {
                keyword: condition?.keyword || '',

                source_max: Number(condition?.source_max || 0),

                source_count: Number(condition?.source_count || 0),

                car_model_code: condition?.car_model_code || '',

                prompt_format: condition?.prompt_format || '',

                source_type: sourceType,

                ai_model: condition?.ai_model || AI_MODEL_OPTIONS_LANG[1]?.value,

                exclude_keywords: condition?.exclude_keywords || [],

                template_id: Number(condition?.template_id),

                template_content: JSON.stringify(jsonTemplate, null, 2),
            };

            const response: any = await postKeywordGenerateNews(request);
            if (response?.code === 200) {
                setCondition((_prev: any) => ({ ..._prev, id: response?.data?.id }));
                setIsInterval((_prev) => true);
                triggerRefresh && triggerRefresh();
            } else {
                setIsGenerating((_prev) => false);
                toast(`${response || ""}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsGenerating((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const formatResultDetail = (item: any, option: any = { id: '' }) => {
        const _condition = {
            id: item?.id,
            keyword: item?.keyword,
            keyword_sub: item?.keyword_sub,
            count_title: item?.count_title,
            count_body: item?.count_body,
            count_content: item?.count_content,
            sub_count_title: item?.sub_count_title,
            sub_count_body: item?.sub_count_body,
            sub_count_content: item?.sub_count_content,
            source_count: item?.source_count,
            source_max: item?.source_max,
            source_date: 1,
            source_date_from: item?.source_date_from,
            source_date_to: item?.source_date_to,
            source_type: sourceType,
            total_news: item?.total_news,
            // prompt_format: item?.prompt_format || '',
            // section_prompt_format: item?.section_prompt_format || '',
            // section: `${item?.section_prompt_config?.type}=${item?.section_prompt_config?.key}`,
            // exclude_keywords: item?.exclude_keywords || [],
            ai_model: item?.ai_model || AI_MODEL_OPTIONS_LANG[0]?.value,
            template_content: item?.template_content || ''
        };
        return ({ ...item?.results[0], condition: _condition, ...option });
    };

    const handleReGenerating = async () => {
        try {
            if (!condition?.id) {
                return;
            }

            setIsReGenerating((_prev) => true);

            const request = {
                prompt_format: condition?.prompt_format || '',
                ai_model: condition?.ai_model || AI_MODEL_OPTIONS_LANG[1]?.value,
            };

            const response: any = await putKeywordReGenerateNews(`${condition?.id}`, request);
            setIsReGenerating((_prev) => false);
            if (response?.code === 200) {
                const item = formatResultDetail(response?.data, { id: newsDetail?.id || '' });
                handleSetNewsDetail(item);
                toast(t("The process has been completed."), CONFIG_OPTION_TOAST_NORMAL);
                triggerRefresh && triggerRefresh();
            } else {
                toast(`${response || ""}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsReGenerating((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const handleSavePrompt = async () => {
        try {
            if (!condition?.template_id) {
                return;
            }
            setIsSavePrompt((_prev) => true);

            const _config = {
                content: condition?.prompt_format || '',
            };

            const [res1]: any = await Promise.all([putTemplateCar(condition?.template_id, _config)]);

            setIsSavePrompt((_prev) => false);
            if (res1?.code === 200) {
                toast(t("The process has been completed."), CONFIG_OPTION_TOAST_NORMAL);
                triggerRefresh && triggerRefresh('TEMPLATE');
            } else {
                toast(`${res1 || ""}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsSavePrompt((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const handleAddTemplate = async () => {
        try {
            setIsTemplating((_prev) => true);
            const _config = {
                name: addTemplate?.name || '',
                content: addTemplate?.content || ''
            };
            const [res1]: any = await Promise.all([postTemplateCar(_config)]);
            setIsTemplating((_prev) => false);
            if (res1?.code === 200) {
                toast(t("The process has been completed."), CONFIG_OPTION_TOAST_NORMAL);
                triggerRefresh && triggerRefresh('TEMPLATE');
                setAddTemplate((_prev: any) => null);
            } else {
                toast(`${res1 || ""}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsSavePrompt((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };




    useEffect(() => {
        let intervalId: any;
        const fetchDetail = async () => {
            try {
                const response: any = await getKeywordGenerateNews(condition?.id || '');
                // Check if the polling should stop
                if ([
                    NEWS_GENERATE_REQUEST_STATUS?.COMPLETED,
                    NEWS_GENERATE_REQUEST_STATUS.COLLECT_ERROR,
                    NEWS_GENERATE_REQUEST_STATUS.GENERATE_ERROR,
                    NEWS_GENERATE_REQUEST_STATUS.ERROR_NOT_ENOUGH_NEWS,
                    NEWS_GENERATE_REQUEST_STATUS.OTHER_ERROR
                ]?.includes(response.data?.status)) {
                    clearInterval(intervalId);
                    setIsInterval((_prev) => false);
                    setIsGenerating((_prev) => false);
                    const _mess = `${[NEWS_GENERATE_REQUEST_STATUS?.COMPLETED]?.includes(response.data?.status) ? t("The process has been completed.") : t("Failed to collect news matching the conditions.")}`;
                    const _type = [NEWS_GENERATE_REQUEST_STATUS?.COMPLETED]?.includes(response.data?.status) ? CONFIG_OPTION_TOAST_NORMAL : CONFIG_OPTION_TOAST_ERROR;
                    toast(_mess, _type);
                    if (response?.data) {
                        const item = formatResultDetail(response?.data, { id: '' });
                        handleSetNewsDetail(item);
                        triggerRefresh && triggerRefresh();
                    }
                }
            } catch (error: any) {
                toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
                clearInterval(intervalId); // Stop polling on error
                setIsInterval((_prev) => false);
            }
        };

        if (!!condition && !!isInterval) {
            intervalId = setInterval(fetchDetail, 3000); // Poll every 3 seconds
            fetchDetail(); // Immediate call on mount
        }

        return () => {
            clearInterval(intervalId);
        };

    }, [JSON.stringify(condition), isInterval]);


    const copyTextToClipboard = useCallback(async (text: string) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);
        } else {
            document.execCommand('copy', true, text);
        }
        toast(`${t('Copied to the clipboard.')}`, CONFIG_OPTION_TOAST_INFO);
    }, []);

    useEffect(() => {
        if (!newsDetail?.id && templateCars?.length) {
            const _template_prompt = templateCars?.find((s) => (s?.value === condition?.template_id))?.content || '';
            setCondition((_prev: any) => ({ ..._prev, prompt_format: _template_prompt }));
        }
    }, [condition?.template_id, JSON.stringify(templateCars)]);

    const initialValueAIModel = useMemo(() => {
        const initialValue = AI_MODEL_OPTIONS_LANG?.find((md: any) => (md?.value === condition?.ai_model)) || AI_MODEL_OPTIONS_LANG[1];
        return initialValue;
    }, [AI_MODEL_OPTIONS_LANG, condition?.ai_model]);

    const initialValueCarModel = useMemo(() => {
        const initialValue = CAR_MODEL_OPTIONS_LANG?.find((md: any) => (String(md?.value) === String(condition?.car_model_code))) || null;
        return initialValue;
    }, [CAR_MODEL_OPTIONS_LANG, condition?.car_model_code]);

    const initialValueCarModelLineUp = useMemo(() => {
        const initialValue = lineUpOptions?.filter((md: any) => ((String(condition?.car_model_line_up || '')?.split(','))?.includes(String(md?.value)))) || [];
        return initialValue;
    }, [lineUpOptions, condition?.car_model_line_up]);

    const initialValueCarModelGroup = useMemo(() => {
        const initialValue = groupOptions?.filter((md: any) => ((String(condition?.car_model_group || '')?.split(','))?.includes(String(md?.value)))) || [];
        return initialValue;
    }, [groupOptions, condition?.car_model_group]);

    const initialValueTemplateCar = useMemo(() => {
        const initialValue = templateCars?.find((md: any) => (md?.value === condition?.template_id)) || null;
        return initialValue;
    }, [templateCars, condition?.template_id]);

    const initialValueKeywords = useMemo(() => {
        const initialValue = (condition?.exclude_keywords || detail?.condition?.exclude_keywords || [])?.map((s: string) => ({ id: s, text: s }));
        return initialValue;
    }, [detail?.condition?.exclude_keywords, condition?.exclude_keywords]);


    useEffect(() => {
        if (!!detail) {
            handleSetNewsDetail(detail);
        }
        return () => {
            setCondition((_prev) => null);
            setJsonTemplate((_prev: any) => '');
            setTypeShowInfoRight((_prev: any) => 'JSON_TEMPLATE');
        }
    }, [detail]);

    const getDetailCarModel = async (_code_car_model: string) => {
        try {
            setIsDetailLoading(() => true);
            const res: any = await getModelCar(_code_car_model);
            // setDetailCarModel((_prev: any) => res?.data || []);
            setLineUpOptions((_prev: any) => (res?.data?.line_ups || [])?.map((l: any) => ({ value: l?.line_up_code, label: l?.name })));
            setGroupOptions((_prev: any) => (res?.data?.spec_groups || [])?.map((spec: string) => ({ value: spec, label: spec })));
            setJsonTemplate((_prev: any) => res?.data?.json_template || '');
            setCondition((_prev: any) => ({ ..._prev, car_model_line_up: '', car_model_group: '' }));
            setIsDetailLoading(() => false);
        } catch (error: any) {
            setIsDetailLoading(() => false);
            return error;
        }
    };

    const getDetailCarModelGroup = async (_text_car_model_groups: string = '', _line_up_codes: string = '') => {
        try {
            if (!initialValueCarModel?.value) {
                // setJsonTemplate((_prev: any) => '');
                return;
            }
            setIsLoadingJsonTemplate(() => true);
            const res: any = await getModelCar(initialValueCarModel?.value, { spec_groups: _text_car_model_groups, line_up_codes: _line_up_codes });
            setJsonTemplate((_prev: any) => res?.data?.json_template || '');
            setIsLoadingJsonTemplate(() => false);
        } catch (error: any) {
            setIsLoadingJsonTemplate(() => false);
            console.error('Error fetching car model group:', error);
        }
    };

    const clickShowInfoRight = () => {
        setTypeShowInfoRight((_prev) => _prev === 'JSON_TEMPLATE' ? 'PROMPT' : 'JSON_TEMPLATE');
    }

    const formatToJson = (_input: any) => {
        try {
            if (!!_input && (typeof _input === 'string')) {
                const jsonObject = JSON.parse(_input);
                return jsonObject;
            }
            return _input;
        } catch (error: any) {
            return _input;
        }
    };

    useEffect(() => {
        if (timeoutCallJsonTemplateRef.current) {
            clearTimeout(timeoutCallJsonTemplateRef.current);
        }

        timeoutCallJsonTemplateRef.current = setTimeout(() => {
            const _groups: string = (initialValueCarModelGroup || [])?.map((g: any) => g?.value || '').join(',') || '';
            const _line_ups: string = (initialValueCarModelLineUp || [])?.map((g: any) => g?.value || '').join(',') || '';
            getDetailCarModelGroup(_groups, _line_ups);
        }, 1000);

        return () => {
            if (timeoutCallJsonTemplateRef.current) {
                clearTimeout(timeoutCallJsonTemplateRef.current);
            }
        };
    }, [JSON.stringify(initialValueCarModelGroup), JSON.stringify(initialValueCarModelLineUp)]);

    useEffect(() => {
        if (!!initialValueCarModel?.value) {
            getDetailCarModel(initialValueCarModel?.value);
        }
    }, [initialValueCarModel?.value]);

    useEffect(() => {
        const options = templateCars;
        setTemplateOptions((_prev) => options);
    }, [JSON.stringify(templateCars), i18n?.language]);


    const refLeft = useRef<any>(null);
    const refRight = useRef<any>(null);
    const [position, setPosition] = useState<any>({ left1: 20, left2: 30, top1: 20, top2: 20 });

    useEffect(() => {
        const getPosition = () => {
            if (refLeft.current && refRight.current) {
                const leftRect = refLeft.current.getBoundingClientRect();
                const rightRect = refRight.current.getBoundingClientRect();
                setPosition((_prev: any) => ({ left1: leftRect.left, left2: rightRect.left, top1: leftRect.top, top2: rightRect.top }));
            }
        };

        // Timeout to ensure UI has rendered
        const timeoutId = setTimeout(() => {
            // Get position on mount
            getPosition();
        }, 0);

        // Optionally, track changes on window resize
        window.addEventListener("resize", getPosition);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener("resize", getPosition);
        };
    }, [JSON.stringify(newsDetail)]);

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                centered={true}
                size="xl"
                className={`border-header-footer modal-fullscreen`}
                scrollable={false}
                toggle={onCloseClick}
                keyboard={true}
            >
                <ModalHeader toggle={onCloseClick} className="modal-title-flex-grow-1">
                    <div className="d-flex flex-column gap-0 gap-sm-2 fs-18 fs-md-20">
                        <div className="flex-grow-1">
                            {t('Car Simulator')} <span className="mx-1">{`>`}</span>  {!!newsDetail?.id ? t('AI News') : (
                                <>{t('Button Create Generate News')}</>)} : <span style={{ backgroundColor: '#ffff00' }}>{newsDetail?.keyword || ''}</span> {!!newsDetail?.keyword_sub && <> - <span style={{ backgroundColor: '#a8d2fb' }}>{newsDetail?.keyword_sub || ''}</span></>}
                        </div>
                        {!!newsDetail?.id ? (
                            <div className="d-flex flex-wrap fs-14">
                                <div className="me-2">
                                    <span style={{ backgroundColor: '#ffff00' }}>{newsDetail?.keyword || ''}</span>
                                </div>
                                <div className="mx-2 text-danger">|</div>
                                <div className="me-2 fw-normal">
                                    {t('Template')}: <span className="fw-bold">{templateOptions?.find((md: any) => (String(md?.value) === String(newsDetail?.condition?.template_id)))?.label || '-'}</span>
                                </div>
                                <div className="mx-2 ms-0 text-danger">|</div>
                                <div className="me-2 fw-normal">
                                    {t('AI Model')}: <span className="fw-bold">{(AI_MODEL_OPTIONS_LANG?.find((md: any) => (md?.value === newsDetail?.condition?.ai_model)) || AI_MODEL_OPTIONS_LANG[1])?.label || '-'}</span>
                                </div>
                            </div>
                        ) : (<div className="d-flex flex-wrap fs-14">
                            <div className="me-2 fw-normal">
                                <LabelWrapper label={t('Model')} isShow={true}>
                                    <DropdownOption
                                        name="car_model_code"
                                        dataList={CAR_MODEL_OPTIONS_LANG || []}
                                        placeholder={`${t("Model")}...`}
                                        className={`dropdown-status-rounded z-hight mb-3 mb-lg-2 mb-xxl-1 ${condition?.car_model_code}`}
                                        classNamePrefix="name-prefix"
                                        style={{ width: '300px' }}
                                        initialValue={initialValueCarModel}
                                        isHaveMoreInfo={true}
                                        onChangeSelect={(e: any) => {
                                            setCondition((_prev: any) => ({ ..._prev, car_model_code: e?.value, keyword: e?.name || '' }))
                                        }}
                                    />
                                </LabelWrapper>
                            </div>
                            <div className="me-2 fw-normal">
                                <LabelWrapper label={t('Line-up')} isShow={true}>
                                    <DropdownMultiOption
                                        name=""
                                        dataList={lineUpOptions}
                                        placeholder={`${t("Line-up")}...`}
                                        className="search-filter-category-type dropdown-status-rounded"
                                        classNamePrefix="name-prefix"
                                        style={{ width: '300px' }}
                                        initialValue={initialValueCarModelLineUp || null}
                                        onChangeSelect={(e: any) => {
                                            const _value = (e || [])?.map((v: any) => v?.value).join(',');
                                            setCondition((_prev: any) => ({ ..._prev, car_model_line_up: _value }))
                                        }}
                                        isHasOptionAll={false}
                                        cacheOptions={true}
                                        disabled={isDetailLoading}
                                    />
                                </LabelWrapper>
                            </div>
                            <div className="me-2 fw-normal">
                                <LabelWrapper label={t('Spec & Feature')} isShow={true}>
                                    <DropdownMultiOption
                                        name=""
                                        dataList={groupOptions}
                                        placeholder={`${t("Spec & Feature")}...`}
                                        className="search-filter-category-type dropdown-status-rounded"
                                        classNamePrefix="name-prefix"
                                        style={{ width: '300px' }}
                                        initialValue={initialValueCarModelGroup || null}
                                        onChangeSelect={(e: any) => {
                                            const _value = (e || [])?.map((v: any) => v?.value).join(',');
                                            setCondition((_prev: any) => ({ ..._prev, car_model_group: _value }))
                                        }}
                                        isHasOptionAll={false}
                                        cacheOptions={true}
                                        disabled={isDetailLoading}
                                    />
                                </LabelWrapper>
                            </div>
                            <div className="me-2 fw-normal">
                                <LabelWrapper label={t('Template')} isShow={true}>
                                    <DropdownOption
                                        name="ai_model"
                                        dataList={templateOptions || []}
                                        placeholder={`${t("Template")}...`}
                                        className={`dropdown-status-rounded z-hight-100 mb-3 mb-lg-2 mb-xxl-1`}
                                        classNamePrefix="name-prefix"
                                        style={{ width: '200px' }}
                                        initialValue={initialValueTemplateCar}
                                        onChangeSelect={(e: any) => {
                                            setCondition((_prev: any) => ({ ..._prev, template_id: e?.value }))
                                        }}
                                    />
                                </LabelWrapper>
                            </div>
                            <div className="mx-2 fw-normal">
                                <button
                                    className="btn btn-soft-primary fs-14 px-1 d-flex justify-content-center align-item-center mb-3 mb-lg-2 mb-xxl-1"
                                    type="button"
                                    color="light"
                                    onClick={() => setAddTemplate(() => ({}))}
                                    disabled={(!!isGenerating || !!isReGenerating || !!isSavePrompt || !!isTemplating)}
                                    style={{ width: '120px' }}
                                >
                                    {(!!isTemplating) && <span style={{ transform: 'translateY(1px)' }}><Spinner size="sm"></Spinner></span>}
                                    <span className="ms-1">{t('Button Add Template')}</span>
                                </button>
                            </div>
                            <div className="mx-2 ms-0 text-danger align-self-center mb-3 mb-lg-2 mb-xxl-1">|</div>
                            <div className="me-2 fw-normal">
                                <LabelWrapper label={t('AI Model')} isShow={true}>
                                    <DropdownOption
                                        name="ai_model"
                                        dataList={AI_MODEL_OPTIONS_LANG || []}
                                        placeholder={`${t("AI Model")}...`}
                                        className={`dropdown-status-rounded z-hight mb-3 mb-lg-2 mb-xxl-1 ${condition?.ai_model}`}
                                        classNamePrefix="name-prefix"
                                        style={{ width: '150px' }}
                                        initialValue={initialValueAIModel}
                                        onChangeSelect={(e: any) => {
                                            setCondition((_prev: any) => ({ ..._prev, ai_model: e?.value }))
                                        }}
                                    />
                                </LabelWrapper>
                            </div>
                            <div className="mx-2 ms-0 text-danger align-self-center mb-3 mb-lg-2 mb-xxl-1">|</div>
                            <div className="ms-2 fw-normal">
                                <button
                                    className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center mb-3 mb-lg-2 mb-xxl-1"
                                    type="button"
                                    color="light"
                                    onClick={handleGenerating}
                                    disabled={!!isGenerating || !!isReGenerating || !!isSavePrompt || !!isLoadingJsonTemplate}
                                    style={{ width: '150px' }}
                                >
                                    {isGenerating ? <span style={{ transform: 'translateY(1px)' }}><Spinner size="sm"></Spinner></span> : <i className="ri-refresh-line" style={{ lineHeight: '22px' }} />}
                                    <span className="ms-1">{t("Button Create Generate News")}</span>
                                </button>
                            </div>
                        </div>)}
                        <div className="me-2 fw-normal w-100 mt-2 d-flex flex-wrap gap-3 align-items-start">
                            {/* <div className="flex-grow-1" style={{ minWidth: '240px', maxWidth: '250px' }}>
                                <div className="me-2 fw-normal">
                                    <LabelWrapper label={t('Exclusion Keywords for Collection')} description={t("When collecting source contents, articles with titles containing the specified keywords will not be collected.")} isShow={true}>
                                        
                                        <Hashtag
                                            name="keywords"
                                            placeholder=""
                                            suggestions={[]}
                                            initialValue={initialValueKeywords}
                                            onChangeTag={(event: any) => {
                                                const _k = (event || [])?.map((k: any) => (k?.id));
                                                setCondition((_prev: any) => ({ ..._prev, exclude_keywords: _k }))
                                            }}
                                            idSelected="hashtags-CampaignFormMulti fs-14"
                                            maxHashtags={2000}
                                            maxHeight="150px"
                                            allowDeleteFromEmptyInput={!newsDetail?.id}
                                        />
                                    </LabelWrapper>
                                </div>
                            </div> */}
                            <div className="flex-grow-1" style={{ minWidth: '200px', maxWidth: '915px' }}>
                                <LabelWrapper label={t('Prompt')} isShow={true}>
                                    <Input
                                        name="text"
                                        type="textarea"
                                        className={`form-control`}
                                        placeholder=""
                                        rows={3}
                                        style={{ width: '100%', minHeight: '88px' }}
                                        value={condition?.prompt_format || ''}
                                        onChange={(event: any) => {
                                            const input = event.target.value;
                                            setCondition((_prev: any) => ({ ..._prev, prompt_format: input }))
                                        }}
                                        autoComplete="off"
                                    />
                                </LabelWrapper>
                                <div className="w-100 mb-1 mb-xl-0 fs-12 text-muted pt-1">{t("Source data is labeled as 'JSON data'.")}</div>
                            </div>
                            <ul className={`d-flex flex-row flex-md-column gap-0 gap-xl-2 mb-0 ps-0 align-self-end box-button-render-news pb-22`}>
                                {(!!condition?.id && !isGenerating) && <li className="list-inline-item" id={`re-generate-button`}>
                                    <button
                                        className="btn btn-soft-primary fs-14 px-1 d-flex justify-content-center align-item-center mb-2 mb-xl-0"
                                        type="button"
                                        color="light"
                                        onClick={handleReGenerating}
                                        disabled={(!!isGenerating || !!isReGenerating || !!isSavePrompt || !!isLoadingJsonTemplate)}
                                        style={{ width: '150px' }}
                                    >
                                        {(!!isGenerating || !!isReGenerating) ? <span style={{ transform: 'translateY(1px)' }}><Spinner size="sm"></Spinner></span> : <i className="ri-refresh-line" style={{ lineHeight: '22px' }} />}
                                        <span className="ms-1">{t('Button Create Re Generate News')}</span>
                                    </button>
                                </li>}
                                <li className="list-inline-item" id={`save-prompt-button`}>
                                    <button
                                        className="btn btn-soft-primary fs-14 px-1 d-flex justify-content-center align-item-center mb-2 mb-xl-0"
                                        type="button"
                                        color="light"
                                        onClick={handleSavePrompt}
                                        disabled={(!!isGenerating || !!isReGenerating || !!isSavePrompt)}
                                        style={{ width: '150px' }}
                                    >
                                        {(!!isSavePrompt) ? <span style={{ transform: 'translateY(1px)' }}><Spinner size="sm"></Spinner></span> : <i className="ri-login-circle-line" style={{ lineHeight: '22px' }} />}
                                        <span className="ms-1">{t('Button Save Prompt')}</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className="position-relative">
                    <span className="position-absolute text-success fs-13 text-end px-2 d-none d-lg-block" style={{
                        top: position?.top1,
                        left: position?.left1 + 10,
                        transform: `translateY(-${position?.top1 - 10}px)`,
                        zIndex: 10,
                        backgroundColor: 'var(--vz-secondary-bg)'
                    }}>
                        {t('Generated News')}
                    </span>
                    <span className="position-absolute text-primary fs-13 text-end px-2 d-none d-lg-block" style={{
                        top: position?.top2,
                        left: position?.left2 + 10,
                        transform: `translateY(-${position?.top2 - 10}px)`,
                        zIndex: 10,
                        backgroundColor: 'var(--vz-secondary-bg)'
                    }}>
                        {typeShowInfoRight === 'JSON_TEMPLATE' ? t('Json Template') : t('Prompt')}
                    </span>
                    <div className="h-100-pc w-100 overflow-x-hidden">
                        <Row className="w-100-pc px-0 h-100-pc">
                            <Col lg={8} className={`h-100-pc mt-2 mt-md-0`}>
                                <span className="position-absolute text-success fs-13 text-end px-2 d-block d-lg-none" style={{
                                    top: 0,
                                    left: 5,
                                    transform: `translateY(-${10}px)`,
                                    zIndex: 10,
                                    backgroundColor: 'var(--vz-secondary-bg)'
                                }}>
                                    {t('Generated News')}
                                </span>
                                <div ref={refLeft} className="inner-modal-body color-scrollbar-os position-relative " style={{ border: '1.5px dashed var(--vz-success)', minHeight: '50dvh' }}>
                                    <span className="d-flex align-items-center text-success position-absolute fs-13" style={{ top: 5, right: 10 }}>
                                        {!!newsDetail?.title && <span className="text-primary d-flex align-items-center cursor-pointer me-3" onClick={() => copyTextToClipboard(`${newsDetail?.title || ''}\n\n${newsDetail?.body}`)}><i className="ri ri-file-copy-line" style={{ transform: 'translateY(1px)' }} /> {t('Copy')}</span>}
                                    </span>
                                    <div className="p-2">
                                        {(!!isGenerating || !!isReGenerating) ? <SpinnerScreen zIndex={9} isShow={true} /> : (
                                            <><p className="mb-3 fs-22 fs-md-23" style={{ lineHeight: '170%', fontWeight: 600 }}>
                                                <HighlightText text={newsDetail?.title || ''} keyword={newsDetail?.keyword || ''} sub_keyword={newsDetail?.keyword_sub} />
                                            </p>
                                                <p className="mb-3 mt-3 fs-17 fs-md-18" style={{ whiteSpace: 'pre-line', lineHeight: '170%' }}>
                                                    <HighlightText text={newsDetail?.body || ''} keyword={newsDetail?.keyword || ''} sub_keyword={newsDetail?.keyword_sub} />
                                                </p></>)}
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className="h-100-pc ms-1 ms-lg-0 pe-3 me-lg-0 mt-4  mt-md-0">
                                <span className="position-absolute text-primary fs-13 text-end px-2 d-block d-lg-none" style={{
                                    top: 0,
                                    left: 5,
                                    transform: `translateY(-${10}px)`,
                                    zIndex: 9,
                                    backgroundColor: 'var(--vz-secondary-bg)'
                                }}>
                                    {typeShowInfoRight === 'JSON_TEMPLATE' ? t('Json Template') : t('Prompt')}
                                </span>
                                <Row className="h-100-pc align-content-between gap-3">
                                    {isLoadingJsonTemplate && <span
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            textAlign: 'center',
                                            zIndex: 99
                                        }}
                                    ><Spinner size="sm" className="text-primary fs-20" style={{ width: '40px', height: '40px' }}></Spinner></span>}
                                    <>
                                        <div className={`color-scrollbar-os position-relative box-source-news chill-1 px-0`} ref={refRight}>
                                            <div className="px-0" style={{ minHeight: '70dvh' }}>
                                                {(typeShowInfoRight === 'JSON_TEMPLATE') ? (<JSONPretty id="json-pretty" data={formatToJson(jsonTemplate)}></JSONPretty>
                                                ) :
                                                    <div className="py-3 px-2">
                                                        <div className="">
                                                            <div className="p-2 fs-17 fs-md-18" style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: newsDetail?.prompt || '' }}></div>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter className="position-relative footer-button-new" lang={i18n?.language || 'en'}>
                    <div className="w-100 d-flex gap-2 justify-content-between align-items-center flex-wrap ">
                        <strong>
                            {/* <div className="d-flex flex-column align-items-start">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 fs-14 fs-md-16">
                                        {t('Main Keyword Frequency')}:
                                    </div>
                                    <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                                        <span className="fw-medium">{(!!isGenerating || !!isReGenerating) ? 0 : (Number(newsDetail?.count_title || 0) + Number(newsDetail?.count_body || 0))}</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 fs-14 fs-md-16">
                                        {t('Sub Keyword Frequency')}:
                                    </div>
                                    <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                                        <span className="fw-medium">{(!!isGenerating || !!isReGenerating) ? 0 : (Number(newsDetail?.sub_count_title || 0) + Number(newsDetail?.sub_count_body || 0))}</span>
                                    </div>
                                </div>
                            </div> */}
                        </strong>
                        <div className="d-flex flex-grow-1 gap-2 gap-sm-3 justify-content-end justify-content-sm-end mt-2 mt-sm-0">
                            <div className="d-flex gap-2 gap-sm-3">
                                <button
                                    className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center"
                                    type="button"
                                    color="light"
                                    onClick={clickShowInfoRight}
                                    style={{ width: '120px' }}
                                >
                                    <span className="ms-1">{typeShowInfoRight === 'JSON_TEMPLATE' ? t('Prompt') : t('Json Template')}</span>
                                </button>
                                <button
                                    className="btn btn-secondary fs-14 px-1 d-flex justify-content-center align-item-center"
                                    type="button"
                                    color="light"
                                    onClick={onCloseClick}
                                    style={{ width: '75px' }}
                                >
                                    <i className="ri-close-fill" style={{ lineHeight: '22px' }} /> <span className="ms-1">{t("Button Close")}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={!!addTemplate}
                centered={true}
                size="md"
                scrollable={true}
            >
                <ModalHeader toggle={() => setAddTemplate(null)}>
                    <>{t("Button Add Template")}</>
                </ModalHeader>
                <ModalBody className="pt-0">
                    <div>
                        <Col sm={12} md={12} xl={12} className="mt-0">
                            <Label htmlFor="name" className="form-label">{t('Name')}<span className="text-danger"> *</span></Label>
                            <Input className="form-control"
                                id="name"
                                type="text"
                                value={addTemplate?.name || ''}
                                placeholder={t('Name')}
                                onChange={(e) => {
                                    setAddTemplate((_prev: any) => ({ ..._prev, name: e?.target?.value || '' }))
                                }}
                                autoComplete='off'
                            />
                        </Col>
                        <Col sm={12} md={12} xl={12} className="mt-3">
                            <Label htmlFor="content" className="form-label">{t('Prompt')}</Label>
                            <Input className="form-control"
                                id="content"
                                type="textarea"
                                rows={4}
                                value={addTemplate?.content || ''}
                                placeholder={t('Prompt')}
                                onChange={(e) => {
                                    setAddTemplate((_prev: any) => ({ ..._prev, content: e?.target?.value || '' }))
                                }}
                                autoComplete='off'
                            />
                        </Col>
                        <Col sm={12} md={12} xl={12} className='mt-4 pt-1 text-end'>
                            <button
                                style={{ width: '160px' }}
                                type="button"
                                onClick={() => handleAddTemplate()}
                                disabled={isTemplating || (!addTemplate?.name)}
                                className="btn btn-primary fs-14 rounded-pill">
                                {isTemplating ? <Spinner size="sm me-2" ></Spinner> : <></>}
                                {t('Button Add Template')}
                            </button>
                        </Col>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
};

export default ModalGenerateCarNews;

