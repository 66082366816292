import { ICategory } from 'api/types/_category';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import DropdownOption from 'components/Common/DropdownOption';
import Hashtag from 'components/Common/Hashtag';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import { useFormik } from "formik";
import { AI_MODEL_OPTIONS, SYSTEM_CONFIG_KEY } from 'helpers/constans';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { getAllCategory, getConfiguration, putConfiguration, putConfigurationCategory } from 'store/thunks';
import * as Yup from "yup";


const Configuration = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const AI_MODEL_OPTIONS_LANG: any = AI_MODEL_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [categoryActive, setCategoryActive] = useState<number>(0);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }
    setIsOpen((_prev) => true);
  };

  const handleSubmitConfiguration = async (values: any) => {
    try {
      if (isLoading) {
        return;
      }

      setIsLoading((_prev) => true);

      const _data_configs = {
        configs: [
          {
            key: SYSTEM_CONFIG_KEY.NEWS_BOOSTING_CREATE_DAILY_SEARCH_VOLUME_FROM,
            value: String(values?.daily_search_volume_from || ''),
            is_active: 1,
          }
        ]
      };

      const _data_category_configs = {
        keyword_title_count: Number(values?.keyword_title_count),
        keyword_body_count: Number(values?.keyword_body_count),
        keyword_content_count: Number(values?.keyword_content_count),

        sub_keyword_content_count: Number(values?.sub_keyword_content_count),

        source_start_minus_day: Number(values?.source_start_minus_day),
        source_max: Number(values?.source_max),
        source_count: Number(values?.source_count),
        total_news_per_day: Number(values?.total_news_per_day),
        ai_model: values?.ai_model?.value || AI_MODEL_OPTIONS_LANG[0]?.value,
        prompt_format: values?.prompt_format,
        allow_reuse_source_article: Number(values?.allow_reuse_source_article),
        keyword_excludes: (values?.keyword_excludes || [])?.map((k: any) => (k?.text || ''))
      };

      const category_id: number = categories[categoryActive]?.id;
      const [res1, res2]: any = await Promise.all([putConfiguration(_data_configs), putConfigurationCategory(category_id, _data_category_configs)]);
      setCategories((_prev: any) => {
        return _prev?.map((_c: any, idx: number) => ((idx === categoryActive) ? ({ ..._c, config: _data_configs }) : _c));
      });
      setIsOpen((_prev) => false);
      setIsLoading((_prev) => false);
      if (res1?.code === 200 && res2?.code === 200) {
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${res1?.message || res1 || res2?.message || res2}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsOpen((_prev) => false);
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    daily_search_volume_from: Yup.string().nullable(),
    prompt_format: Yup.string().nullable(),
    total_news_per_day: Yup.string().nullable(),
    keyword_title_count: Yup.string().nullable(),
    keyword_body_count: Yup.string().nullable(),
    keyword_content_count: Yup.string().nullable(),
    sub_keyword_content_count: Yup.string().nullable(),
    source_count: Yup.string().nullable(),
    source_max: Yup.string().nullable(),
    source_start_minus_day: Yup.string().nullable(),
    ai_model: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).nullable(),
    allow_reuse_source_article: Yup.number().nullable(),
    keyword_excludes: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        text: Yup.string(),
      })
    ).nullable(),
  });

  const formik = useFormik({
    initialValues: {
      daily_search_volume_from: '',
      prompt_format: '',
      total_news_per_day: '',
      keyword_title_count: '',
      keyword_body_count: '',
      keyword_content_count: '',
      sub_keyword_content_count: '',
      source_count: '',
      source_max: '',
      source_start_minus_day: '',
      ai_model: null,
      allow_reuse_source_article: 0,
      keyword_excludes: []
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handelChangeCategory = (index: number) => {
    setCategoryActive((_prev) => index);
    const _category_current = categories[index];
    setValueForm(_category_current?.config || {});
  };

  const setValueForm = (_configs: any) => {

    const _config_prompt_format = _configs?.prompt_format || '';
    const _config_total_news_per_day = _configs?.total_news_per_day;
    const _config_keyword_title_count = _configs?.keyword_title_count;
    const _config_keyword_body_count = _configs?.keyword_body_count;
    const _config_keyword_content_count = _configs?.keyword_content_count;
    const _config_sub_keyword_content_count = _configs?.sub_keyword_content_count;
    const _config_source_max = _configs?.source_max;
    const _config_source_count = _configs?.source_count;
    const _config_source_start_minus_day = _configs?.source_start_minus_day;

    const _config_ai_model = AI_MODEL_OPTIONS_LANG?.find((v: any) => String(v?.value) === String(_configs?.ai_model)) || AI_MODEL_OPTIONS_LANG[0];

    const _config_allow_reuse_source_article = _configs?.allow_reuse_source_article;

    const _config_keyword_excludes = _configs?.keyword_excludes?.map((k: any) => ({
      id: k || '',
      text: k || '',
    })) || [];

    const _config_daily_search_volume_from = _configs?.daily_search_volume_from;

    formik?.setFieldValue('prompt_format', _config_prompt_format);
    formik?.setFieldValue('total_news_per_day', _config_total_news_per_day);
    formik?.setFieldValue('keyword_title_count', _config_keyword_title_count);
    formik?.setFieldValue('keyword_body_count', _config_keyword_body_count);
    formik?.setFieldValue('keyword_content_count', _config_keyword_content_count);
    formik?.setFieldValue('sub_keyword_content_count', _config_sub_keyword_content_count);
    formik?.setFieldValue('source_count', _config_source_count);
    formik?.setFieldValue('source_max', _config_source_max);
    formik?.setFieldValue('source_start_minus_day', _config_source_start_minus_day);
    formik?.setFieldValue('ai_model', _config_ai_model);
    formik?.setFieldValue('allow_reuse_source_article', Number(_config_allow_reuse_source_article || 0));
    formik?.setFieldValue('keyword_excludes', _config_keyword_excludes);

    if (_config_daily_search_volume_from !== null && _config_daily_search_volume_from !== undefined) {
      formik?.setFieldValue('daily_search_volume_from', _config_daily_search_volume_from);
    }
  };

  const handleCallAllOption = async () => {
    try {
      const [res1, res2]: any = await Promise.all([getAllCategory({}), getConfiguration({})]);
      setCategories((_prev) => res1?.data || []);
      if (res1?.data && res2?.data) {
        const _configs = res2?.data?.list || [];
        const _config_daily_search_volume_from = _configs?.find((item: any) => item?.key === SYSTEM_CONFIG_KEY.NEWS_BOOSTING_CREATE_DAILY_SEARCH_VOLUME_FROM) || {};
        setValueForm({ ...(res1?.data?.[0]?.config || {}), daily_search_volume_from: _config_daily_search_volume_from?.value });
      }
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t('Configuration')} - ${t('Boosting News')} | PRESS Ai`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Boosting News')} pageTitle={t('Configuration')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.CONFIGURATION_AI_NEWS, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)', minHeight: '75dvh' }}>
                  <CardBody className="pt-3 mb-3">
                    <form onSubmit={formik.handleSubmit}>
                      <div style={{ maxWidth: '100%', margin: '0 auto' }}>
                        <Row>
                          <Col md={4} className="px-4">
                            <Row>
                              <div className="position-relative mb-3 mt-1 mt-md-0" style={{ border: '1px dashed var(--vz-primary)', borderRadius: '4px', padding: '10px' }}>
                                <span className="text-primary position-absolute fs-13 px-1" style={{ backgroundColor: "var(--vz-secondary-bg)", top: '0px', transform: 'translateY(-50%)' }}>{t('Boosting Keyword')}</span>
                                <Col sm={12} md={12} xl={12} className='mt-2 '>
                                  <Label htmlFor="daily_search_volume_from" className="form-label">{t('Daily Search Volume')} <span className="text-danger"> *</span>
                                    <TooltipCustom
                                      title={t("Only generate news for keywords with a daily search volume above the specified threshold.")}
                                      className="d-inline-block vertical-align-middle ms-1"
                                      style={{ transform: 'translateY(1px)' }}
                                    >
                                      <i className="ri-question-line align-bottom text-secondary" ></i>
                                    </TooltipCustom>
                                  </Label>
                                  <Input
                                    className="form-control"
                                    id="daily_search_volume_from"
                                    name="daily_search_volume_from"
                                    type="text"
                                    value={formik?.values?.daily_search_volume_from || ''}
                                    placeholder={t('Daily Search Volume')}
                                    autoComplete='off'
                                    onChange={(event: any) => {
                                      const input = event.target.value;
                                      const onlyNumbers = input.replace(/\D/g, "");
                                      const s = onlyNumbers;
                                      formik.setFieldValue("daily_search_volume_from", s);
                                    }}
                                  />
                                  {formik.touched.daily_search_volume_from && formik.errors.daily_search_volume_from ? (
                                    <div className="text-danger mt-2">{formik.errors.daily_search_volume_from}</div>
                                  ) : null}
                                </Col>
                              </div>
                            </Row>
                          </Col>

                          <Col md={8} className="px-4">
                            <Row>
                              <div className="mb-3 position-relative" style={{ border: '1px dashed var(--vz-primary)', borderRadius: '4px', padding: '10px' }}>
                                <span className="text-primary position-absolute fs-13 px-1" style={{ backgroundColor: "var(--vz-secondary-bg)", top: '0px', transform: 'translateY(-50%)' }}>{t('Category')}</span>
                                <Col sm={12} md={12} xl={12} className='mb-3 mt-2 px-0'>
                                  {/* <div className="position-relative mb-4" style={{ borderBottom: '1px dashed var(--vz-secondary-color)', opacity: '0.8' }}>
                                    <span className="position-absolute px-3 text-primary" style={{
                                      left: '50%',
                                      top: '0%',
                                      transform: 'translate(-50%, -50%)',
                                      backgroundColor: 'var(--vz-card-bg)',
                                      textAlign: 'center'
                                    }}>{t('Category')}</span>
                                  </div> */}
                                  <div className="d-flex gap-2 flex-wrap" style={{ minHeight: '29px' }}>
                                    {categories?.map((item, index) => (
                                      <button type="button" className={`btn px-3 btn-sm px-md-4 py-btn-section ${categoryActive === index ? `bg-primary text-white` : `border-gray text-secondary`}`} key={item?.id}
                                        onClick={(e) => { e.preventDefault(); handelChangeCategory(index) }}
                                      >
                                        {t(item?.name || '')}
                                      </button>
                                    ))}
                                  </div>
                                </Col>
                                <div className="mb-3 position-relative" style={{ border: '1px dashed var(--vz-primary)', borderRadius: '4px', padding: '10px' }}>
                                  <span className="text-primary position-absolute fs-13 px-1" style={{ backgroundColor: "var(--vz-secondary-bg)", top: '0px', transform: 'translateY(-50%)' }}>{t('Keyword')}</span>
                                  <Col sm={12} md={12} xl={12} className='mt-1 '>
                                    <Label htmlFor="keyword_title_count" className="form-label">{t('Title')} <span className="text-danger"> *</span>
                                      <TooltipCustom
                                        title={t("Sets the number of keyword matches in the title when collecting articles.")}
                                        className="d-inline-block vertical-align-middle ms-1"
                                        style={{ transform: 'translateY(1px)' }}
                                      >
                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                      </TooltipCustom>
                                    </Label>
                                    <Input
                                      className="form-control"
                                      id="keyword_title_count"
                                      name="keyword_title_count"
                                      type="text"
                                      value={formik?.values?.keyword_title_count || ''}
                                      placeholder={t('Title')}
                                      autoComplete='off'
                                      onChange={(event: any) => {
                                        const input = event.target.value;
                                        const onlyNumbers = input.replace(/\D/g, "");
                                        const s = onlyNumbers;
                                        formik.setFieldValue("keyword_title_count", s);
                                      }}
                                    />
                                    {formik.touched.keyword_title_count && formik.errors.keyword_title_count ? (
                                      <div className="text-danger mt-2">{formik.errors.keyword_title_count}</div>
                                    ) : null}
                                  </Col>

                                  <Col sm={12} md={12} xl={12} className='mt-2 '>
                                    <Label htmlFor="keyword_body_count" className="form-label">{t('Body')} <span className="text-danger"> *</span>
                                      <TooltipCustom
                                        title={t("Sets the number of keyword matches in the body when collecting articles.")}
                                        className="d-inline-block vertical-align-middle ms-1"
                                        style={{ transform: 'translateY(1px)' }}
                                      >
                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                      </TooltipCustom>
                                    </Label>
                                    <Input
                                      className="form-control"
                                      id="keyword_body_count"
                                      name="keyword_body_count"
                                      type="text"
                                      value={formik?.values?.keyword_body_count || ''}
                                      placeholder={t('Body')}
                                      autoComplete='off'
                                      onChange={(event: any) => {
                                        const input = event.target.value;
                                        const onlyNumbers = input.replace(/\D/g, "");
                                        const s = onlyNumbers;
                                        formik.setFieldValue("keyword_body_count", s);
                                      }}
                                    />
                                    {formik.touched.keyword_body_count && formik.errors.keyword_body_count ? (
                                      <div className="text-danger mt-2">{formik.errors.keyword_body_count}</div>
                                    ) : null}
                                  </Col>

                                  <Col sm={12} md={12} xl={12} className='mt-2 pb-1'>
                                    <Label htmlFor="keyword_content_count" className="form-label">{t('Title + Body')} <span className="text-danger"> *</span>
                                      <TooltipCustom
                                        title={t("Sets the number of keyword matches in either the title or the body when collecting articles.")}
                                        className="d-inline-block vertical-align-middle ms-1"
                                        style={{ transform: 'translateY(1px)' }}
                                      >
                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                      </TooltipCustom>
                                    </Label>
                                    <Input
                                      className="form-control"
                                      id="keyword_content_count"
                                      name="keyword_content_count"
                                      type="text"
                                      autoComplete='off'
                                      value={formik?.values?.keyword_content_count || ''}
                                      placeholder={t('Title + Body')}
                                      onChange={(event: any) => {
                                        const input = event.target.value;
                                        const onlyNumbers = input.replace(/\D/g, "");
                                        const s = onlyNumbers;
                                        formik.setFieldValue("keyword_content_count", s);
                                      }}
                                    />
                                    {formik.touched.keyword_content_count && formik.errors.keyword_content_count ? (
                                      <div className="text-danger mt-2">{formik.errors.keyword_content_count}</div>
                                    ) : null}
                                  </Col>
                                </div>

                                <div style={{ border: '1px dashed var(--vz-primary)', borderRadius: '4px', padding: '10px' }} className="position-relative">
                                  <span className="text-primary position-absolute fs-13 px-1" style={{ backgroundColor: "var(--vz-secondary-bg)", top: '0px', transform: 'translateY(-50%)' }}>{t('Sub Keyword')}</span>
                                  <Col sm={12} md={12} xl={12} className='mt-2 mb-1 '>
                                    <Label htmlFor="sub_keyword_content_count" className="form-label">{t('Title + Body')} <span className="text-danger"> *</span>
                                      <TooltipCustom
                                        title={t("Sets the number of sub-keyword matches in either the title or the body when collecting articles.")}
                                        className="d-inline-block vertical-align-middle ms-1"
                                        style={{ transform: 'translateY(1px)' }}
                                      >
                                        <i className="ri-question-line align-bottom text-secondary" ></i>
                                      </TooltipCustom>
                                    </Label>
                                    <Input
                                      className="form-control"
                                      id="sub_keyword_content_count"
                                      name="sub_keyword_content_count"
                                      type="text"
                                      autoComplete='off'
                                      value={formik?.values?.sub_keyword_content_count || ''}
                                      placeholder={t('Title + Body')}
                                      onChange={(event: any) => {
                                        const input = event.target.value;
                                        const onlyNumbers = input.replace(/\D/g, "");
                                        const s = onlyNumbers;
                                        formik.setFieldValue("sub_keyword_content_count", s);
                                      }}
                                    />
                                    {formik.touched.sub_keyword_content_count && formik.errors.sub_keyword_content_count ? (
                                      <div className="text-danger mt-2">{formik.errors.sub_keyword_content_count}</div>
                                    ) : null}
                                  </Col>
                                </div>
                                <Col sm={12} md={12} xl={12} className='mt-2 '>
                                  <Label htmlFor="source_start_minus_day" className="form-label">{t('Source Dates')} <span className="text-danger"> *</span>
                                    <TooltipCustom
                                      title={t("Sets the past date range for collecting articles.")}
                                      className="d-inline-block vertical-align-middle ms-1"
                                      style={{ transform: 'translateY(1px)' }}
                                    >
                                      <i className="ri-question-line align-bottom text-secondary" ></i>
                                    </TooltipCustom>
                                  </Label>
                                  <Input
                                    className="form-control"
                                    id="source_start_minus_day"
                                    name="source_start_minus_day"
                                    type="text"
                                    autoComplete='off'
                                    value={formik?.values?.source_start_minus_day || ''}
                                    placeholder={t('Source Dates')}
                                    onChange={(event: any) => {
                                      const input = event.target.value;
                                      const onlyNumbers = input.replace(/\D/g, "");
                                      const s = onlyNumbers;
                                      formik.setFieldValue("source_start_minus_day", s);
                                    }}
                                  />
                                  {formik.touched.source_start_minus_day && formik.errors.source_start_minus_day ? (
                                    <div className="text-danger mt-2">{formik.errors.source_start_minus_day}</div>
                                  ) : null}
                                </Col>

                                <Col sm={12} md={12} xl={12} className='mt-2 '>
                                  <Label htmlFor="source_max" className="form-label">{t('Max Articles')} <span className="text-danger"> *</span>
                                    <TooltipCustom
                                      title={t("Sets the maximum number of articles to be collected.")}
                                      className="d-inline-block vertical-align-middle ms-1"
                                      style={{ transform: 'translateY(1px)' }}
                                    >
                                      <i className="ri-question-line align-bottom text-secondary" ></i>
                                    </TooltipCustom>
                                  </Label>
                                  <Input
                                    className="form-control"
                                    id="source_max"
                                    name="source_max"
                                    type="text"
                                    autoComplete='off'
                                    value={formik?.values?.source_max || ''}
                                    placeholder={t('Max Articles')}
                                    onChange={(event: any) => {
                                      const input = event.target.value;
                                      const onlyNumbers = input.replace(/\D/g, "");
                                      const s = onlyNumbers;
                                      formik.setFieldValue("source_max", s);
                                    }}
                                  />
                                  {formik.touched.source_max && formik.errors.source_max ? (
                                    <div className="text-danger mt-2">{formik.errors.source_max}</div>
                                  ) : null}
                                </Col>
                                <Col sm={12} md={12} xl={12} className='mt-2'>
                                  <div style={{ minHeight: '80px' }}>
                                    <label className="form-label">
                                      {t("Exclusion Keywords for Collection")}
                                    </label>
                                    <TooltipCustom
                                      title={t("When collecting source contents, articles with titles containing the specified keywords will not be collected.")}
                                      className="d-inline-block vertical-align-middle ms-1"
                                      style={{ transform: 'translateY(1px)' }}
                                    >
                                      <i className="ri-question-line align-bottom text-secondary" ></i>
                                    </TooltipCustom>
                                    <Hashtag
                                      name="keyword_excludes"
                                      placeholder={`${t("Exclusion Keywords for Collection")}...`}
                                      suggestions={[]}
                                      initialValue={formik?.values?.keyword_excludes || []}
                                      onChangeTag={(event: any) => {
                                        formik.setFieldValue("keyword_excludes", event);
                                      }}
                                      idSelected="hashtags-CampaignFormMulti"
                                      maxHashtags={2000}
                                      maxHeight="150px"
                                    />
                                  </div>
                                </Col>
                                <Col sm={12} md={12} xl={12} className='mt-2 '>
                                  <Label htmlFor="source_count" className="form-label">{t('Source Articles')} <span className="text-danger"> *</span>
                                    <TooltipCustom
                                      title={t("Sets the number of articles to be used for generating new content.")}
                                      className="d-inline-block vertical-align-middle ms-1"
                                      style={{ transform: 'translateY(1px)' }}
                                    >
                                      <i className="ri-question-line align-bottom text-secondary" ></i>
                                    </TooltipCustom>
                                  </Label>
                                  <Input
                                    className="form-control"
                                    id="source_count"
                                    name="source_count"
                                    type="text"
                                    autoComplete='off'
                                    value={formik?.values?.source_count || ''}
                                    placeholder={t('Source Articles')}
                                    onChange={(event: any) => {
                                      const input = event.target.value;
                                      const onlyNumbers = input.replace(/\D/g, "");
                                      const s = onlyNumbers;
                                      formik.setFieldValue("source_count", s);
                                    }}
                                  />
                                  {formik.touched.source_count && formik.errors.source_count ? (
                                    <div className="text-danger mt-2">{formik.errors.source_count}</div>
                                  ) : null}
                                </Col>
                                <Col sm={12} md={12} xl={12} className='mt-2 '>
                                  <Label htmlFor="total_news_per_day" className="form-label">{t('Total News Per Day')} <span className="text-danger"> *</span>
                                    <TooltipCustom
                                      title={t("Sets the maximum number of articles to be generated per day for each keyword.")}
                                      className="d-inline-block vertical-align-middle ms-1"
                                      style={{ transform: 'translateY(1px)' }}
                                    >
                                      <i className="ri-question-line align-bottom text-secondary" ></i>
                                    </TooltipCustom>
                                  </Label>
                                  <Input
                                    className="form-control"
                                    id="total_news_per_day"
                                    name="total_news_per_day"
                                    type="text"
                                    autoComplete='off'
                                    value={formik?.values?.total_news_per_day || ''}
                                    placeholder={t('Total News Per Day')}
                                    onChange={(event: any) => {
                                      const input = event.target.value;
                                      const onlyNumbers = input.replace(/\D/g, "");
                                      const s = onlyNumbers;
                                      formik.setFieldValue("total_news_per_day", s);
                                    }}
                                  />
                                  {formik.touched.total_news_per_day && formik.errors.total_news_per_day ? (
                                    <div className="text-danger mt-2">{formik.errors.total_news_per_day}</div>
                                  ) : null}
                                </Col>
                                <Col sm={12} md={12} xl={12} className='mt-2'>
                                  <Label htmlFor="ai_model" className="form-label">{t('AI Model')}</Label> <span className="text-danger"> *</span>
                                  <DropdownOption
                                    name="ai_model"
                                    dataList={AI_MODEL_OPTIONS_LANG || []}
                                    placeholder={`${t("AI Model")}...`}
                                    className={`dropdown-status-rounded z-hight mb-3 mb-lg-2 mb-xxl-1`}
                                    classNamePrefix="name-prefix"
                                    style={{ width: '100%' }}
                                    initialValue={formik?.values?.ai_model}
                                    onChangeSelect={(e: any) => {
                                      formik.setFieldValue("ai_model", e);
                                    }}
                                  />
                                </Col>
                                <Col sm={12} md={12} xl={12} className='mt-2 '>
                                  <Label htmlFor="prompt_format" className="form-label">{t('Prompt')} <span className="text-danger"> *</span>
                                    <TooltipCustom
                                      title={t("Specifies the SLM prompt to be used for generating articles.")}
                                      className="d-inline-block vertical-align-middle ms-1"
                                      style={{ transform: 'translateY(1px)' }}
                                    >
                                      <i className="ri-question-line align-bottom text-secondary" ></i>
                                    </TooltipCustom>
                                  </Label>
                                  <Input
                                    className="form-control"
                                    id="prompt_format"
                                    name="prompt_format"
                                    type="textarea"
                                    autoComplete='off'
                                    rows={8}
                                    value={formik?.values?.prompt_format || ''}
                                    placeholder={t('Prompt')}
                                    onChange={(e: any) => {
                                      formik?.setFieldValue('prompt_format', e?.target?.value || '');
                                    }}
                                  />
                                  {formik.touched.prompt_format && formik.errors.prompt_format ? (
                                    <div className="text-danger mt-2">{formik.errors.prompt_format}</div>
                                  ) : null}
                                </Col>
                                <Col sm={12} md={12} xl={12} className='mt-3 '>
                                  <div className="form-check form-switch mb-0">
                                    <Input className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id="allow_reuse_source_article"
                                      name="allow_reuse_source_article"
                                      checked={!!formik?.values?.allow_reuse_source_article}
                                      onChange={(event: any) => formik.setFieldValue("allow_reuse_source_article", Number(event?.target.checked))}
                                    />
                                    <Label className="form-check-label" for="allow_reuse_source_article">{t('Allow reusing the source article for each session generated.')}</Label>
                                  </div>
                                </Col>
                              </div>
                            </Row>
                          </Col>
                          <Col sm={12} md={12} xl={12} className='mt-4 pt-1 text-end'>
                            <button
                              style={{ width: '150px' }}
                              type="submit"
                              disabled={(isLoading)}
                              className="btn btn-primary fs-14 rounded-pill">
                              {<>{t('Button Update')}</>}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>

        <Modal
          isOpen={!!isOpen}
          centered={true}
          // size="lg"
          toggle={() => setIsOpen(false)}
          keyboard={true}
        >
          <ModalHeader>
            {t('Configuration')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col sm={12}>
                <div className="row g-3">
                  <div className="mt-3 text-center">
                    <h6 className="mb-2 fs-19 text-center mt-1">
                      {t("Are you sure you want to update this?")}
                    </h6>
                    <p className="fs-15 mb-4 d-flex justify-content-center align-items-start align-items-sm-center text-center ">
                      {t('After confirming the update action, the data will be updated. Do you want to proceed with the update action.')}
                    </p>
                    <div className="d-flex gap-3 mt-2 justify-content-center">
                      <button
                        className="btn btn-primary rounded-pill fs-14"
                        type="button"
                        color="light"
                        onClick={() => handleSubmitConfiguration(formik?.values)}
                        style={{ width: '120px' }}
                      >
                        {(isLoading) ? <Spinner size="sm me-2" ></Spinner> : <>{t('Button Update')}</>}
                      </button>
                      <button
                        className="btn btn-secondary rounded-pill fs-14 ms-2"
                        type="button"
                        color="light"
                        onClick={() => setIsOpen(false)}
                        style={{ width: '120px' }}
                      >
                        {t("Button Close")}
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal >

        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />

      </div >
    </React.Fragment >
  );
};

export default Configuration;