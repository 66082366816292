import { INews, IPartner } from 'api/types/_news';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import CopyWrapper from 'components/Common/CopyWrapper';
import DropdownOption from 'components/Common/DropdownOption';
import InputsRange from 'components/Common/InputsRange';
import LabelWrapper from 'components/Common/LabelWrapper';
import TableContainer from 'components/Common/TableContainer';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import RangeDatePicker from 'components/pickers/RangeDatePicker';
import { NEWS_TYPE, SECTION_KEYWORD_OPTIONS, STATUS_NEWS_OPTIONS, TYPE_NEWS_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from 'react-countup';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from 'reselect';
import { deleteNewsMulti, getAllCategory, getAllPartners, getArticleTrackings as onGetArticleTrackings, postPublishUrlNews } from 'store/thunks';
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import HighlightText from '../Components/HighlightText';
import ImportArticleForm from '../Components/ImportArticleForm';
import ImportMediaArticleForm from '../Components/ImportMediaArticleForm';
import NestingFilter from '../Components/NestingFilter';
import ChartToBase64 from './ChartToBase64';
import RankingChart from './RankingChart';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import ModalConfirm from 'components/Common/ModalConfirm';

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'ctr_count';

const TrackingNewsList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const modalBodyInnerRef = useRef<any>(null);

  const STATUS_NEWS_OPTIONS_LANG = STATUS_NEWS_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const SECTION_KEYWORD_OPTIONS_LANG = SECTION_KEYWORD_OPTIONS?.map((item: any) => ({
    value: `${item?.value}`,
    label: t(item?.label),
  }));

  const TYPE_NEWS_OPTIONS_LANG = TYPE_NEWS_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const RANK_NEWS_OPTIONS_LANG = [{ value: '2', label: 'Included' }, { value: '1', label: 'Not Included' }]?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const IMPRESSION_OPTIONS_LANG = [{ value: '1', label: 'Yes' }, { value: '0', label: 'No' }]?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const END_DATE = `${moment().format("Y-MM-DD")}`;

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 100),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, 'DESC'),
    date: withDefault(StringParam, END_DATE),
    keyword: withDefault(StringParam, ''),
    title: withDefault(StringParam, ''),
    partner_id: withDefault(StringParam, ''),
    partner_code_id: withDefault(StringParam, ''),
    exposure_rank_start: withDefault(StringParam, ''),
    exposure_rank_end: withDefault(StringParam, ''),
    impression_count_start: withDefault(StringParam, ''),
    impression_count_end: withDefault(StringParam, ''),
    news_type: withDefault(StringParam, ''),
    section: withDefault(StringParam, ''),
    category_id: withDefault(StringParam, ''),
    partner_news_expose_flg: withDefault(StringParam, ''),
    published_start: withDefault(StringParam, `${moment().subtract(7, 'days').format("Y-MM-DD")}`),
    published_end: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
  });

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

  const [startExposureSearch, setStartExposureSearch] = useState<string>(query?.exposure_rank_start || "");
  const [endExposureSearch, setEndExposureSearch] = useState<string>(query?.exposure_rank_end || "");

  const [startImpressionSearch, setStartImpressionSearch] = useState<string>(query?.impression_count_start || "");
  const [endImpressionSearch, setEndImpressionSearch] = useState<string>(query?.impression_count_end || "");

  const [rankSearch, setRankSearch] = useState<Option | null>(RANK_NEWS_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.exposure_rank_type)) || null);

  const [impressionSearch, setImpressionSearch] = useState<Option | null>(IMPRESSION_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.partner_news_expose_flg)) || null);

  const [typeNewsSearch, setTypeNewsSearch] = useState<Option | null>(TYPE_NEWS_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.news_type)) || null);

  const [sectionSearch, setSectionSearch] = useState<Option | null>(!!(query?.section || query?.category_id) ? { value: query?.section || query?.category_id, label: '' } : null);

  const [mediaSearch, setMediaSearch] = useState<Option | null>(!!query?.partner_id ? { value: query?.partner_id, label: '' } : null);

  const [mediaSectionSearch, setMediaSectionSearch] = useState<Option | null>(!!query?.partner_code_id ? { value: query?.partner_code_id, label: '' } : null);

  const [dateSearch, setDateSearch] = useState<any>(query?.date ? moment(query?.date, 'Y-MM-DD').toDate() : null);

  const [dateRangeSearch, setDateRangeSearch] = useState<any[]>([query?.published_start ? moment(query?.published_start, 'Y-MM-DD').toDate() : null, query?.published_end ? moment(query?.published_end || '', 'Y-MM-DD').toDate() : null]);
  const [startDate, endDate] = dateRangeSearch;

  const [boostingCategories, setBoostingCategories] = useState<Option[]>([]);

  const [isOpenImportMediaNews, setIsOpenImportMediaNews] = useState<boolean>(false);

  const [isOpenImportNews, setIsOpenImportNews] = useState<boolean>(false);

  const [isOpenChart, setIsOpenChart] = useState<boolean>(false);
  const [chart, setChart] = useState<any>(null);

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<{news_id: number} | null>(null);
  
  const [partners, setPartners] = useState<IPartner[]>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Tracking;

  const TrackingProperties = createSelector(
    selectLayoutState,
    (state) => ({
      articleTrackings: state.articleTrackings,
      isArticleTrackingSuccess: state.isArticleTrackingSuccess,
      isArticleTrackingLoading: state.isArticleTrackingLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { articleTrackings, error, isArticleTrackingLoading } = useSelector(TrackingProperties);

  useEffect(() => {
    dispatch(onGetArticleTrackings(query));
  }, [JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      keyword: keywordSearch || '',
      title: titleSearch || '',
      partner_id: mediaSearch?.value || '',
      partner_code_id: mediaSectionSearch?.value || '',
      exposure_rank_start: startExposureSearch || '',
      exposure_rank_end: endExposureSearch || '',

      impression_count_start: startImpressionSearch || '',
      impression_count_end: endImpressionSearch || '',

      exposure_rank_type: rankSearch?.value || '',
      news_type: typeNewsSearch?.value,
      partner_news_expose_flg: impressionSearch?.value || '',
      category_id: boostingCategories?.find((_s) => _s?.value === sectionSearch?.value)?.value || '',
      section: SECTION_KEYWORD_OPTIONS_LANG?.find((_s) => _s?.value === sectionSearch?.value)?.value || '',
      date: dateSearch ? moment(new Date(dateSearch)).format("Y-MM-DD") : '',
      published_start: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      published_end: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      page: 1,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      title: '',
      keyword: '',
      partner_id: '',
      partner_code_id: '',
      exposure_rank_start: '',
      exposure_rank_end: '',
      impression_count_start: '',
      impression_count_end: '',
      exposure_rank_type: '',
      partner_news_expose_flg: '',
      news_type: '',
      section: '',
      category_id: '',
      date: `${moment().format("Y-MM-DD")}`,
      published_start: `${moment().subtract(7, 'days').format("Y-MM-DD")}`,
      published_end: `${moment().format("Y-MM-DD")}`,
      sort_by: TYPE_SELECT_DEFAULT,
      order_by: 'DESC',
      page: 1,
      time_request: + new Date(),
    };
    setQuery(queryNew, "push");
    setTitleSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setSectionSearch(null);
    setRankSearch(null);
    setTypeNewsSearch(null);
    setMediaSearch(null);
    setMediaSectionSearch(null);
    setImpressionSearch(null);
    setStartExposureSearch(queryNew?.exposure_rank_start);
    setEndExposureSearch(queryNew?.exposure_rank_end);
    setStartImpressionSearch(queryNew?.impression_count_start);
    setEndImpressionSearch(queryNew?.impression_count_end);
    setDateSearch(moment(queryNew?.date || "", 'Y-MM-DD').toDate());
    setDateRangeSearch([moment(queryNew?.published_start || "", 'Y-MM-DD').toDate(), moment(queryNew?.published_end || "", 'Y-MM-DD').toDate()]);
  };

  // Begin:: update link
  const [itemUpdateLink, setItemUpdateLink] = useState<{ id: number, link: string } | null>(null);
  const [isLoadingUpdateLink, setIsLoadingUpdateLink] = useState<boolean>(false);

  const handleUpdateLink = async () => {
    try {
      if (!itemUpdateLink) {
        return;
      }
      setIsLoadingUpdateLink((_prev) => true);
      const response: any = await postPublishUrlNews(itemUpdateLink?.id, { url: itemUpdateLink?.link });
      setIsLoadingUpdateLink((_prev) => false);
      if (response?.code === 200) {
        setItemUpdateLink((_prev) => null);
        dispatch(onGetArticleTrackings(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdateLink((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  // End:: update memo

  const handleOpenModelChart = (_chart: any) => {
    setChart((_prev: any) => _chart);
    setIsOpenChart((_prev) => true);
  };


  // Begin::Delete
  const handleConfirmDelete = (item: any) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!initialValuesDefault?.news_id && !countRowSelected) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const ids = initialValuesDefault?.news_id ? [initialValuesDefault?.news_id] : (optionsSelected || []).flat()?.map((item: any) => item?.value);
      const response: any = await deleteNewsMulti({
        ids: ids
      });
      if (response?.code === 200) {
        dispatch(onGetArticleTrackings(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  const [optionsSelected, setOptionsSelected] = useState<any>([]);

  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    // if (!!isFirstLoadingPageRef.current) {
    //   return;
    // }
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          if (prevClone[index]?.length > 0) {
            prevClone[index] = [];
            return prevClone;
          }
          prevClone[index] = (articleTrackings?.list || [])?.reduce((arr:any, item: any) => ( String(item?.news_type) === String(NEWS_TYPE.EXTERNAL_NEWS) ? ([...arr, ({ value: item?.news_id, label: item?.news_id })]) : arr), []);
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === articleTrackings?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  // End:: handle select table 

  // Column
  const columns = useMemo(
    () => [
      // {
      //   Header: t('Key'),
      //   accessor: "",
      //   filterable: true,
      //   sortable: false,
      //   thClass: 'text-start align-middle',
      //   thWidth: 50,
      //   thComponent: () => (<>
      //     <div className="form-check">
      //       <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
      //       <label className="form-check-label" htmlFor={`cell-check-all`}></label>
      //     </div>
      //   </>
      //   ),
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <>
      //         <div className={`form-check`}>
      //           <input className="form-check-input" type="checkbox" disabled={String(item?.news_type) !== String(NEWS_TYPE.EXTERNAL_NEWS)} name="table" checked={isChecked({ value: cell?.row?.original?.news_id, label: cell?.row?.original?.news_id })} value={cell?.row?.original?.news_id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.news_id, label: cell?.row?.original?.news_id }), query?.page, e)} id={`cell-check-${cell?.row?.original?.news_id ?? ''}`} />
      //           <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.news_id ?? ''}`}></label>
      //         </div>
      //       </>
      //     )
      //   },
      // },
      {
        Header: t('ID'),
        accessor: "news_id",
        filterable: true,
        sortable: true,
        thWidth: 90,
        thClass: "align-middle text-center",
        Cell: (cell: any) => (
          <>
            <div className="text-center" style={{ minWidth: '60px' }}>
              <CopyWrapper contentCopy={cell?.value}>
                {cell?.value}
              </CopyWrapper>
            </div>
          </>
        ),
      },
      {
        Header: t('Media'),
        accessor: "partner_id",
        filterable: true,
        sortable: false,
        thWidth: 140,
        thClass: 'align-middle text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const _partner = partners?.find((_p: any) => String(_p?.id) === String(item?.partner_id));
          return (<>
            <div style={{ minWidth: '120px' }} className="text-center">
              {_partner?.name || '-'}
            </div>
          </>)
        },
      },
      {
        Header: t('Media Section'),
        accessor: "partner_code_id",
        filterable: false,
        sortable: false,
        thClass: "align-middle text-center",
        thWidth: 150,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const _partner = partners?.find((_p: any) => String(_p?.id) === String(item?.partner_id));
          const _partner_code = _partner?.partner_codes?.find((_s: any) => String(_s?.id) === String(item?.partner_code_id))
          return (
            <div style={{ minWidth: '120px' }} className="text-center">
              {_partner_code?.section_title || '-'}
            </div>
          );
        }
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: true,
        sortable: true,
        thWidth: 120,
        thClass: 'align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ width: '80px' }} className="fs-15">{cell?.value || '-'}</div>
          </>)
        },
      },
      {
        Header: t('Sub Keywords'),
        accessor: "keyword_sub",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ width: '100px' }} className="fs-15">{cell?.value || '-'}</div>
          </>)
        },
      },
      {
        Header: t('Article Title'),
        accessor: "title",
        filterable: true,
        sortable: false,
        thClass: 'align-middle',
        thWidth: (window.innerWidth < 1650 ? 270 : 600),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <CopyWrapper contentCopy={item?.title || ''} style={{ display: 'inline-flex' }}>
              <div style={{ minWidth: '250px', maxWidth: '600px' }} className="fs-15">
                {String(item?.news_type) === String(NEWS_TYPE.EXTERNAL_NEWS) && <div className="icon-e">E</div>}
                <Link
                  className="nav-link hover-underline d-inline-block"
                  target="_blank"
                  to={item?.partner_news_url || ''} >
                  <HighlightText text={item?.title || ''} keyword={item?.keyword || ''} opacity_bg={0.7} />
                </Link>
              </div>
            </CopyWrapper>
          </>)
        },
      },
      {
        Header: t('Impression Trend'),
        accessor: "impression_chart",
        filterable: true,
        sortable: false,
        thWidth: i18n?.language === 'en' ? 160 : 150,
        thClass: 'align-middle text-center',
        description: t('Impression trend for the past 7 days up to the previous day.'),
        Cell: (cell: any) => {
          const item = cell?.row?.original; // Number(c?.impression_count || 0)
          const _data_chart = item?.chart?.map((c: any) => ({ date: c?.date, index: Number(c?.impression_count || 0) }))
          return (<>
            <div className="d-flex justify-content-center text-center" onClick={() => handleOpenModelChart({ type: 'IMPRESSION_TREND', title: t('Impression Trend'), keyword: t('Impression'), data: _data_chart })}>
              <div style={{ width: '120px', height: '28px', overflow: 'hidden' }} className="cursor-pointer">
                <ChartToBase64 data={_data_chart} name={item?.keyword || ''} />
              </div>
            </div>
          </>)
        },
      },
      {
        Header: t('Daily Search Volume'),
        accessor: "daily_search_volume",
        filterable: true,
        sortable: true,
        thWidth: i18n?.language === 'en' ? 190 : 110,
        thClass: 'align-middle text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center pe-2" style={{ minWidth: i18n?.language === 'en' ? '170px' : '80px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
          </>)
        },
      },
      {
        Header: t('Impression Rate'),
        accessor: "impression_rate_count",
        filterable: true,
        sortable: true,
        thWidth: i18n?.language === 'en' ? 170 : 100,
        thClass: 'align-middle text-center',
        description: t('The impression rate is the percentage of exposure compared to the daily search volume.'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center" style={{ minWidth: i18n?.language === 'en' ? '150px' : '80px' }}>{formatNumberWithCommas(cell?.value || 0)} %</div>
          </>)
        },
      },
      {
        Header: t('Impression'),
        accessor: "impression_count",
        filterable: true,
        sortable: true,
        thWidth: i18n?.language === 'en' ? 120 : 80,
        thClass: 'align-middle text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center" style={{ minWidth: i18n?.language === 'en' ? '100px' : '60px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
          </>)
        },
      },
      {
        Header: t('Click'),
        accessor: "click_count",
        filterable: true,
        sortable: true,
        thWidth: 80,
        thClass: 'align-middle text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center" style={{ minWidth: '60px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
          </>)
        },
      },
      {
        Header: t('CTR'),
        accessor: "ctr_count",
        filterable: true,
        sortable: true,
        thWidth: 80,
        thClass: 'align-middle text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center" style={{ minWidth: '60px' }}>{formatNumberWithCommas(cell?.value || 0)} %</div>
          </>)
        },
      },
      {
        Header: t('Rank Trend'),
        accessor: "rank_chart",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: 'align-middle text-center',
        description: t('Article ranking trends for the past 7 days up to the previous day.'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const _data_chart = item?.chart?.map((c: any) => ({ date: c?.date, index: Number(c?.exposure_rank || 0) }))
          return (<>
            <div className="d-flex justify-content-center text-center" onClick={() => handleOpenModelChart({ type: 'RANK_TREND', title: t('Rank Trend'), keyword: t('Rank'), data: _data_chart })}>
              <div style={{ width: '120px', height: '28px', overflow: 'hidden' }} className="cursor-pointer">
                <ChartToBase64 data={_data_chart} name={item?.keyword || ''} isShowReversedY={true} />
              </div>
            </div>
          </>)
        },
      },
      {
        Header: t('Article Rank'),
        accessor: "exposure_rank",
        filterable: true,
        sortable: true,
        thWidth: i18n?.language === 'en' ? 140 : 110,
        thClass: 'align-middle text-center',
        description: t('The rank at which the article appears in Naver News search results.'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center pe-3" style={{ minWidth: i18n?.language === 'en' ? '120px' : '90px' }}>
              {
                (cell?.value !== null) ? <>
                  {formatNumberWithCommas(cell?.value || 0)}
                </> : '-'
              }
            </div>
          </>)
        },
      },
      {
        Header: t('Published At'),
        accessor: "publish_at",
        filterable: true,
        sortable: false,
        thWidth: i18n?.language === 'en' ? 140 : 100,
        thClass: 'align-middle text-end',
        description: t('The date the article was published to the media via Press AI.'),
        thComponent: () => (
          <span onClick={() => {
            handleChangeSorting({
              sort_by: "publish_at",
              order_by: query?.order_by === "DESC" && query?.sort_by === "publish_at" ? "ASC" : "DESC",
            });
          }}>
            {t("Published At")}
            {query?.sort_by === "publish_at" && (
              <span
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  width: "12px",
                  marginLeft: "2px",
                  verticalAlign: "middle",
                }}
              >
                {query?.order_by === "DESC" ? (
                  <i className={`ri-arrow-up-line fs-13 text-primary`} />
                ) : (
                  <i
                    className={`ri-arrow-down-line fs-13 text-primary`}
                  />
                )}
              </span>
            )}
          </span>
        ),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <div className="text-end pe-3" style={{ minWidth: i18n?.language === 'en' ? '120px' : '80px' }}>
              <TooltipCustom
                title={item?.publish_at || ''}
                className="d-inline-block vertical-align-middle ms-1"
                style={{ transform: 'translateY(1px)' }}
              >
                <span>{item?.publish_at_label || ''}</span>
              </TooltipCustom>
            </div>
          )
        },
      },
      {
        Header: t('Article Date'),
        accessor: "partner_news_rank_time",
        filterable: true,
        sortable: false,
        thWidth: i18n?.language === 'en' ? 140 : 110,
        thClass: 'align-middle text-end',
        description: t('The date the article was first registered in Naver News search results.'),
        thComponent: () => (
          <span onClick={() => {
            handleChangeSorting({
              sort_by: "partner_news_rank_time",
              order_by: query?.order_by === "DESC" && query?.sort_by === "partner_news_rank_time" ? "ASC" : "DESC",
            });
          }}>
            {t("Article Date")}
            {query?.sort_by === "partner_news_rank_time" && (
              <span
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  width: "12px",
                  marginLeft: "2px",
                  verticalAlign: "middle",
                }}
              >
                {query?.order_by === "DESC" ? (
                  <i className={`ri-arrow-up-line fs-13 text-primary`} />
                ) : (
                  <i
                    className={`ri-arrow-down-line fs-13 text-primary`}
                  />
                )}
              </span>
            )}
          </span>
        ),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <div className="text-end pe-3" style={{ minWidth: i18n?.language === 'en' ? '120px' : '90px' }}>
              {item?.partner_news_rank_time ? <TooltipCustom
                title={item?.partner_news_rank_time || ''}
                className="d-inline-block vertical-align-middle ms-1"
                style={{ transform: 'translateY(1px)' }}
              >
                <span>{item?.partner_news_rank_time_label || ''}</span>
              </TooltipCustom> : <>-</>}
            </div>
          )
        },
      },
      {
        Header: t('Type'),
        accessor: "news_type",
        filterable: true,
        sortable: false,
        thWidth: i18n?.language === 'en' ? 120 : 90,
        thClass: 'align-middle text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center" style={{ minWidth: i18n?.language === 'en' ? '100px' : '70px' }}>
              {TYPE_NEWS_OPTIONS_LANG?.find((_t) => String(_t?.value) === String(item?.news_type))?.label || '-'}
            </div>
          </>)
        },
      },
      {
        Header: t('Section'),
        accessor: "section",
        filterable: true,
        sortable: false,
        thClass: 'align-middle text-center',
        thWidth: i18n?.language === 'en' ? 130 : 90,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<div style={{ minWidth: i18n?.language === 'en' ? '110px' : '70px' }} className="text-center">
            {item?.category?.name || (SECTION_KEYWORD_OPTIONS_LANG?.find((s) => String(s?.value) === String(item?.section))?.label || '-')}
          </div>)
        },
      },
      {
        Header: t('Date'),
        accessor: "date",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: 'align-middle text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '80px' }}>
              <span><span>{arrDate?.[0] || ''}</span></span>
            </div>
          )
        },
      },
     
      {
        Header: t('Created At'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: i18n?.language === 'en' ? 140 : 100,
        thClass: 'align-middle text-end',
        description: t('The date the article was created in Boosting News.'),
        thComponent: () => (
          <span onClick={() => {
            handleChangeSorting({
              sort_by: "created_at",
              order_by: query?.order_by === "DESC" && query?.sort_by === "created_at" ? "ASC" : "DESC",
            });
          }}>
            {t("Created At")}
            {query?.sort_by === "created_at" && (
              <span
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  width: "12px",
                  marginLeft: "2px",
                  verticalAlign: "middle",
                }}
              >
                {query?.order_by === "DESC" ? (
                  <i className={`ri-arrow-up-line fs-13 text-primary`} />
                ) : (
                  <i
                    className={`ri-arrow-down-line fs-13 text-primary`}
                  />
                )}
              </span>
            )}
          </span>
        ),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <div className="text-end pe-3" style={{ minWidth: i18n?.language === 'en' ? '120px' : '80px' }}>
              <TooltipCustom
                title={item?.created_at || ''}
                className="d-inline-block vertical-align-middle ms-1"
                style={{ transform: 'translateY(1px)' }}
              >
                <span>{item?.created_at_label || ''}</span>
              </TooltipCustom>
            </div>
          )
        },
      },
      // {
      //   Header: t('Updated At'),
      //   accessor: "updated_at",
      //   filterable: true,
      //   sortable: true,
      //   thWidth: 140,
      //   thClass: 'align-middle text-end',
      //   Cell: (cell: any) => {
      //     const item = cell?.row?.original;
      //     return (
      //       <div className="text-end pe-3" style={{ minWidth: '130px' }}>
      //         <TooltipCustom
      //           title={item?.updated_at || ''}
      //           className="d-inline-block vertical-align-middle ms-1"
      //           style={{ transform: 'translateY(1px)' }}
      //         >
      //           <span>{item?.updated_at_label || ''}</span>
      //         </TooltipCustom>
      //       </div>
      //     )
      //   },
      // },
      {
        Header: t('Actions'),
        accessor: "__",
        thClass: 'align-middle text-center',
        thWidth: 80,
        sortable: false,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0" style={{ minWidth: '60px' }}>
              {(isHavePermissionRole(ROLES_FOR_APP.ARTICLE_TRACKING, userPermissions)  &&  String(item?.news_type) === String(NEWS_TYPE.EXTERNAL_NEWS))&& (
                <TooltipCustom
                  title={t('Button Delete News')}
                  id={`detail-cp-${item?.id}`}
                >
                  <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                    >
                      <i className="ri-delete-bin-3-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
            </ul>
          );
        },
      },

    ],
    [i18n?.language, JSON.stringify(partners), ] // JSON.stringify(optionsSelected)
  );

  const triggerRefresh = () => {
    setIsOpenImportMediaNews((_prev) => false);
    setIsOpenImportNews((_prev) => false);
    dispatch(onGetArticleTrackings(query));
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy, page: 1 });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [res1, res2]: any = await Promise.all([getAllCategory(), getAllPartners({})]);
      const _boostingCategories = (res1?.data)?.map((_c: any) => ({ label: _c?.name, value: `${_c?.id}` }));
      setBoostingCategories((_prev: any) => _boostingCategories);
      setPartners((_prev: any) => res2?.data);
      if (query?.partner_id) {
        const _partner = res2?.data?.find((_p: any) => String(_p?.id) === String(query?.partner_id));
        setMediaSearch({ value: `${_partner?.id}`, label: _partner?.name });
      }
      if (query?.partner_code_id) {
        const _partner = res2?.data?.find((_p: any) => String(_p?.id) === String(query?.partner_id));
        const _partner_code = _partner?.partner_codes?.find((_s: any) => String(_s?.id) === String(query?.partner_code_id));
        setMediaSectionSearch({ value: `${_partner_code?.id}`, label: _partner_code?.section_title });
      }
      if (query?.category_id) {
        const _category = (_boostingCategories)?.find((s: any) => String(s?.value) === String(query?.category_id));
        setSectionSearch(_category);
      }
      if (query?.section) {
        const _section: any = (SECTION_KEYWORD_OPTIONS_LANG)?.find((s) => String(s?.value) === String(query?.section));
        setSectionSearch(_section);
      }
    } catch (error: any) {
      return error;
    }
  };

  const handleChangeDatePicker = (date: any) => {
    setDateSearch((_prev: any) => date);
  }

  const handleChangeDateRangePicker = (values: any[] = []) => {
    setDateRangeSearch((_prev: any) => values);
  }

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (!mediaSearch?.value) {
      setMediaSearch({ label: t('All'), value: '' });
    }
    if (!mediaSectionSearch?.value) {
      setMediaSectionSearch({ label: t('All'), value: '' });
    }
    if (!sectionSearch?.value) {
      setSectionSearch({ label: t('All'), value: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  useEffect(() => {
    document.title = `${t('News Monitor')} | ${t('PRESS Ai')}`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('News Monitor')} pageTitle={t('Home')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.AI_NEWS, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                  <div className="ribbon round-shape top-info-md" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', }}>{t('This data is analyzed per 10 mins')}</div>
                  <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                    <Row className="g-4 align-items-center">
                      <div className="col-12 col-md-6">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            {(isArticleTrackingLoading && query?.page === 1) ? <Spinner size="sm" className="text-primary"></Spinner> : (<CountUp
                              start={0}
                              end={articleTrackings?.pagination?.total || 0}
                              duration={1}
                              className="text-primary"
                            />)}
                          </h5>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 text-end mt-3 mt-md-4">
                        <div className={`d-flex gap-3 flex-wrap justify-content-end padding-md-right-info-${i18n?.language}`}>
                          {isHavePermissionRole(ROLES_FOR_APP.AI_NEWS, userPermissions) && (<button
                            type="button"
                            className="btn btn-success add-btn rounded-pill mb-2 mb-sm-0"
                            onClick={() => setIsOpenImportNews((_prev) => true)}
                          >
                            <i className="ri-add-line align-bottom"></i>
                            <span className="ms-1">{t('Button Import Article')}</span>
                          </button>)}
                          {isHavePermissionRole(ROLES_FOR_APP.AI_NEWS, userPermissions) && (<button
                            type="button"
                            className="btn btn-success add-btn rounded-pill mb-2 mb-sm-0"
                            onClick={() => setIsOpenImportMediaNews((_prev) => true)}
                          >
                            <i className="ri-upload-line align-bottom"></i>
                            <span className="ms-1">{t('Button Import Bulk Articles')}</span>
                          </button>)}
                        </div>
                      </div>

                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4 px-0 px-lg-3 pt-0 pt-lg-3">
                    <NestingFilter>
                      <Row className="g-4 align-items-end mt-0">
                        <Col sm={6} md={6} lg={6} xl={4} xxl={2} className='mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Media')} isShow={true}>
                            <DropdownOption
                              name="media"
                              dataList={(partners || [])?.map((_p: any) => ({ label: _p?.name, value: `${_p?.id}` }))}
                              placeholder={`${t("Media")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={mediaSearch || null}
                              onChangeSelect={(e: any) => setMediaSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={4} xxl={2} className='mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Media Section')} isShow={true}>
                            <DropdownOption
                              name="media_section"
                              dataList={((partners || [])?.find((_p: any) => String(_p?.id) === String(mediaSearch?.value))?.partner_codes || [])?.map((_s: any) => ({ label: _s?.section_title, value: `${_s?.id}` }))}
                              placeholder={`${t("Media Section")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={mediaSectionSearch || null}
                              onChangeSelect={(e: any) => setMediaSectionSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col sm={6} md={6} lg={6} xl={4} xxl={2} className='mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Type')} isShow={true}>
                            <DropdownOption
                              name="type_news"
                              dataList={TYPE_NEWS_OPTIONS_LANG || []}
                              placeholder={`${t("Type")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={typeNewsSearch || null}
                              onChangeSelect={(e: any) => setTypeNewsSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col sm={6} md={6} lg={6} xl={4} xxl={2} className='mt-3 mt-xxl-2'>
                          <LabelWrapper label={typeNewsSearch?.value === '2' ? t("Category") : t('Section')} isShow={true}>
                            <DropdownOption
                              name="category_or_section"
                              dataList={(typeNewsSearch?.value === '1') ? SECTION_KEYWORD_OPTIONS_LANG : (typeNewsSearch?.value === '2' ? boostingCategories : [])}
                              placeholder={`${typeNewsSearch?.value === '2' ? t("Category") : t('Section')}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={sectionSearch || null}
                              onChangeSelect={(e: any) => setSectionSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col sm={6} md={6} lg={6} xl={4} xxl={2} className='date-picker-wrapper-custom mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Published At')} isShow={!!startDate || !!endDate}>
                            <DatePickerCustom
                              placeholder={`${t('Start Date')} - ${t('End Date')}`}
                              startDate={startDate || null}
                              endDate={endDate || null}
                              onChangePicker={handleChangeDateRangePicker}
                              showOptions={[
                                'today', 'yesterday', 'two_day_ago',
                                'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                'this_week', 'last_week',
                                'this_month', 'last_month',
                              ]}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col sm={6} md={6} lg={6} xl={4} xxl={2} className='date-picker-wrapper-custom mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                            <RangeDatePicker
                              maxDate={moment().toDate()}
                              dateSearch={dateSearch}
                              handleChangeDatePicker={handleChangeDatePicker}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={12} xxl={4} className='order-2 order-xxl-0 mt-3 mt-xxl-3' >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={startExposureSearch}
                            valueTo={endExposureSearch}
                            placeholderFrom={`${t('Rank (Start)')}...`}
                            placeholderTo={`${t('Rank (End)')}...`}
                            labelFrom={`${t('Rank (Start)')}`}
                            labelTo={`${t('Rank (End)')}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) => setStartExposureSearch(val)}
                            onChangeTo={(val) => setEndExposureSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>

                        <Col sm={6} md={6} lg={6} xl={8} xxl={5} className='order-2 order-xxl-0 mt-3 mt-xxl-3' >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={startImpressionSearch}
                            valueTo={endImpressionSearch}
                            placeholderFrom={`${t('Impression (Start)')}...`}
                            placeholderTo={`${t('Impression (End)')}...`}
                            labelFrom={`${t('Impression (Start)')}`}
                            labelTo={`${t('Impression (End)')}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) => setStartImpressionSearch(val)}
                            onChangeTo={(val) => setEndImpressionSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>

                        <Col sm={6} md={6} lg={6} xl={4} xxl={3} className='order-2 order-xxl-0 mt-3 mt-xxl-3' >
                          <LabelWrapper label={t('Impression')} isShow={true}>
                            <DropdownOption
                              name="impression"
                              dataList={IMPRESSION_OPTIONS_LANG || []}
                              placeholder={`${t("Impression")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={impressionSearch || null}
                              onChangeSelect={(e: any) => setImpressionSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col sm={6} md={6} lg={6} xl={4} xxl={2} className='order-3 order-xxl-0 mt-3 mt-xxl-3'>
                          <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('Keyword')}...`}
                              value={keywordSearch}
                              onChange={(e) => setKeywordSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={8} xxl={7} className='order-4 order-xxl-0 mt-3 mt-xxl-3'>
                          <LabelWrapper label={t('News Title')} isShow={!!titleSearch}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('News Title')}...`}
                              value={titleSearch}
                              onChange={(e) => setTitleSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={12} xxl={3} className="order-5 order-xxl-0 hstack gap-1 justify-content-center justify-content-sm-end mt-3 mt-xxl-3">
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-2 me-lg-2"
                              onClick={searchData}
                              disabled={isArticleTrackingLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t('Button Search')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t('Button Reset')}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </NestingFilter>
                    {(Number(countRowSelected) > 0) && <Col sm={12} className="mt-4">
                      {isHavePermissionRole(ROLES_FOR_APP.ARTICLE_TRACKING, userPermissions) &&
                            <button
                              type="button"
                              className="btn btn-soft-danger"
                              style={{ width: '85px' }}
                              disabled={countRowSelected <= 0}
                              onClick={(e) => { e.stopPropagation(); handleConfirmDelete(null) }}
                            >
                              {countRowSelected > 0 && (
                                <span
                                  className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                  style={{ transform: "translate(0%, -70%)" }}
                                >
                                  {formatNumberWithCommas(countRowSelected)}
                                  <span className="visually-hidden">
                                    total keywords selected
                                  </span>
                                </span>
                              )}
                              {t('Button Delete')}
                            </button>
                          }
                      </Col >}
                  </div>
                  <CardBody className="pt-0 px-0">
                    <div className="mx-3 my-4 mt-3">
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle sticky-table-tracking-news"
                        theadClass="table-light"
                        columns={columns}
                        data={articleTrackings?.list?.length ? articleTrackings?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={articleTrackings?.pagination?.total}
                        customPageCount={Math.ceil(Number(articleTrackings?.pagination?.total) / Number(articleTrackings?.pagination?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isArticleTrackingLoading}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}</Row>
        </Container>
        <ToastContainer closeButton={false} newestOnTop={false} className="toast---------text" pauseOnFocusLoss autoClose={2000} limit={2} />
        <Modal
          isOpen={itemUpdateLink !== null}
          centered={true}
          size="md"
          toggle={() => setItemUpdateLink((_prev) => null)}
          keyboard={true}
        >
          <ModalHeader toggle={() => setItemUpdateLink((_prev) => null)}>
            {t('Button Update Link')}
          </ModalHeader>
          <ModalBody className="">
            <Col md={12} lg={12}>
              <div>
                <label className="form-label">
                  {t("Link")}
                  <span className="text-danger"> *</span>
                </label>
                <Input
                  type="textarea"
                  rows={2}
                  id="link"
                  name="link"
                  autocomplete={false}
                  value={itemUpdateLink?.link}
                  placeholder={`${t("Link")}...`}
                  onChange={(event: any) => {
                    setItemUpdateLink((prev: any) => ({ ...prev, link: event?.target?.value || '' }))
                  }}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                <button
                  className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                  color="light"
                  type="button"
                  disabled={isLoadingUpdateLink}
                  style={{ width: '100px' }}
                  onClick={() => setItemUpdateLink((_prev) => null)}
                >
                  {t("Button Close")}
                </button>
                <button
                  className="btn btn-primary rounded-pill fs-14"
                  color="success"
                  type="button"
                  disabled={isLoadingUpdateLink}
                  style={{ width: '150px' }}
                  onClick={() => handleUpdateLink()}
                >
                  {isLoadingUpdateLink ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) :
                    <> {t("Button Update")}</>}
                </button>
              </div>
            </Col>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={isOpenChart}
          centered={true}
          size="lg"
          scrollable={false}
          toggle={() => setIsOpenChart((_prev) => false)}
        >
          <ModalHeader toggle={() => setIsOpenChart((_prev) => false)}>
            <>{chart?.title || ''}</>
          </ModalHeader>
          <ModalBody className="pt-2">
            <div style={{ minHeight: "170px" }}>
              <RankingChart
                data={chart?.data}
                name={chart?.keyword || ''}
                height={window.innerWidth < 750 ? 250 : 300}
                style={{ transform: 'translateY(0px)' }}
                isShowXaxis={true}
                isShowYaxis={true}
                isShowTooltip={true}
                showAxisBorder={true}
                fillColor="#FDEBC7"
                fillOpacity={0}
                markersSize={3}
                isRotate={window.innerWidth < 750}
                tickAmountXaxis={8}
                isShowReversedY={chart?.type === 'RANK_TREND'}
              />
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={isOpenImportMediaNews}
          centered={true}
          size="md"
          scrollable={false}
        >
          <ModalHeader toggle={() => setIsOpenImportMediaNews((_prev) => false)}>
            <>{t("Button Import Bulk Articles")}</>
          </ModalHeader>
          <ModalBody className="pt-2">
            <div style={{ minHeight: "170px" }}>
              <ImportMediaArticleForm
                triggerRefresh={triggerRefresh}
              />
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={isOpenImportNews}
          centered={true}
          size="md"
          scrollable={false}
        >
          <ModalHeader toggle={() => setIsOpenImportNews((_prev) => false)}>
            <>{t("Button Import Article")}</>
          </ModalHeader>
          <ModalBody className="pt-2">
            <div style={{ minHeight: "170px" }}>
              <ImportArticleForm
                triggerRefresh={triggerRefresh}
              />
            </div>
          </ModalBody>
        </Modal>

        <ModalConfirm
          header={t('Button Delete News')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div >
    </React.Fragment >
  );
};

export default TrackingNewsList;