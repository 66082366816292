import { createAsyncThunk } from "@reduxjs/toolkit";
import categoryApi from 'api/categoryApi';
import { formatQueryParams } from "helpers/format";

export const getCategories = createAsyncThunk("Categories", async (params: any = {}) => {
  try {
    const response = await categoryApi.categories(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllCategory = async (params: any = {}) => {
  try {
    const response = await categoryApi.allCategory(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getCategory = async (id: string | number, params: any = {}) => {
  try {
    const response = await categoryApi.getCategory(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postCategory = async (data: any = {}) => {
  try {
    const response = await categoryApi.postCategory(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putCategory = async (id: string | number, data: any = {}) => {
  try {
    const response = await categoryApi.putCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCategory = async (id: string | number, data: any = {}) => {
  try {
    const response = await categoryApi.deleteCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const putConfigurationCategory = async (id: string | number, data: any = {}) => {
  try {
    const response = await categoryApi.putConfigurationCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const getAllSection = async (params: any = {}) => {
  try {
    const response = await categoryApi.allSection(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const allModelCar = async (params: any = {}) => {
  try {
    const response = await categoryApi.allModelCar(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getModelCar = async (id: string | number, params: any = {}) => {
  try {
    const response = await categoryApi.getModelCar(id, params)
    return response;
  } catch (error) {
    return error;
  }
};


export const allTemplateCar = async (params: any = {}) => {
  try {
    const response = await categoryApi.allTemplateCar(params)
    return response;
  } catch (error) {
    return error;
  }
};


export const postTemplateCar = async (data: any = {}) => {
  try {
    const response = await categoryApi.postTemplateCar(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putTemplateCar = async (id: string | number, data: any = {}) => {
  try {
    const response = await categoryApi.putTemplateCar(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

