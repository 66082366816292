import { ICategory } from 'api/types/_category';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { formatNumberWithCommas } from 'helpers/format';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import TableContainer from "../../components/Common/TableContainer";
import { deleteCategory, getCategories as onGetCategories } from "../../store/thunks";
import CategoryForm from './CategoryForm';
export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'seq';

registerLocale('en', en);
registerLocale('ko', ko);

export interface Props {
  triggerRefreshCategory?: () => void;
}

const CategoryManagement = ({ triggerRefreshCategory }: Props) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const [query, setQuery]: any = useState({
    time_request: 0,
    page: 1,
    limit: 30,
    sort_by: TYPE_SELECT_DEFAULT,
    order_by: 'ASC',
    name: '',
  });

  const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

  const [categoryEdit, setCategoryEdit] = useState<ICategory & { isCopy?: boolean } | null>(null);

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<ICategory | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Category;

  const CategoryProperties = createSelector(
    selectLayoutState,
    (state) => ({
      categories: state.categories,
      isCategorySuccess: state.isCategorySuccess,
      isCategoryLoading: state.isCategoryLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { categories, error, isCategoryLoading } = useSelector(CategoryProperties);


  useEffect(() => {
    dispatch(onGetCategories(query));
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery((_prev: any) => ({
      ..._prev,
      name: nameSearch || '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    }));
  };

  const resetData = () => {
    setQuery((_prev: any) => ({
      ..._prev,
      name: '',
      sort_by: TYPE_SELECT_DEFAULT,
      order_by: 'ASC',
      time_request: + new Date(),
      page: 1,
    }))
    setNameSearch((_prev) => "");
  };


  // Begin::Update User
  const onCloseClick = () => {
    setCategoryEdit((_prev) => null);
    setIsOpen((_prev) => false)
  }

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(onGetCategories(query));
    triggerRefreshCategory && triggerRefreshCategory();
  }
  //End::Update User

  // Begin::Delete
  const handleConfirmDelete = (item: ICategory) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteCategory(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetCategories(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        triggerRefreshCategory && triggerRefreshCategory();
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('ID'),
        accessor: "id",
        filterable: true,
        sortable: true,
        thWidth: 90,
        Cell: (cell: any) => (
          <>
            <span style={{ minWidth: '50px' }}>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Order'),
        accessor: "seq",
        filterable: true,
        sortable: true,
        thWidth: 100,
        thClass: 'text-center',
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '80px' }} className="text-center pe-3">{formatNumberWithCommas(cell?.value)}</div>
          </>
        ),
      },
      {
        Header: t('Name'),
        accessor: "name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '100px' }}>
              {item?.name || '-'}
            </div>
          </>)
        },
      },
      {
        Header: t('Keyword Count'),
        accessor: "keyword_count",
        filterable: true,
        sortable: false,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '100px' }} className="text-center">
              {formatNumberWithCommas(item?.keyword_count)}
            </div>
          </>)
        },
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 110,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 110,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Action'),
        thClass: 'text-center',
        thWidth: 100,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0" style={{ minWidth: '70px' }}>
              {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_UPDATE, userPermissions) && <TooltipCustom
                title={t('Button Update Category')}
                id={`update-us-${item?.id}`}
              >
                <li className=" list-inline-item me-0" id={`update-us-${item?.id}`}>
                  <Link className="btn btn-sm btn-soft-primary edit-item-btn" to="#"
                    onClick={(e) => { e.preventDefault(); setCategoryEdit((prev) => item) }}
                  >
                    <i className="ri-pencil-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>}
              {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_DELETE, userPermissions) && (
                <TooltipCustom
                  title={t('Button Delete Category')}
                  id={`detail-cp-${item?.id}`}
                >
                  <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                    >
                      <i className="ri-delete-bin-3-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, query, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery((_prev: any) => ({ ..._prev, page: page + 1 }));
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {

    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t('Lab')} - ${t('Category Management')} | PRESS Ai`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div>
        <div>
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-5 con-sm-6">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            <CountUp
                              start={0}
                              end={categories?.pagination?.total || 0}
                              duration={1}
                              className="text-primary"
                            />
                          </h5>
                        </div>
                      </div>
                      <div className="col-7 con-sm-6 text-end">
                        {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_CREATE, userPermissions) && (<div className="col-sm-auto">
                          <div>
                            <button
                              type="button"
                              className="btn btn-success add-btn rounded-pill"
                              id="create-btn"
                              onClick={() => setIsOpen((_prev) => true)}
                            >
                              <i className="ri-add-line align-bottom me-1"></i>
                              {t('Button Create Category')}
                            </button>
                          </div>
                        </div>)}
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={6} md={6} xl={2} xxl={2} className='mt-3 mt-md-2'>
                        <LabelWrapper label={t('Name')} isShow={!!nameSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Name')}...`}
                            value={nameSearch}
                            onChange={(e) => setNameSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} xl={4} xxl={4} className="hstack gap-1 justify-content-center justify-content-sm-end justify-content-md-between mt-3 mt-md-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isCategoryLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={categories?.list?.length ? categories?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={categories?.pagination?.total}
                      customPageCount={Math.ceil(Number(categories?.pagination?.total) / Number(categories?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isCategoryLoading}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}</Row>
        </div>
        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />
        <Modal
          isOpen={!!categoryEdit?.id || isOpen}
          centered={true}
          size="md"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseClick}>
            <>{!!(categoryEdit?.id) ? t("Button Update Category") : t("Button Create Category")}</>  <>{(categoryEdit?.id) && (' - ' + (categoryEdit?.name || '') || '')}</>
          </ModalHeader>
          <ModalBody className="pt-2">
            <CategoryForm
              isModal={true}
              seqNext={Number(categories?.pagination?.total) + 1}
              id={String(categoryEdit?.id || '')}
              isCopy={!!(categoryEdit?.isCopy)}
              triggerRefresh={triggerRefresh}
            />
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t('Button Delete Category')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div >
    </React.Fragment >
  );
};

export default CategoryManagement;