import { createSlice } from "@reduxjs/toolkit";
import { IKeywordBoost, IKeywordBoostV2, IKeywordRealTime } from "api/types/_keyword";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getKeywordBoosts, getKeywordBoostV2s, getKeywordRealTimes } from "./thunk";


export interface IState {
  keywordBoosts: ResponseData<IKeywordBoost[]> & PaginationResponse | null,
  isKeywordBoostLoading: boolean,
  isKeywordBoostSuccess: boolean,

  allKeywordBoost: ResponseData<IKeywordBoost[]> | null,

  keywordBoostV2s: ResponseData<IKeywordBoostV2[]> & PaginationResponse | null,
  isKeywordBoostV2Loading: boolean,
  isKeywordBoostV2Success: boolean,

  keywordRealTimes: ResponseData<IKeywordRealTime[]> & PaginationResponse | null,
  isKeywordRealTimeLoading: boolean,
  isKeywordRealTimeSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  keywordBoosts: null,
  isKeywordBoostLoading: false,
  isKeywordBoostSuccess: false,

  allKeywordBoost: null,

  keywordBoostV2s: null,
  isKeywordBoostV2Loading: false,
  isKeywordBoostV2Success: false,

  keywordRealTimes: null,
  isKeywordRealTimeLoading: false,
  isKeywordRealTimeSuccess: false,

  error: {},
};

const KeywordSlice = createSlice({
  name: "Keyword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get KeywordBoosts
    builder.addCase(getKeywordBoosts.pending, (state: IState, action: any) => {
      state.isKeywordBoostLoading = true
    });
    builder.addCase(getKeywordBoosts.fulfilled, (state: IState, action: any) => {
      state.keywordBoosts = action.payload.data;
      state.isKeywordBoostSuccess = true;
      state.isKeywordBoostLoading = false;
    });
    builder.addCase(getKeywordBoosts.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordBoostSuccess = false;
      state.isKeywordBoostLoading = false;
    });

    //get KeywordBoostV2s
    builder.addCase(getKeywordBoostV2s.pending, (state: IState, action: any) => {
      state.isKeywordBoostV2Loading = true
    });
    builder.addCase(getKeywordBoostV2s.fulfilled, (state: IState, action: any) => {
      state.keywordBoostV2s = action.payload.data;
      state.isKeywordBoostV2Success = true;
      state.isKeywordBoostV2Loading = false;
    });
    builder.addCase(getKeywordBoostV2s.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordBoostV2Success = false;
      state.isKeywordBoostV2Loading = false;
    });

    //get KeywordRealTimes
    builder.addCase(getKeywordRealTimes.pending, (state: IState, action: any) => {
      state.isKeywordRealTimeLoading = true
    });
    builder.addCase(getKeywordRealTimes.fulfilled, (state: IState, action: any) => {
      state.keywordRealTimes = action.payload.data;
      state.isKeywordRealTimeSuccess = true;
      state.isKeywordRealTimeLoading = false;
    });
    builder.addCase(getKeywordRealTimes.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordRealTimeSuccess = false;
      state.isKeywordRealTimeLoading = false;
    });
  },
});

export default KeywordSlice.reducer;