import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "components/Hooks/UserHooks";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes/allRoutes";

const Navdata = () => {
    const history = useNavigate();
    const { t } = useTranslation();
    const { userPermissions } = useRole();

    const [menuActive, setMenuActive] = useState<string>('DASHBOARD');

    // Multi Level
    const [isLevel1, setIsLevel1] = useState<boolean>(false);

    const [isCurrentState, setIsCurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
    }, [history]);

    const menuItems: any = [
        {
            id: "dashboard",
            label: t('Dashboards'),
            icon: <FeatherIcon icon="home" className="icon-dual" />,
            link: ROUTES.DASHBOARD,
            click: function (e: any) {
                setMenuActive((prev) => 'DASHBOARD');
                setIsCurrentState((prev) => 'Dashboard');
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'DASHBOARD'),
            isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD_VIEW, userPermissions),
            // subItems: []
        },
        {
            id: "multilevel12",
            label: t("AI News"),
            icon: <i className="ri-newspaper-line" />,
            link: ROUTES.AI_NEWS,
            click: function (e: any) {
                setMenuActive((prev) => "AI_NEWS");
                setIsCurrentState((prev) => "AI News");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "AI_NEWS",
            isShow: isHavePermissionRole([ROLES_FOR_APP.AI_NEWS], userPermissions),
        },
        {
            id: "multilevel12",
            label: t("Boosting News"),
            icon: <i className="ri-newspaper-line" />,
            link: ROUTES.BOOSTING_NEWS,
            click: function (e: any) {
                setMenuActive((prev) => "BOOSTING_NEWS");
                setIsCurrentState((prev) => "Boosting News");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "BOOSTING_NEWS",
            isShow: isHavePermissionRole([ROLES_FOR_APP.BOOSTING_NEWS], userPermissions),
        },
        {
            id: "multilevel12",
            label: t("Boosting Keyword"),
            icon: <i className="ri-flashlight-line" />,
            link: ROUTES.KEYWORD_BOOST_V2_LIST,
            click: function (e: any) {
                setMenuActive((prev) => "BOOSTING_KEYWORD_V2_LIST");
                setIsCurrentState((prev) => "Boosting Keyword");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "BOOSTING_KEYWORD_V2_LIST",
            isShow: isHavePermissionRole([ROLES_FOR_APP.KEYWORD_BOOST_LIST], userPermissions),
        },
        {
            id: "multilevel5",
            label: t("Boosting Content"),
            icon: <i className="ri-flashlight-line" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "BOOSTING_CONTENT");
                setIsCurrentState((prev) => "Boosting Content");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "BOOSTING_CONTENT",
            isShow: isHavePermissionRole(
                [
                    ROLES_FOR_APP.CAMPAIGN_CAR_LIST,
                    ROLES_FOR_APP.DATA_CAR_LIST
                ],
                userPermissions
            ),
            subItems: [
                {
                    id: "car",
                    label: t('Car'),
                    link: ROUTES.CAR,
                    isShow: isHavePermissionRole([ROLES_FOR_APP.CAMPAIGN_CAR_LIST, ROLES_FOR_APP.DATA_CAR_LIST], userPermissions),
                },
            ],
        },
        {
            id: "multilevel12",
            label: t("Real-Time News"),
            icon: <i className="ri-newspaper-line" />,
            link: ROUTES.REAL_TIME_NEWS,
            click: function (e: any) {
                setMenuActive((prev) => "REAL_TIME_NEWS");
                setIsCurrentState((prev) => "Real-Time News");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "REAL_TIME_NEWS",
            isShow: isHavePermissionRole([ROLES_FOR_APP.REAL_TIME_NEWS], userPermissions),
        },
        {
            id: "multilevel12",
            label: t("Real-Time Keyword"),
            icon: <i className="ri-flashlight-line" />,
            link: ROUTES.KEYWORD_REAL_TIME,
            click: function (e: any) {
                setMenuActive((prev) => "KEYWORD_REAL_TIME");
                setIsCurrentState((prev) => "Real-Time Keyword");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "KEYWORD_REAL_TIME",
            isShow: isHavePermissionRole([ROLES_FOR_APP.KEYWORD_REAL_TIME_LIST], userPermissions),
        },
        {
            id: "multilevel12",
            label: t("News Monitor"),
            icon: <i className="ri-line-chart-fill" />,
            link: ROUTES.ARTICLE_TRACKING,
            click: function (e: any) {
                setMenuActive((prev) => "ARTICLE_TRACKING");
                setIsCurrentState((prev) => "News Monitor");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "ARTICLE_TRACKING",
            isShow: isHavePermissionRole([ROLES_FOR_APP.ARTICLE_TRACKING], userPermissions),
        },
        {
            id: "multilevel5",
            label: t("Statistics"),
            icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "STATISTICS");
                setIsCurrentState((prev) => "Statistics");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "STATISTICS",
            isShow: isHavePermissionRole(
                [
                    ROLES_FOR_APP.DAILY_STATISTICS,
                    ROLES_FOR_APP.KEYWORD_STATISTICS,
                    ROLES_FOR_APP.MEDIA_STATISTICS,
                ],
                userPermissions
            ),
            subItems: [
                {
                    id: "daily-statistics",
                    label: t('Daily Statistics'),
                    link: ROUTES.DAILY_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.DAILY_STATISTICS, userPermissions),
                },
                {
                    id: "keyword-statistics",
                    label: t('Keyword Statistics'),
                    link: ROUTES.KEYWORD_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_STATISTICS, userPermissions),
                },
                {
                    id: "media-statistics",
                    label: t('Media Statistics'),
                    link: ROUTES.MEDIA_STATISTICS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.MEDIA_STATISTICS, userPermissions),
                },
            ],
        },
        // {
        //     id: "multilevel12",
        //     label: t("Journalists"),
        //     icon: <i className="ri-user-2-line" />,
        //     link: ROUTES.JOURNALIST_LIST,
        //     click: function (e: any) {
        //         setMenuActive((prev) => "JOURNALIST_LIST");
        //         setIsCurrentState((prev) => "Journalists");
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: menuActive === "JOURNALIST_LIST",
        //     isShow: isHavePermissionRole([ROLES_FOR_APP.JOURNALIST_LIST], userPermissions),
        // },
        {
            id: "multilevel12",
            label: t("User Management"),
            icon: <i className="ri-user-star-line" />,
            link: ROUTES.USER_LIST,
            click: function (e: any) {
                setMenuActive((prev) => "USER_MANAGEMENT");
                setIsCurrentState((prev) => "User Management");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "USER_MANAGEMENT",
            isShow: isHavePermissionRole([ROLES_FOR_APP.USER_LIST], userPermissions),
        },
        {
            id: "multilevel6",
            label: t("Operator Management"),
            icon: <FeatherIcon icon="users" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "USER");
                setIsCurrentState((prev) => "User");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "USER",
            isShow: isHavePermissionRole([ROLES_FOR_APP.OPERATOR_LIST, ROLES_FOR_APP.ROLE_LIST], userPermissions),
            subItems: [
                {
                    id: "user",
                    label: t("Operator"),
                    link: ROUTES.OPERATOR_LIST,
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.OPERATOR_LIST,
                        userPermissions
                    ),
                },
                {
                    id: "role-list",
                    label: t("Role"),
                    link: ROUTES.OPERATOR_ROLE,
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.ROLE_LIST,
                        userPermissions
                    ),
                },
                {
                    id: "activity-logs",
                    label: t("Activity Logs"),
                    link: ROUTES.OPERATOR_ACTIVITY_LOG,
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.ACTIVITY_LOG_LIST,
                        userPermissions
                    ),
                },
            ],
        },
        {
            id: "multilevel6",
            label: t("Sending Email"),
            icon: <i className="ri-mail-send-line" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "SENDING_EMAIL");
                setIsCurrentState((prev) => "Sending Email");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "SENDING_EMAIL",
            isShow: isHavePermissionRole([ROLES_FOR_APP.JOURNALIST_LIST, ROLES_FOR_APP.MAIL_LOG, ROLES_FOR_APP.MAIL_STATISTICS], userPermissions),
            subItems: [
                {
                    id: "sending-email",
                    label: t("Sending Email"),
                    link: ROUTES.JOURNALIST_LIST,
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.JOURNALIST_LIST,
                        userPermissions
                    ),
                },
                {
                    id: "email-statistics",
                    label: t("Email Statistics"),
                    link: ROUTES.MAIL_STATISTICS,
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.MAIL_STATISTICS,
                        userPermissions
                    ),
                },
                {
                    id: "email-log",
                    label: t("Email Log"),
                    link: ROUTES.MAIL_LOG,
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.MAIL_LOG,
                        userPermissions
                    ),
                },
            ],
        },
        {
            id: "multilevel9",
            label: t('Configuration'),
            icon: <i className="ri-settings-5-line" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'CONFIGURATION');
                setIsCurrentState((prev) => "Configuration");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'CONFIGURATION'),
            isShow: isHavePermissionRole([
                ROLES_FOR_APP.CONFIGURATION_AI_NEWS,
                ROLES_FOR_APP.CONFIGURATION_BOOSTING_NEWS
            ], userPermissions),
            subItems: [
                {
                    id: "ai-news",
                    label: t('AI News'),
                    link: ROUTES.CONFIGURATION_AI_NEWS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.CONFIGURATION_AI_NEWS, userPermissions),
                },
                {
                    id: "boosting-news",
                    label: t('Boosting News'),
                    link: ROUTES.CONFIGURATION_BOOSTING_NEWS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.CONFIGURATION_BOOSTING_NEWS, userPermissions),
                },
                {
                    id: "real-time-news",
                    label: t('Real-Time News'),
                    link: ROUTES.CONFIGURATION_REAL_TIME_NEWS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.CONFIGURATION_REAL_TIME_NEWS, userPermissions),
                },
            ],
        },
        {
            id: "multilevel9",
            label: t('Image Management'),
            icon: <i className="ri ri-landscape-line fs-20" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'IMAGE_MANAGEMENT');
                setIsCurrentState((prev) => "Image Management");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'IMAGE_MANAGEMENT'),
            isShow: isHavePermissionRole([
                ROLES_FOR_APP.GOOGLE_IMAGE_LIST,
            ], userPermissions),
            subItems: [
                {
                    id: "google-image",
                    label: t('Google Image'),
                    link: ROUTES.GOOGLE_IMAGE_LIST,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.GOOGLE_IMAGE_LIST, userPermissions),
                },
            ],
        },
        {
            id: "multilevel9",
            label: t('Simulator'),
            icon: <i className="ri ri-profile-line fs-20" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'SIMULATOR');
                setIsCurrentState((prev) => "Simulator");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'SIMULATOR'),
            isShow: isHavePermissionRole([
                ROLES_FOR_APP.GENERAL_NEWS_LIST,
                ROLES_FOR_APP.GENERATE_NEWS_LIST,
            ], userPermissions),
            subItems: [
                {
                    id: "general-simulator",
                    label: t('General Simulator'),
                    link: ROUTES.GENERAL_NEWS_LIST,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.GENERAL_NEWS_LIST, userPermissions),
                },
                {
                    id: "news-simulator",
                    label: t('News Simulator'),
                    link: ROUTES.GENERATE_NEWS_LIST,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.GENERATE_NEWS_LIST, userPermissions),
                },
                {
                    id: "car-simulator",
                    label: t('Car Simulator'),
                    link: ROUTES.GENERATE_CAR_NEWS_LIST,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.GENERATE_NEWS_LIST, userPermissions),
                },
            ],
        },
        {
            id: "multilevel9",
            label: t('Lab'),
            icon: <i className="ri ri-flask-line fs-20" style={{ transform: 'translateX(-1px)' }} />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'LAB');
                setIsCurrentState((prev) => "Lab");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'LAB'),
            isShow: isHavePermissionRole([
                ROLES_FOR_APP.GENERAL_NEWS_LIST,
                ROLES_FOR_APP.GENERATE_NEWS_LIST,
            ], userPermissions),
            subItems: [
                {
                    id: "namuwiki-simulator",
                    label: t('Namuwiki Simulator'),
                    link: ROUTES.GENERATE_NAMUWIKI_NEWS_LIST,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.GENERATE_NEWS_LIST, userPermissions),
                },
                {
                    id: "extract-daily-search-volume",
                    label: t('Extract Daily Search Volume'),
                    link: ROUTES.EXTRACT_DAILY_SEARCH_VOLUME,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.EXTRACT_DAILY_SEARCH_VOLUME, userPermissions),
                },
                // {
                //     id: "multilevel12",
                //     label: t("Category Management"),
                //     icon: <i className="ri-flashlight-line" />,
                //     link: ROUTES.CATEGORY_LIST,
                //     click: function (e: any) {
                //         setMenuActive((prev) => "CATEGORY_LIST");
                //         setIsCurrentState((prev) => "Category Management");
                //         updateIconSidebar(e);
                //     },
                //     stateVariables: menuActive === "CATEGORY_LIST",
                //     isShow: isHavePermissionRole([ROLES_FOR_APP.CATEGORY_LIST], userPermissions),
                // },
                {
                    id: "multilevel12",
                    label: t("Boosting Keywords"),
                    icon: <i className="ri-flashlight-line" />,
                    link: ROUTES.KEYWORD_BOOST_LIST,
                    click: function (e: any) {
                        setMenuActive((prev) => "BOOSTING_KEYWORD_LIST");
                        setIsCurrentState((prev) => "Boosting Keywords");
                        updateIconSidebar(e);
                    },
                    stateVariables: menuActive === "BOOSTING_KEYWORD_LIST",
                    isShow: isHavePermissionRole([ROLES_FOR_APP.KEYWORD_BOOST_LIST], userPermissions),
                },
            ],
        },
        // {
        //     id: "logout",
        //     label: "Logout",
        //     icon: <FeatherIcon icon="log-out" className="icon-log-out" />,
        //     link: "/logout",
        //     stateVariables: isDashboard,
        //     click: function (e: any) {
        //         e.preventDefault();
        //         setIsDashboard(!isDashboard);
        //         setIsCurrentState( (prev) =>  'Dashboard');
        //         updateIconSidebar(e);
        //     },
        // },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
