import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import DropdownStatus from "components/Common/DropdownStatus";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { useFormik } from "formik";
import { STATUS_USER_OPTIONS } from "helpers/constans";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import { getUser, postUser, putUser } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    triggerRefresh?: () => void;
}

const UserForm = ({
    id = '',
    triggerRefresh
}: Props) => {
    const { t, i18n } = useTranslation();

    const { userPermissions } = useRole();

    const STATUS_USER_OPTIONS_LANG: Option[] = STATUS_USER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const [isChangePassword, setIsChangePassword] = useState<boolean>(!id);

    const [passwordShow, setPasswordShow] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name || '',
                email: values?.email || '',
                company: values?.company || '',
                domain: values?.domain || '',
                news_daily_limit: Number(values?.news_daily_limit || 0),
                boosting_keyword_flg: Number(values?.boosting_keyword_flg || 0),
                phone_number: values?.phone_number || '',
                contact_email: values?.contact_email || '',
                contact_name: values?.contact_name || '',
                contact_address: values?.contact_address || '',
                contact_phone_number: values?.contact_phone_number || '',
                is_active: Number(values?.is_active ? values?.is_active?.value : STATUS_USER_OPTIONS_LANG[0]?.value), // 0 | 1,
                ...(!!values?.password ? { password: values?.password ?? "" } : {})
            };
            const response: any = id ? await putUser(id, data) : await postUser(data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(`${t("This is required")}`),
        email: Yup.string().email(`${t('Please enter a valid email address')}`).required(`${t('This is required')}`),
        password: !id ? Yup.string().min(8, `${t('Password must be at least 8 characters')}`).required(`${t("Password is required")}`) : Yup.string().nullable(),
        company: Yup.string().nullable(),
        domain: Yup.string().nullable(),
        news_daily_limit: Yup.string().nullable(),
        boosting_keyword_flg: Yup.string().nullable(),
        phone_number: Yup.string().nullable(),
        contact_email: Yup.string().nullable(),
        contact_name: Yup.string().nullable(),
        contact_address: Yup.string().nullable(),
        contact_phone_number: Yup.string().nullable(),
        is_active: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .nullable(),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            company: '',
            domain: '',
            news_daily_limit: '30',
            boosting_keyword_flg: 0,
            phone_number: '',
            contact_email: '',
            contact_name: '',
            contact_address: '',
            contact_phone_number: '',
            is_active: null
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const vName = valueDefault?.name || '';
        const vEmail = valueDefault?.email || '';
        const vCompany = valueDefault?.company || '';
        const vDomain = valueDefault?.domain || '';
        const vNewsDailyLimit = Number(valueDefault?.configs?.news_daily_limit || 0);

        const vBoostingKeywordFlg = Number(valueDefault?.configs?.boosting_keyword_flg ?? 0);

        const vPhoneNumber = valueDefault?.phone_number || '';
        const vContactEmail = valueDefault?.contact_email || '';
        const vContactName = valueDefault?.contact_name || '';
        const vContactAddress = valueDefault?.contact_address || '';
        const vContactPhoneNumber = valueDefault?.contact_phone_number || '';
        const vIsActive = STATUS_USER_OPTIONS_LANG?.find(
            (item) => String(item?.value) === String(valueDefault?.is_active)
        );

        setTimeout(() => {
            formik.setFieldValue("name", vName);
            formik.setFieldValue("email", vEmail);
            formik.setFieldValue("company", vCompany);
            formik.setFieldValue("phone_number", vPhoneNumber);
            formik.setFieldValue("domain", vDomain);
            formik.setFieldValue("contact_name", vContactName);
            formik.setFieldValue("contact_email", vContactEmail);
            formik.setFieldValue("contact_phone_number", vContactPhoneNumber);
            formik.setFieldValue("contact_address", vContactAddress);
            formik.setFieldValue("password", '');
            formik.setFieldValue("is_active", vIsActive);
            formik.setFieldValue("news_daily_limit", vNewsDailyLimit);
            formik.setFieldValue("boosting_keyword_flg", vBoostingKeywordFlg);
        }, 300);
    };

    const handleGeneratePassword = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
        let password: string = "";

        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            password += characters[randomIndex];
        }
        formik.setFieldValue("password", password);
    };

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            const [resDetail]: any = await Promise.all([idItem ? getUser(idItem) : {},]);
            if (resDetail?.data) {
                handleSetValueForm(resDetail?.data);
                setIsLoadingDetail((_prev) => false);
            }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <React.Fragment>
            {isLoadingDetail && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(164 164 164 / 36%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Spinner size="sm" color="primary"></Spinner>
            </div>}
            <div>
                <Row>
                    <form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col lg={6}>
                                <div>
                                    <label className="form-label">
                                        {t("Status")}
                                        <span className="text-danger"> *</span>
                                    </label>
                                    <DropdownStatus
                                        name="is_active"
                                        dataList={STATUS_USER_OPTIONS_LANG || []}
                                        placeholder={`${t("Status")}...`}
                                        className="dropdown-status-rounded"
                                        classNamePrefix="name-prefix"
                                        initialValue={formik?.values?.is_active || STATUS_USER_OPTIONS_LANG[0]}
                                        onChangeSelect={(event: any) =>
                                            formik.setFieldValue("is_active", event)
                                        }
                                        colors={["danger", "success"]}
                                    />
                                    {formik.touched.is_active && formik.errors.is_active ? (
                                        <div className="text-danger mt-2">
                                            {formik.errors.is_active}
                                        </div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="name" className="form-label">{t('Name')} <span className="text-danger">*</span></Label>
                                    <Input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder={t('Name')}
                                        value={formik?.values?.name}
                                        onChange={(event: any) => formik.setFieldValue('name', event?.target?.value)}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="text-danger mt-2">{formik.errors.name}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="emailInput" className="form-label">{t('Email')} <span className="text-danger"> *</span></Label>
                                    <Input
                                        name="email"
                                        type="text"
                                        className="form-control"
                                        id="emailInput"
                                        placeholder={t('Email')}
                                        value={formik?.values?.email}
                                        onChange={(event: any) => formik.setFieldValue('email', event?.target?.value)}
                                        disabled={!!id}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="text-danger mt-2">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="password" className={`form-label d-flex ${!!id ? 'justify-content-between' : ''}`}>
                                        {t('Password')} {!id ? <span className="text-danger"> *</span> :
                                            <div className="flex-shrink-0 ms-4">
                                                <div className="form-check form-switch">
                                                    <Input
                                                        name="is_active"
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="directMessage"
                                                        checked={!!Number(isChangePassword)}
                                                        onChange={(event: any) => setIsChangePassword((_prev) => !_prev)}
                                                    />
                                                    <label htmlFor="directMessage" className="form-check-label fs-12">{t('Change Password')}</label>
                                                </div>
                                            </div>
                                        }
                                    </Label>
                                    <div className="position-relative">
                                        <Input
                                            name="password"
                                            type={passwordShow ? "text" : "password"}
                                            className="form-control pe-5"
                                            id="password"
                                            autoComplete="off"
                                            placeholder={t('Password')}
                                            value={formik?.values?.password}
                                            onChange={(event: any) => formik.setFieldValue('password', event?.target?.value)}
                                            disabled={!isChangePassword}
                                        />
                                        <div className="d-flex position-absolute end-0 top-0" style={{ listStyle: 'none' }}>
                                            <TooltipCustom
                                                title={!!passwordShow ? t('Hidden Password') : t('Show Password')}
                                            >
                                                <button className="btn btn-link text-decoration-none text-muted" disabled={!isChangePassword} onClick={() => setPasswordShow((prev) => !prev)} type="button" id="password-addon">{!!passwordShow ? <i className="ri ri-eye-off-line align-middle"></i> : <i className="ri ri-eye-line align-middle"></i>}</button>
                                            </TooltipCustom>
                                            <TooltipCustom
                                                title={t('Generate Password')}
                                            >
                                                <button className="btn btn-link text-decoration-none text-muted" disabled={!isChangePassword} onClick={() => handleGeneratePassword()} type="button"><i className="ri ri-refresh-line align-middle"></i></button>
                                            </TooltipCustom>
                                        </div>
                                    </div>
                                    {formik.touched.password && formik.errors.password ? (
                                        <div className="text-danger mt-2">{formik.errors.password}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="company" className="form-label">{t('Company')}</Label>
                                    <Input
                                        name="company"
                                        type="text"
                                        className="form-control"
                                        id="company"
                                        placeholder={t('Company')}
                                        value={formik?.values?.company}
                                        onChange={(event: any) => formik.setFieldValue('company', event?.target?.value)}
                                    />
                                    {formik.touched.company && formik.errors.company ? (
                                        <div className="text-danger mt-2">{formik.errors.company}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="phone_number" className="form-label">{t('Phone')}</Label>
                                    <Input
                                        name="phone_number"
                                        type="text"
                                        className="form-control"
                                        id="phone_number"
                                        placeholder={t('Phone')}
                                        value={formik?.values?.phone_number}
                                        onChange={(event: any) => formik.setFieldValue('phone_number', event?.target?.value)}
                                    />
                                    {formik.touched.phone_number && formik.errors.phone_number ? (
                                        <div className="text-danger mt-2">{formik.errors.phone_number}</div>
                                    ) : null}
                                </div>
                            </Col>

                            <Col lg={6}>
                                <div className="mb-4">
                                    <Label htmlFor="domain" className="form-label">{t('Domain')}</Label>
                                    <Input
                                        name="domain"
                                        type="text"
                                        className="form-control"
                                        id="domain"
                                        placeholder={t('Domain')}
                                        value={formik?.values?.domain}
                                        onChange={(event: any) => formik.setFieldValue('domain', event?.target?.value)}
                                    />
                                    {formik.touched.domain && formik.errors.domain ? (
                                        <div className="text-danger mt-2">{formik.errors.domain}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-4">
                                    <Label htmlFor="news_daily_limit" className="form-label">
                                        {t('Usage restrictions')}
                                        <TooltipCustom
                                            title={t("Daily article limit")}
                                            className="d-inline-block vertical-align-middle ms-1"
                                            style={{ transform: 'translateY(1px)' }}
                                        >
                                            <i className="ri-question-line align-bottom text-secondary" ></i>
                                        </TooltipCustom>
                                    </Label>
                                    <Input
                                        name="news_daily_limit"
                                        type="text"
                                        className="form-control"
                                        id="news_daily_limit"
                                        placeholder={t('Usage restrictions')}
                                        value={formik?.values?.news_daily_limit}
                                        onChange={(event: any) => {
                                            const input = event.target.value;
                                            const onlyNumbers = input.replace(/\D/g, "");
                                            const s = Number(onlyNumbers);
                                            formik.setFieldValue("news_daily_limit", s);
                                        }}
                                    />
                                    {formik.touched.news_daily_limit && formik.errors.news_daily_limit ? (
                                        <div className="text-danger mt-2">{formik.errors.news_daily_limit}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="form-check form-switch mb-0 mt-0 mb-3" >
                                    <Input className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="boosting_keyword_flg"
                                        name="boosting_keyword_flg"
                                        checked={!!formik?.values?.boosting_keyword_flg}
                                        onChange={(event: any) => formik.setFieldValue('boosting_keyword_flg', Number(event?.target.checked))}
                                    />

                                    <Label className="form-check-label" for="boosting_keyword_flg">{t('Boosting Keyword')}</Label>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="hstack gap-2 justify-content-end">
                                    {isHavePermissionRole(ROLES_FOR_APP.USER_UPDATE, userPermissions) && (
                                        <button
                                            style={{ width: '140px' }}
                                            type="submit"
                                            className="btn btn-primary fs-14 rounded-pill">
                                            {isLoading ? <Spinner size="sm me-2" ></Spinner> : <></>}
                                            {id ? t('Button Update User') : t('Button Create User')}
                                        </button>)}
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Row >
            </div>
        </React.Fragment >
    );
};

export default UserForm;
