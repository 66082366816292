import { createSlice } from "@reduxjs/toolkit";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IDailyStatistic, IKeywordStatistic, IMediaStatistic } from "api/types/_statistic";
import { dailyStatistics, keywordStatistics, mediaStatistics } from "./thunk";



export interface IState {
  daily: ResponseData<IDailyStatistic[]> & PaginationResponse | null,
  isDailyLoading: boolean,
  isDailySuccess: boolean,

  keyword: ResponseData<IKeywordStatistic[]> & PaginationResponse | null,
  isKeywordLoading: boolean,
  isKeywordSuccess: boolean,

  media: ResponseData<IMediaStatistic[]> & PaginationResponse | null,
  isMediaLoading: boolean,
  isMediaSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  daily: null,
  isDailyLoading: false,
  isDailySuccess: false,

  keyword: null,
  isKeywordLoading: false,
  isKeywordSuccess: false,

  media: null,
  isMediaLoading: false,
  isMediaSuccess: false,

  error: {},
};

const StatisticSlice = createSlice({
  name: "Statistic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Daily Statistics
    builder.addCase(dailyStatistics.pending, (state: IState, action: any) => {
      state.isDailyLoading = true
    });
    builder.addCase(dailyStatistics.fulfilled, (state: IState, action: any) => {
      state.daily = action.payload.data;
      state.isDailySuccess = true;
      state.isDailyLoading = false;
    });
    builder.addCase(dailyStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isDailySuccess = false;
      state.isDailyLoading = false;
    });

    // Keyword Statistics
    builder.addCase(keywordStatistics.pending, (state: IState, action: any) => {
      state.isKeywordLoading = true
    });
    builder.addCase(keywordStatistics.fulfilled, (state: IState, action: any) => {
      state.keyword = action.payload.data;
      state.isKeywordSuccess = true;
      state.isKeywordLoading = false;
    });
    builder.addCase(keywordStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordSuccess = false;
      state.isKeywordLoading = false;
    });

    // Media Statistics
    builder.addCase(mediaStatistics.pending, (state: IState, action: any) => {
      state.isMediaLoading = true
    });
    builder.addCase(mediaStatistics.fulfilled, (state: IState, action: any) => {
      state.media = action.payload.data;
      state.isMediaSuccess = true;
      state.isMediaLoading = false;
    });
    builder.addCase(mediaStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaSuccess = false;
      state.isMediaLoading = false;
    });

  },
});

export default StatisticSlice.reducer;