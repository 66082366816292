import { INewsGenerate } from 'api/types/_news';
import { Option } from 'api/types/_public';
import BreadCrumb from 'components/Common/BreadCrumb';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import DropdownStatus from 'components/Common/DropdownStatus';
import LabelWrapper from 'components/Common/LabelWrapper';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { AI_MODEL_OPTIONS, COLORS_STATUS_GENERATE_NEWS, NEWS_GENERATE_REQUEST_SOURCE_TYPE, PROMPT_GENERAl_NEWS_DEFAULT, STATUS_GENERATE_NEWS_OPTIONS, SYSTEM_CONFIG_KEY } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import TableContainer from "../../../components/Common/TableContainer";
import { deleteGenerateNewsMulti, getAllSection, getConfiguration, getGenerateNews as onGetGenerateNews } from "../../../store/thunks";
import ModalGenerateNews from './ModalGenerateNews';
import { ISection } from 'api/types/_master';
import TooltipCustom from 'components/Common/TooltipCustom';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import ModalConfirm from 'components/Common/ModalConfirm';
export interface Tag {
    id: string;
    text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);

const GeneralNewsList = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();

    const STATUS_GENERATE_NEWS_OPTIONS_LANG: any = STATUS_GENERATE_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
    const AI_MODEL_OPTIONS_LANG: any = AI_MODEL_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const START_DATE = `${moment().subtract(7, 'days').format("Y-MM-DD")}`;
    const END_DATE = `${moment().format("Y-MM-DD")}`;

    const SOURCE_TYPE = NEWS_GENERATE_REQUEST_SOURCE_TYPE.GENERAL;

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 30),
        sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
        order_by: withDefault(StringParam, 'DESC'),
        source_type: withDefault(StringParam, `${SOURCE_TYPE}`),
        create_at_from: withDefault(StringParam, START_DATE),
        create_at_to: withDefault(StringParam, END_DATE),
        keyword: withDefault(StringParam, ''),
        // keyword_sub: withDefault(StringParam, ''),
        status: withDefault(StringParam, ''),
    });

    const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
    const [keywordSubSearch, setKeywordSubSearch] = useState<string>(query?.keyword_sub || "");
    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_GENERATE_NEWS_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(query?.status)) || null);

    const [dateSearch, setDateSearch] = useState<any[]>([query?.create_at_from ? moment(query?.create_at_from, 'Y-MM-DD').toDate() : null, query?.create_at_to ? moment(query?.create_at_to || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;

    const [newsDetail, setNewsDetail] = useState<INewsGenerate | null>(null);

    const [sections, setSections] = useState<ISection[]>([]);
    const [commonPrompt, setCommonPrompt] = useState<string>('');
    const [excludeKeywords, setExcludeKeywords] = useState<string>('');


    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.News;

    const KeywordProperties = createSelector(
        selectLayoutState,
        (state) => ({
            generateNews: state.generateNews,
            isGenerateNewsSuccess: state.isGenerateNewsSuccess,
            isGenerateNewsLoading: state.isGenerateNewsLoading,
            error: state.error,
        })
    );

    // Inside your component
    const { generateNews, error, isGenerateNewsLoading } = useSelector(KeywordProperties);

    useEffect(() => {
        dispatch(onGetGenerateNews(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        const queryNew = {
            ...query,
            source_type: `${SOURCE_TYPE}`,
            keyword: keywordSearch || '',
            keyword_sub: keywordSubSearch || '',
            create_at_from: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            create_at_to: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            status: `${statusSearch?.value}`,
            sort_by: TYPE_SELECT_DEFAULT,
            page: 1,
            time_request: + new Date()
        };
        setQuery(queryNew);
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            source_type: `${SOURCE_TYPE}`,
            keyword: '',
            keyword_sub: '',
            status: '',
            create_at_from: `${moment().subtract(7, 'days').format("Y-MM-DD")}`,
            create_at_to: `${moment().format("Y-MM-DD")}`,
            order_by: 'DESC',
            sort_by: TYPE_SELECT_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setKeywordSearch((_prev) => "");
        setKeywordSubSearch((_prev) => "");
        setStatusSearch(null);
        setDateSearch([moment(queryNew?.create_at_from || "", 'Y-MM-DD').toDate(), moment(queryNew?.create_at_to || "", 'Y-MM-DD').toDate()]);
    };

    const triggerRefresh = (type: string = 'ALL') => {
        setQuery({
            ...query,
            time_request: + new Date(),
        });
        if (type === 'ALL') {
            handleCallAllOption();
        }

    }
    //End::Update

    const handleSetNewsDetail = (detail: INewsGenerate | null) => {
        setNewsDetail((_prev) => detail);
    }

    const onCloseClick = () => {
        handleSetNewsDetail(null);
    }

    // Begin::Delete

    const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
    const [initialValuesDefault, setInitialValuesDefault] = useState<INewsGenerate | null>(null);

    const handleConfirmDelete = (item: INewsGenerate) => {
        setInitialValuesDefault((_prev) => item);
        setIsConfirm((_prev) => true);
    };

    const handleActionDelete = async () => {
        if (!(initialValuesDefault?.id)) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteGenerateNewsMulti({
                ids: [initialValuesDefault?.id]
            });
            if (response?.code === 200) {
                dispatch(onGetGenerateNews(query));
                setIsConfirmLoading((_prev) => false);
                setIsConfirm((_prev) => false);
                setInitialValuesDefault((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setInitialValuesDefault((_prev) => null);
        setIsConfirm((_prev) => false);
    }
    // End::Delete

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: true,
                sortable: false,
                width: 60,
                thClass: 'text-start align-middle',
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '40px' }}>{cell?.value}</div>
                    </>
                ),
            },
            {
                Header: t('Keyword'),
                accessor: "keyword",
                filterable: true,
                sortable: false,
                width: 120,
                thClass: 'text-start align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (<>
                        <div style={{ minWidth: '100px' }}>
                            {cell?.value || '-'}
                        </div>
                    </>)
                },
            },
            {
                Header: t('News Title'),
                accessor: "total_news",
                filterable: true,
                sortable: false,
                thClass: 'text-start align-middle',
                // width: 600,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const _condition = {
                        id: item?.id,
                        keyword: item?.keyword,
                        keyword_sub: item?.keyword_sub,
                        count_title: item?.count_title,
                        count_body: item?.count_body,
                        count_content: item?.count_content,
                        sub_count_title: item?.sub_count_title,
                        sub_count_body: item?.sub_count_body,
                        sub_count_content: item?.sub_count_content,
                        source_count: item?.source_count,
                        source_max: item?.source_max,
                        source_date: 1,
                        source_date_from: String(item?.source_date_from || '').split(' ')?.[0] || '',
                        source_date_to: String(item?.source_date_to || '').split(' ')?.[0] || '',
                        total_news: item?.total_news,
                        prompt_format: item?.prompt_format || '',
                        section_prompt_format: item?.section_prompt_format || '',
                        section: `${item?.section_prompt_config?.type}=${item?.section_prompt_config?.key}`,
                        ai_model: item?.ai_model || AI_MODEL_OPTIONS_LANG[1]?.value,
                        exclude_keywords: item?.exclude_keywords || []
                    };
                    return (<>
                        <div className="text-start" style={{ minWidth: '500px' }}>
                            {(item?.results?.length) ? item?.results?.map((result: any, index: number) => (
                                <Link
                                    className="nav-link cursor-pointer hover-underline mb-1"
                                    target="_blank"
                                    to='#'
                                    onClick={(e) => { e.preventDefault(); handleSetNewsDetail({ ...result, condition: _condition }) }}
                                    key={index}
                                >
                                    {result?.title || ''}
                                </Link>
                            )) : '-'}
                        </div>
                    </>)
                },
            },
            {
                Header: t('Status'),
                accessor: "status",
                filterable: false,
                sortable: false,
                thClass: "align-middle text-center",
                thWidth: i18n?.language === 'en' ? 90 : 80,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '60px' }} className="text-center fs-16">
                            <span className={`rounded-pill badge bg-${COLORS_STATUS_GENERATE_NEWS[`${cell?.value || 0}`] || 'success'}`}>
                                {STATUS_GENERATE_NEWS_OPTIONS_LANG?.find((s: any) => String(s?.value) === String(item?.status))?.label || ''}
                            </span>
                        </div>
                    );
                }
            },
            {
                Header: t('Created At'),
                accessor: "created_at",
                filterable: true,
                sortable: true,
                thWidth: 130,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end pe-3" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end align-middle',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Actions'),
                accessor: "__",
                thClass: 'align-middle text-center',
                thWidth: 100,
                sortable: false,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0" style={{ minWidth: '80px' }}>
                            {isHavePermissionRole(ROLES_FOR_APP.AI_NEWS, userPermissions) && (
                                <TooltipCustom
                                    title={t('Button Delete News')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                                        <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-3-fill align-bottom"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                            )}
                        </ul>
                    );
                },
            },
        ],
        [i18n?.language, query, userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleCallAllOption = async () => {
        try {
            const [res1, res2]: any = await Promise.all([getAllSection({ source_type: SOURCE_TYPE }), getConfiguration({})]);
            setSections((_prev) => res1?.data || []);
            setCommonPrompt((_prev) => res2?.data?.list?.find((cf: any) => cf?.key === SYSTEM_CONFIG_KEY.SIMULATOR_GENERAL_COMMON_PROMPT)?.value || '');
            setExcludeKeywords((_prev) => (res2?.data?.list?.find((cf: any) => cf?.key === SYSTEM_CONFIG_KEY.SIMULATOR_GENERAL_EXCLUDE_KEYWORDS)?.value || []));
        } catch (error: any) {
            return error;
        }
    };

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        handleCallAllOption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title = `${t('Simulator')} - ${t('General Simulator')} | PRESS Ai`;
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('General Simulator')} pageTitle={t('Simulator')} />
                    <Row>
                        {isHavePermissionRole(ROLES_FOR_APP.GENERATE_NEWS_LIST, userPermissions) && (
                            <Col lg={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm-7">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span>
                                                            <span className="me-2">{t('Total')}:</span>
                                                            <CountUp
                                                                start={0}
                                                                end={generateNews?.pagination?.total || 0}
                                                                duration={1}
                                                                className="text-primary"
                                                            />
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-sm-5 text-end">
                                                {isHavePermissionRole(ROLES_FOR_APP.GENERATE_NEWS_CREATE, userPermissions) && (<div className="col-sm-auto">
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-success add-btn rounded-pill"
                                                            id="create-btn"
                                                            onClick={() => {
                                                                const _condition = {
                                                                    id: '',
                                                                    keyword: '',
                                                                    keyword_sub: '',
                                                                    count_title: '',
                                                                    count_body: '',
                                                                    count_content: '',
                                                                    sub_count_title: '',
                                                                    sub_count_body: '',
                                                                    sub_count_content: '',
                                                                    source_count: 3,
                                                                    source_date: 1,
                                                                    source_max: 30,
                                                                    total_news: 1,
                                                                    source_date_from: '',
                                                                    source_date_to: '',
                                                                    prompt_format: commonPrompt,
                                                                    section_prompt_format: sections[0]?.prompt || '',
                                                                    section: `${sections[0]?.type}=${sections[0]?.key}`,
                                                                    ai_model: AI_MODEL_OPTIONS_LANG[1]?.value,
                                                                    exclude_keywords: excludeKeywords
                                                                };
                                                                handleSetNewsDetail({ keyword: '', keyword_sub: '', title: '', body: '', exclude_keywords: excludeKeywords, condition: _condition } as any)
                                                            }}
                                                        >
                                                            <i className="ri-add-line align-bottom me-1"></i>
                                                            {t('Button Create Generate News')}
                                                        </button>
                                                    </div>
                                                </div>)}
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={6} lg={6} xl={6} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Keyword')}...`}
                                                        value={keywordSearch}
                                                        onChange={(e) => setKeywordSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            {/* <Col sm={6} md={6} lg={6} xl={6} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Sub Keyword')} isShow={!!keywordSubSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Sub Keyword')}...`}
                                                        value={keywordSubSearch}
                                                        onChange={(e) => setKeywordSubSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col> */}
                                            <Col sm={6} md={6} lg={6} xl={6} xxl={2} className='mt-3 mt-xxl-2'>
                                                <LabelWrapper label={t('Status')} isShow={true}>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_GENERATE_NEWS_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded z-hight"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={statusSearch || null}
                                                        onChangeSelect={(e: any) => setStatusSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All'), value: '' }}
                                                        colors={COLORS_STATUS_GENERATE_NEWS}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} lg={6} xl={6} xxl={3} className='date-picker-wrapper-custom mt-3 mt-xxl-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} lg={6} xl={6} xxl={5} className="hstack gap-1 justify-content-center justify-content-sm-end justify-content-xxl-start mt-3 mt-xxl-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isGenerateNewsLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle"
                                            theadClass="table-light"
                                            columns={columns}
                                            data={generateNews?.list?.length ? generateNews?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={generateNews?.pagination?.total}
                                            customPageCount={Math.ceil(Number(generateNews?.pagination?.total) / Number(generateNews?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isGenerateNewsLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Container>

                <ModalGenerateNews
                    sourceType={SOURCE_TYPE}
                    isOpen={!!newsDetail}
                    detail={newsDetail}
                    sections={sections}
                    onClose={onCloseClick}
                    triggerRefresh={triggerRefresh}
                />

                <ModalConfirm
                    header={t('Button Delete News')}
                    isOpen={isOpenConfirm}
                    isLoading={isConfirmLoading}
                    onClose={onCloseConfirmClick}
                    onConfirm={handleActionDelete}
                />

                <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={3000} limit={2} />
            </div >
        </React.Fragment >
    );
};

export default GeneralNewsList;