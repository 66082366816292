import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';
import keywordApi from 'api/keywordApi';
import { formatQueryParams } from "helpers/format";

export const getKeywordBoosts = createAsyncThunk("KeywordBoosts", async (params: any = {}) => {
  try {
    const response = await keywordApi.keywordBoosts(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getKeywordBoostStatistics = async (params: any = {}) => {
  try {
    const response = await keywordApi.getKeywordBoostStatistics(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};


export const getKeywordAutocomplete = async (params: any = {}) => {
  try {
    const response = await keywordApi.getKeywordAutocomplete(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};



export const getKeywordBoost = async (id: string | number, params: any = {}) => {
  try {
    const response = await keywordApi.getKeywordBoost(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postKeywordBoost = async (data: any = {}) => {
  try {
    const response = await keywordApi.postKeywordBoost(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putKeywordBoost = async (id: number | string, data: any = {}) => {
  try {
    const response = await keywordApi.putKeywordBoost(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putStatusKeywordMulti = async (data: any = {}) => {
  try {
    const response = await keywordApi.putStatusKeywordMulti(data);
    return response;
  } catch (error) {
    return error;
  }
};


export const deleteKeywordBoostMulti = async (data: any = {}) => {
  try {
    const response = await keywordApi.deleteKeywordBoostMulti(data);
    return response;
  } catch (error) {
    return error;
  }
};



export const getKeywordBoostV2s = createAsyncThunk("KeywordBoosts V2", async (params: any = {}) => {
  try {
    const response = await keywordApi.keywordBoostV2s(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getKeywordBoostV2Statistics = async (params: any = {}) => {
  try {
    const response = await keywordApi.getKeywordBoostV2Statistics(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};


export const getKeywordBoostV2 = async (id: string | number, params: any = {}) => {
  try {
    const response = await keywordApi.getKeywordBoostV2(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postKeywordBoostV2 = async (data: any = {}) => {
  try {
    const response = await keywordApi.postKeywordBoostV2(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const importKeywordBoostV2ByExcel = async (data: any = {}) => {
  try {
    const response = await keywordApi.importKeywordBoostV2ByExcel(data);
    return response;
  } catch (error) {
    return error;
  }
};


export const putKeywordBoostV2 = async (id: number | string, data: any = {}) => {
  try {
    const response = await keywordApi.putKeywordBoostV2(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putStatusKeywordV2Multi = async (typeStatus: "ACTIVATE" | "DEACTIVATE" = "ACTIVATE", data: any = {}) => {
  try {
    const response = await keywordApi.putStatusKeywordV2Multi(typeStatus, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const deleteKeywordBoostV2Multi = async (data: any = {}) => {
  try {
    const response = await keywordApi.deleteKeywordBoostV2Multi(data);
    return response;
  } catch (error) {
    return error;
  }
};


export const downloadExcelKeywordBoostV2 = async (params: any = {}) => {
  try {
    const response = await keywordApi.downloadExcelKeywordBoostV2(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};



export const postKeywordToolConvert = async (data: any = {}) => {
  try {
    const response = await keywordApi.postKeywordToolConvert(data);
    return response;
  } catch (error) {
    return error;
  }
};



export const getKeywordRealTimes = createAsyncThunk("Keywords Real Time", async (params: any = {}) => {
  try {
    const response = await keywordApi.keywordRealTimes(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});