import { CONFIG_OPTION_TOAST_ERROR } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { useFormik } from "formik";
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { postKeywordToolConvert } from 'store/thunks';
import * as Yup from "yup";

registerLocale('en', en);
registerLocale('ko', ko);

const FILE_SIZE = 10 * 1024 * 1024; // 10MB
const SUPPORTED_FORMATS = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const ExtractDailySearchVolume = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mess, setMess] = useState<string>('');


  const handleSubmit = async (values: any) => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading((_prev) => true);
      const data = {
        date: values?.date || '',
        files: values?.file || null,
        email: values?.email || '',
      };
      const response: any = await postKeywordToolConvert(data);
      if (response?.code === 200) {
        setIsLoading((_prev) => false);
        setMess((_prev) => String(t("The processing will take approximately X minutes. Once completed, the results will be sent to the email: x@gmail. Please check your email.")).replace('[MINUTES]', Math.floor(Number(response?.data?.estimate_time || 0) / 60).toString()).replace('[EMAIL]', values?.email || ''));
      } else {
        setIsLoading((_prev) => false);
        toast(`${response?.message || response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    date: Yup.string().required(`${t("Date is required")}`),
    email: Yup.string().required(t('Please enter your email')).email(t('Please include an @ in the email address')),
    file: Yup.mixed()
      .required(t('An Excel file is required')).test(
        'fileSize',
        t('File too large. Max size is 10MB.'),
        (value: any) => value && value?.size <= FILE_SIZE
      ).test(
        'fileFormat',
        t('Unsupported Format. Please upload an Excel file.'),
        (value: any) => value && SUPPORTED_FORMATS.includes(value?.type)
      ),
  });

  const formik = useFormik({
    initialValues: {
      date: `${moment().subtract(1, 'days').format("Y-MM-DD")}`,
      file: '',
      email: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleChangePicker = (value: Date | null) => {
    formik?.setFieldValue('date', value ? moment(new Date(value)).format("Y-MM-DD") : '');
  }

  const handleChangeFile = (value: any | null) => {
    formik?.setFieldValue('file', value?.target?.files[0] || null);
  }

  const handleChangeEmail = (value: any | null) => {
    formik?.setFieldValue('email', value?.target?.value || '');
  }

  useEffect(() => {
    document.title = `${t('Lab')} - ${t('Extract Daily Search Volume')} | PRESS Ai`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Extract Daily Search Volume')} pageTitle={t('Lab')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.EXTRACT_DAILY_SEARCH_VOLUME, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)', minHeight: '75dvh' }}>
                  <CardBody className="pt-3">
                    <form onSubmit={formik.handleSubmit}>
                      <div style={{ maxWidth: '500px', margin: '0 auto' }}>
                        <Row>
                          <Col sm={12} md={12} xl={12} className='date-picker-wrapper-custom mt-3'>
                            <Label htmlFor="date" className="form-label">{t('Date')} <span className="text-danger"> *</span></Label>
                            <DatePicker
                              id="date"
                              className="form-control search"
                              placeholderText={`${t('Date')}...`}
                              selected={formik?.values?.date ? new Date(formik?.values?.date) : null}
                              value={formik?.values?.date || ''}
                              dateFormat="yyyy-MM-dd"
                              maxDate={moment().toDate()}
                              isClearable={false}
                              locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                              onChange={handleChangePicker}
                            />
                            {formik.touched.date && formik.errors.date ? (
                              <div className="text-danger mt-2">{formik.errors.date}</div>
                            ) : null}
                          </Col>
                          <Col sm={12} md={12} xl={12} className='mt-3 '>
                            <Label htmlFor="formSizeDefault" className="form-label">{t('Excel File')} <span className="text-danger"> *</span></Label>
                            <div className="custom-file-button">
                              <Input className="form-control" accept=".xls,.xlsx"
                                label="Upload Your File"
                                data-title="Upload Your File"
                                id="formSizeDefault"
                                type="file"
                                onChange={handleChangeFile}
                              />
                              {!formik?.values?.file && <div className="text-upload">{t('No file chosen')}</div>}
                              <span className="icon-upload"> <i className="ri-upload-line" /></span>
                            </div>
                            {formik.touched.file && formik.errors.file ? (
                              <div className="text-danger mt-2">{formik.errors.file}</div>
                            ) : null}
                          </Col>
                          <Col sm={12} md={12} xl={12} className='mt-3 '>
                            <Label htmlFor="email" className="form-label">{t('Email')} <span className="text-danger"> *</span></Label>
                            <Input className="form-control"
                              id="email"
                              type="text"
                              placeholder={t('File Sent To Email')}
                              onChange={handleChangeEmail}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div className="text-danger mt-2">{formik.errors.email}</div>
                            ) : null}
                          </Col>
                          <Col sm={12} md={12} xl={12} className='mt-4 pt-1 text-end'>
                            <button
                              style={{ width: '150px' }}
                              type="submit"
                              disabled={isLoading}
                              className="btn btn-primary fs-14 rounded-pill">
                              {isLoading ? <Spinner size="sm me-2" ></Spinner> : <></>}
                              {t('Button Upload')}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>

        <Modal
          isOpen={!!mess}
          centered={true}
          // size="lg"
          toggle={() => setMess('')}
          keyboard={true}
        >
          <ModalHeader>
            {t('Extract Daily Search Volume')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col sm={12}>
                <div className="row g-3">
                  <div className="mt-3 text-center">
                    <h6 className="mb-2 fs-19 text-center mt-1">
                      {t("Upload successful and is being processed")}
                    </h6>
                    <p className="fs-15 mb-4 d-flex justify-content-center align-items-start align-items-sm-center text-center ">
                      {mess || ''}
                    </p>
                    <div className="d-flex gap-3 mt-2 justify-content-center">
                      <button
                        className="btn btn-soft-secondary rounded-pill fs-14"
                        type="button"
                        color="light"
                        onClick={() => setMess('')}
                        style={{ minWidth: '100px' }}
                      >
                        {t("Button Close")}
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal >

        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />

      </div >
    </React.Fragment >
  );
};

export default ExtractDailySearchVolume;