import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ResponseData } from "./types/_public";
import { IConfig, IFile, IKeywordConfig, ISectionPromptConfig } from "./types/_system";
const api = new APIClient();

const path = '';
const systemApi = {
  postUploadFileMulti(dataForm: any): Promise<AxiosResponse<ResponseData<IFile>, any>> {
    const url = `${path}/upload/files`;
    const formData = new FormData();

    if (Array.isArray(dataForm?.files)) {
      dataForm.files.forEach((file: any, index: number) => {
        formData.append(`files`, file);
      });
    } else if (dataForm?.files) {
      formData.append('files', dataForm.files);
    }

    if (dataForm?.action) {
      formData.append('action', dataForm.action); // File save don't delete 
    }

    return api.post(url, formData, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getConfiguration(params: any = {}): Promise<AxiosResponse<ResponseData<IConfig>, any>> {
    const url = `${path}/systems/configs`;
    return api.get(url, params)
  },
  putConfiguration(dataForm: any): Promise<AxiosResponse<ResponseData<IConfig>, any>> {
    const url = `${path}/systems/configs`;
    return api.put(url, dataForm)
  },
  putSectionPrompt(dataForm: any): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `/section-prompt-config`;
    return api.put(url, dataForm)
  },
  getSectionConfigurations(params: any = {}): Promise<AxiosResponse<ResponseData<ISectionPromptConfig>, any>> {
    const url = `/masters/section-configs`;
    return api.get(url, params)
  },
  putSectionPromptConfiguration(dataForm: any): Promise<AxiosResponse<ResponseData<ISectionPromptConfig[]>, any>> {
    const url = `/section-config`;
    return api.put(url, dataForm)
  },
  getKeywordsConfiguration(params: any = {}): Promise<AxiosResponse<ResponseData<IKeywordConfig>, any>> {
    const url = `/keyword-excludes`;
    return api.get(url, params)
  },
  postKeywordsConfiguration(dataForm: any): Promise<AxiosResponse<ResponseData<IKeywordConfig>, any>> {
    const url = `/keyword-excludes`;
    return api.post(url, dataForm)
  },
}
export default systemApi;
