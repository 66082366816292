import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/Dashboard";

//pages
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';
import Settings from '../pages/Pages/Profile/Settings/Settings';

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

import UserList from "pages/User/User";

import CategoryList from "pages/Category";

import OperatorList from "pages/Operator/Operator";
import Profile from "pages/Operator/Profile";
import RoleList from "pages/Operator/Role";

import AINewsList from "pages/News/AINews";

import ConfigurationAINews from "pages/Configuration/AINews";
import ConfigurationBoostingNews from "pages/Configuration/BoostingNews";
import ConfigurationRealTimeNews from "pages/Configuration/RealTimeNews";
import ExtractDailySearchVolume from "pages/ExtractDailySearchVolume";
import JournalistList from "pages/Journalist";
import KeywordBoostList from "pages/KeywordBoost";
import KeywordBoostV2List from "pages/KeywordBoostV2";
import MailLog from "pages/Mail/MailLog";
import MailStatistic from "pages/Mail/MailStatistic";
import BoostingNewsList from "pages/News/BoostingNews";
import GeneralNewsList from "pages/News/GenerateNews/GeneralNewsList";
import GenerateNamuwikiNewsList from "pages/News/GenerateNews/GenerateNamuwikiNewsList";
import GenerateNaverNewsList from "pages/News/GenerateNews/GenerateNaverNewsList";
import GenerateCarNewsList from "pages/News/GenerateNews/GeneralCarNewsList";
import RealTimeNewsList from "pages/News/RealTimeNews";
import ActivityLogList from "pages/Operator/ActivityLog";
import KeywordRealTime from "pages/KeywordRealTime";
import GoogleImage from "pages/Image/Google";
import TrackingNewsList from "pages/News/TrackingNews";
import DailyStatistic from "pages/Statistics/DailyStatistic";
import KeywordStatistic from "pages/Statistics/KeywordStatistic";
import MediaStatistic from "pages/Statistics/MediaStatistic";
import CampaignCarList from "pages/Car/Campaign";
import BoostingContentList from "pages/News/BoostingContent";
import Car from "pages/Car";

const ROUTES = {
  LOGIN: "/login",
  LOGOUT: "/logout",

  HOME: '/',
  DASHBOARD: '/dashboard',

  PROFILE: '/profile',
  PROFILE_SETTING: '/pages-profile-settings',

  USER_LIST: '/user/list',

  CATEGORY_LIST: '/category',

  AI_NEWS: '/ai-news',

  BOOSTING_NEWS: '/boosting-news',

  KEYWORD_BOOST_LIST: '/boosting-keyword-v1',

  KEYWORD_BOOST_V2_LIST: '/boosting-keyword',

  REAL_TIME_NEWS: '/real-time-news',

  KEYWORD_REAL_TIME: '/real-time-keyword',

  BOOSTING_CONTENT_NEWS: '/boosting-content',

  CAR: '/boosting-content/car',

  ARTICLE_TRACKING: '/article-tracking',

  JOURNALIST_LIST: '/sending-email',

  GENERAL_NEWS_LIST: '/general-simulator',

  GENERATE_NEWS_LIST: '/news-simulator',

  GENERATE_NAMUWIKI_NEWS_LIST: '/namuwiki-simulator',

  GENERATE_CAR_NEWS_LIST: '/car-simulator',

  EXTRACT_DAILY_SEARCH_VOLUME: '/extract-daily-search-volume',

  MAIL_LOG: '/email-logs',

  MAIL_STATISTICS: '/email-statistics',

  DAILY_STATISTICS: '/statistics/daily-statistics',
  KEYWORD_STATISTICS: '/statistics/keyword-statistics',
  MEDIA_STATISTICS: '/statistics/media-statistics',

  CONFIGURATION_AI_NEWS: '/configuration/ai-news',
  CONFIGURATION_BOOSTING_NEWS: '/configuration/boosting-news',
  CONFIGURATION_REAL_TIME_NEWS: '/configuration/real-time-news',

  OPERATOR_LIST: '/operator/list',
  OPERATOR_ROLE: '/operator/role',
  OPERATOR_ACTIVITY_LOG: '/operator/activity-logs',
  OPERATOR_PROFILE: '/operator/profile',

  GOOGLE_IMAGE_LIST: '/image-management/google-image',

}

const ROUTE_NAMES = [
  {
    link: ROUTES.LOGIN,
    name: "Login",
  },
  // {
  //   link: ROUTES.LOGOUT,
  //   name: 'Log Out'
  // },
  {
    link: ROUTES.DASHBOARD,
    name: "Dashboards",
  },
  {
    link: ROUTES.PROFILE,
    name: "Profile",
  },
  {
    link: ROUTES.USER_LIST,
    name: "User Management",
  },
  {
    link: ROUTES.CATEGORY_LIST,
    name: "Category Management",
  },
  {
    link: ROUTES.AI_NEWS,
    name: "AI News",
  },
  {
    link: ROUTES.BOOSTING_NEWS,
    name: "Boosting News",
  },
  {
    link: ROUTES.KEYWORD_BOOST_LIST,
    name: "Boosting Keywords",
  },
  {
    link: ROUTES.KEYWORD_BOOST_V2_LIST,
    name: "Boosting Keyword",
  },
  {
    link: ROUTES.REAL_TIME_NEWS,
    name: "Real-Time News",
  },
  {
    link: ROUTES.KEYWORD_REAL_TIME,
    name: "Real-Time Keyword",
  },
  {
    link: ROUTES.BOOSTING_CONTENT_NEWS,
    name: "Boosting Content",
  },
  {
    link: ROUTES.CAR,
    name: "Car",
  },
  {
    link: ROUTES.ARTICLE_TRACKING,
    name: "News Monitor",
  },
  {
    link: ROUTES.JOURNALIST_LIST,
    name: "Sending Email",
  },
  {
    link: ROUTES.GENERAL_NEWS_LIST,
    name: "General Simulator",
  },
  {
    link: ROUTES.GENERATE_NEWS_LIST,
    name: "News Simulator",
  },
  {
    link: ROUTES.GENERATE_NAMUWIKI_NEWS_LIST,
    name: "Namuwiki Simulator",
  },
  {
    link: ROUTES.EXTRACT_DAILY_SEARCH_VOLUME,
    name: "Extract Daily Search Volume",
  },
  {
    link: ROUTES.MAIL_LOG,
    name: "Email Log",
  },
  {
    link: ROUTES.MAIL_STATISTICS,
    name: "Email Statistics",
  },
  {
    link: ROUTES.DAILY_STATISTICS,
    name: "Daily Statistics",
  },
  {
    link: ROUTES.KEYWORD_STATISTICS,
    name: "Keyword Statistics",
  },
  {
    link: ROUTES.MEDIA_STATISTICS,
    name: "Media Statistics",
  },
  {
    link: ROUTES.OPERATOR_LIST,
    name: "Operator",
  },
  {
    link: ROUTES.OPERATOR_ROLE,
    name: "Role",
  },
  {
    link: ROUTES.OPERATOR_ACTIVITY_LOG,
    name: "Activity Logs",
  },
  {
    link: ROUTES.CONFIGURATION_AI_NEWS,
    name: "AI News",
  },
  {
    link: ROUTES.CONFIGURATION_BOOSTING_NEWS,
    name: "Boosting News",
  },
  {
    link: ROUTES.CONFIGURATION_REAL_TIME_NEWS,
    name: "Real-Time News",
  },
  {
    link: ROUTES.GOOGLE_IMAGE_LIST,
    name: "Google Image",
  },

];

const authProtectedRoutes = [
  { path: ROUTES.DASHBOARD, component: <DashboardCrm /> },

  { path: ROUTES.PROFILE_SETTING, component: <Settings /> },
  { path: ROUTES.PROFILE, component: <UserProfile /> },

  { path: ROUTES.USER_LIST, component: <UserList /> },

  { path: ROUTES.CATEGORY_LIST, component: <CategoryList /> },

  { path: ROUTES.AI_NEWS, component: <AINewsList /> },

  { path: ROUTES.BOOSTING_NEWS, component: <BoostingNewsList /> },

  { path: ROUTES.ARTICLE_TRACKING, component: <TrackingNewsList /> },

  { path: ROUTES.KEYWORD_BOOST_LIST, component: <KeywordBoostList /> },

  { path: ROUTES.KEYWORD_BOOST_V2_LIST, component: <KeywordBoostV2List /> },

  { path: ROUTES.REAL_TIME_NEWS, component: <RealTimeNewsList /> },

  { path: ROUTES.CAR, component: <Car /> },

  { path: ROUTES.BOOSTING_CONTENT_NEWS, component: <BoostingContentList /> },

  { path: ROUTES.KEYWORD_REAL_TIME, component: <KeywordRealTime /> },

  { path: ROUTES.JOURNALIST_LIST, component: <JournalistList /> },

  { path: ROUTES.GENERATE_NEWS_LIST, component: <GenerateNaverNewsList /> },

  { path: ROUTES.GENERAL_NEWS_LIST, component: <GeneralNewsList /> },

  { path: ROUTES.GENERATE_NAMUWIKI_NEWS_LIST, component: <GenerateNamuwikiNewsList /> },

  { path: ROUTES.GENERATE_CAR_NEWS_LIST, component: <GenerateCarNewsList /> },

  { path: ROUTES.EXTRACT_DAILY_SEARCH_VOLUME, component: <ExtractDailySearchVolume /> },

  { path: ROUTES.MAIL_LOG, component: <MailLog /> },

  { path: ROUTES.MAIL_STATISTICS, component: <MailStatistic /> },

  { path: ROUTES.DAILY_STATISTICS, component: <DailyStatistic /> },
  { path: ROUTES.KEYWORD_STATISTICS, component: <KeywordStatistic /> },
  { path: ROUTES.MEDIA_STATISTICS, component: <MediaStatistic /> },

  { path: ROUTES.OPERATOR_LIST, component: <OperatorList /> },
  { path: ROUTES.OPERATOR_ROLE, component: <RoleList /> },
  { path: ROUTES.OPERATOR_ACTIVITY_LOG, component: <ActivityLogList /> },
  { path: ROUTES.OPERATOR_PROFILE, component: <Profile /> },

  { path: ROUTES.CONFIGURATION_AI_NEWS, component: <ConfigurationAINews /> },
  { path: ROUTES.CONFIGURATION_BOOSTING_NEWS, component: <ConfigurationBoostingNews /> },
  { path: ROUTES.CONFIGURATION_REAL_TIME_NEWS, component: <ConfigurationRealTimeNews /> },

  { path: ROUTES.GOOGLE_IMAGE_LIST, component: <GoogleImage /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to={ROUTES.USER_LIST} />,
  },
  { path: "*", component: <Navigate to={ROUTES.DASHBOARD} /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages
  { path: "/pages-coming-soon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes, ROUTE_NAMES, ROUTES };

