import { INews, IPartner } from 'api/types/_news';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import FeatherIcon from "feather-icons-react";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import { downloadNews, publishNews, unPublishNews } from 'store/thunks';
import ModalPartner from './ModalPartner';
import ModalConfirm from 'components/Common/ModalConfirm';

interface BoxFooterNewsProps {
    isListNewsLoading: boolean;
    type_page_no_param: string;
    newsDetail: INews | null;
    currentLang: string;
    handlePreviousNews: (index: number | undefined) => void,
    handleNextNews: (index: number | undefined) => void,
    handleDownloaded?: (info: any) => void,
    handlePublished?: (info: any) => void,
    handleUnPublished?: (info: any) => void,
    onCloseClick: () => void,
    onSaveClick?: (is_show_noti_when_success?: boolean) => void,
    onShowInfoRightClick?: () => void,
    isSaving?: boolean,
    isShowSave?: boolean,
    isShowButtons?: boolean,
    typeShowInfoRight?: 'IMAGE' | 'SOURCE',
    partners?: IPartner[]
}

const BoxFooterNews: React.FC<BoxFooterNewsProps> = ({ partners, newsDetail, currentLang, isListNewsLoading, type_page_no_param, handlePreviousNews, handleNextNews, handleDownloaded, handlePublished, handleUnPublished, onCloseClick, onSaveClick, onShowInfoRightClick, isSaving = false, isShowButtons = true, isShowSave = false, typeShowInfoRight = 'IMAGE' }) => {
    const { t } = useTranslation();

    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isPublishing, setIsPublishing] = useState<boolean>(false);


    const [isOpenConfirmUnPublish, setIsOpenConfirmUnPublish] = useState<boolean>(false);
    const [isUnPublishing, setIsUnPublishing] = useState<boolean>(false);

    const clickPreviousNews = (index: number | undefined) => {
        handlePreviousNews && handlePreviousNews(index);
    };

    const clickNextNews = (index: number | undefined) => {
        handleNextNews && handleNextNews(index);
    };

    const clickClose = () => {
        onCloseClick && onCloseClick();
    };

    const clickSave = async (is_show_noti_when_success: boolean = true) => {
        if (onSaveClick) {
            await onSaveClick(is_show_noti_when_success);
        }
    }

    const clickShowInfoRight = () => {
        onShowInfoRightClick && onShowInfoRightClick();
    }

    async function downloadFileFromUrl(fileUrl: string, fileName: string) {
        try {
            // Send a file download request using Fetch API
            const response = await fetch(fileUrl);

            // Check if the response is unsuccessful
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Get the file content as a blob
            const blob = await response.blob();

            // Create a blob URL
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a link to download the file
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName; // Set the downloaded file name
            document.body.appendChild(link);

            // Trigger the file download
            link.click();

            // Revoke the blob URL and remove the link element
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(link);

        } catch (error) {
            console.error("Download failed:", error);
        }
    };

    const handleDownloadNews = async (id_news: string | number = '', type_file: 'WORD' | 'PDF' | 'ZIP' = 'WORD') => {
        try {
            if (!id_news) {
                return;
            }
            setIsDownloading((_prev) => true);
            const res: any = await clickSave(false);
            const response: any = await downloadNews(id_news, { type: type_file, language_code: currentLang });
            if (response?.data) {
                setIsDownloading((_prev) => false);
                // Get file name from URL or set default name
                const urlParts = response?.data?.url.split('/');
                const fileName = urlParts[urlParts.length - 1] || 'AI_News.pdf';
                downloadFileFromUrl(response?.data?.url, fileName);
                handleDownloaded && handleDownloaded({ id: response?.data?.id, is_downloaded: 1, is_used: 1, admin: response?.data?.admin });
            } else {
                setIsDownloading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsDownloading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    }

    const clickOpenPublish = () => {
        setIsOpen(true);
    }

    const onClickClosePublish = () => {
        setIsOpen(false);
    }

    const handlePublish = async (_data: { partner_id: number, partner_code_id?: number }) => {
        try {
            if (!_data || !newsDetail?.id) {
                return;
            }
            setIsPublishing((_prev) => true);
            const res: any = await clickSave(false);
            const response: any = await publishNews(newsDetail?.id, _data);
            if (response?.data) {
                setIsPublishing((_prev) => false);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
                onClickClosePublish();
                handlePublished && handlePublished({ id: response?.data?.id, is_published: 1, is_used: 1, admin: response?.data?.admin, news_publish_log: response?.data?.news_publish_log });
            } else {
                setIsPublishing((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsPublishing((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const handleUnPublish = async () => {
        try {
            if (!newsDetail?.id) {
                return;
            }
            setIsUnPublishing((_prev) => true);
            const response: any = await unPublishNews(newsDetail?.id);
            if (response?.data) {
                setIsUnPublishing((_prev) => false);
                setIsOpenConfirmUnPublish((_prev) => false);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
                handleUnPublished && handleUnPublished({ id: response?.data?.id, is_downloaded: 0, is_published: 0, is_used: 0, admin: null, news_publish_log: null });
            } else {
                setIsUnPublishing((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsUnPublishing((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    return (
        <>
            <div className="w-100 d-flex gap-2 justify-content-between align-items-center flex-wrap ">
                {isShowButtons && <div className="d-flex flex-grow-1 gap-2 gap-sm-3 justify-content-start justify-content-sm-start mt-2 mt-sm-0">
                    <button
                        className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center"
                        type="button"
                        color="light"
                        onClick={() => clickSave(true)}
                        style={{ width: '80px' }}
                        disabled={isSaving}
                    >
                        {isSaving ? <span><Spinner size="sm" ></Spinner></span> : <i className="ri-login-circle-line" />} <span className="ms-1">{t("Button Save")}</span>
                    </button>
                    {!!newsDetail?.is_used && (
                        <button
                            className="btn btn-success fs-14 px-1 d-flex justify-content-center align-item-center"
                            type="button"
                            color="light"
                            onClick={() => setIsOpenConfirmUnPublish(true)}
                            style={{ width: '100px' }}
                            disabled={isUnPublishing || isPublishing}
                        >
                            {isUnPublishing ? <span><Spinner size="sm" ></Spinner></span> : <i className="ri-indeterminate-circle-line" />} <span className="ms-1">{t("Button UnPublish News")}</span>
                        </button>
                    )}
                </div>}

                <div className="d-flex flex-grow-1 gap-2 gap-sm-3 justify-content-end justify-content-sm-end mt-2 mt-sm-0">
                    {isShowButtons && <>
                        <div className="d--dd-none d-md-flex">
                            <div className="btn-group btn-width-next-news">
                                <button type="button" title={t('Previous News')} style={{ height: '40px' }} aria-pressed="false"
                                    className="btn fs-14 btn-secondary d-flex justify-content-center align-item-center"
                                    disabled={isListNewsLoading || !!newsDetail?.disabled_prev}
                                    onClick={() => clickPreviousNews(newsDetail?.index)}
                                >
                                    {(isListNewsLoading && type_page_no_param === 'prev') ? <span style={{ transform: 'translateY(3px)', width: '18px' }}><Spinner size="sm" ></Spinner></span> : <FeatherIcon style={{ width: '18px' }} icon="chevron-left" className="fs-14" role="img" />}
                                </button>
                                <button type="button" title={t('Next News')} style={{ height: '40px' }} aria-pressed="false"
                                    className="btn fs-14 btn-secondary d-flex justify-content-center align-item-center"
                                    disabled={isListNewsLoading || !!newsDetail?.disabled_next}
                                    onClick={() => clickNextNews(newsDetail?.index)}
                                >
                                    {(isListNewsLoading && type_page_no_param === 'next') ? <span style={{ transform: 'translateY(3px)', width: '18px' }}><Spinner size="sm" ></Spinner></span> : <FeatherIcon style={{ width: '18px' }} icon="chevron-right" className="fs-14" role="img" />}
                                </button>
                            </div>
                        </div>
                        {!newsDetail?.is_used && <button
                            className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center"
                            type="button"
                            color="light"
                            onClick={clickOpenPublish}
                            style={{ width: '95px' }}
                            disabled={isUnPublishing || isPublishing || !!newsDetail?.is_used}
                        >
                            <i className="ri-share-circle-line" style={{ lineHeight: '22px' }} /> <span className="ms-1">{t('Publish')}</span>
                        </button>}
                        <button
                            className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center"
                            type="button"
                            color="light"
                            onClick={clickShowInfoRight}
                            style={{ width: '80px' }}
                            disabled={isSaving}
                        >
                            <span className="ms-1">{typeShowInfoRight === 'IMAGE' ? t("Source") : t('Image')}</span>
                        </button>
                    </>}
                    <div className="d-flex gap-2 gap-sm-3">
                        {isShowButtons && <>{!newsDetail?.is_used && <ButtonGroup>
                            <UncontrolledDropdown>
                                <DropdownToggle tag="button" className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center btn-width-down-news" disabled={isDownloading || !!newsDetail?.is_used}>
                                    {isDownloading ? <span><Spinner size="sm" ></Spinner></span> : <i className="ri-download-line" />} <span className="ms-1">{t("Button Use News")}</span> <i className=" ms-1 mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => handleDownloadNews(newsDetail?.id, 'WORD')}>{t('File Word')}</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => handleDownloadNews(newsDetail?.id, 'PDF')}>{t('File PDF')}</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => handleDownloadNews(newsDetail?.id, 'ZIP')}>{t('File Zip')}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ButtonGroup>}
                        </>}
                        <button
                            className="btn btn-secondary fs-14 px-1 d-flex justify-content-center align-item-center"
                            type="button"
                            color="light"
                            onClick={clickClose}
                            style={{ width: '75px' }}
                        >
                            <i className="ri-close-fill" style={{ lineHeight: '22px' }} /> <span className="ms-1">{t("Button Close")}</span>
                        </button>
                    </div>
                </div>
            </div>
            <ModalConfirm
                header={t('Button UnPublish News')}
                textButtonConfirm={t('Button UnPublish News')}
                classButtonConfirm={'btn-success'}
                title={t("Do you want to change the status to unused?")}
                content={t("Published information will be reset.")}
                isOpen={!!isOpenConfirmUnPublish}
                isLoading={isUnPublishing}
                classIconButtonConfirm=''
                isShowIcon={false}
                onClose={() => setIsOpenConfirmUnPublish((_prev) => false)}
                onConfirm={handleUnPublish}
            />
            <ModalPartner isOpen={isOpen} isPublishing={isPublishing} partners={partners} onPublish={handlePublish} onCloseClick={onClickClosePublish} />
        </>
    );
};

export default React.memo(BoxFooterNews);
