import { createSlice } from "@reduxjs/toolkit";
import { IMailLog, IMailStatistic } from "api/types/_mail";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getMailLogs, getMailStatistics } from "./thunk";

export interface IState {
  mailLogs: ResponseData<IMailLog[]> & PaginationResponse | null,
  isMailLogLoading: boolean,
  isMailLogSuccess: boolean,

  mailStatistics: ResponseData<IMailStatistic[]> & PaginationResponse | null,
  isMailStatisticLoading: boolean,
  isMailStatisticSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  mailLogs: null,
  isMailLogLoading: false,
  isMailLogSuccess: false,

  mailStatistics: null,
  isMailStatisticLoading: false,
  isMailStatisticSuccess: false,

  error: {},
};

const MailSlice = createSlice({
  name: "Mail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Mail Logs
    builder.addCase(getMailLogs.pending, (state: IState, action: any) => {
      state.isMailLogLoading = true
    });
    builder.addCase(getMailLogs.fulfilled, (state: IState, action: any) => {
      state.mailLogs = action.payload.data;
      state.isMailLogSuccess = true;
      state.isMailLogLoading = false;
    });
    builder.addCase(getMailLogs.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMailLogSuccess = false;
      state.isMailLogLoading = false;
    });

    //get Mail Statistics
    builder.addCase(getMailStatistics.pending, (state: IState, action: any) => {
      state.isMailStatisticLoading = true
    });
    builder.addCase(getMailStatistics.fulfilled, (state: IState, action: any) => {
      state.mailStatistics = action.payload.data;
      state.isMailStatisticSuccess = true;
      state.isMailStatisticLoading = false;
    });
    builder.addCase(getMailStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMailStatisticSuccess = false;
      state.isMailStatisticLoading = false;
    });
  },
});

export default MailSlice.reducer;