import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Dashboard CRM
import DashboardReducer from "./dashboard/reducer";

// User
import UserReducer from "./user/reducer";

// Operator
import OperatorReducer from "./operator/reducer";

// Role
import RoleReducer from "./role/reducer";

// Global
import GlobalReducer from "./global/reducer";

// News
import NewsReducer from "./news/reducer";

// Keyword
import KeywordReducer from "./keyword/reducer";

// Journalist
import JournalistReducer from "./journalist/reducer";

// System
import SystemReducer from "./system/reducer";

// Mail
import MailReducer from "./mail/reducer";

// Category
import CategoryReducer from "./category/reducer";


// Image
import ImageReducer from "./image/reducer";

// Log
import LogReducer from "./logging/reducer";

// Tracking
import TrackingReducer from "./tracking/reducer";

// Statistics
import StatisticReducer from "./statistic/reducer";


// Campaigns
import CampaignReducer from "./campaign/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Dashboard: DashboardReducer,
    User: UserReducer,
    Operator: OperatorReducer,
    Role: RoleReducer,
    Global: GlobalReducer,
    Category: CategoryReducer,
    News: NewsReducer,
    Keyword: KeywordReducer,
    Journalist: JournalistReducer,
    System: SystemReducer,
    Mail: MailReducer,
    Log: LogReducer,
    Image: ImageReducer,
    Tracking: TrackingReducer,
    Statistic: StatisticReducer,
    Campaign: CampaignReducer,
});

export default rootReducer;