import { createSlice } from "@reduxjs/toolkit";
import { IActivityLog } from "api/types/_logging";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getActivityLogs } from "./thunk";


export interface IState {
  activityLogs: ResponseData<IActivityLog[]> & PaginationResponse | null,
  isActivityLogLoading: boolean,
  isActivityLogSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  activityLogs: null,
  isActivityLogLoading: false,
  isActivityLogSuccess: false,

  error: {},
};

const LoggingSlice = createSlice({
  name: "Logging",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get User Activity Logs
    builder.addCase(getActivityLogs.pending, (state: IState, action: any) => {
      state.isActivityLogLoading = true
    });
    builder.addCase(getActivityLogs.fulfilled, (state: IState, action: any) => {
      state.activityLogs = action.payload.data;
      state.isActivityLogSuccess = true;
      state.isActivityLogLoading = false;
    });
    builder.addCase(getActivityLogs.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isActivityLogSuccess = false;
      state.isActivityLogLoading = false;
    });

  },
});


export default LoggingSlice.reducer;