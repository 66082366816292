import { IPartner } from "api/types/_news";
import { Option } from "api/types/_public";
import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllPartners, keywordStatistics as onGetKeywordStatistics } from "../../../store/thunks";


const SORT_BY_DEFAULT = 'pv_sum';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const KeywordStatistic = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        media_id: withDefault(StringParam, ''),
        date: withDefault(StringParam, `${moment().format("Y-MM-DD")}`),
        partner_id: withDefault(StringParam, ''),
        keyword: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Statistic;

    const StatisticProperties = createSelector(
        selectLayoutState,
        (state) => ({
            keyword: state.keyword,
            isKeywordSuccess: state.isKeywordSuccess,
            isKeywordLoading: state.isKeywordLoading,
            error: state.error,
        })
    );

    const { keyword, isKeywordLoading } = useSelector(StatisticProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

    const [dateSearch, setDateSearch] = useState<string>(query?.date || "");

    const [mediaSearch, setMediaSearch] = useState<Option | null>(!!query?.partner_id ? { value: query?.partner_id, label: '' } : null);

    const [partners, setPartners] = useState<IPartner[]>([]);

    useEffect(() => {
        dispatch(onGetKeywordStatistics(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            date: dateSearch || '',
            keyword: keywordSearch || '',
            partner_id: mediaSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            date: `${moment().format("Y-MM-DD")}`,
            partner_id: '',
            keyword: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setKeywordSearch('');
        setDateSearch(`${moment().format("Y-MM-DD")}`);
        setMediaSearch({ label: t('All'), value: '' });
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Ranking'),
                accessor: "rank",
                filterable: true,
                sortable: false,
                thClass: 'align-middle text-start',
                thWidth: 90,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            {!!item?.text_row ? <></> : <div className="text-start" style={{ minWidth: '60px' }}>
                                {((query?.page - 1) * (query?.limit)) + (cell?.cell?.row?.index)}
                            </div>}
                        </>
                    )
                },
            },
            {
                Header: t('Keyword'),
                accessor: "keyword",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-start',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-start" style={{ minWidth: '100px' }}>{item?.text_row || cell?.value || ''}</div>
                        </>
                    )
                },
            },
            {
                Header: t('PV'),
                accessor: "pv_sum",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 90,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '80px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: '',
                accessor: "__",
                filterable: true,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 90,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '80px' }}></div>
                        </>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (value: Date | null) => {
        setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM-DD") : '');
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => true);
                const [res1]: any = await Promise.all([getAllPartners()]);
                setIsCallAllOptionLoading((_prev) => false);
                setPartners((_prev: any) => res1?.data);
                if (query?.partner_id) {
                    const _partner = res1?.data?.find((_p: any) => String(_p?.id) === String(query?.partner_id));
                    setMediaSearch({ value: `${_partner?.id}`, label: _partner?.name });
                }

            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        document.title = `${t('Keyword Statistics')} | ${t('PRESS Ai')}`;
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Keyword Statistics')} pageTitle={t('Statistics')} />
                    {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_STATISTICS, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={keyword?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={6} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={true}>
                                                    <DropdownOption
                                                        name="media"
                                                        dataList={(partners || [])?.map((_p: any) => ({ label: _p?.name, value: `${_p?.id}` }))}
                                                        placeholder={`${t("Media")}...`}
                                                        className="dropdown-status-rounded z-hight"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={mediaSearch || null}
                                                        onChangeSelect={(e: any) => setMediaSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Keyword')}...`}
                                                        value={keywordSearch}
                                                        onChange={(e) => setKeywordSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                                                    <DatePicker
                                                        className="form-control search"
                                                        placeholderText={`${t('Date')}...`}
                                                        value={dateSearch || ''}
                                                        dateFormat="yyyy-MM-dd"
                                                        isClearable={true}
                                                        locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                                                        onChange={handleChangePicker}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={3} xxl={3} className="hstack gap-1 justify-content-center justify-content-sm-end justify-content-xl-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isKeywordLoading || isCallAllOptionLoading)}
                                                    >
                                                        {(isKeywordLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed table-have-first-row-is-total"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={keyword?.list?.length ? (
                                                [
                                                    {
                                                        ...keyword?.total,
                                                        text_row: t('Sum')
                                                    },
                                                    ...keyword?.list]
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={keyword?.pagination?.total}
                                            customPageCount={Math.ceil(Number(keyword?.pagination?.total) / Number(keyword?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isKeywordLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default KeywordStatistic;
