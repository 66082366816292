import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import React, { useEffect } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export interface Tag {
    id: string;
    text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);

const DataCarList = (activeTab?: any) => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();


    useEffect(() => {
        document.title = `${t('Car')} - ${t('Data Car')} | PRESS Ai`;
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);


    return (
        <React.Fragment>
            <div className="page-content">

                <div className="fs-16 text-center my-5">{t('The feature is under development.')}</div>
                <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={3000} limit={2} />
            </div >
        </React.Fragment >
    );
};

export default DataCarList;