import { IImageCategory } from "api/types/_image";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import { getImageCategory, postImageCategory, putImageCategory, putImportImageCategoryLevel1 } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    categories?: { value: string, label: string }[],
    isShowLevel1Dropdown?: boolean
    triggerRefresh?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FILE_SIZE = 10 * 1024 * 1024; // 10MB
const SUPPORTED_FORMATS = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const GoogleForm = ({
    id = '',
    triggerRefresh,
    categories = [],
    isShowLevel1Dropdown = false,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { userPermissions } = useRole();

    const [initialValuesDefault, setInitialValuesDefault] = useState<IImageCategory | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {

        try {
            setIsLoading((_prev) => true);
            const data = {
                name: Number(initialValuesDefault?.depth) === 2 ? values?.name_level_2 : (isShowLevel1Dropdown ? values?.name_level_1?.value : (values?.name_level_1 || '')),
                files: values?.file || null,
                urls: (values?.urls || [])?.map((url: any) => url?.site),
            };
            const DEPTH_CATEGORY = initialValuesDefault?.depth || 0;
            const response: any = id ? ( (isShowLevel1Dropdown && Number(DEPTH_CATEGORY) === 1) ? await putImportImageCategoryLevel1(values?.name_level_1?.value, data) : await putImageCategory(id, data, DEPTH_CATEGORY)) : await postImageCategory(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name_level_1: !!initialValuesDefault?.depth ? (isShowLevel1Dropdown ? Yup.object({
            value: Yup.string(), 
            label: Yup.string(),
        }).nullable() : Yup.string().nullable()) : (isShowLevel1Dropdown ? Yup.object({
            value: Yup.string(), 
            label: Yup.string(),
        }).required(t('This is required')) : Yup.string().required(t('This is required'))),
        name_level_2: Number(initialValuesDefault?.depth) === 2 ? Yup.string().required(t('This is required')) : Yup.string().nullable(),
        file: Yup.mixed()
            .test(
                'fileSize',
                t('File too large. Max size is 10MB.'),
                (value: any) => !value || (value?.size <= FILE_SIZE)
            )
            .test(
                'fileFormat',
                t('Unsupported Format. Please upload an Excel file.'),
                (value: any) => !value || SUPPORTED_FORMATS.includes(value?.type)
            )
            .nullable(),
        urls: Number(initialValuesDefault?.depth) === 2 ? Yup.array().of(Yup.object({
            id: Yup.number(), // 'id' is optional
            site: Yup.string().required(t('This is required')),
        })).required(t('This is required')) : Yup.array().of(Yup.object({
            id: Yup.number(), // 'id' is optional
            site: Yup.string().nullable(),
        })).nullable(),
    });

    const formik = useFormik({
        initialValues: {
            name_level_1: isShowLevel1Dropdown ? null : '',
            name_level_2: '',
            file: '',
            urls: [{ id: Date.now(), site: '' }],
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleChangeFile = (value: any | null) => {
        formik?.setFieldValue('file', value?.target?.files[0] || null);
    }

    const handleChangeNameLevel1 = (value: any | null) => {
        formik?.setFieldValue('name_level_1', value?.target?.value || '');
    }

    const handleChangeNameLevel2 = (value: any | null) => {
        formik?.setFieldValue('name_level_2', value?.target?.value || '');
    }

    const handleChangeUrl = (index: number, value: string) => {
        const newUrls = [...formik.values.urls];
        newUrls[index].site = value;
        formik.setFieldValue('urls', newUrls); // Update Formik's urls field
    };

    const handleAddUrl = () => {
        const newUrl = { id: Date.now(), site: '' };
        formik.setFieldValue('urls', [...formik.values.urls, newUrl]);
    };

    const handleRemoveUrl = (index: number) => {
        const newUrls = formik.values.urls.filter((_, i) => i !== index);
        formik.setFieldValue('urls', newUrls);
    };

    const handleSetValueForm = (valueDefault: any) => {
        
        const vNameLevel1 = Number(valueDefault?.depth) === 2 ? (isShowLevel1Dropdown ? { value: String(valueDefault?.parent_id || ''), label: '' } : (valueDefault?.groups?.[0]?.name || '')) : (isShowLevel1Dropdown ? ({ value: String(valueDefault?.id), label: valueDefault?.name }) : valueDefault?.name || '');
        const vNameLevel2 = Number(valueDefault?.depth) === 2 ? (valueDefault?.name || '') : '';
        const vUrls = valueDefault?.sites?.length > 0 ? (valueDefault?.sites || [])?.map((site: any) => ({ id: site?.site_id, site: site?.url })) : [{ id: Date.now(), site: '' }];
      
        setTimeout(() => {
            formik.setFieldValue("name_level_1", vNameLevel1);
            formik.setFieldValue("name_level_2", vNameLevel2);
            formik.setFieldValue("urls", vUrls);
        }, 300);
    };

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            const [resDetail]: any = await Promise.all([idItem ? getImageCategory(idItem) : {},]);
            if (resDetail?.data) {
                setInitialValuesDefault((_prev) => resDetail?.data);
                handleSetValueForm(resDetail?.data);
                setIsLoadingDetail((_prev) => false);
            }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);

        return () => {
            formik?.setFieldValue('file', null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <React.Fragment>
            {isLoadingDetail && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(164 164 164 / 36%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Spinner size="sm" color="primary"></Spinner>
            </div>}
            <div>
                <Container>
                    <Row>
                        <form onSubmit={formik.handleSubmit}>
                            <div style={{ maxWidth: '500px', margin: '0 auto' }}>
                                <Row>
                                    <Col sm={12} md={12} xl={12}>
                                        <Label htmlFor="name_level_1" className="form-label">{t('Category Level')} 1 <span className="text-danger"> *</span></Label>
                                        {isShowLevel1Dropdown ? <><DropdownOption
                                            name="name_level_1"
                                            dataList={categories || []}
                                            placeholder={t('Category Level') + '1'}
                                            className={`dropdown-status-rounded z-hight mb-3 mb-lg-2 mb-xxl-1`}
                                            classNamePrefix="name-prefix"
                                            style={{ width: '100%' }}
                                            initialValue={formik?.values?.name_level_1 as any}
                                            onChangeSelect={(e: any) => {
                                                formik.setFieldValue("name_level_1", e);
                                            }}
                                            disabled={Number(initialValuesDefault?.depth) === 2}
                                        />
                                            {formik.touched.name_level_1 && !!formik.errors.name_level_1 ? (
                                                <div className="text-danger mt-2">{formik.errors.name_level_1}</div>
                                            ) : null}
                                        </> :
                                            <>
                                                <Input className="form-control"
                                                    id="name_level_1"
                                                    type="text"
                                                    value={formik?.values?.name_level_1 || ''}
                                                    placeholder={t('Category Level') + '1'}
                                                    onChange={handleChangeNameLevel1}
                                                    disabled={Number(initialValuesDefault?.depth) === 2}
                                                    autoComplete='off'
                                                />
                                                {formik.touched.name_level_1 && formik.errors.name_level_1 ? (
                                                    <div className="text-danger mt-2">{formik.errors.name_level_1}</div>
                                                ) : null}
                                            </>

                                        }

                                    </Col>
                                    {Number(initialValuesDefault?.depth) === 2 &&
                                        <>
                                            <Col sm={12} md={12} xl={12} className="mt-3">
                                                <Label htmlFor="name_level_2" className="form-label">{t('Category Level')} 2<span className="text-danger"> *</span></Label>
                                                <Input className="form-control"
                                                    id="name_level_2"
                                                    type="text"
                                                    value={formik?.values?.name_level_2 || ''}
                                                    placeholder={t('Category Level') + '2'}
                                                    onChange={handleChangeNameLevel2}
                                                    disabled={Number(initialValuesDefault?.depth) === 1}
                                                    autoComplete='off'
                                                />
                                                {formik.touched.name_level_2 && formik.errors.name_level_2 ? (
                                                    <div className="text-danger mt-2">{formik.errors.name_level_2}</div>
                                                ) : null}
                                            </Col>
                                            {formik.values.urls?.length > 0 && <Col sm={12} md={12} xl={12} className="mt-4 pt-3 pb-2 position-relative" style={{ border: '1px dashed var(--vz-primary)', borderRadius: '4px', padding: '10px' }}>
                                                <span className="text-primary position-absolute fs-13 px-1" style={{ backgroundColor: "var(--vz-secondary-bg)", top: '0px', transform: 'translateY(-50%)' }}>{t('Sites')}</span>
                                                {formik.values.urls.map((url, index) => (
                                                    <div className="mb-2 w-100" key={url.id}>
                                                        <Label htmlFor={`url-${url.id}`} className="form-label">{t('Site')} {index + 1} <span className="text-danger"> *</span></Label>
                                                        <div className="d-flex">
                                                            <Input className="form-control"
                                                                id={`url-${url.id}`}
                                                                type="text"
                                                                value={url.site}
                                                                placeholder={t('Site')}
                                                                onChange={(e) => handleChangeUrl(index, e.target.value)}
                                                                autoComplete='off'
                                                            />
                                                            {formik.values.urls.length === Number(index + 1) ? <>
                                                                {formik.values.urls.length > 1 && <button
                                                                    type="button"
                                                                    className="btn btn-soft-danger ms-2"
                                                                    onClick={() => handleRemoveUrl(index)}
                                                                >
                                                                    <i className="ri-subtract-line align-bottom "></i>

                                                                </button>
                                                                }
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-soft-success ms-2"
                                                                    onClick={handleAddUrl}
                                                                >
                                                                    <i className="ri-add-line align-bottom "></i>
                                                                </button></> :
                                                                <>

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-soft-danger ms-2"
                                                                        onClick={() => handleRemoveUrl(index)}
                                                                    >
                                                                        <i className="ri-subtract-line align-bottom "></i>

                                                                    </button>
                                                                </>
                                                            }
                                                        </div>
                                                        {formik.touched.urls && (formik.errors as any)?.urls?.[index]?.site ? (
                                                            <div className="text-danger mt-2">{(formik.errors as any)?.urls?.[index]?.site || ''}</div>
                                                        ) : null}
                                                    </div>
                                                ))}
                                            </Col>
                                            }
                                        </>
                                    }
                                    {(!initialValuesDefault?.depth || Number(initialValuesDefault?.depth) === 1) &&
                                        <Col sm={12} md={12} xl={12} className='mt-3 '>
                                            <Label htmlFor="formSizeDefault" className="form-label">{t('Category Level') + ' 2 (' + t('Excel File') + ')'}</Label>
                                            <div className="custom-file-button">
                                                <Input className="form-control" accept=".xls,.xlsx"
                                                    label="Upload Your File"
                                                    data-title="Upload Your File"
                                                    id="formSizeDefault"
                                                    type="file"
                                                    onChange={handleChangeFile}
                                                />
                                                {!formik?.values?.file && <div className="text-upload">{t('No file chosen')}</div>}
                                                <span className="icon-upload"> <i className="ri-upload-line" /></span>
                                            </div>
                                            {formik.touched.file && formik.errors.file ? (
                                                <div className="text-danger mt-2">{formik.errors.file}</div>
                                            ) : null}
                                        </Col>
                                    }
                                    <Col sm={12} md={12} xl={12} className='mt-4 pt-1 text-end'>
                                        <button
                                            style={{ width: '220px' }}
                                            type="submit"
                                            disabled={isLoading}
                                            className="btn btn-primary fs-14 rounded-pill">
                                            {isLoading ? <Spinner size="sm me-2" ></Spinner> : <></>}
                                            {id ? t('Button Update Image Category') : t('Button Create Image Category')}
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    </Row >
                </Container >
            </div>
            <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />
        </React.Fragment >
    );
};

export default GoogleForm;
