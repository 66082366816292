import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ICategory } from "./types/_category";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IModelCar, ISection } from "./types/_master";
import { IImageCategory } from "./types/_image";
const api = new APIClient();

const path = '/image-categories';
const imageApi = {
  imageCategories(params: any): Promise<AxiosResponse<ResponseData<IImageCategory[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getImageCategory(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IImageCategory>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  allImageCategory(params: any = {}): Promise<AxiosResponse<ResponseData<IImageCategory[]>, any>> {
    const url = `masters/image-categories`
    return api.get(url, params)
  },
  postImageCategory(dataForm: any): Promise<AxiosResponse<ResponseData<IImageCategory>, any>> {
    const url = `${path}/bulk`;
    const formData = new FormData();
    formData.append(`name`, dataForm?.name);
    formData.append(`urls`, dataForm?.urls);
    if (dataForm.files) {
      formData.append('files', dataForm.files);
    }
    return api.post(url, formData, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  postSiteImageCategory(dataForm: any): Promise<AxiosResponse<ResponseData<IImageCategory>, any>> {
    const url = `/image-sites`;
    return api.post(url, dataForm);
  },
  putImageCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IImageCategory>, any>> {
    const url = `${path}/${id}`;
    const formData = { name: dataForm?.name, urls:  dataForm?.urls };
    return api.put(url, formData)
  },
  putImportImageCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IImageCategory>, any>> {
    const url = `${path}/${id}/import`;
    const formData = new FormData();
    if (dataForm.files) {
      formData.append('files', dataForm.files);
    }
    return api.put(url, formData, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  putImportImageCategoryLevel1(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IImageCategory>, any>> {
    const url = `${path}/bulk`;
    const formData = new FormData();
    formData.append('category_depth_1_id', `${id}`);
    if (dataForm.files) {
      formData.append('files', dataForm.files);
    }
    return api.post(url, formData, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  deleteImageCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IImageCategory>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },

  getImageSearchByCategory(params: any = {}): Promise<AxiosResponse<ResponseData<IImageCategory>, any>> {
    const url = `/image-sites`;
    return api.get(url, params)
  },
  postConvertToCdn(dataForm: any): Promise<AxiosResponse<ResponseData<IImageCategory>, any>> {
    const url = `/image-convert`;//`/utilities/convert-cdn`;
    return api.post(url, dataForm);
  },
}
export default imageApi
