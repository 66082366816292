import { ICategory } from 'api/types/_category';
import { IKeywordBoost } from 'api/types/_keyword';
import { Option } from 'api/types/_public';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import CopyWrapper from 'components/Common/CopyWrapper';
import DropdownOption from 'components/Common/DropdownOption';
import DropdownStatus from 'components/Common/DropdownStatus';
import InputsRange from 'components/Common/InputsRange';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import RangeDatePicker from 'components/pickers/RangeDatePicker';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { COLORS_KEYWORD_PUBLISH_LOG_TYPE, COLORS_KEYWORD_TYPE, COLORS_RANK_NEWS, COLORS_STATUS_KEYWORD_BOOST, KEYWORD_PUBLISH_LOG_TYPE_OPTIONS, KEYWORD_TYPE_OPTIONS, RANK_NEWS_OPTIONS, STATUS_KEYWORD_BOOST_OPTIONS, STATUS_USER_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import { debounce } from 'lodash';
import moment from 'moment';
import CategoryManagement from 'pages/Category';
import NestingFilter from 'pages/News/Components/NestingFilter';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import TableContainer from "../../components/Common/TableContainer";
import { deleteKeywordBoostV2Multi, downloadExcelKeywordBoostV2, getAllCategory, getKeywordAutocomplete, getKeywordBoostV2Statistics, getKeywordBoostV2s as onGetKeywordBoostV2s, putKeywordBoostV2, putStatusKeywordV2Multi } from "../../store/thunks";
import KeywordBoostFormV2 from './KeywordBoostFormV2';
import ImportBoostKeywordForm from './ImportBoostKeywordForm';
export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'last_published_at';

registerLocale('en', en);
registerLocale('ko', ko);


const KeywordBoostV2 = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const STATUS_USER_OPTIONS_LANG: any = STATUS_USER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const STATUS_KEYWORD_BOOST_OPTIONS_LANG = STATUS_KEYWORD_BOOST_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const RANK_NEWS_OPTIONS_LANG = RANK_NEWS_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));


  const KEYWORD_TYPE_OPTIONS_LANG = KEYWORD_TYPE_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const KEYWORD_PUBLISH_LOG_TYPE_OPTIONS_LANG = KEYWORD_PUBLISH_LOG_TYPE_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));


  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 50),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, 'DESC'),
    date: withDefault(StringParam, moment().format("Y-MM-DD")),
    keyword: withDefault(StringParam, ''),
    media_name: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
    // start_exposure_rank: withDefault(StringParam, '0'),
    // end_exposure_rank: withDefault(StringParam, '1000'),
    start_daily_search_volume: withDefault(StringParam, '1000'),
    end_daily_search_volume: withDefault(StringParam, ''),
    last_published_at_start: withDefault(StringParam, ''),
    last_published_at_end: withDefault(StringParam, ''),
    exposure_rank_type: withDefault(StringParam, RANK_NEWS_OPTIONS_LANG[0]?.value),
    type: withDefault(StringParam, ''),
    keyword_publish_log_type: withDefault(StringParam, ''),
    category_id: withDefault(StringParam, ''),
  });

  const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);

  const [totalKeywordBoostActivates, setTotalKeywordBoostActivates] = useState<number>(0);

  const [idLoading, setIdLoading] = useState<string | number | null>(null);

  const [listCategory, setListCategory] = useState<ICategory[]>([]);

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<IKeywordBoost | null>(null);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [mediaSearch, setMediaSearch] = useState<string>(query?.media_name || "");
  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_USER_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(query?.status)) || null);

  const [keywordPublishLogTypeSearch, setKeywordPublishLogTypeSearch] = useState<Option | null>(KEYWORD_PUBLISH_LOG_TYPE_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.keyword_publish_log_type)) || null);

  const [typeSearch, setTypeSearch] = useState<Option | null>(KEYWORD_TYPE_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.type)) || null);

  const [rankSearch, setRankSearch] = useState<Option | null>(RANK_NEWS_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.exposure_rank_type)) || null);

  const [startExposureSearch, setStartExposureSearch] = useState<string>(query?.start_exposure_rank || "0");
  const [endExposureSearch, setEndExposureSearch] = useState<string>(query?.end_exposure_rank || "1000");

  const [startLastPublishedSearch, setStartLastPublishedSearch] = useState<string>(query?.last_published_at_start || "");
  const [endLastPublishedSearch, setEndLastPublishedSearch] = useState<string>(query?.last_published_at_end || "");

  const [startDailyVolumeSearch, setStartDailyVolumeSearch] = useState<string>(query?.start_daily_search_volume || "");
  const [endDailyVolumeSearch, setEndDailyVolumeSearch] = useState<string>(query?.end_daily_search_volume || "");

  const [dateSearch, setDateSearch] = useState<any>(query?.date ? moment(query?.date, 'Y-MM-DD').toDate() : null);

  const [keywordEdit, setKeywordEdit] = useState<IKeywordBoost & { isCopy?: boolean } | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);

  const [isOpenImportBoostKeywords, setIsOpenImportBoostKeywords] = useState<boolean>(false);
  

  const [isOpenConfirmChangeStatusMulti, setIsOpenConfirmChangeStatusMulti] = useState<"ACTIVATE" | "DEACTIVATE" | false>(false);

  const [optionsSelected, setOptionsSelected] = useState<any>([]);

  const [isAutocompleting, setIsAutocompleting] = useState<boolean>(false);
  const [keywordAutocompletes, setKeywordAutocompletes] = useState<{ keyword: string, count: string }[]>([]);

  const [isOpenCategoryManagement, setIsOpenCategoryManagement] = useState<boolean>(false);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Keyword;

  const KeywordProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywordBoostV2s: state.keywordBoostV2s,
      isKeywordBoostV2Success: state.isKeywordBoostV2Success,
      isKeywordBoostV2Loading: state.isKeywordBoostV2Loading,
      error: state.error,
    })
  );

  // Inside your component
  const { keywordBoostV2s, error, isKeywordBoostV2Loading } = useSelector(KeywordProperties);

  const onGetKeywordBoostV2Statistics = (params: any) => {
    getKeywordBoostV2Statistics(params).then((res: any) => {
      setTotalKeywordBoostActivates((_prev) => res?.data?.active_count || 0)
    }).catch((error) => { });
  };

  useEffect(() => {
    dispatch(onGetKeywordBoostV2s(query));
    onGetKeywordBoostV2Statistics(query);
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setOptionsSelected((prev: any) => []);
    const queryNew = {
      ...query,
      keyword: keywordSearch || '',
      media_name: mediaSearch || '',
      date: dateSearch ? moment(new Date(dateSearch)).format("Y-MM-DD") : '',
      start_exposure_rank: startExposureSearch || '',
      end_exposure_rank: endExposureSearch || '',
      start_daily_search_volume: startDailyVolumeSearch || '',
      end_daily_search_volume: endDailyVolumeSearch || '',
      last_published_at_start: startLastPublishedSearch || '',
      last_published_at_end: endLastPublishedSearch || '',
      exposure_rank_type: rankSearch?.value,
      type: typeSearch?.value,
      keyword_publish_log_type: keywordPublishLogTypeSearch?.value || '',
      status: `${statusSearch?.value}`,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    };
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      keyword: '',
      media_name: '',
      start_exposure_rank: '0',
      end_exposure_rank: '1000',
      start_daily_search_volume: '1000',
      end_daily_search_volume: '',
      last_published_at_start: '',
      last_published_at_end: '',
      status: '',
      category_id: '',
      exposure_rank_type: RANK_NEWS_OPTIONS_LANG[0]?.value,
      type: '',
      keyword_publish_log_type: '',
      order_by: 'DESC',
      sort_by: TYPE_SELECT_DEFAULT,
      date: `${moment().format("Y-MM-DD")}`,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setKeywordSearch((_prev) => "");
    setMediaSearch((_prev) => "");
    setStatusSearch(null);
    setRankSearch(RANK_NEWS_OPTIONS_LANG[0]);
    setTypeSearch(null);
    setKeywordPublishLogTypeSearch(null);
    setStartExposureSearch(queryNew?.start_exposure_rank);
    setEndExposureSearch(queryNew?.end_exposure_rank);
    setStartDailyVolumeSearch(queryNew?.start_daily_search_volume);
    setEndDailyVolumeSearch(queryNew?.end_daily_search_volume);
    setStartLastPublishedSearch(queryNew?.last_published_at_start);
    setEndLastPublishedSearch(queryNew?.last_published_at_end);
    setDateSearch(moment(queryNew?.date || "", 'Y-MM-DD').toDate());
  };

  // Begin::Update keyword
  const handleUpdateBoostKeyword = async (dataSubmit: { id: string | number, status: number, keyword_sub: string } | undefined = undefined) => {
    try {
      if (!keywordEdit?.id && !dataSubmit) {
        return;
      }
      setIdLoading((_prev) => (dataSubmit?.id || keywordEdit?.id) as string);
      const data = !!dataSubmit ? ({
        status: dataSubmit?.status,
        keyword_sub: dataSubmit?.keyword_sub || '',
      }) : ({
        status: keywordEdit?.status,
        keyword_sub: keywordEdit?.keyword_sub || '',
      });
      const response: any = await putKeywordBoostV2((dataSubmit?.id || keywordEdit?.id) as string, data);
      if (response?.code === 200) {
        setIdLoading((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        triggerRefresh();
      } else {
        setIdLoading((_prev) => null);
        toast(`${response?.message || response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIdLoading((_prev) => null);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }

  };

  const onCloseClick = () => {
    setKeywordAutocompletes((_prev) => ([]));
    setKeywordEdit((_prev) => null);
    setIsOpen((_prev) => false);
  }

  const onCloseFormClick = () => {
    setIsOpenForm((_prev) => false);
  }

  const onCloseImportBoostKeywordsClick = () => {
    setIsOpenImportBoostKeywords((_prev) => false);
  }

  const triggerRefresh = (is_refresh_categories: boolean = false) => {
    onCloseClick();
    onCloseFormClick();
    onCloseConfirmChangeStatusMultiClick();
    onCloseImportBoostKeywordsClick();
    setQuery({
      ...query,
      ...(!!is_refresh_categories ? { category_id: '' } : {}),
      time_request: + new Date(),
    });
    onGetKeywordBoostV2Statistics(query);
    if (is_refresh_categories) {
      handleCallAllOption();
    }
  }

  //End::Update User

  const onCloseCategoryManagementClick = () => {
    setIsOpenCategoryManagement((_prev) => false);
  }

  const triggerRefreshCategory = () => {
    handleCallAllOption();
  }

  // Begin::Delete
  const handleConfirmDelete = (item: IKeywordBoost | null) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!initialValuesDefault?.id && !countRowSelected) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const ids = initialValuesDefault?.id ? [initialValuesDefault?.id] : (optionsSelected || []).flat()?.map((item: any) => item?.value);
      const response: any = await deleteKeywordBoostV2Multi({
        ids: ids
      });
      if (response?.code === 200) {
        triggerRefresh();
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        if (!initialValuesDefault?.id) {
          setOptionsSelected((prev: any) => []);
        }
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  const handleDownloadExcel = async () => {
    try {
      setIsLoadingExportFile((_prev) => true);
      const response: any = await downloadExcelKeywordBoostV2({ ...query });
      if (response?.data) {
        setIsLoadingExportFile((_prev) => false);
        const link = document.createElement('a');
        link.href = response?.data?.url;
        link.download = 'boosting_keywords';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoadingExportFile((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingExportFile((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    // if (!!isFirstLoadingPageRef.current) {
    //   return;
    // }
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (keywordBoostV2s?.list || [])?.map((item: any) => ({ value: item?.id, label: item?.id }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === keywordBoostV2s?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  // End:: handle select table 

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Key'),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: 'text-start align-middle',
        thWidth: 50,
        thComponent: () => (<>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
            <label className="form-check-label" htmlFor={`cell-check-all`}></label>
          </div>
        </>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div className={`form-check`}>
                <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.id, label: cell?.row?.original?.id })} value={cell?.row?.original?.id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.id, label: cell?.row?.original?.id }), query?.page, e)} id={`cell-check-${cell?.row?.original?.id ?? ''}`} />
                <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.id ?? ''}`}></label>
              </div>
            </>
          )
        },
      },
      {
        Header: t('ID'),
        accessor: "id",
        filterable: true,
        sortable: false,
        width: 60,
        thClass: 'text-start align-middle',
        Cell: (cell: any) => (
          <>
            <span style={{ minWidth: '40px' }}>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Active Action'),
        accessor: "status",
        filterable: false,
        sortable: false,
        thClass: 'text-center align-middle',
        width: 100,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const active = item?.status;
          return (
            <div className="text-center form-check form-switch form-switch-sm position-relative" dir="ltr" style={{ minWidth: '80px' }}>
              {(String(idLoading) === String(item?.id)) && <span className="position-absolute" style={{ top: '3px', left: 0 }}><Spinner size="sm" className="text-primary" /></span>}
              <input type="checkbox" className="form-check-input" name="status" id="customSwitchsizelg" checked={Boolean(active)}
                onChange={(event: any) => {
                  if (isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_CREATE, userPermissions)) {
                    handleUpdateBoostKeyword(
                      { id: item?.id, status: event.target.checked, keyword_sub: item?.keyword_sub || '' }
                    );
                  }
                }}
              />
            </div>
          );
        }
      },
      {
        Header: t('Type'),
        accessor: "type",
        filterable: false,
        sortable: false,
        thClass: "align-middle text-center",
        thWidth: i18n?.language === 'en' ? 90 : 80,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: '60px' }} className="text-center fs-16">
              <span className={`rounded-pill badge bg-${COLORS_KEYWORD_TYPE[`${cell?.value || 0}`] || 'success'}`}>
                {KEYWORD_TYPE_OPTIONS_LANG?.find((s: any) => String(s?.value) === String(cell?.value))?.label || ''}
              </span>
            </div>
          );
        }
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: true,
        sortable: true,
        width: 120,
        thClass: 'text-start align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '100px' }}>
              {cell?.value || '-'}
            </div>
          </>)
        },
      },
      {
        Header: t('Sub Keyword'),
        accessor: "keyword_sub",
        filterable: true,
        sortable: true,
        width: 150,
        thClass: 'text-start align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '130px' }}>{cell?.value || '-'}</div>
          </>)
        },
      },
      {
        Header: t('Category'),
        accessor: "category",
        filterable: true,
        sortable: true,
        width: 120,
        thClass: 'text-start align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '100px' }}>{item?.category?.name || '-'}</div>
          </>)
        },
      },
      {
        Header: t('Naver News'),
        accessor: "naver_search_link",
        filterable: true,
        sortable: false,
        thClass: 'text-center align-middle',
        width: i18n?.language === 'en' ? 120 : 100,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center" style={{ width: i18n?.language === 'en' ? '100px' : '80px' }}>
              <CopyWrapper contentCopy={item?.naver_search_link || ''} style={{ display: 'inline-flex' }}>
                <Link
                  className="nav-link hover-underline-primary ps-3"
                  target="_blank"
                  to={item?.naver_search_link || ''} >
                  [{t('Link')}]
                </Link>
              </CopyWrapper>
            </div>

          </>)
        },
      },
      {
        Header: t('Daily Search Volume'),
        accessor: "daily_search_volume",
        filterable: true,
        sortable: true,
        thClass: 'text-end align-middle',
        width: i18n?.language === 'en' ? 170 : 100,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="pe-3 text-end" style={{ minWidth: i18n?.language === 'en' ? '160px' : '80px' }}>{formatNumberWithCommas(cell?.value)}</div>
          </>)
        },
      },
      {
        Header: t('S.M Ranking'),
        accessor: "exposure_rank",
        filterable: true,
        sortable: true,
        thWidth: 140,
        thClass: 'align-middle text-center',
        description: t('This is the ranking of partner media in Naver News search results.'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center pe-3" style={{ minWidth: '120px' }}>{cell?.value ? formatNumberWithCommas(cell?.value || 0) : '-'}</div>
          </>)
        },
      },
      {
        Header: t('Search Media'),
        accessor: "media_name",
        filterable: true,
        sortable: false,
        thClass: 'text-end align-middle',
        width: i18n?.language === 'en' ? 130 : 120,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end hover-underline-primary " style={{ minWidth: i18n?.language === 'en' ? '110px' : '100px' }}>
              <Link
                className="nav-link"
                target="_blank"
                to={item?.news_link || ''} >
                {cell?.value || '-'}
              </Link>
            </div>
          </>)
        },
      },
      {
        Header: t('Affiliate Ranking'),
        accessor: "keyword_publish_logs",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: 'align-middle text-end',
        description: t('These are the rankings of articles published in the last 7 days.'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end" style={{ minWidth: '130px' }}>
              {item?.keyword_publish_logs?.length ? <>{item?.keyword_publish_logs?.map((_log: any) => (
                <div key={_log?.partner_id} className="pe-3 me-1">{_log?.exposure_rank || '-'} ({_log?.partner_name || ''})</div>
              ))}</> : <div className="pe-3 me-1">-</div>}
            </div>
          </>)
        },
      },
      {
        Header: t('Exposure Period'),
        accessor: "last_published_at",
        filterable: true,
        sortable: true,
        thWidth: i18n?.language === 'en' ? 170 : 120,
        thClass: 'align-middle text-end',
        description: t('This is the last published date among the 4 most recent articles in the Naver News search.'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <div className="text-end pe-3 me-3" style={{ minWidth: i18n?.language === 'en' ? '140px' : '100px' }}>
              {item?.last_published_label || '-'}
            </div>
          )
        },
      },
      {
        Header: t('Date'),
        accessor: "date",
        filterable: true,
        sortable: false,
        thWidth: i18n?.language === 'en' ? 150 : 120,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span>{arrDate?.[0] || '-'}</span>
            </div>
          )
        },
      },
      {
        Header: t('Created At'),
        accessor: "created_at",
        filterable: true,
        sortable: true,
        thWidth: i18n?.language === 'en' ? 130 : 120,
        thClass: 'align-middle text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end pe-3" style={{ minWidth: i18n?.language === 'en' ? '110px' : '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: i18n?.language === 'en' ? 110 : 100,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: i18n?.language === 'en' ? '90px' : '80px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Action'),
        thWidth: 100,
        thClass: 'text-center align-middle',
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0" style={{ minWidth: '70px' }}>
              {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_DELETE, userPermissions) && (
                <TooltipCustom
                  title={t('Button Delete Boost Keyword')}
                  id={`detail-cp-${item?.id}`}
                >
                  <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                    >
                      <i className="ri-delete-bin-3-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, query, userPermissions, idLoading, JSON.stringify(optionsSelected)]
  );

  // Begin::Update status
  const handleConfirmChangeStatusMulti = (type_status: "ACTIVATE" | "DEACTIVATE") => {
    setIsOpenConfirmChangeStatusMulti((_prev) => type_status);
  };

  const handleActionChangeStatus = async () => {
    try {
      setIsConfirmLoading((_prev) => true);
      const kw_s = (optionsSelected || []).flat()?.map((item: any) => item?.value);
      const type_status: any = isOpenConfirmChangeStatusMulti || 'ACTIVE';
      const response: any = await putStatusKeywordV2Multi(type_status, {
        ids: kw_s,
      });
      if (response?.code === 200) {
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        triggerRefresh();
        setIsConfirmLoading((_prev) => false);
        setIsOpenConfirmChangeStatusMulti((_prev) => false);
        setOptionsSelected((prev: any) => []);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsOpenConfirmChangeStatusMulti((_prev) => false);
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmChangeStatusMultiClick = () => {
    setIsOpenConfirmChangeStatusMulti((_prev) => false);
  }
  // End::Update status

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const changeLimitPerPage = (limit: number) => {
    const queryNew = {
      ...query,
      limit: limit,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setOptionsSelected((prev: any) => []);
  };


  const handleChangeDatePicker = (date: any) => {
    setDateSearch((_prev: any) => date);
  }

  const handelChangeCategory = (category_id_search: string) => {
    setOptionsSelected((prev: any) => []);
    const queryNew = {
      ...query,
      category_id: category_id_search,
      page: 1,
      time_request: + new Date()
    };
    setQuery(queryNew);
  }

  const handleCallAllOption = async () => {
    try {
      const [res]: any = await Promise.all([getAllCategory()]);
      const list = res?.data || [];
      setListCategory((_prev) => list);
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [keywordAutocomplete, setKeywordAutocomplete] = useState<string>('');

  const handleGetKeywordAutocompleteToSubKeyword = (kw: string) => {
    setKeywordEdit((_prev: any) => ({ ..._prev, keyword_sub: kw }))
  };

  const handleCallKeywordAutocomplete = useCallback(debounce(async (kw: string = '') => {
    try {
      setIsAutocompleting((_prev) => true);
      const response: any = await getKeywordAutocomplete({
        keyword: kw,
      });
      setIsAutocompleting((_prev) => false);
      if (response?.code === 200) {
        setKeywordAutocompletes((_prev) => (response?.data || []));
      }
    } catch (error: any) {
      setIsAutocompleting((_prev) => false);
      return error;
    }
  }, 500), []);


  useEffect(() => {
    if (keywordAutocomplete && !!isOpen) {
      handleCallKeywordAutocomplete(keywordAutocomplete || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordAutocomplete, isOpen]);


  useEffect(() => {
    document.title = `${t('Home')} - ${t('Boosting Keyword')} | PRESS Ai`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Boosting Keyword')} pageTitle={t('Home')} />
          {/* <div className="d-flex justify-content-end gap-3 align-items-center text-end my-2 flex-wrap">
            <div className="d-flex justify-content-end align-items-center">
              <div className="me-1">
                {t('Showcase')}
              </div>
              <ul className="pagination pagination-separated" role="navigation" >
                <li className={`page-item ${String(query?.limit) === '30' ? 'active' : ''}`}>
                  <a rel="prev" role="button" href="/" className="page-link"
                    onClick={(e) => {
                      e.preventDefault();
                      changeLimitPerPage(30);
                    }}
                  >30</a>
                </li>
                <li className={`page-item ${String(query?.limit) === '50' ? 'active' : ''}`}>
                  <a rel="canonical" role="button" href="/" className="page-link" aria-current="page"
                    onClick={(e) => {
                      e.preventDefault();
                      changeLimitPerPage(50);
                    }}
                  >50</a>
                </li>
                <li className={`page-item ${String(query?.limit) === '100' ? 'active' : ''}`}>
                  <a rel="next" role="button" href="/" className="page-link"
                    onClick={(e) => {
                      e.preventDefault();
                      changeLimitPerPage(100);
                    }}
                  >100</a>
                </li>
              </ul>
            </div>
          </div> */}
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                  <div className="ribbon round-shape top-info-lg" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem' }}>{t('This data is analyzed once a day')}</div>
                  <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                    <Row className="g-4 align-items-center">
                      <div className="col-lg-3 mt-5 mt-sm-4">
                        <div>
                          <h5 className="card-title mb-0">
                            <span>
                              <span className="me-2">{t('Total')}:</span>
                              <CountUp
                                start={0}
                                end={keywordBoostV2s?.pagination?.total || 0}
                                duration={1}
                                className="text-primary"
                              />
                            </span>
                            <span className="mx-2 text-muted">|</span>
                            <span>
                              <span className="me-2">{t('Activates')}:</span>
                              <span className="text-primary">{formatNumberWithCommas(totalKeywordBoostActivates || 0)}</span>
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-lg-9 text-end mt-3 mt-lg-4">
                        <div className={`d-flex gap-3 flex-wrap justify-content-end padding-lg-right-info-${i18n?.language}`}>
                          {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_LIST, userPermissions) && (
                            <button
                              type="button"
                              className="btn btn-success add-btn rounded-pill mb-2 mb-sm-0"
                              id="create-btn"
                              onClick={() => setIsOpenCategoryManagement((_prev) => true)}
                            >
                              {t('Category Management')}
                            </button>)}
                          {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_CREATE, userPermissions) && (
                            <button
                              type="button"
                              className="btn btn-success add-btn rounded-pill mb-2 mb-sm-0"
                              id="create-btn"
                              onClick={() => setIsOpenForm((_prev) => true)}
                            >
                              <i className="ri-add-line align-bottom me-1"></i>
                              {t('Button Create Boost Keyword')}
                            </button>)}
                            {/* {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_CREATE, userPermissions) && (<button
                            type="button"
                            className="btn btn-success add-btn rounded-pill mb-2 mb-sm-0"
                            onClick={() => setIsOpenImportBoostKeywords((_prev) => true)}
                          >
                            <i className="ri-upload-line align-bottom"></i>
                            <span className="ms-1">{t('Button Import Bulk Boost Keywords')}</span>
                          </button>)} */}
                          {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_LIST, userPermissions) && (<button
                            type="button"
                            className="btn btn-success add-btn rounded-pill mb-2 mb-sm-0"
                            onClick={handleDownloadExcel}
                            disabled={isLoadingExportFile}
                          >
                            {isLoadingExportFile ? <Spinner size="sm" ></Spinner> : <i className="ri-download-line align-bottom"></i>}
                            <span className="ms-1">{t('Button Download Excel')}</span>
                          </button>)}
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-0 pb-md-4 px-0 px-lg-3 pt-0 pt-lg-3">
                    <NestingFilter>
                      <Row className="g-4 align-items-center mt-0">
                        <Col sm={6} md={6} lg={6} xl={6} xxl={2} className='mt-3 mt-xxl-2' >
                          <LabelWrapper label={t('Type')} isShow={true}>
                            <DropdownStatus
                              name="type"
                              dataList={KEYWORD_TYPE_OPTIONS_LANG || []}
                              placeholder={`${t("Type")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={typeSearch || null}
                              onChangeSelect={(e: any) => setTypeSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                              colors={COLORS_KEYWORD_TYPE}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} xxl={2} className='mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Status')} isShow={true}>
                            <DropdownStatus
                              name="status"
                              dataList={STATUS_KEYWORD_BOOST_OPTIONS_LANG || []}
                              placeholder={`${t("Status")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={statusSearch || null}
                              onChangeSelect={(e: any) => setStatusSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                              colors={COLORS_STATUS_KEYWORD_BOOST}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} xxl={2} className='mt-3 mt-xxl-2' >
                          <LabelWrapper label={t('S.M Ranking')} isShow={true}>
                            <DropdownStatus
                              name="rank"
                              dataList={RANK_NEWS_OPTIONS_LANG || []}
                              placeholder={`${t("S.M Ranking")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={rankSearch || null}
                              onChangeSelect={(e: any) => setRankSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                              colors={COLORS_RANK_NEWS}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} xxl={2} className='mt-3 mt-xxl-2' >
                          <LabelWrapper label={t('Affiliate Ranking')} isShow={true}>
                            <DropdownStatus
                              name="keyword_publish_log_type"
                              dataList={KEYWORD_PUBLISH_LOG_TYPE_OPTIONS_LANG || []}
                              placeholder={`${t("Affiliate Ranking")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={keywordPublishLogTypeSearch || null}
                              onChangeSelect={(e: any) => setKeywordPublishLogTypeSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                              colors={COLORS_KEYWORD_PUBLISH_LOG_TYPE}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6} xxl={4} className='date-picker-wrapper-custom mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                            <RangeDatePicker
                              maxDate={moment().toDate()}
                              dateSearch={dateSearch}
                              handleChangeDatePicker={handleChangeDatePicker}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} xxl={2} className='mt-3 mt-xxl-3'>
                          <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('Keyword')}...`}
                              value={keywordSearch}
                              onChange={(e) => setKeywordSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} xxl={2} className='mt-3 mt-xxl-3'>
                          <LabelWrapper label={t('Search Media')} isShow={!!mediaSearch}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('Search Media')}...`}
                              value={mediaSearch}
                              onChange={(e) => setMediaSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col sm={12} md={6} lg={6} xl={6} xxl={4} className='mt-3 mt-xxl-3' >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={startLastPublishedSearch}
                            valueTo={endLastPublishedSearch}
                            placeholderFrom={`${t('Exposure Period (Start)')}...`}
                            placeholderTo={`${t('Exposure Period (End)')}...`}
                            labelFrom={`${t('Exposure Period (Start)')}`}
                            labelTo={`${t('Exposure Period (End)')}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) => setStartLastPublishedSearch(val)}
                            onChangeTo={(val) => setEndLastPublishedSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6} xxl={4} className='mt-3 mt-xxl-3' >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={startDailyVolumeSearch}
                            valueTo={endDailyVolumeSearch}
                            placeholderFrom={`${t('Daily Search Volume (Start)')}...`}
                            placeholderTo={`${t('Daily Search Volume (End)')}...`}
                            labelFrom={`${t('Daily Search Volume (Start)')}`}
                            labelTo={`${t('Daily Search Volume (End)')}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) => setStartDailyVolumeSearch(val)}
                            onChangeTo={(val) => setEndDailyVolumeSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6} xxl={12} className=" hstack gap-1 justify-content-center justify-content-sm-end justify-content-xxl-end mt-3 mt-xxl-3">
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-2 "
                              onClick={searchData}
                              disabled={isKeywordBoostV2Loading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t('Button Search')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t('Button Reset')}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </NestingFilter>
                  </div>
                  <div className="border border-dashed border-top-0 border-end-0 border-start-0 px-3 py-3">
                    <Col sm={12} md={12} xl={12} xxl={12} className="mb-3 mb-lg-4">
                      <div>
                        <div className="d-flex gap-2 flex-wrap">
                          <button type="button" className={`btn px-3 px-md-4 py-btn-section ${String(query?.category_id) === '' ? 'bg-primary text-white' : 'border-gray text-secondary'}`}
                            onClick={(e) => { e.preventDefault(); handelChangeCategory('') }}
                          >
                            {t('All')}
                          </button>
                          {listCategory?.map((item) => (
                            <button type="button" className={`btn px-3 px-md-4 py-btn-section ${String(query?.category_id) === String(item?.id) ? `bg-primary text-white` : `border-gray text-secondary`}`} key={item?.id}
                              onClick={(e) => { e.preventDefault(); handelChangeCategory(`${item?.id}`) }}
                            >
                              {item?.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={12} xl={12} className="my-0 mt-2 mt-md-0 text-start d-flex flex-wrap gap-3 justify-content-between align-items-center">
                      <div>
                        {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_UPDATE, userPermissions) &&
                          <button
                            type="button"
                            className="btn btn-soft-primary me-3"
                            style={{ width: '85px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatusMulti('ACTIVATE') }}
                          >
                            {countRowSelected > 0 && (
                              <span
                                className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                style={{ transform: "translate(0%, -70%)" }}
                              >
                                {formatNumberWithCommas(countRowSelected)}
                                <span className="visually-hidden">
                                  total keywords selected
                                </span>
                              </span>
                            )}
                            {t('Activate')}
                          </button>
                        }
                        {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_UPDATE, userPermissions) &&
                          <button
                            type="button"
                            className="btn btn-soft-primary me-3"
                            style={{ width: '105px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatusMulti('DEACTIVATE') }}
                          >
                            {countRowSelected > 0 && (
                              <span
                                className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                style={{ transform: "translate(0%, -70%)" }}
                              >
                                {formatNumberWithCommas(countRowSelected)}
                                <span className="visually-hidden">
                                  total keywords selected
                                </span>
                              </span>
                            )}
                            {t('Deactivate')}
                          </button>
                        }
                        {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_DELETE, userPermissions) &&
                          <button
                            type="button"
                            className="btn btn-soft-danger"
                            style={{ width: '85px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmDelete(null) }}
                          >
                            {countRowSelected > 0 && (
                              <span
                                className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                style={{ transform: "translate(0%, -70%)" }}
                              >
                                {formatNumberWithCommas(countRowSelected)}
                                <span className="visually-hidden">
                                  total keywords selected
                                </span>
                              </span>
                            )}
                            {t('Button Delete')}
                          </button>
                        }
                      </div>
                      <div className="">
                        <LabelWrapper label={t('Showcase')} isShow={true}>
                          <DropdownOption
                            name="showcase"
                            dataList={[{ label: '30', value: '30' }, { label: '50', value: '50' }, { label: '100', value: '100' }]}
                            placeholder={`${t("Showcase")}...`}
                            className={`dropdown-status-rounded z-hight-100`}
                            classNamePrefix="name-prefix"
                            style={{ width: '85px' }}
                            initialValue={{ label: `${query?.limit}`, value: `${query?.limit}` }}
                            onChangeSelect={(e: any) => {
                              changeLimitPerPage(e?.value);
                            }}
                          />
                        </LabelWrapper>
                      </div>
                    </Col>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={keywordBoostV2s?.list?.length ? keywordBoostV2s?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={keywordBoostV2s?.pagination?.total}
                      customPageCount={Math.ceil(Number(keywordBoostV2s?.pagination?.total) / Number(keywordBoostV2s?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isKeywordBoostV2Loading}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal
          isOpen={isOpen}
          centered={true}
          size="lg"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseClick}>
            <>{t("Button Update")} </>
            <span className="ms-2">-</span>
            <span className="ms-2" style={{ backgroundColor: '#ffff00' }}>{(keywordEdit?.keyword || '')}</span>
          </ModalHeader>
          <ModalBody className="pt-0">
            <Row>
              <Col sm={12} md={12} lg={6}>
                <div>
                  <div>
                    <div className="mb-4">
                      <Label htmlFor="keyword_sub" className="form-label">{t('Sub Keyword')}</Label>
                      <Input
                        name="keyword_sub"
                        type="text"
                        className="form-control"
                        id="keyword_sub"
                        placeholder={t('Sub Keyword')}
                        value={keywordEdit?.keyword_sub || ''}
                        onChange={(event: any) => {
                          setKeywordEdit((_prev: any) => ({ ..._prev, keyword_sub: event?.target?.value }) as IKeywordBoost);
                          // setKeywordAutocomplete((_prev) => event?.target?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-100 d-flex gap-3 mt-3 justify-content-end align-items-center">
                    <button
                      className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center"
                      type="button"
                      color="light"
                      onClick={() => handleUpdateBoostKeyword()}
                      style={{ width: '110px' }}
                      disabled={!!idLoading}
                    >
                      {!!idLoading && <span className="me-2"><Spinner size="sm" /></span>} <span>{t("Button Update")}</span>
                    </button>
                    <button
                      className="btn btn-secondary fs-14 px-1 d-flex justify-content-center align-item-center"
                      type="button"
                      color="light"
                      onClick={onCloseClick}
                      style={{ width: '75px' }}
                      disabled={!!idLoading}
                    >
                      <span className="ms-1">{t("Button Close")}</span>
                    </button>
                  </div>
                </div>
              </Col>
              <Col m={12} md={12} lg={6}>
                <Label htmlFor="keyword_sub" className="form-label">{t('Related Keywords')}</Label>
                <div className="position-relative">
                  <div className="overflow-y-auto" style={{ height: '220px', border: '1px dashed var(--vz-primary)', borderRadius: '4px', padding: '5px' }}>
                    {keywordAutocompletes?.map((kw) => (
                      <p className="item-keyword-autocomplete" onClick={() => handleGetKeywordAutocompleteToSubKeyword(kw?.keyword)}>
                        <i className='ri-arrow-left-up-line me-1 fs-16 text-muted' />{kw?.keyword}
                      </p>
                    ))}
                  </div>
                  {isAutocompleting && <span className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Spinner size="sm" className="text-primary" />
                  </span>}
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isOpenForm}
          centered={true}
          size="md"
          scrollable={false}
        >
          <ModalHeader toggle={onCloseFormClick}>
            <>{t("Button Create Boost Keyword")}</>
          </ModalHeader>
          <ModalBody className="pt-2">
            <div style={{ minHeight: "300px" }}>
              <KeywordBoostFormV2
                isModal={true}
                categories={listCategory || []}
                id={String(keywordEdit?.id || '')}
                isCopy={!!(keywordEdit?.isCopy)}
                triggerRefresh={triggerRefresh}
              />
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={isOpenImportBoostKeywords}
          centered={true}
          size="md"
          scrollable={false}
        >
          <ModalHeader toggle={onCloseImportBoostKeywordsClick}>
            <>{t("Button Import Bulk Boost Keywords")}</>
          </ModalHeader>
          <ModalBody className="pt-2">
            <div style={{ minHeight: "170px" }}>
              <ImportBoostKeywordForm
              id=""
              categories={listCategory || []}
                triggerRefresh={triggerRefresh}
              />
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={isOpenCategoryManagement}
          centered={true}
          size="xl"
          className={`modal-fullscreen`}
          scrollable={false}
          toggle={onCloseClick}
          keyboard={true}
        >
          <ModalHeader toggle={onCloseCategoryManagementClick}>
            <>{t("Category Management")}</>
          </ModalHeader>
          <ModalBody className="pt-2">
            <CategoryManagement
              triggerRefreshCategory={triggerRefreshCategory}
            />
          </ModalBody>
        </Modal>

        <ModalConfirm
          header={isOpenConfirmChangeStatusMulti === 'ACTIVATE' ? t('Activate') : t('Deactivate')}
          textButtonConfirm={isOpenConfirmChangeStatusMulti === 'ACTIVATE' ? t('Activate') : t('Deactivate')}
          classButtonConfirm={'btn-soft-primary'}
          title={isOpenConfirmChangeStatusMulti === 'ACTIVATE' ? t("Are you sure you want to activate?") : t("Are you sure you want to deactivate?")}
          content={t("After confirming the update action, the data will be updated. Do you want to proceed with the update action.")}
          isOpen={!!isOpenConfirmChangeStatusMulti}
          classIconButtonConfirm=''
          isShowIcon={false}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmChangeStatusMultiClick}
          onConfirm={handleActionChangeStatus}
        />
        <ModalConfirm
          header={t('Button Delete Boost Keyword')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />

      </div >
    </React.Fragment >
  );
};

export default KeywordBoostV2;
