import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import { getImageCategory, postSiteImageCategory } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    triggerRefresh?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const SiteForm = ({
    id = '',
    triggerRefresh,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = React.useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                category_level_1_id: Number(id),
                category_level_2_name: values?.name_level_2,
                urls: (values?.urls || [])?.map((url:any) => url?.site),
            };
            const response: any = await postSiteImageCategory(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    // Update validation schema to make 'site' required and 'id' optional
    const validationSchema = Yup.object({
        name_level_1: Yup.string().nullable(),
        name_level_2: Yup.string().required(t('This is required')),
        urls: Yup.array().of(Yup.object({
            id: Yup.number(), // 'id' is optional
            site: Yup.string().required(t('This is required')),
        })).required(t('This is required')),
    });

    const formik = useFormik({
        initialValues: {
            name_level_1: '',
            name_level_2: '',
            urls: [{ id: Date.now(), site: '' }], // Initialize with one URL field and an optional id
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleChangeUrl = (index: number, value: string) => {
        const newUrls = [...formik.values.urls];
        newUrls[index].site = value;
        formik.setFieldValue('urls', newUrls); // Update Formik's urls field
    };

    const handleAddUrl = () => {
        const newUrl = { id: Date.now(), site: '' };
        formik.setFieldValue('urls', [...formik.values.urls, newUrl]);
    };

    const handleRemoveUrl = (index: number) => {
        const newUrls = formik.values.urls.filter((_, i) => i !== index);
        formik.setFieldValue('urls', newUrls);
    };

    const handleChangeNameLevel1 = (value: any | null) => {
        formik?.setFieldValue('name_level_1', value?.target?.value || '');
    }

    const handleChangeNameLevel2 = (value: any | null) => {
        formik?.setFieldValue('name_level_2', value?.target?.value || '');
    }

    const handleSetValueForm = (valueDefault: any) => {
        const vNameLevel1 = Number(valueDefault?.depth) === 2 ? (valueDefault?.groups?.[0]?.name || '') : (valueDefault?.name || '');
        setTimeout(() => {
            formik.setFieldValue("name_level_1", vNameLevel1);
            formik.setFieldValue("name_level_2", '');
            formik.setFieldValue("urls", valueDefault?.sites?.length > 0 ? (valueDefault?.sites || [])?.map((site:any) => ({ id: site?.site_id, site: site?.url })) : [{ id: Date.now(), site: '' }]);
        }, 300);
    };

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            const [resDetail]: any = await Promise.all([idItem ? getImageCategory(idItem) : {},]);
            if (resDetail?.data) {
                handleSetValueForm(resDetail?.data);
                setIsLoadingDetail((_prev) => false);
            }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <React.Fragment>
            {isLoadingDetail && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(164 164 164 / 36%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Spinner size="sm" color="primary"></Spinner>
            </div>}
            <div>
                <Container>
                    <Row>
                        <form onSubmit={formik.handleSubmit}>
                            <div style={{ maxWidth: '500px', margin: '0 auto' }}>
                                <Row>
                                    <Col sm={12} md={12} xl={12}>
                                        <Label htmlFor="name_level_1" className="form-label">{t('Category Level')} 1 <span className="text-danger"> *</span></Label>
                                        <Input className="form-control"
                                            id="name_level_1"
                                            type="text"
                                            value={formik?.values?.name_level_1 || ''}
                                            placeholder={t('Category Level') + '1'}
                                            onChange={handleChangeNameLevel1}
                                            disabled={true}
                                            autoComplete='off'
                                        />
                                        {formik.touched.name_level_1 && formik.errors.name_level_1 ? (
                                            <div className="text-danger mt-2">{formik.errors.name_level_1}</div>
                                        ) : null}
                                    </Col>
                                    <Col sm={12} md={12} xl={12} className="mt-3">
                                        <Label htmlFor="name_level_2" className="form-label">{t('Category Level')} 2<span className="text-danger"> *</span></Label>
                                        <Input className="form-control"
                                            id="name_level_2"
                                            type="text"
                                            value={formik?.values?.name_level_2 || ''}
                                            placeholder={t('Category Level') + '2'}
                                            onChange={handleChangeNameLevel2}
                                            autoComplete='off'
                                        />
                                        {formik.touched.name_level_2 && formik.errors.name_level_2 ? (
                                            <div className="text-danger mt-2">{formik.errors.name_level_2}</div>
                                        ) : null}
                                    </Col>
                                    <Col sm={12} md={12} xl={12} className="mt-4 pt-3 pb-2 position-relative" style={{ border: '1px dashed var(--vz-primary)', borderRadius: '4px', padding: '10px' }}>
                                        <span className="text-primary position-absolute fs-13 px-1" style={{ backgroundColor: "var(--vz-secondary-bg)", top: '0px', transform: 'translateY(-50%)' }}>{t('Sites')}</span>
                                        {formik.values.urls.map((url, index) => (
                                            <div className="mb-2 w-100" key={url.id}>
                                                <Label htmlFor={`url-${url.id}`} className="form-label">{t('Site')} {index + 1} <span className="text-danger"> *</span></Label>
                                                <div className="d-flex">
                                                    <Input className="form-control"
                                                        id={`url-${url.id}`}
                                                        type="text"
                                                        value={url.site}
                                                        placeholder={t('Site')}
                                                        onChange={(e) => handleChangeUrl(index, e.target.value)}
                                                        autoComplete='off'
                                                    />
                                                    {formik.values.urls.length === Number(index + 1) ? <>
                                                      { formik.values.urls.length > 1 &&  <button
                                                            type="button"
                                                            className="btn btn-soft-danger ms-2"
                                                            onClick={() => handleRemoveUrl(index)}
                                                        >
                                                            <i className="ri-subtract-line align-bottom "></i>

                                                        </button>
                                                    }
                                                    <button
                                                        type="button"
                                                        className="btn btn-soft-success ms-2"
                                                        onClick={handleAddUrl}
                                                    >
                                                        <i className="ri-add-line align-bottom "></i>
                                                    </button></> :
                                                    <>
                                                  
                                                        <button
                                                            type="button"
                                                            className="btn btn-soft-danger ms-2"
                                                            onClick={() => handleRemoveUrl(index)}
                                                        >
                                                            <i className="ri-subtract-line align-bottom "></i>

                                                        </button>
                                                    </>
                                                    }
                                                </div>
                                                {formik.touched.urls && (formik.errors as any)?.urls?.[index]?.site ? (
                                                    <div className="text-danger mt-2">{(formik.errors as any)?.urls?.[index]?.site || ''}</div>
                                                ) : null}
                                            </div>
                                        ))}
                                    </Col>
                                    <Col sm={12} md={12} xl={12} className='mt-4 pt-1 text-end'>
                                        <button
                                            style={{ width: '220px' }}
                                            type="submit"
                                            disabled={isLoading}
                                            className="btn btn-primary fs-14 rounded-pill">
                                            {isLoading ? <Spinner size="sm me-2" ></Spinner> : <></>}
                                            {t('Button Add Image Category')}
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    </Row >
                </Container >
            </div>
            <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />
        </React.Fragment >
    );
};

export default SiteForm;
