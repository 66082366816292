import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import EditorCustom from "components/Common/EditorCustom";
import ModalConfirm from "components/Common/ModalConfirm";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import React, { useEffect, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Input, Label, Row } from 'reactstrap';
import { postSendAllMail, postSendMailMulti, postUploadFileMulti } from "store/thunks";
import * as Yup from "yup";
// Import React FilePond
import 'filepond/dist/filepond.min.css';
import { FilePond, registerPlugin } from 'react-filepond';

import { IMail } from "api/types/_mail";
import TooltipCustom from "components/Common/TooltipCustom";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { formatNumberWithCommas } from "helpers/format";
import ModalShowFullMail from "./ModalShowFullMail";
import PreviewAttachFile from "./PreviewAttachFile";

// // Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

interface Option {
    label: string;
    value: string;
    more?: string
}
export interface Props {
    isModal?: boolean;
    isCanEdit?: boolean;
    emails?: Option[];
    queryParams?: any,
    mailPreview?: IMail | null;
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const SendMail = ({
    emails = [],
    queryParams = null,
    isCanEdit = true,
    mailPreview = null,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { userPermissions } = useRole();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);

    const [openShowFullEmail, setOpenShowFullEmail] = useState<boolean>(false);

    const [toAddresses, setToAddresses] = useState<{ name: string, address: string }[]>([]);

    const refContentNews = useRef<any>(null);

    const [files, setFiles] = useState<any>([]);

    const handleSubmit = async (values: any) => {
        try {
            setIsOpenConfirm((_prev) => true);
        } catch (error: any) {
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        subject: Yup.string().required(`${t("This is required")}`),
        body: Yup.string().nullable()
    });

    const formik = useFormik({
        initialValues: {
            subject: "",
            body: "",
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleOnUpdateFiles = (e: any) => {
        if (!!isCanEdit) {
            setFiles((_prev: any) => e);
        }
    };

    const handleSetValueForm = (valueDefault: any) => {
        const vSubject = valueDefault?.subject || '';
        const vBody = valueDefault?.body || '';

        setTimeout(() => {
            formik.setFieldValue("subject", vSubject);
            formik.setFieldValue("body", vBody);
        }, 300);
    };

    const handleCallAllOption = async (item: IMail | null) => {
        try {
            if (!item) {
                return
            }
            handleSetValueForm(item);
        } catch (error: any) {
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(mailPreview);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mailPreview]);

    const onCloseConfirm = () => {
        setIsOpenConfirm((_prev) => false);
    };

    const handleActionSendMail = async () => {
        try {
            setIsLoading((_prev) => true);
            const values = formik?.values;
            let attachments: { filename: string, filepath: string }[] = [];
            if (files?.length) {
                const res: any = await postUploadFileMulti({
                    files: files?.map((file: any) => file?.file) || []
                });
                if (res?.code === 200) {
                    attachments = res?.data?.map((item: any) => ({ filename: item?.filename, filepath: item?.filepath })) || [];
                }
            }

            const data = {
                ...(!!queryParams ? {} : { to_addresses: toAddresses }),
                subject: values?.subject || '',
                body: refContentNews?.current?.getContent() ?? '',
                attachments: attachments || []
            };

            const response: any = !!queryParams ? await postSendAllMail(queryParams, data) : await postSendMailMulti(data);

            setIsLoading((_prev) => false);

            if (response?.data) {
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const handleCloseClick = () => {
        handleClose && handleClose();
    }

    const handleOpenShowFullEmail = () => {
        setOpenShowFullEmail(() => true);
    }

    const handleCloseShowFullEmail = () => {
        setOpenShowFullEmail(() => false);
    }

    useEffect(() => {
        if (emails?.length) {
            const _emails = emails.flat()?.map((item: any) => ({ name: item?.more, address: item?.label })) || [];
            setToAddresses((_prev) => _emails)
        }
    }, [emails])

    return (
        <React.Fragment>
            <div>
                <Row>
                    <form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col lg={12} className="mb-3">
                                <Label htmlFor="firstNameInput" className="form-label">{t('To')}:</Label>
                                {
                                    !!queryParams && <span className="ms-2"><span className="badge bg-secondary-subtle text-secondary ms-auto cursor-pointer">{t('All Email')}</span></span>
                                }
                                <ul className="list-inline flex-wrap justify-content-start hstack gap-2 mb-0">
                                    {toAddresses?.slice(0, 4)?.map((address, index) => (
                                        <TooltipCustom
                                            title={address?.name || ''}
                                            key={address?.address}
                                        >
                                            <span className="me-1"><span className="badge bg-secondary-subtle text-secondary ms-auto cursor-pointer">{address?.address}</span> {(((index + 1) < toAddresses?.slice(0, 4)?.length) || (toAddresses?.length > 4)) && ', '} </span>
                                        </TooltipCustom>
                                    ))}
                                    {toAddresses?.length > 4 && <TooltipCustom
                                        title={t('more')}
                                    >
                                        <span className="badge bg-primary-subtle text-primary ms-auto cursor-pointer" onClick={handleOpenShowFullEmail}>...{t('more')}</span>
                                    </TooltipCustom>}
                                </ul>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="firstNameInput" className="form-label">{t('Subject')} <span className="text-danger">*</span></Label>
                                    <Input
                                        name="subject"
                                        type="textarea"
                                        rows={1}
                                        className="form-control"
                                        id="firstNameInput"
                                        placeholder={t('Subject')}
                                        value={formik?.values?.subject}
                                        onChange={(event: any) => formik.setFieldValue('subject', event?.target?.value)}
                                    />
                                    {formik.touched.subject && formik.errors.subject ? (
                                        <div className="text-danger mt-2">{formik.errors.subject}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={12}>
                                <Label htmlFor="bodyInput" className="form-label">{t('Body')} <span className="text-danger"> *</span></Label>
                                <EditorCustom
                                    initialValue={formik?.values?.body || ''}
                                    ref={refContentNews}
                                    toolbar={mailPreview ? false : undefined}
                                    height={mailPreview ? '100dvh' : undefined}
                                />
                            </Col>
                            <Col sm={12} className="mt-3">
                                <label className="form-label">
                                    {t("Attach Files")}
                                </label>
                                {!!isCanEdit ? <FilePond
                                    files={files}
                                    onupdatefiles={handleOnUpdateFiles}
                                    allowMultiple={true}
                                    maxFiles={10}
                                    name="files"
                                    allowPaste={true}
                                    allowReorder={true}
                                    instantUpload={false}
                                    // disabled={!!detail?.is_preview}
                                    labelIdle={t("Drag & Drop your image or <span class='filepond--label-action'>Browse</span>")}
                                /> : <PreviewAttachFile files={mailPreview?.attachments} />}
                            </Col>
                            <Col lg={12}>
                                <div className="hstack gap-2 justify-content-end">
                                    {(isHavePermissionRole(ROLES_FOR_APP.JOURNALIST_UPDATE, userPermissions) && isCanEdit) && (
                                        <button
                                            style={{ width: '170px' }}
                                            type="submit"
                                            className="btn btn-primary fs-14 rounded-pill">
                                            {t('Button Send Email')}
                                        </button>)}
                                    {!isCanEdit &&
                                        <button
                                            style={{ width: '80px' }}
                                            type="button"
                                            className="btn btn-secondary fs-14 rounded-pill"
                                            onClick={handleCloseClick}
                                        >
                                            {t('Button Close')}
                                        </button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Row >
            </div>
            <ModalConfirm
                header={t('Button Send Email')}
                textButtonConfirm={t('Button Send Email')}
                classButtonConfirm={'btn-soft-primary'}
                title={t("Are you sure you want to send the email?")}
                content={t("After confirming the email send action, the data will be permanently sent and cannot be recorded. Do you want to proceed with sending the email.")}
                isOpen={isOpenConfirm}
                classIconButtonConfirm=''
                isShowIcon={false}
                isLoading={isLoading}
                onClose={onCloseConfirm}
                onConfirm={handleActionSendMail}
            />
            <ModalShowFullMail isOpen={openShowFullEmail} emails={toAddresses} handleClose={handleCloseShowFullEmail} />
        </React.Fragment >
    );
};

export default SendMail;
