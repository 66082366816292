import { IKeywordBoost } from 'api/types/_keyword';
import { Option } from 'api/types/_public';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import DropdownStatus from 'components/Common/DropdownStatus';
import InputsRange from 'components/Common/InputsRange';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import RangeDatePicker from 'components/pickers/RangeDatePicker';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { COLORS_STATUS_KEYWORD_BOOST, STATUS_KEYWORD_BOOST_OPTIONS, STATUS_USER_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas, formatURL } from 'helpers/format';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import TableContainer from "../../components/Common/TableContainer";
import { deleteKeywordBoostMulti, getKeywordAutocomplete, getKeywordBoostStatistics, getKeywordBoosts as onGetKeywordBoosts, putKeywordBoost, putStatusKeywordMulti } from "../../store/thunks";
import KeywordBoostForm from './KeywordBoostForm';
import CopyWrapper from 'components/Common/CopyWrapper';
export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'monthly_search_volume';

registerLocale('en', en);
registerLocale('ko', ko);


const KeywordBoost = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const STATUS_USER_OPTIONS_LANG: any = STATUS_USER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const STATUS_KEYWORD_BOOST_OPTIONS_LANG = STATUS_KEYWORD_BOOST_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 100),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, 'DESC'),
    date: withDefault(StringParam, moment().subtract(1, 'days').format("Y-MM-DD")),
    keyword: withDefault(StringParam, ''),
    media_name: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
    start_exposure_rank: withDefault(StringParam, '0'),
    end_exposure_rank: withDefault(StringParam, '1000'),
    start_monthly_search_volume: withDefault(StringParam, ''),
    end_monthly_search_volume: withDefault(StringParam, ''),
  });

  const [totalKeywordBoostActivates, setTotalKeywordBoostActivates] = useState<number>(0);

  const [idLoading, setIdLoading] = useState<string | number | null>(null);

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<IKeywordBoost | null>(null);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [mediaSearch, setMediaSearch] = useState<string>(query?.media_name || "");
  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_USER_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(query?.status)) || null);

  const [startExposureSearch, setStartExposureSearch] = useState<string>(query?.start_exposure_rank || "0");
  const [endExposureSearch, setEndExposureSearch] = useState<string>(query?.end_exposure_rank || "1000");

  const [startMonthlyVolumeSearch, setStartMonthlyVolumeSearch] = useState<string>(query?.start_monthly_search_volume || "");
  const [endMonthlyVolumeSearch, setEndMonthlyVolumeSearch] = useState<string>(query?.end_monthly_search_volume || "");

  const [dateSearch, setDateSearch] = useState<any>(query?.date ? moment(query?.date, 'Y-MM-DD').toDate() : null);

  const [keywordEdit, setKeywordEdit] = useState<IKeywordBoost & { isCopy?: boolean } | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);

  const [isOpenConfirmChangeStatusMulti, setIsOpenConfirmChangeStatusMulti] = useState<boolean>(false);

  const [optionsSelected, setOptionsSelected] = useState<any>([]);

  const [isAutocompleting, setIsAutocompleting] = useState<boolean>(false);
  const [keywordAutocompletes, setKeywordAutocompletes] = useState<{ keyword: string, count: string }[]>([]);


  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Keyword;

  const KeywordProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywordBoosts: state.keywordBoosts,
      isKeywordBoostSuccess: state.isKeywordBoostSuccess,
      isKeywordBoostLoading: state.isKeywordBoostLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { keywordBoosts, error, isKeywordBoostLoading } = useSelector(KeywordProperties);

  const onGetKeywordBoostStatistics = (params: any) => {
    getKeywordBoostStatistics(params).then((res: any) => {
      setTotalKeywordBoostActivates((_prev) => res?.data?.active_count || 0)
    }).catch((error) => { });
  };

  useEffect(() => {
    dispatch(onGetKeywordBoosts(query));
    onGetKeywordBoostStatistics(query);
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setOptionsSelected((prev: any) => []);
    const queryNew = {
      ...query,
      keyword: keywordSearch || '',
      media_name: mediaSearch || '',
      date: dateSearch ? moment(new Date(dateSearch)).format("Y-MM-DD") : '',
      start_exposure_rank: startExposureSearch || '',
      end_exposure_rank: endExposureSearch || '',
      start_monthly_search_volume: startMonthlyVolumeSearch || '',
      end_monthly_search_volume: endMonthlyVolumeSearch || '',
      status: `${statusSearch?.value}`,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    };
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      keyword: '',
      media_name: '',
      start_exposure_rank: '0',
      end_exposure_rank: '1000',
      start_monthly_search_volume: '',
      end_monthly_search_volume: '',
      status: '',
      order_by: 'DESC',
      sort_by: TYPE_SELECT_DEFAULT,
      date: `${moment().subtract(1, 'days').format("Y-MM-DD")}`,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setKeywordSearch((_prev) => "");
    setMediaSearch((_prev) => "");
    setStatusSearch(null);
    setStartExposureSearch(queryNew?.start_exposure_rank);
    setEndExposureSearch(queryNew?.end_exposure_rank);
    setStartMonthlyVolumeSearch(queryNew?.start_monthly_search_volume);
    setEndMonthlyVolumeSearch(queryNew?.end_monthly_search_volume);
    setDateSearch(moment(queryNew?.date || "", 'Y-MM-DD').toDate());
  };

  // Begin::Update keyword
  const handleUpdateBoostKeyword = async (dataSubmit: { id: string | number, status: number, keyword_sub: string } | undefined = undefined) => {
    try {
      if (!keywordEdit?.id && !dataSubmit) {
        return;
      }
      setIdLoading((_prev) => (dataSubmit?.id || keywordEdit?.id) as string);
      const data = !!dataSubmit ? ({
        status: dataSubmit?.status,
        keyword_sub: dataSubmit?.keyword_sub || '',
      }) : ({
        status: keywordEdit?.status,
        keyword_sub: keywordEdit?.keyword_sub || '',
      });
      const response: any = await putKeywordBoost((dataSubmit?.id || keywordEdit?.id) as string, data);
      if (response?.code === 200) {
        setIdLoading((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        triggerRefresh();
      } else {
        setIdLoading((_prev) => null);
        toast(`${response?.message || response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIdLoading((_prev) => null);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }

  };


  const onCloseClick = () => {
    setKeywordAutocompletes((_prev) => ([]));
    setKeywordEdit((_prev) => null);
    setIsOpen((_prev) => false);
  }

  const onCloseFormClick = () => {
    setIsOpenForm((_prev) => false);
  }

  const triggerRefresh = () => {
    onCloseClick();
    onCloseFormClick();
    onCloseConfirmChangeStatusMultiClick();
    setQuery({
      ...query,
      time_request: + new Date(),
    });
    onGetKeywordBoostStatistics(query);
  }
  //End::Update User

  // Begin::Delete
  const handleConfirmDelete = (item: IKeywordBoost | null) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!initialValuesDefault?.id && !countRowSelected) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const ids = initialValuesDefault?.id ? [initialValuesDefault?.id] : (optionsSelected || []).flat()?.map((item: any) => item?.value);
      const response: any = await deleteKeywordBoostMulti({
        ids: ids
      });
      if (response?.code === 200) {
        triggerRefresh();
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        if (!initialValuesDefault?.id) {
          setOptionsSelected((prev: any) => []);
        }
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    // if (!!isFirstLoadingPageRef.current) {
    //   return;
    // }
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (keywordBoosts?.list || [])?.map((item: any) => ({ value: item?.id, label: item?.id }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === keywordBoosts?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  // End:: handle select table 

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Key'),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: 'text-start align-middle',
        thWidth: 50,
        thComponent: () => (<>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
            <label className="form-check-label" htmlFor={`cell-check-all`}></label>
            {/* {(countRowSelected > 0) && <span className="position-absolute translate-middle badge bg-primary rounded-pill"
              style={{ top: '-7px', left: '35%' }}
            >
              {countRowSelected || 0}
            </span>
            } */}
          </div>
        </>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div className={`form-check`}>
                <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.id, label: cell?.row?.original?.id })} value={cell?.row?.original?.id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.id, label: cell?.row?.original?.id }), query?.page, e)} id={`cell-check-${cell?.row?.original?.id ?? ''}`} />
                <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.id ?? ''}`}></label>
              </div>
            </>
          )
        },
      },
      {
        Header: t('ID'),
        accessor: "id",
        filterable: true,
        sortable: false,
        width: 60,
        thClass: 'text-start align-middle',
        Cell: (cell: any) => (
          <>
            <span style={{ minWidth: '40px' }}>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Active Action'),
        accessor: "status",
        filterable: false,
        sortable: false,
        thClass: 'text-center align-middle',
        width: 100,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const active = item?.status;
          return (
            <div className="text-center form-check form-switch form-switch-sm position-relative" dir="ltr" style={{ minWidth: '80px' }}>
              {(String(idLoading) === String(item?.id)) && <span className="position-absolute" style={{ top: '3px', left: 0 }}><Spinner size="sm" className="text-primary" /></span>}
              <input type="checkbox" className="form-check-input" name="status" id="customSwitchsizelg" checked={Boolean(active)}
                onChange={(event: any) => {
                  if (isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_CREATE, userPermissions)) {
                    if (event.target.checked) {
                      setKeywordEdit((_prev: any) => ({ ...item, status: event.target.checked }));
                      setKeywordAutocomplete((_prev: any) => item?.keyword);
                      setIsOpen((_prev) => true);
                    } else {
                      handleUpdateBoostKeyword(
                        { id: item?.id, status: event.target.checked, keyword_sub: item?.keyword_sub || '' }
                      );
                    }
                  }
                }}
              />
            </div>
          );
        }
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: true,
        sortable: true,
        width: 120,
        thClass: 'text-start align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '100px' }}>
              {cell?.value || '-'}
            </div>
          </>)
        },
      },
      {
        Header: t('Sub Keyword'),
        accessor: "keyword_sub",
        filterable: true,
        sortable: true,
        width: 150,
        thClass: 'text-start align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '130px' }}>{cell?.value || '-'}</div>
          </>)
        },
      },
      {
        Header: t('Naver News'),
        accessor: "naver_search_link",
        filterable: true,
        sortable: false,
        thClass: 'text-center align-middle',
        width: 120,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center" style={{ width: '100px' }}>
              <CopyWrapper contentCopy={item?.naver_search_link || ''} style={{ display: 'inline-flex' }}>
                <Link
                  className="nav-link hover-underline-primary ps-3"
                  target="_blank"
                  to={item?.naver_search_link || ''} >
                  [{t('Link')}]
                </Link>
              </CopyWrapper>
            </div>

          </>)
        },
      },
      {
        Header: t('Daily Search Volume'),
        accessor: "daily_search_volume",
        filterable: true,
        sortable: true,
        thClass: 'text-end align-middle',
        width: i18n?.language === 'en' ? 170 : 140,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="pe-3 text-end" style={{ minWidth: i18n?.language === 'en' ? '160px' : '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
          </>)
        },
      },
      {
        Header: t('Monthly Search Volume'),
        accessor: "monthly_search_volume",
        filterable: true,
        sortable: true,
        thClass: 'text-end align-middle',
        width: i18n?.language === 'en' ? 200 : 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="pe-3 text-end" style={{ minWidth: i18n?.language === 'en' ? '190px' : '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
          </>)
        },
      },
      {
        Header: '',
        accessor: "__",
        filterable: true,
        sortable: false,
        thClass: 'text-end align-middle',
        width: 40,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end"></div>
          </>)
        },
      },
      {
        Header: t('Partner Media'),
        accessor: "media_name",
        filterable: true,
        sortable: false,
        thClass: 'text-start align-middle',
        width: i18n?.language === 'en' ? 140 : 120,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-start hover-underline-primary" style={{ minWidth: i18n?.language === 'en' ? '120px' : '100px' }}>
              <Link
                className="nav-link"
                target="_blank"
                to={item?.news_link || ''} >
                {cell?.value || '-'}
              </Link>
            </div>
          </>)
        },
      },
      {
        Header: t('Exposure Rank'),
        accessor: "exposure_rank",
        filterable: true,
        sortable: true,
        thClass: 'text-end align-middle',
        width: i18n?.language === 'en' ? 140 : 100,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="pe-3 text-end" style={{ minWidth: i18n?.language === 'en' ? '130px' : '90px' }}>{formatNumberWithCommas(cell?.value)}</div>
          </>)
        },
      },
      {
        Header: t('Date'),
        accessor: "date",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span>{arrDate?.[0] || ''}</span>
            </div>
          )
        },
      },
      {
        Header: t('Created At'),
        accessor: "created_at",
        filterable: true,
        sortable: true,
        thWidth: 130,
        thClass: 'align-middle text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end pe-3" style={{ minWidth: '120px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Action'),
        thWidth: 100,
        thClass: 'text-center align-middle',
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0" style={{ minWidth: '70px' }}>
              {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_DELETE, userPermissions) && (
                <TooltipCustom
                  title={t('Button Delete Boost Keyword')}
                  id={`detail-cp-${item?.id}`}
                >
                  <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                    >
                      <i className="ri-delete-bin-3-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, query, userPermissions, idLoading, JSON.stringify(optionsSelected)]
  );

  // Begin::Update status
  const handleConfirmChangeStatusMulti = () => {
    setIsOpenConfirmChangeStatusMulti((_prev) => true);
  };

  const handleActionChangeStatus = async () => {

    try {
      setIsConfirmLoading((_prev) => true);
      const kw_s = (optionsSelected || []).flat()?.map((item: any) => item?.value);
      const response: any = await putStatusKeywordMulti({
        ids: kw_s,
      });
      if (response?.code === 200) {
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        triggerRefresh();
        setIsConfirmLoading((_prev) => false);
        setIsOpenConfirmChangeStatusMulti((_prev) => false);
        setOptionsSelected((prev: any) => []);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsOpenConfirmChangeStatusMulti((_prev) => false);
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmChangeStatusMultiClick = () => {
    setIsOpenConfirmChangeStatusMulti((_prev) => false);
  }
  // End::Update status

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {

    } catch (error: any) {
      return error;
    }
  };

  const handleChangeDatePicker = (date: any) => {
    setDateSearch((_prev: any) => date);
  }


  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [keywordAutocomplete, setKeywordAutocomplete] = useState<string>('');

  const handleGetKeywordAutocompleteToSubKeyword = (kw: string) => {
    setKeywordEdit((_prev: any) => ({ ..._prev, keyword_sub: kw }))
  };

  const handleCallKeywordAutocomplete = useCallback(debounce(async (kw: string = '') => {
    try {
      setIsAutocompleting((_prev) => true);
      const response: any = await getKeywordAutocomplete({
        keyword: kw,
      });
      setIsAutocompleting((_prev) => false);
      if (response?.code === 200) {
        setKeywordAutocompletes((_prev) => (response?.data || []));
      }
    } catch (error: any) {
      setIsAutocompleting((_prev) => false);
      return error;
    }
  }, 500), []);


  useEffect(() => {
    if (keywordAutocomplete && !!isOpen) {
      handleCallKeywordAutocomplete(keywordAutocomplete || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordAutocomplete, isOpen]);


  useEffect(() => {
    document.title = `${t('Lab')} - ${t('Boosting Keywords')} | PRESS Ai`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Boosting Keywords')} pageTitle={t('Lab')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                  <div className="ribbon round-shape top-info-md" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem' }}>{t('This data is analyzed once a day')}</div>
                  <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                    <Row className="g-4 align-items-center">
                      <div className="col-md-5">
                        <div>
                          <h5 className="card-title mb-0">
                            <span>
                              <span className="me-2">{t('Total')}:</span>
                              <CountUp
                                start={0}
                                end={keywordBoosts?.pagination?.total || 0}
                                duration={1}
                                className="text-primary"
                              />
                            </span>
                            <span className="mx-2 text-muted">|</span>
                            <span>
                              <span className="me-2">{t('Activates')}:</span>
                              <span className="text-primary">{formatNumberWithCommas(totalKeywordBoostActivates || 0)}</span>
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-7 text-end mt-3 mt-md-4">
                        {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_CREATE, userPermissions) && (
                          <div className={`d-flex gap-3 flex-wrap justify-content-end padding-right-info-${i18n?.language}`}>
                            <div>
                              <button
                                type="button"
                                className="btn btn-success add-btn rounded-pill"
                                id="create-btn"
                                onClick={() => setIsOpenForm((_prev) => true)}
                              >
                                <i className="ri-add-line align-bottom me-1"></i>
                                {t('Button Create Boost Keyword')}
                              </button>
                            </div>
                          </div>)}
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={6} md={6} lg={6} xl={6} xxl={3} className='mt-3 mt-md-2'>
                        <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Keyword')}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={6} xl={6} xxl={3} className='mt-3 mt-md-2'>
                        <LabelWrapper label={t('Partner Media')} isShow={!!mediaSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Partner Media')}...`}
                            value={mediaSearch}
                            onChange={(e) => setMediaSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={6} xl={6} xxl={3} className='mt-3 mt-xxl-2'>
                        <LabelWrapper label={t('Status')} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_KEYWORD_BOOST_OPTIONS_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded z-hight"
                            classNamePrefix="name-prefix"
                            initialValue={statusSearch || null}
                            onChangeSelect={(e: any) => setStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All'), value: '' }}
                            colors={COLORS_STATUS_KEYWORD_BOOST}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={6} xl={6} xxl={3} className='date-picker-wrapper-custom mt-3 mt-xxl-2'>
                        <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                          <RangeDatePicker
                            maxDate={moment().toDate()}
                            dateSearch={dateSearch}
                            handleChangeDatePicker={handleChangeDatePicker}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={6} lg={6} xl={6} xxl={6} className='mt-3 mt-xxl-3' >
                        <InputsRange
                          nameFrom="from"
                          nameTo="to"
                          valueFrom={startMonthlyVolumeSearch}
                          valueTo={endMonthlyVolumeSearch}
                          placeholderFrom={`${t('Monthly Search Volume (Start)')}...`}
                          placeholderTo={`${t('Monthly Search Volume (End)')}...`}
                          labelFrom={`${t('Monthly Search Volume (Start)')}`}
                          labelTo={`${t('Monthly Search Volume (End)')}`}
                          isTypeNumber={true}
                          onChangeFrom={(val) => setStartMonthlyVolumeSearch(val)}
                          onChangeTo={(val) => setEndMonthlyVolumeSearch(val)}
                          onKeyDownFrom={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                          onKeyDownTo={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6} lg={6} xl={6} xxl={6} className='mt-3 mt-xxl-3' >
                        <InputsRange
                          nameFrom="from"
                          nameTo="to"
                          valueFrom={startExposureSearch}
                          valueTo={endExposureSearch}
                          placeholderFrom={`${t('Exposure Rank (Start)')}...`}
                          placeholderTo={`${t('Exposure Rank (End)')}...`}
                          labelFrom={`${t('Exposure Rank (Start)')}`}
                          labelTo={`${t('Exposure Rank (End)')}`}
                          isTypeNumber={true}
                          onChangeFrom={(val) => setStartExposureSearch(val)}
                          onChangeTo={(val) => setEndExposureSearch(val)}
                          onKeyDownFrom={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                          onKeyDownTo={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </Col>

                      <Col sm={12} md={12} lg={12} xl={12} xxl={12} className="hstack gap-1 justify-content-center justify-content-md-end justify-content-xxl-end mt-3 mt-xxl-3">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isKeywordBoostLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                      <Col sm={12} md={12} xl={12} className="my-0 mt-4 mt-md-0 text-start">
                        {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_UPDATE, userPermissions) &&
                          <button
                            type="button"
                            className="btn btn-soft-primary me-3"
                            style={{ width: '120px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatusMulti() }}
                          >
                            {countRowSelected > 0 && (
                              <span
                                className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                style={{ transform: "translate(0%, -70%)" }}
                              >
                                {formatNumberWithCommas(countRowSelected)}
                                <span className="visually-hidden">
                                  total keywords selected
                                </span>
                              </span>
                            )}
                            {t('Deactivate')}
                          </button>
                        }
                        {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_DELETE, userPermissions) &&
                          <button
                            type="button"
                            className="btn btn-soft-danger"
                            style={{ width: '120px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmDelete(null) }}
                          >
                            {countRowSelected > 0 && (
                              <span
                                className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                style={{ transform: "translate(0%, -70%)" }}
                              >
                                {formatNumberWithCommas(countRowSelected)}
                                <span className="visually-hidden">
                                  total keywords selected
                                </span>
                              </span>
                            )}
                            {t('Button Delete')}
                          </button>
                        }
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={keywordBoosts?.list?.length ? keywordBoosts?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={keywordBoosts?.pagination?.total}
                      customPageCount={Math.ceil(Number(keywordBoosts?.pagination?.total) / Number(keywordBoosts?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isKeywordBoostLoading}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal
          isOpen={isOpen}
          centered={true}
          size="lg"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseClick}>
            <>{t("Button Update")} </>
            <span className="ms-2">-</span>
            <span className="ms-2" style={{ backgroundColor: '#ffff00' }}>{(keywordEdit?.keyword || '')}</span>
          </ModalHeader>
          <ModalBody className="pt-0">
            <Row>
              <Col sm={12} md={12} lg={6}>
                <div>
                  <div>
                    <div className="mb-4">
                      <Label htmlFor="keyword_sub" className="form-label">{t('Sub Keyword')}</Label>
                      <Input
                        name="keyword_sub"
                        type="text"
                        className="form-control"
                        id="keyword_sub"
                        placeholder={t('Sub Keyword')}
                        value={keywordEdit?.keyword_sub || ''}
                        onChange={(event: any) => {
                          setKeywordEdit((_prev: any) => ({ ..._prev, keyword_sub: event?.target?.value }) as IKeywordBoost);
                          // setKeywordAutocomplete((_prev) => event?.target?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-100 d-flex gap-3 mt-3 justify-content-end align-items-center">
                    <button
                      className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center"
                      type="button"
                      color="light"
                      onClick={() => handleUpdateBoostKeyword()}
                      style={{ width: '110px' }}
                      disabled={!!idLoading}
                    >
                      {!!idLoading && <span className="me-2"><Spinner size="sm" /></span>} <span>{t("Button Update")}</span>
                    </button>
                    <button
                      className="btn btn-secondary fs-14 px-1 d-flex justify-content-center align-item-center"
                      type="button"
                      color="light"
                      onClick={onCloseClick}
                      style={{ width: '75px' }}
                      disabled={!!idLoading}
                    >
                      <span className="ms-1">{t("Button Close")}</span>
                    </button>
                  </div>
                </div>
              </Col>
              <Col m={12} md={12} lg={6}>
                <Label htmlFor="keyword_sub" className="form-label">{t('Related Keywords')}</Label>
                <div className="position-relative">
                  <div className="overflow-y-auto" style={{ height: '220px', border: '1px dashed var(--vz-primary)', borderRadius: '4px', padding: '5px' }}>
                    {keywordAutocompletes?.map((kw) => (
                      <p className="item-keyword-autocomplete" onClick={() => handleGetKeywordAutocompleteToSubKeyword(kw?.keyword)}>
                        <i className='ri-arrow-left-up-line me-1 fs-16 text-muted' />{kw?.keyword}
                      </p>
                    ))}
                  </div>
                  {isAutocompleting && <span className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Spinner size="sm" className="text-primary" />
                  </span>}
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isOpenForm}
          centered={true}
          size="md"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseFormClick}>
            <>{t("Button Create Boost Keyword")}</>
          </ModalHeader>
          <ModalBody className="pt-2">
            <div style={{ minHeight: "300px" }}>
              <KeywordBoostForm
                isModal={true}
                id={String(keywordEdit?.id || '')}
                isCopy={!!(keywordEdit?.isCopy)}
                triggerRefresh={triggerRefresh}
              />
            </div>
          </ModalBody>
        </Modal>

        <ModalConfirm
          header={t('Deactivate')}
          textButtonConfirm={t('Deactivate')}
          classButtonConfirm={'btn-soft-primary'}
          title={t("Are you sure you want to deactivate?")}
          content={t("After confirming the update action, the data will be permanently removed and cannot be updated. Do you want to proceed with the update action.")}
          isOpen={isOpenConfirmChangeStatusMulti}
          classIconButtonConfirm=''
          isShowIcon={false}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmChangeStatusMultiClick}
          onConfirm={handleActionChangeStatus}
        />

        <ModalConfirm
          header={t('Button Delete Boost Keyword')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />

      </div >
    </React.Fragment >
  );
};

export default KeywordBoost;