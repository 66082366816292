import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IMailLog, IMailLogStatistics, IMailSend } from "./types/_mail";
import { ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/mailers';
const mailApi = {
  mailLogs(params: any = {}): Promise<AxiosResponse<ResponseData<IMailLog>>> {
    const url = `${path}`
    return api.get(url, params)
  },
  mailStatistics(params: any = {}): Promise<AxiosResponse<ResponseData<IMailLog>>> {
    const url = `/mailer-statistics`
    return api.get(url, params)
  },
  postSendAllMail(params: any, dataForm: any): Promise<AxiosResponse<ResponseData<IMailSend>, any>> {
    const url = `${path}/send-all`;
    return api.post(url, dataForm);
  },
  postSendMailMulti(dataForm: any): Promise<AxiosResponse<ResponseData<IMailSend>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  getMailLogStatistics(params: any = {}): Promise<AxiosResponse<ResponseData<IMailLogStatistics>>> {
    const url = `/dashboard/summaries/mailers`
    return api.get(url, params)
  },
}
export default mailApi;
