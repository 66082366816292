import { INewsGenerate } from 'api/types/_news';
import { Option } from 'api/types/_public';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import DropdownStatus from 'components/Common/DropdownStatus';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { AI_MODEL_OPTIONS, COLORS_STATUS_CAMPAIGN, NEWS_GENERATE_REQUEST_SOURCE_TYPE, STATUS_CAMPAIGN_OPTIONS } from 'helpers/constans';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CardBody, CardHeader, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import TableContainer from "../../../components/Common/TableContainer";
import { allModelCar, deleteMultiCampaignCar, getCampaignCars as onGetCampaignCars, putCampaignCar } from "../../../store/thunks";
import CampaignForm from './CampaignForm';
export interface Tag {
    id: string;
    text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);

const CampaignCarList = (activeTab?: any) => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();

    const STATUS_CAMPAIGN_OPTIONS_LANG: any = STATUS_CAMPAIGN_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
    const AI_MODEL_OPTIONS_LANG: any = AI_MODEL_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const SOURCE_TYPE = NEWS_GENERATE_REQUEST_SOURCE_TYPE.CAR;

    const START_DATE = '';
    const END_DATE = '';

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 30),
        sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
        order_by: withDefault(StringParam, 'DESC'),
        keyword: withDefault(StringParam, ''),
        start_date: withDefault(StringParam, START_DATE),
        end_date: withDefault(StringParam, END_DATE),
        is_active: withDefault(StringParam, ''),
    });

    const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
    const [keywordSubSearch, setKeywordSubSearch] = useState<string>(query?.keyword_sub || "");
    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_CAMPAIGN_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(query?.is_active)) || null);

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;

    const [newsDetail, setNewsDetail] = useState<INewsGenerate | null>(null);

    const [modelCars, setModelCars] = useState<{ label: string, value: string, name: string, id: string }[]>([]);

    const [idLoading, setIdLoading] = useState<string | number | null>(null);

    const [isShowPrompt, setIsShowPrompt] = useState<boolean>(false);
    const [promptShow, setPromptShow] = useState<string>('');

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Campaign;

    const CampaignProperties = createSelector(
        selectLayoutState,
        (state) => ({
            campaignCars: state.campaignCars,
            isCampaignCarSuccess: state.isCampaignCarSuccess,
            isCampaignCarLoading: state.isCampaignCarLoading,
            error: state.error,
        })
    );

    // Inside your component
    const { campaignCars, error, isCampaignCarLoading } = useSelector(CampaignProperties);

    useEffect(() => {
        dispatch(onGetCampaignCars(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        const queryNew = {
            ...query,
            source_type: `${SOURCE_TYPE}`,
            keyword: keywordSearch || '',
            keyword_sub: keywordSubSearch || '',
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            is_active: `${statusSearch?.value}`,
            sort_by: TYPE_SELECT_DEFAULT,
            page: 1,
            time_request: + new Date()
        };
        setQuery(queryNew);
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            source_type: `${SOURCE_TYPE}`,
            keyword: '',
            keyword_sub: '',
            is_active: '',
            start_date: '', // `${moment().subtract(7, 'days').format("Y-MM-DD")}`
            end_date: '',
            order_by: 'DESC',
            sort_by: TYPE_SELECT_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setKeywordSearch((_prev) => "");
        setKeywordSubSearch((_prev) => "");
        setStatusSearch(null);
        setDateSearch([queryNew?.start_date ? moment(queryNew?.start_date || "", 'Y-MM-DD').toDate() : null, queryNew?.end_date ? moment(queryNew?.end_date || "", 'Y-MM-DD').toDate() : null]);
    };

    const triggerRefresh = async (type: string = '') => {
        onCloseClick();
        setQuery({
            ...query,
            time_request: + new Date(),
        }, "push");
    }
    //End::Update

    const handleSetNewsDetail = (detail: INewsGenerate | null) => {
        setNewsDetail((_prev) => detail);
    }

    const onCloseClick = () => {
        handleSetNewsDetail(null);
    }

    const handleShowPrompt = (_prompt: string = '') => {
        setPromptShow((_prev) => _prompt);
        setIsShowPrompt((_prev) => true);
    }

    // Begin::Delete

    const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
    const [initialValuesDefault, setInitialValuesDefault] = useState<INewsGenerate | null>(null);

    const handleConfirmDelete = (item: INewsGenerate) => {
        setInitialValuesDefault((_prev) => item);
        setIsConfirm((_prev) => true);
    };

    const handleActionDelete = async () => {
        if (!(initialValuesDefault?.id)) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteMultiCampaignCar({ ids: [initialValuesDefault?.id] });
            if (response?.code === 200) {
                dispatch(onGetCampaignCars(query));
                setIsConfirmLoading((_prev) => false);
                setIsConfirm((_prev) => false);
                setInitialValuesDefault((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setInitialValuesDefault((_prev) => null);
        setIsConfirm((_prev) => false);
    }
    // End::Delete

    const handleUpdateCampaignCar = async (dataSubmit: { id: string | number, is_active: number } | undefined = undefined) => {
        try {
            if (!dataSubmit) {
                return;
            }
            setIdLoading((_prev) => (dataSubmit?.id) as string);
            const data = {
                is_active: Number(dataSubmit?.is_active),
            };
            const response: any = await putCampaignCar((dataSubmit?.id) as string, data);
            if (response?.code === 200) {
                setIdLoading((_prev) => null);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh();
            } else {
                setIdLoading((_prev) => null);
                toast(`${response?.message || response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIdLoading((_prev) => null);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: true,
                sortable: true,
                width: 60,
                thClass: 'text-start align-middle',
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '40px' }}>{cell?.value}</div>
                    </>
                ),
            },
            {
                Header: t('Active Action'),
                accessor: "is_active",
                filterable: false,
                sortable: false,
                thClass: 'text-center align-middle',
                width: 100,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    const active = item?.is_active;
                    return (
                        <div className="text-center form-check form-switch form-switch-sm position-relative" dir="ltr" style={{ minWidth: '80px' }}>
                            {(String(idLoading) === String(item?.id)) && <span className="position-absolute" style={{ top: '3px', left: 0 }}><Spinner size="sm" className="text-primary" /></span>}
                            <input type="checkbox" className="form-check-input" name="status" id="customSwitchsizelg" checked={Boolean(active)}
                                onChange={(event: any) => {
                                    if (isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CAR_UPDATE, userPermissions)) {
                                        handleUpdateCampaignCar(
                                            { id: item?.id, is_active: event.target.checked }
                                        );
                                    }
                                }}
                            />
                        </div>
                    );
                }
            },
            {
                Header: t('Keyword'),
                accessor: "keyword",
                filterable: true,
                sortable: false,
                width: 120,
                thClass: 'text-start align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (<>
                        <div style={{ minWidth: '100px' }}>
                            {item?.keyword?.keyword || '-'}
                        </div>
                    </>)
                },
            },
            {
                Header: t('Sub Keyword'),
                accessor: "keyword_sub",
                filterable: true,
                sortable: false,
                width: 120,
                thClass: 'text-start align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (<>
                        <div style={{ minWidth: '100px' }}>
                            {item?.keyword?.keyword_sub || '-'}
                        </div>
                    </>)
                },
            },
            {
                Header: t('Model'),
                accessor: "car_model",
                filterable: true,
                sortable: false,
                width: 120,
                thClass: 'text-start align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (<>
                        <div style={{ minWidth: '100px' }}>
                            {item?.car_model?.brand_name || ''}  {item?.car_model?.name || ''}
                        </div>
                    </>)
                },
            },
            {
                Header: t('Line-up'),
                accessor: "line_up_names",
                filterable: true,
                sortable: false,
                width: 120,
                thClass: 'text-start align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (<>
                        <div style={{ minWidth: '100px', maxWidth: '250px' }}>
                            {(item?.line_up_names || [])?.map((_name: string, ind: number) => <div><span className="text-muted me-1">{ind + 1}.</span>{_name || ''}</div>)}
                        </div>
                    </>)
                },
            },
            {
                Header: t('Prompt'),
                accessor: "prompt",
                filterable: true,
                sortable: false,
                width: 320,
                thClass: 'text-start align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (<>
                        <div style={{ minWidth: '300px', maxWidth: '350px' }} className="cursor-pointer hover-underline text-over-two-line" onClick={() => handleShowPrompt(item?.prompt)}>
                            {item?.prompt || '-'}
                        </div>
                    </>)
                },
            },
            // {
            //     Header: t('Status'),
            //     accessor: "is_active",
            //     filterable: false,
            //     sortable: false,
            //     thClass: "align-middle text-center",
            //     thWidth: i18n?.language === 'en' ? 90 : 80,
            //     Cell: (cell: any) => {
            //         const item: any = cell?.row?.original;
            //         return (
            //             <div style={{ minWidth: '60px' }} className="text-center fs-16">
            //                 <span className={`rounded-pill badge bg-${COLORS_STATUS_CAMPAIGN[`${cell?.value || 0}`] || 'success'}`}>
            //                     {STATUS_CAMPAIGN_OPTIONS_LANG?.find((s: any) => String(s?.value) === String(cell?.value))?.label || ''}
            //                 </span>
            //             </div>
            //         );
            //     }
            // },
            {
                Header: t('Created At'),
                accessor: "created_at",
                filterable: true,
                sortable: true,
                thWidth: 130,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end pe-3" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end align-middle',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '100px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Actions'),
                accessor: "__",
                thClass: 'align-middle text-center',
                thWidth: 100,
                sortable: false,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0" style={{ minWidth: '80px' }}>
                            {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CAR_DELETE, userPermissions) && (
                                <TooltipCustom
                                    title={t('Button Delete Campaign Car')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                                        <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-3-fill align-bottom"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                            )}
                        </ul>
                    );
                },
            },
        ],
        [i18n?.language, idLoading, JSON.stringify(userPermissions)]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleCallAllOption = async () => {
        try {
            const [res1]: any = await Promise.all([allModelCar({ limit: 1000, order_by: 'DESC', sort_by: 'sale' })]);
            setModelCars((_prev: any) => (res1?.data?.list || [])?.map((ml: any, index: number) => ({ value: String(ml?.code), id: String(ml?.id), label: `${index + 1}. ${ml?.brand_name} ${ml?.name} (${ml?.sale})`, name: `${ml?.brand_name} ${ml?.name}` })));
        } catch (error: any) {
            return error;
        }
    };

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        handleCallAllOption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title = `${t('Car')} - ${t('Campaign Car')} | PRESS Ai`;
        document.body.classList.remove('vertical-sidebar-enable');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <Row>
                {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CAR_LIST, userPermissions) && (
                    <Col lg={12}>
                        <div>
                            <CardHeader className="border-0">
                                <Row className="g-4 align-items-center">
                                    <div className="col-sm-7">
                                        <div>
                                            <h5 className="card-title mb-0">
                                                <span>
                                                    <span className="me-2">{t('Total')}:</span>
                                                    <CountUp
                                                        start={0}
                                                        end={campaignCars?.pagination?.total || 0}
                                                        duration={1}
                                                        className="text-primary"
                                                    />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-5 text-end">
                                        {isHavePermissionRole(ROLES_FOR_APP.CAMPAIGN_CAR_CREATE, userPermissions) && (<div className="col-sm-auto">
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-success add-btn rounded-pill"
                                                    id="create-btn"
                                                    onClick={() => {
                                                        handleSetNewsDetail({ keyword: '', keyword_sub: '' } as any)
                                                    }}
                                                >
                                                    <i className="ri-add-line align-bottom me-1"></i>
                                                    {t('Button Create Campaign Car')}
                                                </button>
                                            </div>
                                        </div>)}
                                    </div>
                                </Row>
                            </CardHeader>
                            <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                <Row className="g-4 align-items-center mt-0">
                                    <Col sm={6} md={6} lg={6} xl={6} xxl={2} className='mt-3 mt-md-2'>
                                        <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                                            <Input
                                                type="text"
                                                className="form-control search"
                                                placeholder={`${t('Keyword')}...`}
                                                value={keywordSearch}
                                                onChange={(e) => setKeywordSearch(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        searchData();
                                                    }
                                                }}
                                            />
                                        </LabelWrapper>
                                    </Col>
                                    {/* <Col sm={6} md={6} lg={6} xl={6} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Sub Keyword')} isShow={!!keywordSubSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Sub Keyword')}...`}
                                                        value={keywordSubSearch}
                                                        onChange={(e) => setKeywordSubSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col> */}
                                    <Col sm={6} md={6} lg={6} xl={6} xxl={2} className='mt-3 mt-xxl-2'>
                                        <LabelWrapper label={t('Status')} isShow={true}>
                                            <DropdownStatus
                                                name="status"
                                                dataList={STATUS_CAMPAIGN_OPTIONS_LANG || []}
                                                placeholder={`${t("Status")}...`}
                                                className="dropdown-status-rounded z-hight"
                                                classNamePrefix="name-prefix"
                                                initialValue={statusSearch || null}
                                                onChangeSelect={(e: any) => setStatusSearch(e)}
                                                isHasOptionAll={true}
                                                optionAll={{ label: t('All'), value: '' }}
                                                colors={COLORS_STATUS_CAMPAIGN}
                                            />
                                        </LabelWrapper>
                                    </Col>
                                    <Col sm={6} md={6} lg={6} xl={6} xxl={3} className='date-picker-wrapper-custom mt-3 mt-xxl-2'>
                                        <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                            <DatePickerCustom
                                                placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                startDate={startDate || null}
                                                endDate={endDate || null}
                                                onChangePicker={handleChangePicker}
                                                showOptions={[
                                                    'today', 'yesterday', 'two_day_ago',
                                                    'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                    'this_week', 'last_week',
                                                    'this_month', 'last_month',
                                                ]}
                                            />
                                        </LabelWrapper>
                                    </Col>
                                    <Col sm={6} md={6} lg={6} xl={6} xxl={5} className="hstack gap-1 justify-content-center justify-content-sm-end justify-content-xxl-start mt-3 mt-xxl-2">
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-primary me-2 "
                                                onClick={searchData}
                                                disabled={isCampaignCarLoading}
                                            >
                                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                {t('Button Search')}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary fs-14"
                                                onClick={resetData}
                                            >
                                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                {t('Button Reset')}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <CardBody className="pt-3">
                                <TableContainer
                                    className="custom-header-css"
                                    divClass="table-responsive table-card"
                                    tableClass="align-middle"
                                    theadClass="table-light"
                                    columns={columns}
                                    data={campaignCars?.list?.length ? campaignCars?.list : []}
                                    customPageSize={query.limit}
                                    customPageIndex={query.page - 1}
                                    totalRecords={campaignCars?.pagination?.total}
                                    customPageCount={Math.ceil(Number(campaignCars?.pagination?.total) / Number(campaignCars?.pagination?.limit))}
                                    handleChangePage={handleChangePage}
                                    manualSorting={true}
                                    sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                    handleChangeSorting={handleChangeSorting}
                                    isLoading={isCampaignCarLoading}
                                    isShowHeaderTableFixedTab={activeTab}
                                />
                            </CardBody>
                        </div>
                    </Col>
                )}
            </Row>

            <Modal
                isOpen={!!isShowPrompt}
                centered={true}
                size="lg"
                scrollable={true}
                toggle={() => setIsShowPrompt(() => false)}
            >
                <ModalHeader toggle={() => setIsShowPrompt(() => false)}>
                    <>{t("Prompt")}</>
                </ModalHeader>
                <ModalBody className="pt-0">
                    <div className="fs-15" style={{ whiteSpace: 'break-spaces' }}>
                        {promptShow || ''}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-center align-item-center">
                        <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => setIsShowPrompt(() => false)}
                            style={{ width: '100px' }}
                        >
                            <i className="ri-close-fill" style={{ lineHeight: '22px' }} /> <span className="ms-1">{t("Button Close")}</span>
                        </button>
                    </div>
                </ModalFooter>
            </Modal>

            <CampaignForm
                isOpen={!!newsDetail}
                detail={newsDetail}
                modelCars={modelCars}
                onClose={onCloseClick}
                triggerRefresh={triggerRefresh}
            />

            <ModalConfirm
                header={t('Button Delete Campaign Car')}
                isOpen={isOpenConfirm}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />

            <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={3000} limit={2} />
        </React.Fragment >
    );
};

export default CampaignCarList;