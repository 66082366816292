import { createAsyncThunk } from "@reduxjs/toolkit";
import loggingApi from "api/loggingApi";

import { formatQueryParams } from "helpers/format";

export const getActivityLogs = createAsyncThunk("Activity Logs", async (params: any = {}) => {
  try {
    const response = await loggingApi.activityLogs(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});


export const getActivityLogsByNormal = async (params: any = {}) => {
  try {
    const response = await loggingApi.activityLogs(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};