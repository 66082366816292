import { createAsyncThunk } from "@reduxjs/toolkit";
import operatorApi from 'api/operatorApi';
import { formatQueryParams } from "helpers/format";
import 'react-toastify/dist/ReactToastify.css';

export const getOperators = createAsyncThunk("Operators", async (params: any = {}) => {
  try {
    const response = await operatorApi.operators(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getOperatorsByNormal = async (params: any = {}) => {
  try {
    const response = await operatorApi.operators(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};
export const getAllOperators = async (params: any = {}) => {
  try {
    const response = await operatorApi.allOperators(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getOperator = async (id: string | number, params: any = {}) => {
  try {
    const response = await operatorApi.getOperator(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postOperator = async (data: any = {}) => {
  try {
    const response = await operatorApi.postOperator(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putOperator = async (id: string | number, data: any = {}) => {
  try {
    const response = await operatorApi.putOperator(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteOperator = async (id: string | number, data: any = {}) => {
  try {
    const response = await operatorApi.deleteOperator(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const putProfile = async (data: any = {}) => {
  try {
    const response = await operatorApi.putProfile(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putProfiles = async (data: any = {}) => {
  try {
    const response = await operatorApi.putProfiles(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putChangePasswordProfile = async (data: any = {}) => {
  try {
    const response = await operatorApi.putChangePasswordProfile(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putEnable2FA = async (data: any = {}) => {
  try {
    const response = await operatorApi.putEnable2FA(data);
    return response;
  } catch (error) {
    return error;
  }
};
