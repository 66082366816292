import { createAsyncThunk } from "@reduxjs/toolkit";
import newsApi from 'api/newsApi';
import { formatQueryParams } from "helpers/format";

export const getListNews = createAsyncThunk("List News", async (params: any = {}) => {
  try {
    const response = await newsApi.aiNews(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getGenerateNews = createAsyncThunk("Generate News", async (params: any = {}) => {
  try {
    const response = await newsApi.generateNews(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getAINewsByNormal = async (params: any = {}) => {
  try {
    const response = await newsApi.aiNews(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const getNews = async (id: string | number, params: any = {}) => {
  try {
    const response = await newsApi.news(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const putNews = async (id: number | string, data: any = {}) => {
  try {
    const response = await newsApi.putNews(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const postPublishUrlNews = async (id: number | string, data: any = {}) => {
  try {
    const response = await newsApi.postPublishUrlNews(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const downloadNews = async (id: string | number, params: any = {}) => {
  try {
    const response = await newsApi.downloadNews(id, formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const downloadExcelNews = async (params: any = {}) => {
  try {
    const response = await newsApi.downloadExcelNews(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

export const importMediaMewsByExcel = async (data: any = {}) => {
  try {
    const response = await newsApi.importMediaMewsByExcel(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const postNewsTracking = async (data: any = {}) => {
  try {
    const response = await newsApi.postNewsTracking(data);
    return response;
  } catch (error) {
    return error;
  }
};


export const publishNews = async (id: string | number, params: any = {}) => {
  try {
    const response = await newsApi.publishNews(id, formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};


export const unPublishNews = async (id: string | number, params: any = {}) => {
  try {
    const response = await newsApi.unPublishNews(id, params)
    return response;
  } catch (error) {
    return error;
  }
};


export const cancelMyNews = async (id: string | number, params: any = {}) => {
  try {
    const response = await newsApi.cancelMyNews(id, formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};


export const deleteNewsMulti = async (data: any = {}) => {
  try {
    const response = await newsApi.deleteNewsMulti(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getKeywordGenerateNews = async (id: string | number, params: any = {}) => {
  try {
    const response = await newsApi.keywordGenerateNews(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postKeywordGenerateNews = async (data: any = {}) => {
  try {
    const response = await newsApi.postKeywordGenerateNews(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putKeywordGenerateNews = async (id: number | string, data: any = {}) => {
  try {
    const response = await newsApi.putKeywordGenerateNews(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putKeywordReGenerateNews = async (id: number | string, data: any = {}) => {
  try {
    const response = await newsApi.putKeywordReGenerateNews(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const deleteKeywordGenerateNews = async (data: any = {}) => {
  try {
    const response = await newsApi.deleteKeywordGenerateNews(data);
    return response;
  } catch (error) {
    return error;
  }
};


export const deleteGenerateNewsMulti = async (data: any = {}) => {
  try {
    const response = await newsApi.deleteGenerateNewsMulti(data);
    return response;
  } catch (error) {
    return error;
  }
};


export const getAllPartners = async (params: any = {}) => {
  try {
    const response = await newsApi.allPartners(params)
    return response;
  } catch (error) {
    return error;
  }
};


export const getGenerateBoostingNews = async (params: any = {}) => {
  try {
    const response = await newsApi.getGenerateBoostingNews(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postGenerateBoostingNews = async (data: any = {}) => {
  try {
    const response = await newsApi.postGenerateBoostingNews(data);
    return response;
  } catch (error) {
    return error;
  }
};


export const putGenerateBoostingNews = async (id: string | number, data: any = {}) => {
  try {
    const response = await newsApi.putGenerateBoostingNews(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const getTemplateSampleNewsExcel = async (params: any = {}) => {
  try {
    const response = await newsApi.getTemplateSampleNewsExcel(params)
    return response;
  } catch (error) {
    return error;
  }
};
