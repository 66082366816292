import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ICategory, IConfigCategory } from "./types/_category";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IModelCar, ISection, ITemplateCar } from "./types/_master";
const api = new APIClient();

const path = '/categories';
const categoryApi = {
  categories(params: any): Promise<AxiosResponse<ResponseData<ICategory[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getCategory(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  allCategory(params: any = {}): Promise<AxiosResponse<ResponseData<ICategory[]>, any>> {
    const url = `masters/categories`
    return api.get(url, params)
  },
  postCategory(dataForm: any): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICategory>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  putConfigurationCategory(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IConfigCategory>, any>> {
    const url = `${path}/${id}/config`;
    return api.put(url, dataForm)
  },
  allSection(params: any = {}): Promise<AxiosResponse<ResponseData<ISection[]>, any>> {
    const url = `masters/section-prompt-configs`
    return api.get(url, params)
  },
  allModelCar(params: any = {}): Promise<AxiosResponse<ResponseData<IModelCar[]>, any>> {
    const url = `/cars`
    return api.get(url, params)
  },
  getModelCar(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IModelCar>, any>> {
    const url = `/cars/${id}/json-template`
    return api.get(url, params)
  },
  allTemplateCar(params: any = {}): Promise<AxiosResponse<ResponseData<ITemplateCar[]>, any>> {
    const url = `/masters/templates`
    return api.get(url, params)
  },
  postTemplateCar(dataForm: any): Promise<AxiosResponse<ResponseData<ITemplateCar>, any>> {
    const url = `/templates`;
    return api.post(url, dataForm)
  },
  putTemplateCar(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ITemplateCar>, any>> {
    const url = `/templates/${id}`;
    return api.put(url, dataForm)
  },
}
export default categoryApi
