import { Editor } from '@tinymce/tinymce-react';
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import React, { forwardRef, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { postUploadFileMulti } from 'store/thunks';

// Define the TinyMCE plugins to be used
const PLUGINS = [
  'advlist', 'anchor', 'autolink', 'charmap',
  'image', 'link', 'lists', 'media',
  'searchreplace', 'table', 'visualblocks', //'inlinecss'
];

// Define the TinyMCE toolbar configuration
const TOOLBAR = "blocks | aligncenter alignjustify alignleft alignnone alignright| forecolor  backcolor | bold italic strikethrough underline | table | image  media link autolink | insertdatetime | advlist anchor autolink charmap searchreplace visualblocks "

// Interface for the custom editor's props
interface EditorCustomProps {
  name?: string,
  height?: string,
  plugins?: string[],
  toolbar?: string | boolean,
  initialValue?: any,
  fontSize?: number,
  isAutoCenterImageUpload?: boolean // Whether to auto-center uploaded images
}

const EditorCustom = ({
  name = '',
  height = 'calc(100dvh - 500px)',
  plugins = PLUGINS,
  toolbar = TOOLBAR,
  initialValue,
  fontSize = 14,
  isAutoCenterImageUpload = false
}: EditorCustomProps, ref: any) => {
  const { t, i18n } = useTranslation(); // Hook for translations
  const editorRef = useRef<any>(null); // Ref to hold the TinyMCE editor instance
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // File picker callback for handling image uploads
  const filePickerCallback = (callback: any, value: any, meta: any) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file'); // Create a file input
    input.setAttribute('accept', 'image/*'); // Accept only images
    input.onchange = async () => {
      const file: any = input?.files?.[0];
      const maxAllowedSize = 20 * 1024 * 1024; // Limit file size to 20MB
      if (file?.size > maxAllowedSize) {
        toast(`${t('Please choose a file with a size not exceeding 20 MB.')}`, CONFIG_OPTION_TOAST_ERROR); // Show error if file is too large
        return;
      }
      setIsLoading(true);
      try {
        const modalElement = document.querySelector('.tox-dialog');
        const saveButton = modalElement?.querySelector('.tox-button[title="Save"]') || modalElement?.querySelector('.tox-button[title="저장"]');

        if (saveButton) {
          saveButton.textContent = t('Loading') + '...'; // Update the button text to "Loading..."
          saveButton.setAttribute('disabled', 'true'); // Optionally disable the button
        }
        // Upload the file to the server
        const response: any = await postUploadFileMulti({ files: file, action: 10 });
        setIsLoading(false);
        if (response?.code === 200) {
          const fileResult = response?.data[0];
          const uploadedUrl = fileResult?.preview_url; // Get the uploaded file's URL

          if (isAutoCenterImageUpload) {
            // Format the image with centering and caption
            const imageHtml = `
              <div style="text-align: center; margin: 1rem 0;">
              <p style="text-align: center;">
                <img src="${uploadedUrl}" alt="${file.name}" style="width: 70%; max-width: 100%; height: auto;" />
                </p>
                <p style="font-style: italic; color: #555;">${file?.name || ''}</p>
              </div>
            `;

            // Insert the image HTML at the current cursor positionßß
            if (editorRef.current) {
              editorRef.current.selection.setContent(imageHtml);

              // Close the TinyMCE modal using the windowManager API
              editorRef.current.windowManager.close();
            }
          } else {
            // Insert the image URL in the default TinyMCE format
            callback(uploadedUrl, {
              alt: file.name,
              title: file.name,
            });
          }

        } else {
          toast(`${response}`, CONFIG_OPTION_TOAST_ERROR); // Show error if upload fails
        }
      } catch (error) {
        console.error('Error uploading file:', error); // Log upload errors
      }
      finally {
        setIsLoading(false); // Stop loading
      }
    };

    input.click(); // Trigger the file input dialog
  };

  return (
    <React.Fragment>
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js" // Path to TinyMCE script
        onInit={(evt, editor) => {
          ref.current = editor; // Pass the editor instance to the parent
          editorRef.current = editor; // Store the editor instance in a ref
        }}
        initialValue={initialValue || ''} // Initial content for the editor
        init={{
          height: height, // Set the editor's height
          menubar: false, // Disable the menu bar
          plugins: plugins, // Load plugins
          toolbar: toolbar, // Configure the toolbar
          content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:${fontSize}px; color: #212529; } p{ margin-top: 0px; margin-bottom: 0rem; }`, // Customize editor styles
          branding: false, // Remove TinyMCE branding
          language_url: "/tinymce/langs/ko_KR.js", // Load language file
          language: `${i18n?.language === 'en' ? 'en' : 'ko_KR'}`, // Set the editor's language
          language_load: false,
          contextmenu: false,
          image_description: false,
          image_title: false, // Hide "Alternative description"
          image_dimensions: false, // Hide "Width" and "Height"
          image_advtab: false, // Hide (Advanced tab)
          // I18n: i18n
          // block_unsupported_drop: true,
          // images_upload_credentials: true,
          automatic_uploads: true, // Enable automatic file uploads
          file_picker_types: 'image', // Allow only image uploads
          file_picker_callback: filePickerCallback // Custom file picker callback
        }}
      />
    </React.Fragment>
  );
};

export default forwardRef(EditorCustom); // Export the component with a forwardRef
