import { Option } from "api/types/_public";

export const SCHEDULE_OPTIONS: Option[] = [
  {
    label: "Now",
    value: "now",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const SCHEDULE_MONTHLY_OPTIONS: Option[] = Array.from(
  { length: 31 },
  (_, index) => {
    const day = (index + 1).toString().padStart(2, "0");
    return { label: `${day}th`, value: day };
  }
);

export const ARR_INDEX_TEXT_DAY_OF_WEEK: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const SCHEDULE_WEEKLY_OPTIONS: Option[] = [
  {
    label: "Monday",
    value: "1",
  },
  {
    label: "Tuesday",
    value: "2",
  },
  {
    label: "Wednesday",
    value: "3",
  },
  {
    label: "Thursday",
    value: "4",
  },
  {
    label: "Friday",
    value: "5",
  },
  {
    label: "Saturday",
    value: "6",
  },
  {
    label: "Sunday",
    value: "7",
  },
];

export const TYPE_SHOW_MORE_HASHTAG = {
  SCROLL: 'scroll',
  MODAL: 'modal'
}

export const FILL_RATE_TIME_OPTIONS: Option[] = [
  {
    label: "01",
    value: "1",
  },
  {
    label: "02",
    value: "2",
  },
  {
    label: "03",
    value: "3",
  },
  {
    label: "04",
    value: "4",
  },
  {
    label: "05",
    value: "5",
  },
  {
    label: "06",
    value: "6",
  },
  {
    label: "07",
    value: "7",
  },
  {
    label: "08",
    value: "8",
  },
  {
    label: "09",
    value: "9",
  },
  {
    label: "10",
    value: "10",
  },
  {
    label: "11",
    value: "11",
  },
  {
    label: "12",
    value: "12",
  },
  {
    label: "13",
    value: "13",
  },
  {
    label: "14",
    value: "14",
  },
  {
    label: "15",
    value: "15",
  },
  {
    label: "16",
    value: "16",
  },
  {
    label: "17",
    value: "17",
  },
  {
    label: "18",
    value: "18",
  },
  {
    label: "19",
    value: "19",
  },
  {
    label: "20",
    value: "20",
  },
  {
    label: "21",
    value: "21",
  },
  {
    label: "22",
    value: "22",
  },
  {
    label: "23",
    value: "23",
  },
  {
    label: "24",
    value: "24",
  },
];


export const OPERATING_OPTIONS: Option[] = [
  {
    label: "00:00",
    value: "0",
  },
  {
    label: "01:00",
    value: "1",
  },
  {
    label: "02:00",
    value: "2",
  },
  {
    label: "03:00",
    value: "3",
  },
  {
    label: "04:00",
    value: "4",
  },
  {
    label: "05:00",
    value: "5",
  },
  {
    label: "06:00",
    value: "6",
  },
  {
    label: "07:00",
    value: "7",
  },
  {
    label: "08:00",
    value: "8",
  },
  {
    label: "09:00",
    value: "9",
  },
  {
    label: "10:00",
    value: "10",
  },
  {
    label: "11:00",
    value: "11",
  },
  {
    label: "12:00",
    value: "12",
  },
  {
    label: "13:00",
    value: "13",
  },
  {
    label: "14:00",
    value: "14",
  },
  {
    label: "15:00",
    value: "15",
  },
  {
    label: "16:00",
    value: "16",
  },
  {
    label: "17:00",
    value: "17",
  },
  {
    label: "18:00",
    value: "18",
  },
  {
    label: "19:00",
    value: "19",
  },
  {
    label: "20:00",
    value: "20",
  },
  {
    label: "21:00",
    value: "21",
  },
  {
    label: "22:00",
    value: "22",
  },
  {
    label: "23:00",
    value: "23",
  },
  {
    label: "24:00",
    value: "24",
  },
];


export const TYPE_ADS_DEVICE_OPTIONS: Option[] = [
  {
    label: "Mobile",
    value: "Mobile",
  },
  {
    label: "PC",
    value: "PC",
  },
];


export const BROWSER_OPTIONS: Option[] = [
  {
    label: "Browser_Samsung",
    value: 'Samsung Browser',
  },
  {
    label: "Browser_Chrome",
    value: 'Chrome',
  },
  {
    label: "Browser_Safari",
    value: 'Safari',
  },
  {
    label: "Browser_Unknown_Browser",
    value: 'Unknown Browser',
  },
];


export const IS_OPTIONS: Option[] = [
  {
    label: "YES",
    value: "1",
  },
  {
    label: "NO",
    value: "0",
  },
];


export const OS_OPTIONS: Option[] = [
  {
    label: "OS_iOS",
    value: 'iOS',
  },
  {
    label: "OS_Mac",
    value: 'Mac',
  },
  {
    label: "OS_Windows",
    value: 'Windows',
  },
  {
    label: "OS_Android",
    value: 'Android',
  },
  {
    label: "OS_Etc",
    value: 'Etc',
  },
];

export const METHOD_OPTIONS: Option[] = [
  {
    label: "GET",
    value: "GET",
  },
  {
    label: "POST",
    value: "POST",
  },
  {
    label: "PUT",
    value: "PUT",
  },
  {
    label: "DELETE",
    value: "DELETE",
  },
];

export const STATUS_USER_OPTIONS: Option[] = [
  {
    label: "Active", // Active
    value: "1",
  },
  {
    label: "Inactive", // Inactive
    value: "0",
  },
];

export enum NEWS_GENERATE_REQUEST_STATUS {
  PENDING = 10,
  COLLECTING = 20,
  COLLECT_ERROR = 21,
  COLLECTED = 22,
  GENERATING = 30,
  GENERATE_ERROR = 31,
  ERROR_NOT_ENOUGH_NEWS = 32,
  COMPLETED = 100,
  OTHER_ERROR = 999,
}

export const COLORS_STATUS_GENERATE_NEWS: any = {
  [`${NEWS_GENERATE_REQUEST_STATUS.PENDING}`]: 'primary',
  [`${NEWS_GENERATE_REQUEST_STATUS.COLLECTING}`]: 'primary',
  [`${NEWS_GENERATE_REQUEST_STATUS.COLLECT_ERROR}`]: 'danger',
  [`${NEWS_GENERATE_REQUEST_STATUS.COLLECTED}`]: 'success',
  [`${NEWS_GENERATE_REQUEST_STATUS.GENERATING}`]: 'primary',
  [`${NEWS_GENERATE_REQUEST_STATUS.GENERATE_ERROR}`]: 'danger',
  [`${NEWS_GENERATE_REQUEST_STATUS.ERROR_NOT_ENOUGH_NEWS}`]: 'danger',
  [`${NEWS_GENERATE_REQUEST_STATUS.COMPLETED}`]: 'success',
  [`${NEWS_GENERATE_REQUEST_STATUS.OTHER_ERROR}`]: 'danger',
};

export const STATUS_GENERATE_NEWS_OPTIONS: Option[] = [
  {
    label: "Pending", // Pending
    value: `${NEWS_GENERATE_REQUEST_STATUS.PENDING}`,
  },
  {
    label: "Collecting", // Collecting
    value: `${NEWS_GENERATE_REQUEST_STATUS.COLLECTING}`,
  },
  {
    label: "Generating", // Generating
    value: `${NEWS_GENERATE_REQUEST_STATUS.GENERATING}`,
  },
  {
    label: "Collected", // Collected
    value: `${NEWS_GENERATE_REQUEST_STATUS.COLLECTED}`,
  },
  {
    label: "Completed", // Completed
    value: `${NEWS_GENERATE_REQUEST_STATUS.COMPLETED}`,
  },
  {
    label: "Collect Error", // Collect Error
    value: `${NEWS_GENERATE_REQUEST_STATUS.COLLECT_ERROR}`,
  },
  {
    label: "Generate Error", // Generate Error
    value: `${NEWS_GENERATE_REQUEST_STATUS.GENERATE_ERROR}`,
  },
  {
    label: "Error Not Enough", // Error Not Enough
    value: `${NEWS_GENERATE_REQUEST_STATUS.ERROR_NOT_ENOUGH_NEWS}`,
  },
  {
    label: "Other Error", // Other Error
    value: `${NEWS_GENERATE_REQUEST_STATUS.OTHER_ERROR}`,
  },
];

export enum NEWS_GENERATE_REQUEST_SOURCE_TYPE {
  NAVER_NEWS = 100,
  NAMUWIKI = 200,
  GENERAL = 300,
  CAR = 400,
}

export const COLORS_STATUS_KEYWORD_BOOST: any = {
  '0': 'secondary',
  '1': 'primary',
};

export const STATUS_KEYWORD_BOOST_OPTIONS: Option[] = [
  {
    label: "Active", // Active
    value: "1",
  },
  {
    label: "Inactive", // Inactive
    value: "0",
  },
];

export const COLORS_STATUS_CAMPAIGN: any = {
  '0': 'danger',
  '1': 'success',
};

export const STATUS_CAMPAIGN_OPTIONS: Option[] = [
  {
    label: "Active", // Active
    value: "1",
  },
  {
    label: "Inactive", // Inactive
    value: "0",
  },
];


export enum KEYWORD_TYPE {
  NEWS = 1,
  CONTENT = 2,

  SYSTEM = 99,
}

export const COLORS_KEYWORD_TYPE: any = {
  [`${KEYWORD_TYPE.NEWS}`]: 'success',
  [`${KEYWORD_TYPE.CONTENT}`]: 'primary',
  [`${KEYWORD_TYPE.SYSTEM}`]: 'danger',
};

export const KEYWORD_TYPE_OPTIONS: Option[] = [
  {
    label: "News", // News
    value: `${KEYWORD_TYPE.NEWS}`,
  },
  {
    label: "Content", // Content
    value: `${KEYWORD_TYPE.CONTENT}`,
  },
  // {
  //   label: "System", // System
  //   value: `${KEYWORD_TYPE.SYSTEM}`,
  // },
];

export enum KEYWORD_PUBLISH_LOG_TYPE {
  YES = 1,
  NO = 2,
}
export const COLORS_KEYWORD_PUBLISH_LOG_TYPE: any = {
  [`${KEYWORD_PUBLISH_LOG_TYPE.YES}`]: 'success',
  [`${KEYWORD_PUBLISH_LOG_TYPE.NO}`]: 'danger',
};

export const KEYWORD_PUBLISH_LOG_TYPE_OPTIONS: Option[] = [
  {
    label: "Ranked", // Ranked
    value: `${KEYWORD_PUBLISH_LOG_TYPE.YES}`,
  },
  {
    label: "Unranked", // Unranked
    value: `${KEYWORD_PUBLISH_LOG_TYPE.NO}`,
  },
];

export enum AIModel {
  GEMMA2_9B = 'gemma2',
  GEMMA2_27B = 'gemma2:27b',
  CHATGPT_4o = 'gpt-4o',
}

export const COLORS_AI_MODEL: any = {
  [AIModel.GEMMA2_9B]: 'success',
  [AIModel.GEMMA2_27B]: 'primary',
  [AIModel.CHATGPT_4o]: 'danger',
};

export const AI_MODEL_OPTIONS: Option[] = [
  {
    label: "GEMMA2 9B", // GEMMA2_9B
    value: AIModel.GEMMA2_9B,
  },
  {
    label: "GEMMA2 27B", // GEMMA2_27B
    value: AIModel.GEMMA2_27B,
  },
  {
    label: "CHATGPT 4o", // CHATGPT_4o
    value: AIModel.CHATGPT_4o,
  },
];

export enum MAIL_STATUS {
  PENDING = 0,
  ERROR = 99,
  SUCCESS = 100,
}

export const COLORS_STATUS_SEND_MAIL: any = {
  [`${MAIL_STATUS.PENDING}`]: 'primary',
  [`${MAIL_STATUS.ERROR}`]: 'danger',
  [`${MAIL_STATUS.SUCCESS}`]: 'success',
};

export const STATUS_SEND_MAIL_OPTIONS: Option[] = [
  {
    label: "Success", // SUCCESS
    value: `${MAIL_STATUS.SUCCESS}`,
  },
  {
    label: "Error", // ERROR
    value: `${MAIL_STATUS.ERROR}`,
  },
  {
    label: "Pending", // PENDING
    value: `${MAIL_STATUS.PENDING}`,
  },
];


export enum MAIL_STATUS_READ {
  READ = 1,
  UN_READ = 0,
}

export const COLORS_STATUS_READ_MAIL: any = {
  [`${MAIL_STATUS_READ.READ}`]: 'success',
  [`${MAIL_STATUS_READ.UN_READ}`]: 'danger',
};

export const STATUS_READ_MAIL_OPTIONS: Option[] = [
  {
    label: "Read", // READ
    value: `${MAIL_STATUS_READ.READ}`,
  },
  {
    label: "Unread", // UN_READ
    value: `${MAIL_STATUS_READ.UN_READ}`,
  },
];


export const COLORS_STATUS_OUT_LINK: any = {
  '0': 'secondary',
  '1': 'success',
  '2': 'primary',
};

export const STATUS_OUT_LINK_OPTIONS: Option[] = [
  {
    label: "OUT_LINK", // OUT_LINK
    value: "1",
  },
  {
    label: "CP", // CP
    value: "2",
  },
];

export const COLORS_SECTION_KEYWORD: any = {
  '100': 'success',
  '200': 'danger',
  '201': 'primary',
  '202': 'warning',
  '203': 'green',
  '204': 'secondary',
  '205': 'purple',
  '206': 'pink',
  '207': 'info',
  '300': 'dark'
};

export enum SECTION_KEYWORD {
  GOOGLE_TREND = 100,
  NAVER_POLITICS = 200,
  NAVER_ECONOMY = 201,
  NAVER_SOCIETY = 202,
  NAVER_LIFE_CULTURE = 203,
  NAVER_IT_SCIENCE = 204,
  NAVER_WORLD = 205,
  NAVER_ENTERTAIN = 206,
  NAVER_SPORT = 207,
  BOOSTING = 300,
  REAL_TIME = 400,
  EXTERNAL = 500,
  CONTENT_CAR = 600,
}

export const SECTION_KEYWORD_OPTIONS: Option[] = [
  {
    label: `Google`,
    value: String(SECTION_KEYWORD.GOOGLE_TREND),
  },
  {
    label: `Politics`,
    value: String(SECTION_KEYWORD.NAVER_POLITICS),
  },
  {
    label: `Economy`,
    value: String(SECTION_KEYWORD.NAVER_ECONOMY),
  },
  {
    label: `Society`,
    value: String(SECTION_KEYWORD.NAVER_SOCIETY),
  },
  {
    label: `Life/Culture`,
    value: String(SECTION_KEYWORD.NAVER_LIFE_CULTURE),
  },
  {
    label: `IT/Science`,
    value: String(SECTION_KEYWORD.NAVER_IT_SCIENCE),
  },
  {
    label: `World`,
    value: String(SECTION_KEYWORD.NAVER_WORLD),
  },
  {
    label: `Entertainment`,
    value: String(SECTION_KEYWORD.NAVER_ENTERTAIN),
  },
  {
    label: `Sports`,
    value: String(SECTION_KEYWORD.NAVER_SPORT),
  },
  // {
  //   label: `Boosting`,
  //   value: String(SECTION_KEYWORD.BOOSTING),
  // },
];

export enum ENUM_STATUS_NEWS {
  USED = 1,
  UNUSED = 0,
};

export const COLORS_STATUS_NEWS: any = {
  '0': 'success',
  '1': 'danger',
};

export const STATUS_NEWS_OPTIONS: Option[] = [
  {
    label: 'Unused', // Unused
    value: `${ENUM_STATUS_NEWS.UNUSED}`,
  },
  {
    label: 'Used', // Used
    value: `${ENUM_STATUS_NEWS.USED}`,
  },
];


export enum ENUM_SENTIMENT_NEWS {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  NEUTRAL = 'Neutral',
};

export const COLORS_SENTIMENT_NEWS: any = {
  [`${ENUM_SENTIMENT_NEWS.POSITIVE}`]: 'primary',
  [`${ENUM_SENTIMENT_NEWS.NEGATIVE}`]: 'danger',
  [`${ENUM_SENTIMENT_NEWS.NEUTRAL}`]: 'secondary',
};

export const SENTIMENT_NEWS_OPTIONS: Option[] = [
  {
    label: 'Positive', // Positive
    value: `${ENUM_SENTIMENT_NEWS.POSITIVE}`,
  },
  {
    label: 'Negative', // Negative
    value: `${ENUM_SENTIMENT_NEWS.NEGATIVE}`,
  },
  {
    label: 'Neutral', // Neutral
    value: `${ENUM_SENTIMENT_NEWS.NEUTRAL}`,
  },
];

export const COLOR_TYPE_SENTIMENT: any = {
  'Positive': 'primary',
  'Negative': 'danger',
  'Neutral': 'muted'
}

export enum NEWS_TYPE {
  AI_NEWS = 1,
  BOOSTING_NEWS = 2,
  REALTIME_NEWS = 3,
  EXTERNAL_NEWS = 4,
  CONTENT_CAR = 5,
}

export const TYPE_NEWS_OPTIONS: Option[] = [
  {
    label: 'AI News', // AI News
    value: `${NEWS_TYPE.AI_NEWS}`,
  },
  {
    label: 'Boosting News', // Boosting News
    value: `${NEWS_TYPE.BOOSTING_NEWS}`,
  },
  {
    label: 'Real-Time News', // Real-Time News
    value: `${NEWS_TYPE.REALTIME_NEWS}`,
  },
  {
    label: 'External News', // External News
    value: `${NEWS_TYPE.EXTERNAL_NEWS}`,
  },
];

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export enum ENUM_RANK_NEWS {
  TOP_1_TO_5 = 'top_1_to_5',
  TOP_6_TO_ALL = 'top_6_to_all',
};

export const COLORS_RANK_NEWS: any = {
  [`${ENUM_RANK_NEWS.TOP_1_TO_5}`]: 'primary',
  [`${ENUM_RANK_NEWS.TOP_6_TO_ALL}`]: 'danger',
};

export const RANK_NEWS_OPTIONS: Option[] = [
  {
    label: 'Top 1–5', // Top 1–5
    value: `${ENUM_RANK_NEWS.TOP_1_TO_5}`,
  },
  {
    label: 'Rank 6 and above', // Rank 6 and above
    value: `${ENUM_RANK_NEWS.TOP_6_TO_ALL}`,
  },
];

export const FREQUENCY_TYPE: any = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
};

export enum SYSTEM_CONFIG_KEY {
  NEWS_AI_CREATE_PROMPT_FORMAT = 'news_ai:create:prompt_format',
  NEWS_AI_CREATE_TOTAL_NEWS_PER_DAY = 'news_ai:create:total_news_per_day',
  NEWS_AI_CREATE_TITLE_COUNT = 'news_ai:create:keyword:title_count',
  NEWS_AI_CREATE_BODY_COUNT = 'news_ai:create:keyword:body_count',
  NEWS_AI_CREATE_CONTENT_COUNT = 'news_ai:create:keyword:content_count',
  NEWS_AI_CREATE_CONTENT_COUNT_SUB_KEYWORD = 'news_ai:create:sub_keyword:content_count',
  NEWS_AI_CREATE_SOURCE_COUNT = 'news_ai:create:source_count',
  NEWS_AI_CREATE_SOURCE_START_MINUS_DAY = 'news_ai:create:source_start_minus_day',
  NEWS_AI_CREATE_SOURCE_MAX = 'news_ai:create:source_max',
  NEWS_AI_CREATE_ALLOW_REUSE_SOURCE_ARTICLE = 'news_ai:create:allow_reuse_source_article',

  NEWS_BOOSTING_CREATE_PROMPT_FORMAT = 'news_boosting:create:prompt_format',
  NEWS_BOOSTING_CREATE_TOTAL_NEWS_PER_DAY = 'news_boosting:create:total_news_per_day',
  NEWS_BOOSTING_CREATE_TITLE_COUNT = 'news_boosting:create:keyword:title_count',
  NEWS_BOOSTING_CREATE_BODY_COUNT = 'news_boosting:create:keyword:body_count',
  NEWS_BOOSTING_CREATE_CONTENT_COUNT = 'news_boosting:create:keyword:content_count',
  NEWS_BOOSTING_CREATE_CONTENT_COUNT_SUB_KEYWORD = 'news_boosting:create:sub_keyword:content_count',
  NEWS_BOOSTING_CREATE_SOURCE_COUNT = 'news_boosting:create:source_count',
  NEWS_BOOSTING_CREATE_SOURCE_START_MINUS_DAY = 'news_boosting:create:source_start_minus_day',
  NEWS_BOOSTING_CREATE_SOURCE_MAX = 'news_boosting:create:source_max',
  NEWS_BOOSTING_CREATE_MODEL = 'news_boosting:create:ai_model',
  NEWS_BOOSTING_CREATE_ALLOW_REUSE_SOURCE_ARTICLE = 'news_boosting:create:allow_reuse_source_article',
  NEWS_BOOSTING_CREATE_DAILY_SEARCH_VOLUME_FROM = 'news_boosting:create:daily_search_volume_from',


  NEWS_REALTIME_CREATE_PROMPT_FORMAT = 'news_realtime:create:prompt_format',
  NEWS_REALTIME_CREATE_TOTAL_NEWS_PER_DAY = 'news_realtime:create:total_news_per_day',
  NEWS_REALTIME_CREATE_KEYWORD_TITLE_COUNT = 'news_realtime:create:keyword:title_count',
  NEWS_REALTIME_CREATE_KEYWORD_BODY_COUNT = 'news_realtime:create:keyword:body_count',
  NEWS_REALTIME_CREATE_KEYWORD_CONTENT_COUNT = 'news_realtime:create:keyword:content_count',
  NEWS_REALTIME_CREATE_SOURCE_COUNT = 'news_realtime:create:source_count',
  NEWS_REALTIME_CREATE_SOURCE_START_MINUS_DAY = 'news_realtime:create:source_start_minus_day',
  NEWS_REALTIME_CREATE_SOURCE_MAX = 'news_realtime:create:source_max',
  NEWS_REALTIME_CREATE_MODEL = 'news_realtime:create:ai_model',
  NEWS_REALTIME_CREATE_ALLOW_REUSE_SOURCE_ARTICLE = 'news_realtime:create:allow_reuse_source_article',


  SIMULATOR_GENERAL_COMMON_PROMPT = 'simulator:general:common_prompt',
  SIMULATOR_NEWS_COMMON_PROMPT = 'simulator:news:common_prompt',
  SIMULATOR_NAMUWIKI_COMMON_PROMPT = 'simulator:namuwiki:common_prompt',

  SIMULATOR_CAR_COMMON_PROMPT = 'simulator:car:common_prompt',


  SIMULATOR_GENERAL_EXCLUDE_KEYWORDS = 'simulator:general:exclude_keywords',
  SIMULATOR_NEWS_EXCLUDE_KEYWORDS = 'simulator:news:exclude_keywords',
  SIMULATOR_NAMUWIKI_EXCLUDE_KEYWORDS = 'simulator:namuwiki:exclude_keywords',


}


export const PROMPT_GENERATE_NEWS_DEFAULT: string = `새로운 기사 제목과 내용을 한국어로 생성하세요. 이는 요약이 아닌 기자가 작성한 것처럼 보이는 기사여야 하며, 최소 1000자 이상이어야 합니다.\n생성된 기사 내용에는 매체 이름이나 기사를 작성한 기자 이름이 포함되지 않습니다.\n기사 내용 외에 다른 설명은 추가하지 마세요.`;

export const PROMPT_GENERAl_NEWS_DEFAULT: string = '';