import { createAsyncThunk } from "@reduxjs/toolkit";
import mailApi from "api/mailApi";
import { formatQueryParams } from "helpers/format";

export const getMailLogs = createAsyncThunk("Mail Logs", async (params: any = {}) => {
  try {
    const response = await mailApi.mailLogs(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getMailStatistics = createAsyncThunk("Mail Statistics", async (params: any = {}) => {
  try {
    const response = await mailApi.mailStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getMailLogStatistics = async (params: any = {}) => {
  try {
    const response = await mailApi.getMailLogStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const postSendAllMail = async (params: any = {}, data: any = {}) => {
  try {
    const response = await mailApi.postSendAllMail(params, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const postSendMailMulti = async (data: any = {}) => {
  try {
    const response = await mailApi.postSendMailMulti(data);
    return response;
  } catch (error) {
    return error;
  }
};
