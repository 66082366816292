import { IJournalist } from 'api/types/_journalist';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_INFO, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import DropdownStatus from 'components/Common/DropdownStatus';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { COLORS_STATUS_OUT_LINK, STATUS_OUT_LINK_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../components/Common/BreadCrumb";
import TableContainer from "../../components/Common/TableContainer";
import { deleteJournalist, getJournalists as onGetJournalists, putJournalist } from "../../store/thunks";
import SendMail from '../Mail/SendMail';
import JournalistForm from './JournalistForm';

interface Option {
  label: string;
  value: string;
  more?: string
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);

const JournalistList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const STATUS_OUT_LINK_OPTIONS_LANG = STATUS_OUT_LINK_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    keyword: withDefault(StringParam, ''),
    media_name: withDefault(StringParam, ''),
    author_name: withDefault(StringParam, ''),
    author_email: withDefault(StringParam, ''),
    out_link_status: withDefault(StringParam, ''),
  });

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [mediaSearch, setMediaSearch] = useState<string>(query?.media_name || "");
  const [nameSearch, setNameSearch] = useState<string>(query?.author_name || "");
  const [emailSearch, setEmailSearch] = useState<string>(query?.author_email || "");
  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_OUT_LINK_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(query?.out_link_status)) || null);

  const [journalistEdit, setJournalistEdit] = useState<IJournalist & { isCopy?: boolean } | null>(null);

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<IJournalist | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isOpenSendMailMulti, setIsOpenSendMailMulti] = useState<boolean>(false);

  const [isOpenSendAllMail, setIsOpenSendAllMail] = useState<boolean>(false);

  const [optionsSelected, setOptionsSelected] = useState<any>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Journalist;

  const JournalistProperties = createSelector(
    selectLayoutState,
    (state) => ({
      journalists: state.journalists,
      isJournalistSuccess: state.isJournalistSuccess,
      isJournalistLoading: state.isJournalistLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { journalists, isJournalistLoading } = useSelector(JournalistProperties);

  useEffect(() => {
    dispatch(onGetJournalists(query));
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      keyword: keywordSearch || '',
      media_name: mediaSearch || '',
      author_name: nameSearch || '',
      author_email: emailSearch || '',
      out_link_status: statusSearch?.value || '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      keyword: '',
      media_name: '',
      author_name: '',
      author_email: '',
      out_link_status: '',
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date(),
      page: 1,
    }, "push");
    setKeywordSearch((_prev) => "");
    setMediaSearch((_prev) => "");
    setNameSearch((_prev) => "");
    setEmailSearch((_prev) => "");
    setStatusSearch((_prev) => null);
  };


  // Begin::Update User
  const onCloseClick = () => {
    setJournalistEdit((_prev) => null);
    setIsOpen((_prev) => false)
  }

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(onGetJournalists(query));
  }
  //End::Update User

  // Begin::Delete
  const handleConfirmDelete = (item: IJournalist) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteJournalist(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetJournalists(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    // if (!!isFirstLoadingPageRef.current) {
    //   return;
    // }
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (journalists?.list || [])?.map((item: any) => ({ value: item?.id, label: item?.author_email, more: item?.author_name }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === journalists?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  // End:: handle select table 

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Key'),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: 'text-start align-middle',
        thWidth: 50,
        thComponent: () => (<>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
            <label className="form-check-label" htmlFor={`cell-check-all`}></label>
          </div>
        </>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div className={`form-check`}>
                <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.id, label: cell?.row?.original?.author_email, more: cell?.row?.original?.author_name })} value={cell?.row?.original?.id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.id, label: cell?.row?.original?.author_email, more: cell?.row?.original?.author_name }), query?.page, e)} id={`cell-check-${cell?.row?.original?.id ?? ''}`} />
                <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.id ?? ''}`}></label>
              </div>
            </>
          )
        },
      },
      {
        Header: t('ID'),
        accessor: "id",
        filterable: true,
        sortable: false,
        width: 60,
        Cell: (cell: any) => (
          <>
            <span style={{ minWidth: '40px' }}>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: true,
        sortable: false,
        width: 100,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '80px' }}>
              {cell?.value || '-'}
            </div>
          </>)
        },
      },
      {
        Header: t('Media'),
        accessor: "media_name",
        filterable: true,
        sortable: false,
        thClass: 'text-start',
        width: i18n?.language === 'en' ? 120 : 100,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-start" style={{ minWidth: i18n?.language === 'en' ? '100px' : '90px' }}>
              {cell?.value || '-'}
            </div>
          </>)
        },
      },
      {
        Header: t('Journalist Name'),
        accessor: "author_name",
        filterable: true,
        sortable: false,
        width: 130,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '120px' }}>{cell?.value || '-'}</div>
          </>)
        },
      },
      {
        Header: t('Journalist Email'),
        accessor: "author_email",
        filterable: true,
        sortable: false,
        width: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '130px' }}>{cell?.value || '-'}</div>
          </>)
        },
      },
      {
        Header: t('Outlink Status'),
        accessor: "out_link_status",
        filterable: true,
        sortable: false,
        thClass: 'text-center',
        width: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<div style={{ minWidth: '130px' }} className="text-center fs-16">
            <span className={`rounded-pill badge bg-${COLORS_STATUS_OUT_LINK[`${cell?.value || 0}`] || 'success'}`}>
              {STATUS_OUT_LINK_OPTIONS_LANG?.find((s) => String(s?.value) === String(item?.out_link_status))?.label || ''}
            </span>
          </div>)
        },
      },
      {
        Header: t('News Title'),
        accessor: "news_title",
        filterable: true,
        sortable: false,
        thClass: 'text-start',
        width: 440,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-start hover-underline" style={{ minWidth: '400px' }}>
              {!item?.media_link ? <span className="nav-link cursor-pointer" onClick={() => {
                toast(`${t('The article does not have a link.')}`, CONFIG_OPTION_TOAST_INFO);
              }}>
                {item?.news_title || '-'}
              </span> : <Link
                className="nav-link"
                target="_blank"
                to={item?.media_link || '#'} >
                {item?.news_title || '-'}
              </Link>}
            </div>
          </>)
        },
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 110,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      // {
      //   Header: t('Updated at'),
      //   accessor: "updated_at",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 110,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => {
      //     const arrDate = String(cell?.value || '').split(' ');
      //     return (
      //       <div className="text-end" style={{ minWidth: '100px' }}>
      //         <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
      //       </div>
      //     )
      //   },
      // },
      // {
      //   Header: t('Action'),
      //   thClass: 'text-center',
      //   thWidth: 100,
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0" style={{ minWidth: '70px' }}>
      //         {isHavePermissionRole(ROLES_FOR_APP.JOURNALIST_UPDATE, userPermissions) && <TooltipCustom
      //           title={t('Button Send Email')}
      //           id={`update-us-${item?.id}`}
      //         >
      //           <li className=" list-inline-item me-0" id={`update-us-${item?.id}`}>
      //             <Link className="btn btn-sm btn-soft-primary edit-item-btn" to="#"
      //               onClick={(e) => {
      //                 e.preventDefault();
      //                 toast(`${t('The feature is under development.')}`, CONFIG_OPTION_TOAST_INFO);
      //               }}
      //             >
      //               <i className="ri-mail-send-fill align-bottom"></i>
      //             </Link>
      //           </li>
      //         </TooltipCustom>
      //         }
      //         {isHavePermissionRole(ROLES_FOR_APP.JOURNALIST_DELETE, userPermissions) && (
      //           <TooltipCustom
      //             title={t('Button Delete Journalist')}
      //             id={`detail-cp-${item?.id}`}
      //           >
      //             <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
      //               <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#"
      //                 onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
      //               >
      //                 <i className="ri-delete-bin-3-fill align-bottom"></i>
      //               </Link>
      //             </li>
      //           </TooltipCustom>
      //         )}
      //       </ul>
      //     );
      //   },
      // },
    ],
    [i18n?.language, query, userPermissions, JSON.stringify(optionsSelected)]
  );


  // Begin::Send All Mail
  const handleOpenSendAllMail = () => {
    setIsOpenSendAllMail((_prev) => true);
  };

  const onCloseSendAllMailClick = () => {
    setIsOpenSendAllMail((_prev) => false);
  }
  // End::Send All Mail

  // Begin::Send Mail Multi
  const handleOpenSendMailMulti = () => {
    setIsOpenSendMailMulti((_prev) => true);
  };

  const onCloseSendMailMultiClick = () => {
    setIsOpenSendMailMulti((_prev) => false);
  }
  // End::Send Mail Multi

  const triggerRefreshAfterSendMail = () => {
    onCloseSendMailMultiClick();
    onCloseSendAllMailClick();
    dispatch(onGetJournalists(query));
    setOptionsSelected((_prev: any) => []);
  }




  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {

    } catch (error: any) {
      return error;
    }
  };

  const handleChangeStatusItem = async (item: any, status: boolean) => {
    try {
      const { email, fist_name, last_name, role_id } = structuredClone(item);
      const data = {
        email,
        fist_name,
        last_name,
        role_id,
        is_active: Number(status),
      };
      const response: any = await putJournalist(item?.id, data);
      if (response?.data) {
        dispatch(onGetJournalists(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!statusSearch?.value) {
      setStatusSearch((_prev) => ({ label: t('All'), value: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  useEffect(() => {
    document.title = `${t('Sending Email')} - ${t('Sending Email')} | Pressai-AI`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Sending Email')} pageTitle={t('Sending Email')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.JOURNALIST_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-5 con-sm-6">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            <CountUp
                              start={0}
                              end={journalists?.pagination?.total || 0}
                              duration={1}
                              className="text-primary"
                            />
                          </h5>
                        </div>
                      </div>
                      {/* <div className="col-7 con-sm-6 text-end">
                        {isHavePermissionRole(ROLES_FOR_APP.JOURNALIST_CREATE, userPermissions) && (<div className="col-sm-auto">
                          <div>
                            <button
                              type="button"
                              className="btn btn-success add-btn rounded-pill"
                              id="create-btn"
                              onClick={() => setIsOpen((_prev) => true)}
                            >
                              <i className="ri-add-line align-bottom me-1"></i>
                              {t('Button Create Journalist')}
                            </button>
                          </div>
                        </div>)}
                      </div> */}
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={6} md={6} lg={6} xl={2} xxl={2} className='mt-3 mt-xl-2'>
                        <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Keyword')}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={6} xl={2} xxl={2} className='mt-3 mt-xl-2'>
                        <LabelWrapper label={t('Media')} isShow={!!mediaSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Media')}...`}
                            value={mediaSearch}
                            onChange={(e) => setMediaSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={6} xl={3} xxl={3} className='mt-3 mt-xl-2'>
                        <LabelWrapper label={t('Journalist Name')} isShow={!!nameSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Journalist Name')}...`}
                            value={nameSearch}
                            onChange={(e) => setNameSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={6} xl={3} xxl={3} className='mt-3 mt-xl-2'>
                        <LabelWrapper label={t('Journalist Email')} isShow={!!emailSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Journalist Email')}...`}
                            value={emailSearch}
                            onChange={(e) => setEmailSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={6} xl={2} xxl={2} className='mt-3 mt-xl-2'>
                        <LabelWrapper label={t('Status')} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_OUT_LINK_OPTIONS_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded z-hight"
                            classNamePrefix="name-prefix"
                            initialValue={statusSearch || null}
                            onChangeSelect={(e: any) => setStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All'), value: '' }}
                            colors={COLORS_STATUS_OUT_LINK}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={6} xl={12} xxl={12} className="hstack gap-1 justify-content-center justify-content-sm-end justify-content-xl-end mt-3">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isJournalistLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                      <Col sm={12} md={12} xl={12} className="my-0 mt-4 mt-md-0 text-start ">
                        {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_UPDATE, userPermissions) && <div>
                          <button
                            type="button"
                            className="btn btn-soft-primary me-3"
                            style={{ width: '120px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleOpenSendMailMulti() }}
                          >
                            {countRowSelected > 0 && (
                              <span
                                className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                style={{ transform: "translate(0%, -70%)" }}
                              >
                                {formatNumberWithCommas(countRowSelected)}
                                <span className="visually-hidden">
                                  total keywords selected
                                </span>
                              </span>
                            )}
                            {t('Button Send Email')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-soft-primary "
                            style={{ width: '120px' }}
                            onClick={(e) => { e.stopPropagation(); handleOpenSendAllMail() }}
                          >
                            {t('Button Send All Email')}
                          </button>
                        </div>}
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={journalists?.list?.length ? journalists?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={journalists?.pagination?.total}
                      customPageCount={Math.ceil(Number(journalists?.pagination?.total) / Number(journalists?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isJournalistLoading}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}</Row>
        </Container>
        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />
        <Modal
          isOpen={!!journalistEdit?.id || isOpen}
          centered={true}
          size="lg"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseClick}>
            <>{!!(journalistEdit?.id) ? t("Button Update Journalist") : t("Button Create Journalist")}</>  <>{(journalistEdit?.id) && (' - ' + (journalistEdit?.first_name || '') + (journalistEdit?.last_name || '') || '')}</>
          </ModalHeader>
          <ModalBody className="pt-2">
            <div style={{ minHeight: "calc(100dvh - 300px)" }}>
              <JournalistForm
                isModal={true}
                id={String(journalistEdit?.id || '')}
                isCopy={!!(journalistEdit?.isCopy)}
                triggerRefresh={triggerRefresh}
              />
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={isOpenSendMailMulti || isOpenSendAllMail}
          centered={true}
          size="xxll"
          scrollable={true}
        >
          <ModalHeader toggle={isOpenSendAllMail ? onCloseSendAllMailClick : onCloseSendMailMultiClick}>
            <>{t("Button Send Email")}</>
          </ModalHeader>
          <ModalBody className="pt-0">
            <div style={{ minHeight: "calc(100dvh - 300px)" }}>
              <SendMail
                isModal={true}
                emails={optionsSelected || []}
                triggerRefresh={triggerRefreshAfterSendMail}
                queryParams={isOpenSendAllMail ? { ...query, total: '1000' } : null}
              />
            </div>
          </ModalBody>
        </Modal>

        <ModalConfirm
          header={t('Button Delete Journalist')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div>
    </React.Fragment >
  );
};

export default JournalistList;