import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';
import userApi from 'api/userApi';
import { formatQueryParams } from "helpers/format";

export const getUsers = createAsyncThunk("Users", async (params: any = {}) => {
  try {
    const response = await userApi.users(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getUsersByNormal = async (params: any = {}) => {
  try {
    const response = await userApi.users(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};
export const getAllUsers = async (params: any = {}) => {
  try {
    const response = await userApi.allUsers(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getUser = async (id: string | number, params: any = {}) => {
  try {
    const response = await userApi.getUser(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postUser = async (data: any = {}) => {
  try {
    const response = await userApi.postUser(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putUser = async (id: string | number, data: any = {}) => {
  try {
    const response = await userApi.putUser(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteUser = async (id: string | number, data: any = {}) => {
  try {
    const response = await userApi.deleteUser(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

