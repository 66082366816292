import { createAsyncThunk } from "@reduxjs/toolkit";
import systemApi from "api/systemApi";

export const postUploadFileMulti = async (data: any = {}) => {
  try {
    const response = await systemApi.postUploadFileMulti(data);
    return response;
  } catch (error) {
    return error;
  }
};


export const getConfigurations = createAsyncThunk("Configs", async (params: any = {}) => {
  try {
    const response = await systemApi.getConfiguration(params)
    return response;
  } catch (error) {
    return error;
  }
});

export const getConfiguration = async (params: any = {}) => {
  try {
    const response = await systemApi.getConfiguration(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const putConfiguration = async (data: any = {}) => {
  try {
    const response = await systemApi.putConfiguration(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putSectionPrompt = async (data: any = {}) => {
  try {
    const response = await systemApi.putSectionPrompt(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllSectionConfiguration = async (params: any = {}) => {
  try {
    const response = await systemApi.getSectionConfigurations(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const putSectionPromptConfiguration = async (data: any = {}) => {
  try {
    const response = await systemApi.putSectionPromptConfiguration(data);
    return response;
  } catch (error) {
    return error;
  }
};


export const getKeywordsConfiguration = async (params: any = {}) => {
  try {
    const response = await systemApi.getKeywordsConfiguration(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postKeywordsConfiguration = async (data: any = {}) => {
  try {
    const response = await systemApi.postKeywordsConfiguration(data);
    return response;
  } catch (error) {
    return error;
  }
};

