import { Option } from 'api/types/_public';
import BreadCrumb from 'components/Common/BreadCrumb';
import DropdownStatus from 'components/Common/DropdownStatus';
import LabelWrapper from 'components/Common/LabelWrapper';
import { useRole } from 'components/Hooks/UserHooks';
import RangeDatePicker from 'components/pickers/RangeDatePicker';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { COLORS_STATUS_READ_MAIL, COLORS_STATUS_SEND_MAIL, STATUS_READ_MAIL_OPTIONS, STATUS_SEND_MAIL_OPTIONS } from 'helpers/constans';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import TableContainer from "../../../components/Common/TableContainer";
import { getMailLogStatistics, getMailLogs as onGetMailLogs } from "../../../store/thunks";
import SendMail from '../SendMail';
import { IMail, IMailLog, IMailLogStatistics } from 'api/types/_mail';
import TooltipCustom from 'components/Common/TooltipCustom';
import DatePickerCustom from 'components/Common/DatePickerCustom';
export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);


const MailLog = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const STATUS_SEND_MAIL_OPTIONS_LANG = STATUS_SEND_MAIL_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const STATUS_READ_MAIL_OPTIONS_LANG = STATUS_READ_MAIL_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const START_DATE = `${moment().subtract(7, 'days').format("Y-MM-DD")}`;
  const END_DATE = `${moment().format("Y-MM-DD")}`;

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, 'DESC'),
    created_at_from: withDefault(StringParam, START_DATE),
    created_at_to: withDefault(StringParam, END_DATE),
    to_name: withDefault(StringParam, ''),
    to_address: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
    is_read: withDefault(StringParam, ''),
  });

  const [mailLogStatistics, setMailLogStatistics] = useState<IMailLogStatistics | null>(null);

  const [nameSearch, setNameSearch] = useState<string>(query?.to_name || "");
  const [emailSearch, setEmailSearch] = useState<string>(query?.to_address || "");
  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_SEND_MAIL_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(query?.status)) || null);
  const [readSearch, setReadSearch] = useState<Option | null>(STATUS_READ_MAIL_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(query?.is_read)) || null);

  const [dateSearch, setDateSearch] = useState<any[]>([query?.created_at_from ? moment(query?.created_at_from, 'Y-MM-DD').toDate() : null, query?.created_at_to ? moment(query?.created_at_to || '', 'Y-MM-DD').toDate() : null]);
  const [startDate, endDate] = dateSearch;

  const [mailPreview, setMailPreview] = useState<IMailLog | null>(null);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Mail;

  const MailProperties = createSelector(
    selectLayoutState,
    (state) => ({
      mailLogs: state.mailLogs,
      isMailLogSuccess: state.isMailLogSuccess,
      isMailLogLoading: state.isMailLogLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { mailLogs, isMailLogLoading } = useSelector(MailProperties);

  const onGetMailLogStatistics = (params: any) => {
    getMailLogStatistics(params).then((res: any) => {
      setMailLogStatistics((_prev) => res?.data || null)
    }).catch((error) => { });
  };


  useEffect(() => {
    dispatch(onGetMailLogs(query));
    onGetMailLogStatistics(query);
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    const queryNew = {
      ...query,
      to_name: nameSearch || '',
      to_address: emailSearch || '',
      created_at_from: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      created_at_to: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      status: `${statusSearch?.value}`,
      is_read: `${readSearch?.value ?? ''}`,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    };
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      to_name: '',
      to_address: '',
      status: '',
      is_read: '',
      order_by: 'DESC',
      sort_by: TYPE_SELECT_DEFAULT,
      created_at_from: `${moment().subtract(7, 'days').format("Y-MM-DD")}`,
      created_at_to: `${moment().format("Y-MM-DD")}`,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setNameSearch((_prev) => "");
    setEmailSearch((_prev) => "");
    setStatusSearch(null);
    setReadSearch(null);
    setDateSearch([moment(queryNew?.created_at_from || "", 'Y-MM-DD').toDate(), moment(queryNew?.created_at_to || "", 'Y-MM-DD').toDate()]);
  };

  const handleOpenMailPreview = (item: any) => {
    setMailPreview((_prev: any) => item);
  };

  const onCloseMailPreview = () => {
    setMailPreview((_prev: any) => null);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('ID'),
        accessor: "id",
        filterable: true,
        sortable: false,
        width: 60,
        thClass: 'text-start align-middle',
        Cell: (cell: any) => (
          <>
            <span style={{ minWidth: '40px' }}>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Status'),
        accessor: "status",
        filterable: false,
        sortable: false,
        thClass: 'text-center align-middle',
        width: 90,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: '60px' }} className="text-center fs-16">
              <span className={`rounded-pill badge bg-${COLORS_STATUS_SEND_MAIL[`${cell?.value || 0}`] || 'success'}`}>
                {STATUS_SEND_MAIL_OPTIONS_LANG?.find((s) => String(s?.value) === String(cell?.value))?.label || ''}
              </span>
            </div>
          );
        }
      },
      {
        Header: t('Read Mail'),
        accessor: "is_read",
        filterable: false,
        sortable: false,
        thClass: 'text-center align-middle',
        width: 100,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: '80px' }} className="text-center fs-16">
              <span className={`rounded-pill badge bg-${COLORS_STATUS_READ_MAIL[`${cell?.value || 0}`] || 'success'}`}>
                {STATUS_READ_MAIL_OPTIONS_LANG?.find((s) => String(s?.value) === String(cell?.value))?.label || ''}
              </span>
            </div>
          );
        }
      },
      // {
      //   Header: t('From'),
      //   accessor: "from_name",
      //   filterable: true,
      //   sortable: false,
      //   width: 170,
      //   thClass: 'text-end align-middle',
      //   Cell: (cell: any) => {
      //     const item = cell?.row?.original;
      //     return (<>
      //       <div className="text-end" style={{ minWidth: '150px' }}>
      //         {item?.from_name || '-'}
      //         <br />
      //         <span className="fs-12 text-muted">{item?.from_address}</span>
      //       </div>
      //     </>)
      //   },
      // },
      {
        Header: t('To'),
        accessor: "to_name",
        filterable: true,
        sortable: false,
        width: 170,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end" style={{ minWidth: '150px' }}>
              {item?.to_name || '-'}
              <br />
              <span className="fs-12 text-muted">{item?.to_address}</span>
            </div>
          </>)
        },
      },
      {
        Header: '',
        accessor: "__",
        filterable: true,
        sortable: false,
        width: 40,
        thClass: 'text-start align-middle',
        Cell: (cell: any) => {
          return (<>
            <div style={{ minWidth: '30px' }}></div>
          </>)
        },
      },
      {
        Header: t('Subject'),
        accessor: "subject",
        filterable: true,
        sortable: false,
        width: 400,
        thClass: 'text-start align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '350px' }} className="nav-link cursor-pointer hover-underline" onClick={() => handleOpenMailPreview(item)}>
              {cell?.value || '-'}
            </div>
          </>)
        },
      },
      // {
      //   Header: t('Reply To'),
      //   accessor: "reply_to",
      //   filterable: true,
      //   sortable: false,
      //   width: 200,
      //   thClass: 'text-start align-middle',
      //   Cell: (cell: any) => {
      //     const item = cell?.row?.original;
      //     return (<>
      //       <div style={{ minWidth: '180px' }}>
      //         {cell?.value || '-'}
      //       </div>
      //     </>)
      //   },
      // },
      {
        Header: t('Send At'),
        accessor: "send_at",
        filterable: true,
        sortable: true,
        thWidth: 120,
        thClass: 'align-middle text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end pe-3" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || '-'}</span> <br /> <span className="text-secondary">{arrDate?.[1] || '-'}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Created At'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'align-middle text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      // {
      //   Header: t('Action'),
      //   thClass: 'text-center',
      //   thWidth: 100,
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0" style={{ minWidth: '70px' }}>
      //         <TooltipCustom
      //           title={t('Open Mail')}
      //           id={`update-us-${item?.id}`}
      //         >
      //           <li className=" list-inline-item me-0" id={`update-us-${item?.id}`}>
      //             <Link className="btn btn-sm btn-soft-primary edit-item-btn" to="#"
      //               onClick={(e) => {
      //                 e.preventDefault();
      //                 handleOpenMailPreview(item);
      //               }}
      //             >
      //               <i className="ri-mail-send-fill align-bottom"></i>
      //             </Link>
      //           </li>
      //         </TooltipCustom>
      //       </ul>
      //     );
      //   },
      // },
    ],
    [i18n?.language, query, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {

    } catch (error: any) {
      return error;
    }
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }



  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    document.title = `${t('Sending Email')} - ${t('Email Log')} | PRESS Ai`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Email Log')} pageTitle={t('Sending Email')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.MAIL_LOG, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm-12">
                        <div>
                          <h5 className="card-title mb-0">
                            <span>
                              <span className="me-2">{t('Total')}:</span>
                              <CountUp
                                start={0}
                                end={mailLogs?.pagination?.total || 0}
                                duration={1}
                                className="text-primary"
                              />
                            </span>
                          </h5>
                        </div>
                      </div>
                      {/* <div className="col-sm-5 text-end">
                        {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_BOOST_CREATE, userPermissions) && (<div className="col-sm-auto">
                          <div>
                            <button
                              type="button"
                              className="btn btn-success add-btn rounded-pill"
                              id="create-btn"
                              onClick={() => setIsOpenForm((_prev) => true)}
                            >
                              <i className="ri-add-line align-bottom me-1"></i>
                              {t('Button Create Boost Keyword')}
                            </button>
                          </div>
                        </div>)}
                      </div> */}
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Col md={12} xl={12}>
                      <div className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics justify-content-start mb-3 mb-xl-2 position-relative" style={{ maxWidth: '100%', padding: '10px 5px 5px' }}>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Success')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isMailLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={mailLogStatistics?.success?.count || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Pending')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isMailLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={mailLogStatistics?.pending?.count || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Error')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isMailLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={mailLogStatistics?.error?.count || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Read')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isMailLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={mailLogStatistics?.read?.count || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
                          <CardBody className="p-3">
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">{t('Unread')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                              <div>
                                <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isMailLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={mailLogStatistics?.unread?.count || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={6} md={6} lg={3} xl={3} xxl={2} className='mt-3 mt-xxl-2'>
                        <LabelWrapper label={t('Name')} isShow={!!nameSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Name')}...`}
                            value={nameSearch}
                            onChange={(e) => setNameSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={3} xl={3} xxl={3} className='mt-3 mt-xxl-2'>
                        <LabelWrapper label={t('Email')} isShow={!!emailSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Email')}...`}
                            value={emailSearch}
                            onChange={(e) => setEmailSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={3} xl={3} xxl={2} className='mt-3 mt-xxl-2'>
                        <LabelWrapper label={t('Status')} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_SEND_MAIL_OPTIONS_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded z-hight"
                            classNamePrefix="name-prefix"
                            initialValue={statusSearch || null}
                            onChangeSelect={(e: any) => setStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All'), value: '' }}
                            colors={COLORS_STATUS_SEND_MAIL}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={3} xl={3} xxl={2} className='mt-3 mt-xxl-2'>
                        <LabelWrapper label={t('Read Mail')} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_READ_MAIL_OPTIONS_LANG || []}
                            placeholder={`${t("Read Mail")}...`}
                            className="dropdown-status-rounded z-hight"
                            classNamePrefix="name-prefix"
                            initialValue={readSearch || null}
                            onChangeSelect={(e: any) => setReadSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All'), value: '' }}
                            colors={COLORS_STATUS_READ_MAIL}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={3} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-xxl-2'>
                        <LabelWrapper label={t('Date')} isShow={true}>
                          <DatePickerCustom
                            placeholder={`${t('Start Date')} - ${t('End Date')}`}
                            startDate={startDate || null}
                            endDate={endDate || null}
                            onChangePicker={handleChangePicker}
                            showOptions={[
                              'today', 'yesterday', 'two_day_ago',
                              'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                              'this_week', 'last_week',
                              'this_month', 'last_month',
                            ]}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={6} md={6} lg={9} xl={9} xxl={12} className="hstack gap-1 justify-content-center justify-content-sm-end mt-3 mt-xxl-3">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isMailLogLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={mailLogs?.list?.length ? mailLogs?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={mailLogs?.pagination?.total}
                      customPageCount={Math.ceil(Number(mailLogs?.pagination?.total) / Number(mailLogs?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isMailLogLoading}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal
          isOpen={!!mailPreview}
          centered={true}
          size="xxll"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseMailPreview}>
            <>{t("Button Send Email")}</>
          </ModalHeader>
          <ModalBody className="pt-2">
            <div style={{ minHeight: "calc(100dvh - 300px)" }}>
              <SendMail
                isModal={true}
                mailPreview={mailPreview}
                emails={[{ value: String(mailPreview?.id), label: mailPreview?.to_address || '', more: mailPreview?.to_name || '' }]}
                isCanEdit={false}
                handleClose={onCloseMailPreview}
              />
            </div>
          </ModalBody>
        </Modal>
        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />
      </div >
    </React.Fragment >
  );
};

export default MailLog;