import { INewsGenerate } from "api/types/_news";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import DropdownMultiOption from "components/Common/DropdownMultiOption";
import DropdownOption from "components/Common/DropdownOption";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import 'react-json-pretty/themes/monikai.css';
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { postCampaignCar } from "store/campaign/thunk";
import { getModelCar } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isOpen?: boolean;
    detail?: INewsGenerate | null,
    modelCars?: { label: string, value: string, name: string, id: string }[],
    onClose?: () => void
    triggerRefresh?: (type?: string) => void;
}

const CampaignForm = ({
    isOpen = false,
    detail = null,
    modelCars = [],
    onClose,
    triggerRefresh
}: Props) => {
    const { t, i18n } = useTranslation();

    const CAR_MODEL_OPTIONS_LANG: any = modelCars;

    const [lineUpOptions, setLineUpOptions] = useState<Option[]>([]);

    const [groupOptions, setGroupOptions] = useState<Option[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false);


    const onCloseClick = () => {
        onClose && onClose();
    }

    const getDetailCarModel = async (_code_car_model: string) => {
        try {
            setIsDetailLoading(() => true);
            const res: any = await getModelCar(_code_car_model);
            setLineUpOptions((_prev: any) => (res?.data?.line_ups || [])?.map((l: any) => ({ value: l?.line_up_code, label: l?.name })));
            setIsDetailLoading(() => false);
        } catch (error: any) {
            setIsDetailLoading(() => false);
            return error;
        }
    };

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                keyword: values?.keyword || '',
                keyword_sub: values?.keyword_sub || '',
                prompt: values?.prompt || '',
                car_model_code: values?.car_model_code?.value || '',
                car_line_up_codes: (values?.car_line_up_codes || [])?.map((v: any) => v?.value) || [],
            };
            const response: any = await postCampaignCar(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        keyword: Yup.string().required(`${t("This is required")}`),
        keyword_sub: Yup.string().nullable(),
        prompt: Yup.string().required(`${t("This is required")}`),
        car_model_code: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).required(`${t("This is required")}`),
        car_line_up_codes: Yup.array().of(Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        })).required(`${t("This is required")}`),
    });

    const formik = useFormik({
        initialValues: {
            keyword: '',
            keyword_sub: '',
            prompt: '',
            car_model_code: null,
            car_line_up_codes: null,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    useEffect(() => {
        if (!!(formik?.values?.car_model_code as any)?.value) {
            formik.setFieldValue('keyword', (formik?.values?.car_model_code as any)?.name || '');
            getDetailCarModel((formik?.values?.car_model_code as any)?.value);
        }

    }, [JSON.stringify(formik?.values?.car_model_code)]);

    useEffect(() => {
        if (!detail) {
            formik.setFieldValue('keyword', '');
            formik.setFieldValue('keyword_sub', '');
            formik.setFieldValue('prompt', '');
            formik.setFieldValue('car_model_code', null);
            formik.setFieldValue('car_line_up_codes', null);
        }
    }, [JSON.stringify(detail)]);

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                centered={true}
                size="lg"
                className={`border-header-footer`}
                scrollable={false}
                toggle={onCloseClick}
                keyboard={true}
            >
                <ModalHeader toggle={onCloseClick} className="modal-title-flex-grow-1">
                    <div className="d-flex flex-column gap-0 gap-sm-2 fs-18 fs-md-20">
                        <div className="flex-grow-1">
                            <>{t('Button Create Campaign Car')}</>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className="position-relative">
                    <div>
                        <form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col sm={12} className="mb-3">
                                    <Label htmlFor="car_model_code" className="form-label">{t('Model')} <span className="text-danger"> *</span></Label>
                                    <DropdownOption
                                        name="car_model_code"
                                        dataList={CAR_MODEL_OPTIONS_LANG}
                                        placeholder={`${t("Model")}...`}
                                        className="search-filter-category-type dropdown-status-rounded"
                                        classNamePrefix="name-prefix"
                                        initialValue={formik?.values?.car_model_code || null}
                                        onChangeSelect={(e: any) => {
                                            formik.setFieldValue('car_model_code', e)
                                        }}
                                        isHasOptionAll={false}
                                        isHaveMoreInfo={true}
                                        disabled={isDetailLoading}
                                    />
                                    {formik.touched.car_model_code && formik.errors.car_model_code ? (
                                        <div className="text-danger mt-2">{formik.errors.car_model_code}</div>
                                    ) : null}
                                </Col>
                                <Col sm={12} className="mb-3 position-relative">
                                    <Label htmlFor="car_line_up_codes" className="form-label">{t('Line-up')} ({t('Multi')}) <span className="text-danger"> *</span> </Label>
                                    <DropdownMultiOption
                                        name="car_line_up_codes"
                                        dataList={lineUpOptions}
                                        placeholder={`${t("Line-up")}...`}
                                        className="search-filter-category-type dropdown-status-rounded"
                                        classNamePrefix="name-prefix"
                                        initialValue={formik?.values?.car_line_up_codes || null}
                                        onChangeSelect={(e: any) => {
                                            formik.setFieldValue('car_line_up_codes', e)
                                        }}
                                        isHasOptionAll={false}
                                        disabled={isDetailLoading}
                                    />
                                    {isDetailLoading && <span className="position-absolute text-primary" style={{ top: '38px', right: '55px' }}><Spinner size="sm"></Spinner></span>}
                                    {formik.touched.car_line_up_codes && formik.errors.car_line_up_codes ? (
                                        <div className="text-danger mt-2">{formik.errors.car_line_up_codes}</div>
                                    ) : null}
                                </Col>
                                <Col sm={6} className="mb-3">
                                    <Label htmlFor="keyword" className="form-label">{t('Keyword')} <span className="text-danger"> *</span></Label>
                                    <Input
                                        name="keyword"
                                        type="text"
                                        className="form-control"
                                        id="keyword"
                                        placeholder={t('Keyword')}
                                        value={formik?.values?.keyword}
                                        onChange={(event: any) => formik.setFieldValue('keyword', event?.target?.value)}
                                    />
                                    {formik.touched.keyword && formik.errors.keyword ? (
                                        <div className="text-danger mt-2">{formik.errors.keyword}</div>
                                    ) : null}
                                </Col>
                                <Col sm={6} className="mb-3">
                                    <Label htmlFor="keyword_sub" className="form-label">{t('Sub Keyword')}</Label>
                                    <Input
                                        name="keyword_sub"
                                        type="text"
                                        className="form-control"
                                        id="keyword_sub"
                                        placeholder={t('Sub Keyword')}
                                        value={formik?.values?.keyword_sub}
                                        onChange={(event: any) => formik.setFieldValue('keyword_sub', event?.target?.value)}
                                    />
                                    {formik.touched.keyword_sub && formik.errors.keyword_sub ? (
                                        <div className="text-danger mt-2">{formik.errors.keyword_sub}</div>
                                    ) : null}
                                </Col>
                                <Col sm={12} className="mb-4">
                                    <Label htmlFor="prompt" className="form-label">{t('Prompt')} <span className="text-danger"> *</span></Label>
                                    <Input
                                        name="text"
                                        type="textarea"
                                        className={`form-control`}
                                        placeholder={t('Prompt')}
                                        rows={6}
                                        style={{ width: '100%', minHeight: '100px' }}
                                        value={formik?.values?.prompt}
                                        onChange={(event: any) => {
                                            formik.setFieldValue('prompt', event?.target?.value)
                                        }}
                                        autoComplete="off"
                                    />
                                    {formik.touched.prompt && formik.errors.prompt ? (
                                        <div className="text-danger mt-2">{formik.errors.prompt}</div>
                                    ) : null}
                                </Col>
                                <Col sm={12} className="mb-2" >
                                    <div className="d-flex gap-3 justify-content-end align-item-center">
                                        <button
                                            className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center"
                                            type="submit"
                                            style={{ width: '160px' }}
                                            disabled={isLoading}
                                        >
                                            {isLoading && <span style={{ transform: 'translateY(1px)' }} className="me-1"><Spinner size="sm"></Spinner></span>}<span>{t('Button Create Campaign Car')}</span>
                                        </button>
                                        <button
                                            className="btn btn-secondary fs-14 px-1 d-flex justify-content-center align-item-center"
                                            type="button"
                                            onClick={onCloseClick}
                                            style={{ width: '75px' }}
                                        >
                                            <i className="ri-close-fill" style={{ lineHeight: '22px' }} /> <span className="ms-1">{t("Button Close")}</span>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
};

export default CampaignForm;

