import React from "react";
import { useTranslation } from "react-i18next";
import robotAI from "../../../assets/images/robot-ai.gif";

interface SpinnerScreenProps {
    children?: React.ReactNode;
    isShow?: boolean,
    isShowIcon?: boolean,
    style?: any,
    textLoading?: string,
    zIndex?: number
}

const SpinnerScreen: React.FC<SpinnerScreenProps> = ({ children, isShow, isShowIcon = true, style, zIndex = 99, textLoading = '' }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className={!!isShow ? '' : 'd-none'} style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#fff',
            zIndex: zIndex,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...style
        }}>
            <div className="position-relative text-center d-flex flex-column align-items-center justify-content-center">
                {isShowIcon && <img width="150" height="150" src={robotAI} alt={`${textLoading || ''}`} />}
                {!!textLoading && <p className="wave-container text-primary">
                    {(`${textLoading || ''}`).split("").map((char, index) => (
                        <span
                            key={index}
                            className={`wave-char ${char === " " ? "space" : ""}`}
                            style={{ animationDelay: `${index * 0.1}s` }}
                        >
                            {char === " " ? "\u00A0" : char}
                        </span>
                    ))}
                </p>}
            </div>
        </div>
    );
};

export default React.memo(SpinnerScreen);
