import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IKeywordBoost, IKeywordBoostV2, IKeywordRealTime, IKeywordToolConvert } from "./types/_keyword";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/keyword-cores';
const path_v2 = '/keywords';
const path_real_time = '/keyword-realtime'
const keywordApi = {
  keywordBoosts(params: any): Promise<AxiosResponse<ResponseData<IKeywordBoost[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getKeywordBoostStatistics(params: any): Promise<AxiosResponse<ResponseData<IKeywordBoost[]> & PaginationResponse, any>> {
    const url = `${path}/statistics`
    return api.get(url, params)
  },
  getKeywordBoost(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IKeywordBoost>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postKeywordBoost(dataForm: any): Promise<AxiosResponse<ResponseData<IKeywordBoost>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putKeywordBoost(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IKeywordBoost>, any>> {
    const url = `${path}/${id}/status`;
    return api.put(url, dataForm)
  },
  putStatusKeywordMulti(dataForm: any): Promise<AxiosResponse<ResponseData<IKeywordBoost>, any>> {
    const url = `${path}/status/multiple-deactivate`;
    return api.put(url, dataForm)
  },
  deleteKeywordBoostMulti(dataForm: any): Promise<AxiosResponse<ResponseData<IKeywordBoost>, any>> {
    const url = `${path}`;
    return api.delete(url, {
      data: dataForm
    })
  },
  getKeywordAutocomplete(params: any): Promise<AxiosResponse<ResponseData<IKeywordBoost[]> & PaginationResponse, any>> {
    const url = `/keyword-autocomplete`
    return api.get(url, params)
  },

  keywordBoostV2s(params: any): Promise<AxiosResponse<ResponseData<IKeywordBoostV2[]> & PaginationResponse, any>> {
    const url = `${path_v2}`
    return api.get(url, params)
  },
  getKeywordBoostV2Statistics(params: any): Promise<AxiosResponse<ResponseData<IKeywordBoostV2[]> & PaginationResponse, any>> {
    const url = `${path_v2}/statistics`
    return api.get(url, params)
  },
  getKeywordBoostV2(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IKeywordBoostV2>, any>> {
    const url = `${path_v2}/${id}`;
    return api.get(url, params)
  },
  postKeywordBoostV2(dataForm: any): Promise<AxiosResponse<ResponseData<IKeywordBoostV2>, any>> {
    const url = `${path_v2}`;
    return api.post(url, dataForm)
  },
  putKeywordBoostV2(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IKeywordBoostV2>, any>> {
    const url = `${path_v2}/${id}`;
    return api.put(url, dataForm)
  },
  importKeywordBoostV2ByExcel(dataForm: any): Promise<AxiosResponse<ResponseData<IKeywordBoostV2>, any>> {
    const url = `${path_v2}/bulk`;
    const formData = new FormData();
    if (dataForm.files) {
      formData.append('files', dataForm.files);
    }
    if (dataForm.category_id) {
      formData.append('category_id', dataForm.category_id);
    }
    if (dataForm.category_name) {
      formData.append('category_name', dataForm.category_name);
    }
    formData.append('status', dataForm.status);
    return api.post(url, formData, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  putStatusKeywordV2Multi(typeStatus: 'ACTIVATE' | 'DEACTIVATE', dataForm: any): Promise<AxiosResponse<ResponseData<IKeywordBoostV2>, any>> {
    const url = `${path_v2}/status/multiple-${typeStatus === 'ACTIVATE' ? 'activate' : 'deactivate'}`;
    return api.put(url, dataForm)
  },
  deleteKeywordBoostV2Multi(dataForm: any): Promise<AxiosResponse<ResponseData<IKeywordBoostV2>, any>> {
    const url = `${path_v2}`;
    return api.delete(url, {
      data: dataForm
    })
  },
  downloadExcelKeywordBoostV2(params: any): Promise<AxiosResponse<ResponseData<IKeywordBoostV2[]> & PaginationResponse, any>> {
    const url = `${path_v2}/export`
    return api.get(url, params)
  },
  postKeywordToolConvert(dataForm: any): Promise<AxiosResponse<ResponseData<IKeywordToolConvert>, any>> {
    const url = `/keyword-tools/convert`;
    const formData = new FormData();
    formData.append(`date`, dataForm?.date);
    formData.append(`email`, dataForm?.email);
    formData.append('files', dataForm.files);

    return api.post(url, formData, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },


  keywordRealTimes(params: any): Promise<AxiosResponse<ResponseData<IKeywordRealTime[]> & PaginationResponse, any>> {
    const url = `${path_real_time}`
    return api.get(url, params)
  },
}
export default keywordApi
