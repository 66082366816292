import { createAsyncThunk } from "@reduxjs/toolkit";
import statisticApi from "api/statisticApi";
import { formatQueryParams } from "helpers/format";

export const dailyStatistics = createAsyncThunk("Daily Statistics", async (params: any = {}) => {
  try {
    const response = await statisticApi.dailyStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const keywordStatistics = createAsyncThunk("Keyword Statistics", async (params: any = {}) => {
  try {
    const response = await statisticApi.keywordStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const mediaStatistics = createAsyncThunk("Media Statistics", async (params: any = {}) => {
  try {
    const response = await statisticApi.mediaStatistics(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});