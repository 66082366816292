import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import RangeDatePicker from "components/pickers/RangeDatePicker";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Input, Label, Row, Spinner } from 'reactstrap';
import { postNewsTracking } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    categories?: { value: string, label: string }[],
    isShowLevel1Dropdown?: boolean
    triggerRefresh?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);


const ImportArticleForm = ({
    triggerRefresh,
}: Props) => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const response: any = await postNewsTracking(values);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        keyword: Yup.string().required(`${t("This is required")}`),
        title: Yup.string().required(`${t("This is required")}`),
        url: Yup.string().required(`${t("This is required")}`),
        published_at: Yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            keyword: '',
            title: '',
            url: '',
            published_at: `${moment().format("Y-MM-DD")}`
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    useEffect(() => {
        return () => {
            formik?.setFieldValue('keyword', '');
            formik?.setFieldValue('title', '');
            formik?.setFieldValue('url', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <div>
                <Container>
                    <Row>
                        <form onSubmit={formik.handleSubmit}>
                            <div style={{ maxWidth: '500px', margin: '0 auto' }}>
                                <Row>
                                <Col sm={12} md={12} xl={12} className='mt-3 date-picker-wrapper-custom '>
                                    <Label htmlFor="published_at" className="form-label">{t('Published at')}</Label>
                                            <div>
                                            <RangeDatePicker
                                            dateSearch={formik?.values?.published_at}
                                            maxDate={moment().toDate()}
                                            handleChangeDatePicker={(_date:any) => {
                                                formik.setFieldValue('published_at', _date ? moment(new Date(_date)).format("Y-MM-DD") : '');
                                            }}
                                            />
                                            </div>
                                    </Col>
                                    <Col sm={12} md={12} xl={12} className='mt-3 '>
                                        <Label htmlFor="keyword" className="form-label">{t('Keyword')} <span className="text-danger"> *</span></Label>
                                        <Input
                                            name="keyword"
                                            type="text"
                                            className="form-control"
                                            id="keyword"
                                            placeholder={t('Keyword')}
                                            value={formik?.values?.keyword}
                                            onChange={(event: any) => formik.setFieldValue('keyword', event?.target?.value)}
                                        />
                                        {formik.touched.keyword && formik.errors.keyword ? (
                                            <div className="text-danger mt-2">{formik.errors.keyword}</div>
                                        ) : null}
                                    </Col>
                                    <Col sm={12} md={12} xl={12} className='mt-3 '>
                                        <Label htmlFor="title" className="form-label">{t('Tracking Article Title')} <span className="text-danger"> *</span></Label>
                                        <Input
                                            name="title"
                                            type="textarea"
                                            className="form-control"
                                            id="title"
                                            rows={4}
                                            placeholder={t('Tracking Article Title')}
                                            value={formik?.values?.title}
                                            onChange={(event: any) => formik.setFieldValue('title', event?.target?.value)}
                                        />
                                        {formik.touched.title && formik.errors.title ? (
                                            <div className="text-danger mt-2">{formik.errors.title}</div>
                                        ) : null}
                                    </Col>
                                    <Col sm={12} md={12} xl={12} className='mt-3 '>
                                        <Label htmlFor="url" className="form-label">{t('URL')} <span className="text-danger"> *</span></Label>
                                        <Input
                                            name="url"
                                            type="text"
                                            className="form-control"
                                            id="url"
                                            placeholder={t('URL')}
                                            value={formik?.values?.url}
                                            onChange={(event: any) => formik.setFieldValue('url', event?.target?.value)}
                                        />
                                        {formik.touched.url && formik.errors.url ? (
                                            <div className="text-danger mt-2">{formik.errors.url}</div>
                                        ) : null}
                                    </Col>
                                    
                                    <Col sm={12} md={12} xl={12} className='mt-4 pt-1 text-end'>
                                        <button
                                            style={{ width: '190px' }}
                                            type="submit"
                                            disabled={isLoading}
                                            className="btn btn-primary fs-14 rounded-pill">
                                            {isLoading ? <Spinner size="sm me-1" ></Spinner> : <></>}
                                            {t('Button Import Article')}
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    </Row >
                </Container >
            </div>
            <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />
        </React.Fragment >
    );
};

export default ImportArticleForm;
