import { IKeywordBoost } from 'api/types/_keyword';
import { Option } from 'api/types/_public';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import DropdownStatus from 'components/Common/DropdownStatus';
import InputsRange from 'components/Common/InputsRange';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import RangeDatePicker from 'components/pickers/RangeDatePicker';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { COLORS_STATUS_KEYWORD_BOOST, STATUS_KEYWORD_BOOST_OPTIONS, STATUS_USER_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import TableContainer from "../../components/Common/TableContainer";
import { deleteKeywordBoostV2Multi, getKeywordBoostV2Statistics, getKeywordRealTimes as onGetKeywordRealTimes, putKeywordBoostV2, putStatusKeywordV2Multi } from "../../store/thunks";
export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'pv';

registerLocale('en', en);
registerLocale('ko', ko);


const KeywordRealTime = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const STATUS_USER_OPTIONS_LANG: any = STATUS_USER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const STATUS_KEYWORD_BOOST_OPTIONS_LANG = STATUS_KEYWORD_BOOST_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 100),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, 'DESC'),
    date: withDefault(StringParam, moment().format("Y-MM-DD")),
    keyword: withDefault(StringParam, ''),
    // media_name: withDefault(StringParam, ''),
    // status: withDefault(StringParam, ''),
    // start_uv: withDefault(StringParam, ''),
    // end_uv: withDefault(StringParam, ''),
    // start_pv: withDefault(StringParam, ''),
    // end_pv: withDefault(StringParam, ''),
    // start_monthly_search_volume: withDefault(StringParam, ''),
    // end_monthly_search_volume: withDefault(StringParam, ''),
    category_id: withDefault(StringParam, ''),
  });

  const [totalKeywordBoostActivates, setTotalKeywordBoostActivates] = useState<number>(0);

  const [idLoading, setIdLoading] = useState<string | number | null>(null);

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<IKeywordBoost | null>(null);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  // const [mediaSearch, setMediaSearch] = useState<string>(query?.media_name || "");

  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_USER_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(query?.status)) || null);

  const [startUVSearch, setStartUVSearch] = useState<string>(query?.start_uv || "");
  const [endUVSearch, setEndUVSearch] = useState<string>(query?.end_uv || "");

  const [startPVSearch, setStartPVSearch] = useState<string>(query?.start_pv || "");
  const [endPVSearch, setEndPVSearch] = useState<string>(query?.end_pv || "");

  const [startMonthlyVolumeSearch, setStartMonthlyVolumeSearch] = useState<string>(query?.start_monthly_search_volume || "");
  const [endMonthlyVolumeSearch, setEndMonthlyVolumeSearch] = useState<string>(query?.end_monthly_search_volume || "");

  const [dateSearch, setDateSearch] = useState<any>(query?.date ? moment(query?.date, 'Y-MM-DD').toDate() : null);

  const [keywordEdit, setKeywordEdit] = useState<IKeywordBoost & { isCopy?: boolean } | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isOpenConfirmChangeStatusMulti, setIsOpenConfirmChangeStatusMulti] = useState<"ACTIVATE" | "DEACTIVATE" | false>(false);

  const [optionsSelected, setOptionsSelected] = useState<any>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Keyword;

  const KeywordProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywordRealTimes: state.keywordRealTimes,
      isKeywordRealTimeSuccess: state.isKeywordRealTimeSuccess,
      isKeywordRealTimeLoading: state.isKeywordRealTimeLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { keywordRealTimes, error, isKeywordRealTimeLoading } = useSelector(KeywordProperties);

  const onGetKeywordBoostV2Statistics = (params: any) => {
    getKeywordBoostV2Statistics(params).then((res: any) => {
      setTotalKeywordBoostActivates((_prev) => res?.data?.active_count || 0)
    }).catch((error) => { });
  };

  useEffect(() => {
    dispatch(onGetKeywordRealTimes(query));
    // onGetKeywordBoostV2Statistics(query);
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setOptionsSelected((prev: any) => []);
    const queryNew = {
      ...query,
      keyword: keywordSearch || '',
      // media_name: mediaSearch || '',
      date: dateSearch ? moment(new Date(dateSearch)).format("Y-MM-DD") : '',
      start_uv: startUVSearch || '',
      end_uv: endUVSearch || '',
      start_pv: startPVSearch || '',
      end_pv: endPVSearch || '',
      start_monthly_search_volume: startMonthlyVolumeSearch || '',
      end_monthly_search_volume: endMonthlyVolumeSearch || '',
      // status: `${statusSearch?.value}`,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    };
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      keyword: '',
      // media_name: '',
      start_uv: '',
      end_uv: '',
      start_pv: '',
      end_pv: '',
      start_monthly_search_volume: '',
      end_monthly_search_volume: '',
      // status: '',
      category_id: '',
      order_by: 'DESC',
      sort_by: TYPE_SELECT_DEFAULT,
      date: `${moment().format("Y-MM-DD")}`,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setKeywordSearch((_prev) => "");
    // setMediaSearch((_prev) => "");
    // setStatusSearch(null);
    setStartUVSearch(queryNew?.start_uv);
    setEndUVSearch(queryNew?.end_uv);
    setStartPVSearch(queryNew?.start_pv);
    setEndPVSearch(queryNew?.end_pv);
    // setStartMonthlyVolumeSearch(queryNew?.start_monthly_search_volume);
    // setEndMonthlyVolumeSearch(queryNew?.end_monthly_search_volume);
    setDateSearch(moment(queryNew?.date || "", 'Y-MM-DD').toDate());
  };
  // 

  // Begin::Update keyword
  const handleUpdateBoostKeyword = async (dataSubmit: { id: string | number, status: number, keyword_sub: string } | undefined = undefined) => {
    try {
      if (!keywordEdit?.id && !dataSubmit) {
        return;
      }
      setIdLoading((_prev) => (dataSubmit?.id || keywordEdit?.id) as string);
      const data = !!dataSubmit ? ({
        status: dataSubmit?.status,
        keyword_sub: dataSubmit?.keyword_sub || '',
      }) : ({
        status: keywordEdit?.status,
        keyword_sub: keywordEdit?.keyword_sub || '',
      });
      const response: any = await putKeywordBoostV2((dataSubmit?.id || keywordEdit?.id) as string, data);
      if (response?.code === 200) {
        setIdLoading((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        triggerRefresh();
      } else {
        setIdLoading((_prev) => null);
        toast(`${response?.message || response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIdLoading((_prev) => null);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }

  };

  const onCloseClick = () => {
    setKeywordEdit((_prev) => null);
    setIsOpen((_prev) => false);
  }

  const triggerRefresh = (is_refresh_categories: boolean = false) => {
    onCloseClick();
    onCloseConfirmChangeStatusMultiClick();
    setQuery({
      ...query,
      ...(!!is_refresh_categories ? { category_id: '' } : {}),
      time_request: + new Date(),
    });
    // onGetKeywordBoostV2Statistics(query);
    if (is_refresh_categories) {
      handleCallAllOption();
    }
  }
  //End::Update User

  // Begin::Delete
  const handleConfirmDelete = (item: IKeywordBoost | null) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!initialValuesDefault?.id && !countRowSelected) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const ids = initialValuesDefault?.id ? [initialValuesDefault?.id] : (optionsSelected || []).flat()?.map((item: any) => item?.value);
      const response: any = await deleteKeywordBoostV2Multi({
        ids: ids
      });
      if (response?.code === 200) {
        triggerRefresh();
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        if (!initialValuesDefault?.id) {
          setOptionsSelected((prev: any) => []);
        }
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    // if (!!isFirstLoadingPageRef.current) {
    //   return;
    // }
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (keywordRealTimes?.list || [])?.map((item: any) => ({ value: item?.id, label: item?.id }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === keywordRealTimes?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  // End:: handle select table 

  // Column
  const columns = useMemo(
    () => [
      // {
      //   Header: t('Key'),
      //   accessor: "",
      //   filterable: true,
      //   sortable: false,
      //   thClass: 'text-start align-middle',
      //   thWidth: 50,
      //   thComponent: () => (<>
      //     <div className="form-check">
      //       <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
      //       <label className="form-check-label" htmlFor={`cell-check-all`}></label>
      //     </div>
      //   </>
      //   ),
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <>
      //         <div className={`form-check`}>
      //           <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.id, label: cell?.row?.original?.id })} value={cell?.row?.original?.id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.id, label: cell?.row?.original?.id }), query?.page, e)} id={`cell-check-${cell?.row?.original?.id ?? ''}`} />
      //           <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.id ?? ''}`}></label>
      //         </div>
      //       </>
      //     )
      //   },
      // },
      // {
      //   Header: t('ID'),
      //   accessor: "id",
      //   filterable: true,
      //   sortable: false,
      //   width: 60,
      //   thClass: 'text-start align-middle',
      //   Cell: (cell: any) => (
      //     <>
      //       <span style={{ minWidth: '40px' }}>{cell?.value}</span>
      //     </>
      //   ),
      // },
      {
        Header: t('Ranking'),
        accessor: "rank",
        filterable: true,
        sortable: false,
        thClass: 'align-middle text-start',
        thWidth: 90,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="text-start" style={{ minWidth: '60px' }}>{((query?.page - 1) * (query?.limit)) + (cell?.cell?.row?.index + 1)}</div>
            </>
          )
        },
      },
      // {
      //   Header: t('Active Action'),
      //   accessor: "status",
      //   filterable: false,
      //   sortable: false,
      //   thClass: 'text-center align-middle',
      //   width: 100,
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     const active = item?.status;
      //     return (
      //       <div className="text-center form-check form-switch form-switch-sm position-relative" dir="ltr" style={{ minWidth: '80px' }}>
      //         {(String(idLoading) === String(item?.id)) && <span className="position-absolute" style={{ top: '3px', left: 0 }}><Spinner size="sm" className="text-primary" /></span>}
      //         <input type="checkbox" className="form-check-input" name="status" id="customSwitchsizelg" disabled checked={Boolean(active)}
      //           onChange={(event: any) => {
      //             if (isHavePermissionRole(ROLES_FOR_APP.KEYWORD_REAL_TIME_CREATE, userPermissions)) {
      //               handleUpdateBoostKeyword(
      //                 { id: item?.id, status: event.target.checked, keyword_sub: item?.keyword_sub || '' }
      //               );
      //             }
      //           }}
      //         />
      //       </div>
      //     );
      //   }
      // },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: true,
        sortable: true,
        width: 120,
        thClass: 'text-start align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '100px' }}>
              {cell?.value || '-'}
            </div>
          </>)
        },
      },
      // {
      //   Header: t('Monthly Search Volume'),
      //   accessor: "monthly_search_volume",
      //   filterable: true,
      //   sortable: true,
      //   thClass: 'text-end align-middle',
      //   width: i18n?.language === 'en' ? 200 : 150,
      //   Cell: (cell: any) => {
      //     const item = cell?.row?.original;
      //     return (<>
      //       <div className="pe-3 text-end" style={{ minWidth: i18n?.language === 'en' ? '190px' : '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
      //     </>)
      //   },
      // },
      {
        Header: t('PV'),
        accessor: "pv",
        filterable: true,
        sortable: true,
        thClass: 'text-end align-middle',
        width: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="pe-3 text-end" style={{ minWidth: '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
          </>)
        },
      },
      {
        Header: t('UV'),
        accessor: "uv",
        filterable: true,
        sortable: true,
        thClass: 'text-end align-middle',
        width: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="pe-3 text-end" style={{ minWidth: '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
          </>)
        },
      },
      {
        Header: '',
        accessor: "__",
        filterable: true,
        sortable: false,
        thClass: 'text-end align-middle',
        width: 40,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end"></div>
          </>)
        },
      },
      // {
      //   Header: t('Partner Media'),
      //   accessor: "media_name",
      //   filterable: true,
      //   sortable: false,
      //   thClass: 'text-start align-middle',
      //   width: i18n?.language === 'en' ? 140 : 120,
      //   Cell: (cell: any) => {
      //     const item = cell?.row?.original;
      //     return (<>
      //       <div className="text-start hover-underline-primary" style={{ minWidth: i18n?.language === 'en' ? '120px' : '100px' }}>
      //         <Link
      //           className="nav-link"
      //           target="_blank"
      //           to={item?.news_link || ''} >
      //           {cell?.value || '-'}
      //         </Link>
      //       </div>
      //     </>)
      //   },
      // },
      {
        Header: t('Date'),
        accessor: "date",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span>{arrDate?.[0] || ''}</span>
            </div>
          )
        },
      },
      {
        Header: '',
        accessor: "_",
        filterable: true,
        sortable: false,
        thClass: 'text-end align-middle',
        width: 40,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end"></div>
          </>)
        },
      },
      {
        Header: t('Created At'),
        accessor: "created_at",
        filterable: true,
        sortable: true,
        thWidth: 130,
        thClass: 'align-middle text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end pe-3" style={{ minWidth: '120px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      // {
      //   Header: t('Action'),
      //   thWidth: 100,
      //   thClass: 'text-center align-middle',
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0" style={{ minWidth: '70px' }}>
      //         {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_REAL_TIME_DELETE, userPermissions) && (
      //           <TooltipCustom
      //             title={t('Button Delete Boost Keyword')}
      //             id={`detail-cp-${item?.id}`}
      //           >
      //             <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
      //               <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#"
      //                 onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
      //               >
      //                 <i className="ri-delete-bin-3-fill align-bottom"></i>
      //               </Link>
      //             </li>
      //           </TooltipCustom>
      //         )}
      //       </ul>
      //     );
      //   },
      // },
    ],
    [i18n?.language, query, userPermissions, idLoading, JSON.stringify(optionsSelected)]
  );

  // Begin::Update status
  const handleConfirmChangeStatusMulti = (type_status: "ACTIVATE" | "DEACTIVATE") => {
    setIsOpenConfirmChangeStatusMulti((_prev) => type_status);
  };

  const handleActionChangeStatus = async () => {
    try {
      setIsConfirmLoading((_prev) => true);
      const kw_s = (optionsSelected || []).flat()?.map((item: any) => item?.value);
      const type_status: any = isOpenConfirmChangeStatusMulti || 'ACTIVE';
      const response: any = await putStatusKeywordV2Multi(type_status, {
        ids: kw_s,
      });
      if (response?.code === 200) {
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        triggerRefresh();
        setIsConfirmLoading((_prev) => false);
        setIsOpenConfirmChangeStatusMulti((_prev) => false);
        setOptionsSelected((prev: any) => []);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsOpenConfirmChangeStatusMulti((_prev) => false);
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmChangeStatusMultiClick = () => {
    setIsOpenConfirmChangeStatusMulti((_prev) => false);
  }
  // End::Update status

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleChangeDatePicker = (date: any) => {
    setDateSearch((_prev: any) => date);
  }

  const handleCallAllOption = async () => {
    try {

    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t('Home')} - ${t('Real-Time Keyword')} | PRESS Ai`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Real-Time Keyword')} pageTitle={t('Home')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_REAL_TIME_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm-4 col-md-6 ">
                        <div>
                          <h5 className="card-title mb-0">
                            <span>
                              <span className="me-2">{t('Total')}:</span>
                              <CountUp
                                start={0}
                                end={keywordRealTimes?.pagination?.total || 0}
                                duration={1}
                                className="text-primary"
                              />
                            </span>
                            {/* <span className="mx-2 text-muted">|</span>
                            <span>
                              <span className="me-2">{t('Activates')}:</span>
                              <span className="text-primary">{formatNumberWithCommas(totalKeywordBoostActivates || 0)}</span>
                            </span> */}
                          </h5>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={4} md={4} lg={4} xl={4} xxl={4} className='mt-3 mt-xxl-2'>
                        <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Keyword')}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      {/* <Col sm={6} md={6} lg={6} xl={6} xxl={3} className='mt-3 mt-md-2'>
                        <LabelWrapper label={t('Partner Media')} isShow={!!mediaSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Partner Media')}...`}
                            value={mediaSearch}
                            onChange={(e) => setMediaSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col> */}
                      {/* <Col sm={12} md={6} lg={5} xl={5} xxl={5} className='order-1 order-md-0 mt-3 mt-xxl-2' >
                        <InputsRange
                          nameFrom="from"
                          nameTo="to"
                          valueFrom={startMonthlyVolumeSearch}
                          valueTo={endMonthlyVolumeSearch}
                          placeholderFrom={`${t('Monthly Search Volume (Start)')}...`}
                          placeholderTo={`${t('Monthly Search Volume (End)')}...`}
                          labelFrom={`${t('Monthly Search Volume (Start)')}`}
                          labelTo={`${t('Monthly Search Volume (End)')}`}
                          isTypeNumber={true}
                          onChangeFrom={(val) => setStartMonthlyVolumeSearch(val)}
                          onChangeTo={(val) => setEndMonthlyVolumeSearch(val)}
                          onKeyDownFrom={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                          onKeyDownTo={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </Col> */}
                      {/* <Col sm={6} md={6} lg={2} xl={2} xxl={2} className='mt-3 mt-xxl-2'>
                        <LabelWrapper label={t('Status')} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_KEYWORD_BOOST_OPTIONS_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded z-hight"
                            classNamePrefix="name-prefix"
                            initialValue={statusSearch || null}
                            onChangeSelect={(e: any) => setStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All'), value: '' }}
                            colors={COLORS_STATUS_KEYWORD_BOOST}
                          />
                        </LabelWrapper>
                      </Col> */}
                      <Col sm={3} md={4} lg={3} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-xxl-2'>
                        <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                          <RangeDatePicker
                            maxDate={moment().toDate()}
                            dateSearch={dateSearch}
                            handleChangeDatePicker={handleChangeDatePicker}
                          />
                        </LabelWrapper>
                      </Col>

                      {/* <Col sm={12} md={4} lg={4} xl={4} xxl={4} className='mt-3 mt-xxl-3' >
                        <InputsRange
                          nameFrom="from"
                          nameTo="to"
                          valueFrom={startPVSearch}
                          valueTo={endPVSearch}
                          placeholderFrom={`${t('PV (Start)')}...`}
                          placeholderTo={`${t('PV (End)')}...`}
                          labelFrom={`${t('PV (Start)')}`}
                          labelTo={`${t('PV (End)')}`}
                          isTypeNumber={true}
                          onChangeFrom={(val) => setStartPVSearch(val)}
                          onChangeTo={(val) => setEndPVSearch(val)}
                          onKeyDownFrom={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                          onKeyDownTo={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </Col> */}
                      {/* <Col sm={12} md={4} lg={4} xl={4} xxl={4} className='mt-3 mt-xxl-3' >
                        <InputsRange
                          nameFrom="from"
                          nameTo="to"
                          valueFrom={startUVSearch}
                          valueTo={endUVSearch}
                          placeholderFrom={`${t('UV (Start)')}...`}
                          placeholderTo={`${t('UV (End)')}...`}
                          labelFrom={`${t('UV (Start)')}`}
                          labelTo={`${t('UV (End)')}`}
                          isTypeNumber={true}
                          onChangeFrom={(val) => setStartUVSearch(val)}
                          onChangeTo={(val) => setEndUVSearch(val)}
                          onKeyDownFrom={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                          onKeyDownTo={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </Col> */}

                      <Col sm={5} md={4} lg={5} xl={5} xxl={5} className="order-1 order-md-0 hstack gap-1 justify-content-center justify-content-sm-start justify-content-xxl-start mt-3 mt-xxl-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isKeywordRealTimeLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                      {/* <Col sm={12} md={12} xl={12} className="my-0 mt-4 mt-md-0 text-start">
                        {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_REAL_TIME_UPDATE, userPermissions) &&
                          <button
                            type="button"
                            className="btn btn-soft-primary me-3"
                            style={{ width: '85px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatusMulti('ACTIVATE') }}
                          >
                            {countRowSelected > 0 && (
                              <span
                                className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                style={{ transform: "translate(0%, -70%)" }}
                              >
                                {formatNumberWithCommas(countRowSelected)}
                                <span className="visually-hidden">
                                  total keywords selected
                                </span>
                              </span>
                            )}
                            {t('Activate')}
                          </button>
                        }
                        {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_REAL_TIME_UPDATE, userPermissions) &&
                          <button
                            type="button"
                            className="btn btn-soft-primary me-3"
                            style={{ width: '105px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatusMulti('DEACTIVATE') }}
                          >
                            {countRowSelected > 0 && (
                              <span
                                className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                style={{ transform: "translate(0%, -70%)" }}
                              >
                                {formatNumberWithCommas(countRowSelected)}
                                <span className="visually-hidden">
                                  total keywords selected
                                </span>
                              </span>
                            )}
                            {t('Deactivate')}
                          </button>
                        }
                        {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_REAL_TIME_DELETE, userPermissions) &&
                          <button
                            type="button"
                            className="btn btn-soft-danger"
                            style={{ width: '85px' }}
                            disabled={countRowSelected <= 0}
                            onClick={(e) => { e.stopPropagation(); handleConfirmDelete(null) }}
                          >
                            {countRowSelected > 0 && (
                              <span
                                className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                style={{ transform: "translate(0%, -70%)" }}
                              >
                                {formatNumberWithCommas(countRowSelected)}
                                <span className="visually-hidden">
                                  total keywords selected
                                </span>
                              </span>
                            )}
                            {t('Button Delete')}
                          </button>
                        }
                      </Col> */}
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={keywordRealTimes?.list?.length ? keywordRealTimes?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={keywordRealTimes?.pagination?.total}
                      customPageCount={Math.ceil(Number(keywordRealTimes?.pagination?.total) / Number(keywordRealTimes?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isKeywordRealTimeLoading}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>

        <ModalConfirm
          header={isOpenConfirmChangeStatusMulti === 'ACTIVATE' ? t('Activate') : t('Deactivate')}
          textButtonConfirm={isOpenConfirmChangeStatusMulti === 'ACTIVATE' ? t('Activate') : t('Deactivate')}
          classButtonConfirm={'btn-soft-primary'}
          title={isOpenConfirmChangeStatusMulti === 'ACTIVATE' ? t("Are you sure you want to activate?") : t("Are you sure you want to deactivate?")}
          content={t("After confirming the update action, the data will be updated. Do you want to proceed with the update action.")}
          isOpen={!!isOpenConfirmChangeStatusMulti}
          classIconButtonConfirm=''
          isShowIcon={false}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmChangeStatusMultiClick}
          onConfirm={handleActionChangeStatus}
        />

        <ModalConfirm
          header={t('Button Delete Boost Keyword')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />

        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />

      </div >
    </React.Fragment >
  );
};

export default KeywordRealTime;