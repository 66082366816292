import { createSlice } from "@reduxjs/toolkit";
import { getArticleTrackings } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IArticleTracking } from "api/types/_tracking";


export interface IState {
  articleTrackings: ResponseData<IArticleTracking[]> & PaginationResponse | null,
  isArticleTrackingLoading: boolean,
  isArticleTrackingSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  articleTrackings: null,
  isArticleTrackingLoading: false,
  isArticleTrackingSuccess: false,

  error: {},
};

const TrackingSlice = createSlice({
  name: "Tracking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get News Monitors
    builder.addCase(getArticleTrackings.pending, (state: IState, action: any) => {
      state.isArticleTrackingLoading = true
    });
    builder.addCase(getArticleTrackings.fulfilled, (state: IState, action: any) => {
      state.articleTrackings = action.payload.data;
      state.isArticleTrackingSuccess = true;
      state.isArticleTrackingLoading = false;
    });
    builder.addCase(getArticleTrackings.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isArticleTrackingSuccess = false;
      state.isArticleTrackingLoading = false;
    });
  },
});

export default TrackingSlice.reducer;