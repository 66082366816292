import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import { useRole } from "components/Hooks/UserHooks";
import { useFormik } from "formik";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Col, Input, Label, Row, Spinner } from 'reactstrap';
import { getCategory, postCategory, putCategory } from "store/thunks";
import * as Yup from "yup";

export interface Props {
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    seqNext?: number | string,
    triggerRefresh?: () => void;
}

const CategoryForm = ({
    id = '',
    seqNext = '1',
    triggerRefresh
}: Props) => {
    const { t, i18n } = useTranslation();

    const { userPermissions } = useRole();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                seq: Number(values?.seq || 0),
                name: values?.name || '',
            };
            const response: any = id ? await putCategory(id, data) : await postCategory(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        seq: Yup.string().required(`${t("This is required")}`),
        name: Yup.string().required(`${t("This is required")}`),
    });

    const formik = useFormik({
        initialValues: {
            seq: seqNext,
            name: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const vSeq = valueDefault?.seq || '';
        const vName = valueDefault?.name || '';
        setTimeout(() => {
            formik.setFieldValue("seq", vSeq);
            formik.setFieldValue("name", vName);
        }, 300);
    };

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            const [resDetail]: any = await Promise.all([idItem ? getCategory(idItem) : {},]);
            if (resDetail?.data) {
                handleSetValueForm(resDetail?.data);
                setIsLoadingDetail((_prev) => false);
            }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <React.Fragment>
            {isLoadingDetail && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(164 164 164 / 36%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Spinner size="sm" color="primary"></Spinner>
            </div>}
            <div>
                <Row>
                    <form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col xs={12} sm={3}>
                                <div className="mb-3">
                                    <Label htmlFor="name" className="form-label">{t('Order')} <span className="text-danger">*</span></Label>
                                    <Input
                                        name="seq"
                                        type="text"
                                        className="form-control"
                                        id="seq"
                                        placeholder={t('Order')}
                                        value={formik?.values?.seq}
                                        onChange={(event: any) => {
                                            const input = event.target.value;
                                            const onlyNumbers = String(input).replace(/\D/g, '');
                                            formik.setFieldValue('seq', onlyNumbers)
                                        }}
                                    />
                                    {formik.touched.seq && formik.errors.seq ? (
                                        <div className="text-danger mt-2">{formik.errors.seq}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col xs={12} sm={9}>
                                <div className="mb-4 mb-sm-3">
                                    <Label htmlFor="name" className="form-label">{t('Name')} <span className="text-danger">*</span></Label>
                                    <Input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder={t('Name')}
                                        value={formik?.values?.name}
                                        onChange={(event: any) => formik.setFieldValue('name', event?.target?.value)}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="text-danger mt-2">{formik.errors.name}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="hstack gap-2 justify-content-end">
                                    {isHavePermissionRole(ROLES_FOR_APP.CATEGORY_UPDATE, userPermissions) && (
                                        <button
                                            style={{ width: '180px' }}
                                            type="submit"
                                            className="btn btn-primary fs-14 rounded-pill">
                                            {isLoading ? <Spinner size="sm me-2" ></Spinner> : <></>}
                                            {id ? t('Button Update Category') : t('Button Create Category')}
                                        </button>)}
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Row >
            </div>
        </React.Fragment >
    );
};

export default CategoryForm;
