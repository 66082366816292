import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ICampaignCar } from "./types/_campaign";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/campaigns';
const campaignApi = {
  campaignCars(params: any): Promise<AxiosResponse<ResponseData<ICampaignCar[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getCampaignCar(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<ICampaignCar>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  allCampaignCar(params: any = {}): Promise<AxiosResponse<ResponseData<ICampaignCar[]>, any>> {
    const url = `masters/campaigns-car`
    return api.get(url, params)
  },
  postCampaignCar(dataForm: any): Promise<AxiosResponse<ResponseData<ICampaignCar>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putCampaignCar(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<ICampaignCar>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteMultiCampaignCar(dataForm: any): Promise<AxiosResponse<ResponseData<ICampaignCar>, any>> {
    const url = `${path}`;
    return api.delete(url, {
      data: dataForm
    });
  },
}
export default campaignApi
