import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

interface Option {
    name?: string;
    address: string
}

export interface Props {
    isOpen?: boolean;
    emails?: Option[];
    handleClose?: () => void;
}


const ModalShowFullMail = ({
    isOpen = false,
    emails = [],
    handleClose,
}: Props) => {

    const { t, i18n } = useTranslation();

    const handleCloseClick = () => {
        handleClose && handleClose();
    }


    return (
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                centered={true}
                size="lg"
                scrollable={true}
            >
                <ModalHeader toggle={handleCloseClick}>
                    <>{t("To Mails")}</>
                </ModalHeader>
                <ModalBody className="pt-0">
                    <Row>
                        {emails?.map((item, index) => (
                            <Col sm={6} md={4} lg={4} className="mb-3" key={index}>
                                <div className="d-flex align-items-center hover-bg-eaeaea">
                                    <div className="text-start me-1" style={{ width: '20px' }}>
                                        {index + 1}.
                                    </div>
                                    <div className="text-start">
                                        <span>
                                            {item?.name}
                                        </span>
                                        <br />
                                        <span className="text-muted fs-12">
                                            {item?.address}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Col lg={12}>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                style={{ width: '80px' }}
                                type="button"
                                className="btn btn-secondary fs-14 rounded-pill"
                                onClick={handleCloseClick}
                            >
                                {t('Button Close')}
                            </button>
                        </div>
                    </Col>
                </ModalFooter>
            </Modal>
        </React.Fragment >
    );
};

export default React.memo(ModalShowFullMail);
