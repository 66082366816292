import { createAsyncThunk } from "@reduxjs/toolkit";
import imageApi from 'api/imageApi';
import { formatQueryParams } from "helpers/format";

export const getImageCategories = createAsyncThunk("Image Categories", async (params: any = {}) => {
  try {
    const response = await imageApi.imageCategories(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllImageCategory = async (params: any = {}) => {
  try {
    const response = await imageApi.allImageCategory(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getImageCategory = async (id: string | number, params: any = {}) => {
  try {
    const response = await imageApi.getImageCategory(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postImageCategory = async (data: any = {}) => {
  try {
    const response = await imageApi.postImageCategory(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putImageCategory = async (id: string | number, data: any = {}, DEPTH_CATEGORY: number = 0) => {
  try {
    if (DEPTH_CATEGORY === 2) {
      const response = await imageApi.putImageCategory(id, data);
      return response;
    }

    const response = await imageApi.putImportImageCategory(id, data);
    return response;

  } catch (error) {
    return error;
  }
};

export const putImportImageCategoryLevel1 = async (id: string | number, data: any = {}) => {
  try {
    const response = await imageApi.putImportImageCategoryLevel1(id, data);
    return response;

  } catch (error) {
    return error;
  }
};


export const postSiteImageCategory = async (data: any = {}) => {
  try {
    const response = await imageApi.postSiteImageCategory(data);
    return response;
  } catch (error) {
    return error;
  }
};



export const deleteImageCategory = async (id: string | number, data: any = {}) => {
  try {
    const response = await imageApi.deleteImageCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const getImageSearchByCategory = async (params: any = {}) => {
  try {
    const response = await imageApi.getImageSearchByCategory(params)
    return response;
  } catch (error) {
    return error;
  }
};


export const postConvertToCdn = async (data: any = {}) => {
  try {
    const response = await imageApi.postConvertToCdn(data);
    return response;
  } catch (error) {
    return error;
  }
};
