import { ICategory } from 'api/types/_category';
import { INews, IPartner } from 'api/types/_news';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_INFO, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import CopyWrapper from 'components/Common/CopyWrapper';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import DropdownStatus from 'components/Common/DropdownStatus';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TableContainer from 'components/Common/TableContainer';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import { COLORS_RANK_NEWS, COLORS_SENTIMENT_NEWS, COLORS_STATUS_NEWS, COLOR_TYPE_SENTIMENT, RANK_NEWS_OPTIONS, SECTION_KEYWORD, SENTIMENT_NEWS_OPTIONS, STATUS_NEWS_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from 'react-countup';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { deleteNewsMulti, downloadExcelNews, getAINewsByNormal, getAllCategory, getAllImageCategory, getAllPartners, getGenerateBoostingNews, postGenerateBoostingNews, putGenerateBoostingNews, putNews } from 'store/thunks';
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BoxFooterNews from '../Components/BoxFooterNews';
import BoxGeneratedNews from '../Components/BoxGeneratedNews';
import BoxImageSearch from '../Components/BoxImageSearch';
import BoxSourceNews from '../Components/BoxSourceNews';
import ButtonLanguage from '../Components/ButtonLanguage';
import NestingFilter from '../Components/NestingFilter';
import HighlightText from '../Components/HighlightText';
import InputsRange from 'components/Common/InputsRange';
import ImportMediaArticleForm from '../Components/ImportMediaArticleForm';

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'last_published_at';

const BoostingContentList = () => {
  let document_w: any = window.document;
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const modalRef = useRef<any>(null);
  const modalBodyInnerRef = useRef<any>(null);

  const modalSourceInnerRefs = useRef<any>([]);

  const isFirstLoadingPageRef = useRef<any>(true);

  const STATUS_NEWS_OPTIONS_LANG = STATUS_NEWS_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const SENTIMENT_NEWS_OPTIONS_LANG = SENTIMENT_NEWS_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const RANK_NEWS_OPTIONS_LANG = RANK_NEWS_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const START_DATE = `${moment().format("Y-MM-DD")}`;
  const END_DATE = `${moment().format("Y-MM-DD")}`;

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 100),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, 'DESC'),
    start_date: withDefault(StringParam, START_DATE),
    end_date: withDefault(StringParam, END_DATE),
    keyword: withDefault(StringParam, ''),
    title: withDefault(StringParam, ''),
    is_used: withDefault(StringParam, STATUS_NEWS_OPTIONS_LANG[0]?.value),
    section: withDefault(StringParam, `${SECTION_KEYWORD.CONTENT_CAR}`),
    // category_id: withDefault(StringParam, ''),
    // sentiment_value: withDefault(StringParam, ''),
    type_page_no_param: withDefault(StringParam, ''),
    exposure_rank_type: withDefault(StringParam, ''),
    start_daily_search_volume: withDefault(StringParam, ''),
    end_daily_search_volume: withDefault(StringParam, ''),
    last_published_at_start: withDefault(StringParam, ''),
    last_published_at_end: withDefault(StringParam, ''),
  });

  const [isLoadingExportFile, setIsLoadingExportFile] = useState<boolean>(false);

  const [listCategory, setListCategory] = useState<ICategory[]>([]);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

  const [rankSearch, setRankSearch] = useState<Option | null>(RANK_NEWS_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.exposure_rank_type)) || null);

  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_NEWS_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.is_used)) || null);

  const [sentimentSearch, setSentimentSearch] = useState<Option | null>(SENTIMENT_NEWS_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.sentiment_value)) || null);

  const [startLastPublishedSearch, setStartLastPublishedSearch] = useState<string>(query?.last_published_at_start || "");
  const [endLastPublishedSearch, setEndLastPublishedSearch] = useState<string>(query?.last_published_at_end || "");

  const [startDailyVolumeSearch, setStartDailyVolumeSearch] = useState<string>(query?.start_daily_search_volume || "");
  const [endDailyVolumeSearch, setEndDailyVolumeSearch] = useState<string>(query?.end_daily_search_volume || "");

  const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
  const [startDate, endDate] = dateSearch;

  const [newsDetail, setNewsDetail] = useState<INews | null>(null);

  const [listNews, setListNews] = useState<{ list: INews[], total: number }>({ list: [], total: 0 });
  const [isListNewsLoading, setIsListNewsLoading] = useState<boolean>(false);

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<INews | null>(null);

  const [imageCategories, setImageCategories] = useState<any>([]);
  const [imageInsert, setImageInsert] = useState<any>(null);

  const [partners, setPartners] = useState<IPartner[]>([]);

  const onSetImageInsert = (item: any) => {
    setImageInsert((_prev: any) => item);
  }

  const handleQueryData = async () => {
    setIsListNewsLoading((prev) => true);
    if (isFirstLoadingPageRef?.current === true && query?.page !== 1) {
      setQuery({
        ...query,
        type_page_no_param: '',
        page: 1
      });
      return;
    }
    isFirstLoadingPageRef.current = false;
    const res: any = await getAINewsByNormal(query);
    setListNews((prev: any) => ({
      list: res?.data?.list || [],
      total: res?.data?.pagination?.total || 0,
    }));
    setIsListNewsLoading((prev) => false);

    if (!!newsDetail && query?.type_page_no_param) {
      if (query?.type_page_no_param === 'prev') {
        setNewsDetail((_prev) => res?.data?.list[res?.data?.list?.length - 1]);
        return;
      }
      if (query?.type_page_no_param === 'next') {
        setNewsDetail((_prev) => res?.data?.list[0]);
        return;
      }
    }
  };

  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1,
      type_page_no_param: 'next'
    });
  }

  const handleLoadPrev = () => {
    setQuery({
      ...query,
      page: query?.page - 1,
      type_page_no_param: 'prev'
    });
  }


  useEffect(() => {
    handleQueryData();
  }, [JSON.stringify(query)]);

  const searchData = () => {
    const queryNew = {
      ...query,
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      keyword: keywordSearch || '',
      title: titleSearch || '',
      is_used: statusSearch?.value,
      sentiment_value: sentimentSearch?.value,
      exposure_rank_type: rankSearch?.value,
      start_daily_search_volume: startDailyVolumeSearch || '',
      end_daily_search_volume: endDailyVolumeSearch || '',
      last_published_at_start: startLastPublishedSearch || '',
      last_published_at_end: endLastPublishedSearch || '',
      section: `${SECTION_KEYWORD.CONTENT_CAR}`,
      page: 1,
      time_request: + new Date()
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setListNews((prev: any) => ({
        list: [],
        total: 0,
      }));
    }
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      title: '',
      keyword: '',
      is_used: STATUS_NEWS_OPTIONS_LANG[0]?.value,
      section: `${SECTION_KEYWORD.CONTENT_CAR}`,
      category_id: '',
      sentiment_value: '',
      exposure_rank_type: '',
      start_daily_search_volume: '',
      end_daily_search_volume: '',
      last_published_at_start: '',
      last_published_at_end: '',
      start_date: `${moment().format("Y-MM-DD")}`,
      end_date: `${moment().format("Y-MM-DD")}`,
      sort_by: TYPE_SELECT_DEFAULT,
      order_by: 'DESC',
      page: 1,
      time_request: + new Date(),
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setListNews((prev: any) => ({
        list: [],
        total: 0,
      }));
    }
    setQuery(queryNew, "push");
    setTitleSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setStatusSearch(STATUS_NEWS_OPTIONS_LANG[0]);
    setSentimentSearch(null);
    setRankSearch(null);
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    setStartDailyVolumeSearch(queryNew?.start_daily_search_volume);
    setEndDailyVolumeSearch(queryNew?.end_daily_search_volume);
    setStartLastPublishedSearch(queryNew?.last_published_at_start);
    setEndLastPublishedSearch(queryNew?.last_published_at_end);
  };

  const handleSetNewsDetail = (detail: INews | null) => {
    setNewsDetail((_prev) => detail);
    if (!!detail && (window?.innerWidth <= 900) && (!document_w.fullscreenElement && !document_w.mozFullScreenElement && !document_w.webkitFullscreenElement)) {
      setTimeout(async () => {
        try {
          if (modalRef.current && modalRef.current.requestFullscreen) {
            await modalRef?.current?.requestFullscreen();
          } else if (modalRef.current && modalRef.current.webkitRequestFullscreen) {
            await modalRef?.current?.webkitRequestFullscreen(); // Safari
          } else if (modalRef.current && modalRef.current.msRequestFullscreen) {
            await modalRef?.current?.msRequestFullscreen(); // IE
          }
        } catch (error) {
          //console.error("Failed to enter fullscreen:", error);
        }
      }, 280);
    }
  };

  const onCloseClick = () => {
    setCurrentLang((_prev) => 'ko');
    handleSetNewsDetail(null);
    onSetImageInsert(null);
    setTypeShowInfoRight((_prev) => 'IMAGE');
  }

  const [typeShowInfoRight, setTypeShowInfoRight] = useState<'IMAGE' | 'SOURCE'>('IMAGE');
  const onShowInfoRightClick = () => {
    setTypeShowInfoRight((_prev) => (_prev === 'IMAGE' ? 'SOURCE' : 'IMAGE'));
  }

  const refContentNews = useRef<any>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const resetCursorToStart = () => {
    const editor = refContentNews?.current;
    if (editor) {
      // Find the first <br> tag or &nbsp; in the content after insertion
      const body = editor.getBody();
      const brNode = body.querySelector('br'); // Find the first <br> tag
      let targetNode = null;

      if (brNode) {
        targetNode = brNode; // Prioritize the <br> tag
      } else {
        // If no <br> is found, search for &nbsp; in text nodes
        const textNodes = Array.from(body.childNodes).filter(
          (node: any) => node.nodeType === Node.TEXT_NODE && node.nodeValue?.includes('\u00A0')
        );
        if (textNodes.length > 0) {
          targetNode = textNodes[0];
        }
      }

      if (targetNode) {
        // Place the cursor immediately after the <br> tag or &nbsp;
        const parentNode = targetNode.parentNode;
        const offset = Array.from(parentNode.childNodes).indexOf(targetNode) + 1;
        editor.selection.setCursorLocation(parentNode, offset);
      }

      // Update the bookmark state at the new cursor location
      const newBookmark = editor.selection.getBookmark();
      // editor?.selection.setCursorLocation(null, 0);
    }

  };

  const onSaveClick = async (is_show_noti_when_success: boolean = true) => {
    try {
      if (!newsDetail?.id) {
        return;
      }
      setIsSaving((_prev) => true);
      const response: any = await putNews(newsDetail?.id, {
        title: refContentNews?.current?.getTitle() || '',
        body: refContentNews?.current?.getContent() || ''
      });
      if (response?.code === 200) {
        setListNews((prev: any) => ({
          list: (prev?.list || []).map((item: any) => {
            if (String(item?.id) === String(newsDetail?.id)) {
              return ({ ...item, ...response?.data })
            }
            return item;
          }),
          total: Number(prev?.total || 0),
        }));
        setNewsDetail((_prev: any) => ({ ..._prev, ...response?.data }));
        setIsSaving((_prev) => false);
        if (is_show_noti_when_success) {
          toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        }
      } else {
        setIsSaving((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsSaving((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const scrollModalBodyToTop = () => {
    if (modalBodyInnerRef.current) {
      modalBodyInnerRef.current.scrollTo({ top: 0 });
    }
    if (modalSourceInnerRefs.current) {
      modalSourceInnerRefs.current.forEach((ref: any) => {
        if (ref) {
          ref.scrollTo({ top: 0 });
        }
      });
    }
  };

  const handlePreviousNews = (index_news_current: number = 0) => {
    if (listNews?.list?.length) {
      const indexNewsPrevious = Number(index_news_current) - 1;
      const newsPrevious = listNews?.list[indexNewsPrevious];
      if (!!newsPrevious) {
        handleSetNewsDetail({
          ...(newsPrevious),
          index: indexNewsPrevious,
          disabled_prev: false,
          disabled_next: false
        });
        scrollModalBodyToTop();
        setCurrentLang((_prev) => 'ko');
      }
      if (!newsPrevious) {
        if (Number(query?.page) > 1) {
          handleLoadPrev();
        } else {
          toast(t('There is no news article anymore!'), CONFIG_OPTION_TOAST_INFO);
        }
      }
    }
  };

  const handleNextNews = (index_news_current: number = 0) => {
    if (listNews?.list?.length) {
      const indexNewsNext = Number(index_news_current) + 1;
      const newsNext = listNews?.list[indexNewsNext];
      if (!!newsNext) {
        handleSetNewsDetail({
          ...(newsNext),
          index: indexNewsNext,
          disabled_prev: false,
          disabled_next: false,
        });
        scrollModalBodyToTop();
        setCurrentLang((_prev) => 'ko');
      }
      if (!newsNext) {
        if (Number(query?.page) < Math.ceil(Number(listNews?.total) / Number(query?.limit || 300))) {
          handleLoadMore();
        } else {
          toast(t('There is no news article anymore!'), CONFIG_OPTION_TOAST_INFO);
        }
      }
    }
  };

  const handleDownloaded = (info: any) => {
    if (!!info?.id) {
      setListNews((prev: any) => ({
        list: (prev?.list || []).map((item: any) => {
          if (String(item?.id) === String(info?.id)) {
            return ({ ...item, ...info })
          }
          return item;
        }),
        total: Number(prev?.total || 0),
      }));
      setNewsDetail((_prev: any) => ({ ..._prev, ...info }));
    }
  };

  const handlePublished = (info: any) => {
    if (!!info?.id) {
      setListNews((prev: any) => ({
        list: (prev?.list || []).map((item: any) => {
          if (String(item?.id) === String(info?.id)) {
            return ({ ...item, ...info })
          }
          return item;
        }),
        total: Number(prev?.total || 0),
      }));
      setNewsDetail((_prev: any) => ({ ..._prev, ...info }));
    }
  };

  const handleUnPublished = (info: any) => {
    if (!!info?.id) {
      setListNews((prev: any) => ({
        list: (prev?.list || []).map((item: any) => {
          if (String(item?.id) === String(info?.id)) {
            return ({ ...item, ...info })
          }
          return item;
        }),
        total: Number(prev?.total || 0),
      }));
      setNewsDetail((_prev: any) => ({ ..._prev, ...info }));
    }
  };


  const [currentLang, setCurrentLang] = useState<string>('ko');
  const handleChangeLang = (_lang: string) => {
    const lang = newsDetail?.translates?.find((tr) => tr?.language_code === _lang);
    setNewsDetail((_prev) => (
      {
        ..._prev,
        title: lang?.title,
        body: lang?.body,
      } as INews
    ));
    setCurrentLang((_prev) => _lang);
  };

  // Begin::Delete
  const handleConfirmDelete = (item: INews) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteNewsMulti({
        ids: [initialValuesDefault?.id]
      });
      if (response?.code === 200) {
        setListNews((prev: any) => ({
          list: (prev?.list || []).filter((item: any) => String(item?.id) !== String(initialValuesDefault?.id)),
          total: Number(prev?.total || 0) - 1,
        }));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  const handleDownloadExcel = async () => {
    try {
      setIsLoadingExportFile((_prev) => true);
      const response: any = await downloadExcelNews({ ...query });
      if (response?.data) {
        setIsLoadingExportFile((_prev) => false);
        const link = document.createElement('a');
        link.href = response?.data?.url;
        link.download = 'boosting_keywords';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsLoadingExportFile((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingExportFile((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('ID'),
        accessor: "id",
        filterable: true,
        sortable: false,
        thWidth: 90,
        thClass: "align-middle text-center",
        Cell: (cell: any) => (
          <>

            <div className="text-center" style={{ minWidth: '60px' }}>
              <CopyWrapper contentCopy={cell?.value}>
                {cell?.value}
              </CopyWrapper>
            </div>

          </>
        ),
      },
      {
        Header: t('Status'),
        accessor: "is_used",
        filterable: false,
        sortable: false,
        thClass: "align-middle text-center",
        thWidth: i18n?.language === 'en' ? 90 : 80,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: '60px' }} className="text-center fs-16">
              <span className={`rounded-pill badge bg-${COLORS_STATUS_NEWS[`${cell?.value || 0}`] || 'success'}`}>
                {STATUS_NEWS_OPTIONS_LANG?.find((s) => String(s?.value) === String(cell?.value))?.label || ''}
              </span>
            </div>
          );
        }
      },
      {
        Header: t('Category'),
        accessor: "category",
        filterable: true,
        sortable: false,
        thWidth: i18n?.language === 'en' ? 130 : 90,
        thClass: 'align-middle text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<div style={{ minWidth: '70px' }} className="text-center d-flex flex-column justify-content-center align-items-center gap-1 fs-16">
            <span key={item?.section} className="badge bg-primary-subtle text-primary">
              {item?.category?.name || ''}
            </span>
          </div>)
        },
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: true,
        sortable: true,
        thWidth: 100,
        thClass: 'align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ width: '80px' }} className="fs-15">{cell?.value || '-'}</div>
          </>)
        },
      },
      {
        Header: t('Sub Keywords'),
        accessor: "keyword_sub",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'align-middle',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ width: '100px' }} className="fs-15">{cell?.value || '-'}</div>
          </>)
        },
      },
      {
        Header: t('News Title'),
        accessor: "title",
        filterable: true,
        sortable: false,
        thClass: 'align-middle',
        thWidth: !listNews?.list?.length ? 100 : (window.innerWidth < 1650 ? 270 : 600),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '250px', maxWidth: '600px' }} className="fs-15 cursor-pointer hover-underline" onClick={(e) => { e.preventDefault(); handleSetNewsDetail({ ...item, index: cell?.row?.index }) }}>
              <HighlightText text={item?.title || ''} keyword={item?.keyword || ''} opacity_bg={0.7} />
            </div>
          </>)
        },
      },
      {
        Header: t('Media'),
        accessor: "news_publish_log",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: 'align-middle text-end',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const _news_publish_log = item?.news_publish_log;
          const _partner = partners?.find((_p) => String(_p?.id) === String(_news_publish_log?.partner_id));
          return (<>
            <div style={{ minWidth: '110px' }} className="fs-15 text-end">
              {_partner?.name || '-'}
            </div>
          </>)
        },
      },
      {
        Header: t('Daily Search Volume'),
        accessor: "daily_search_volume",
        filterable: true,
        sortable: true,
        thWidth: i18n?.language === 'en' ? 190 : 110,
        thClass: 'align-middle text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center pe-2" style={{ minWidth: i18n?.language === 'en' ? '170px' : '80px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
          </>)
        },
      },
      {
        Header: t('Article Rank'),
        accessor: "exposure_rank",
        filterable: true,
        sortable: true,
        thWidth: i18n?.language === 'en' ? 140 : 110,
        thClass: 'align-middle text-center',
        description: t('The rank at which the article appears in Naver News search results.'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center pe-3" style={{ minWidth: i18n?.language === 'en' ? '120px' : '90px' }}>
              {
                (cell?.value !== null) ? <>
                  {formatNumberWithCommas(cell?.value || 0)}
                </> : '-'
              }
            </div>
          </>)
        },
      },
      {
        Header: t('Affiliate Ranking'),
        accessor: "keyword_publish_logs",
        filterable: true,
        sortable: false,
        thWidth: i18n?.language === 'en' ? 150 : 90,
        thClass: 'align-middle text-end',
        description: t('These are the rankings of articles published in the last 7 days.'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end" style={{ minWidth: i18n?.language === 'en' ? '130px' : '70px' }}>
              {item?.keyword_publish_logs?.length ? <>{item?.keyword_publish_logs?.map((_log: any) => (
                <div key={_log?.partner_id} className="pe-3 me-1">{_log?.exposure_rank || '-'} ({_log?.partner_name || ''})</div>
              ))}</> : <div className="pe-3 me-1">-</div>}
            </div>
          </>)
        },
      },
      {
        Header: t('Exposure Period'),
        accessor: "last_published_at",
        filterable: true,
        sortable: true,
        thWidth: i18n?.language === 'en' ? 170 : 120,
        thClass: 'align-middle text-end',
        description: t('This is the last published date among the 4 most recent articles in the Naver News search.'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <div className="text-end pe-3 me-3" style={{ minWidth: i18n?.language === 'en' ? '140px' : '100px' }}>
              {item?.last_published_label || '-'}
            </div>
          )
        },
      },
      {
        Header: t('Source Date'),
        accessor: "news_sources",
        filterable: true,
        sortable: false,
        thWidth: i18n?.language === 'en' ? 110 : 90,
        thClass: 'align-middle text-end',
        Cell: (cell: any) => {
          const firstSource = (cell?.value || [])?.[0];
          return (
            <div className="text-end" style={{ minWidth: i18n?.language === 'en' ? '90px' : '70px' }}>
              <TooltipCustom
                title={firstSource?.publish_time || ''}
                className="d-inline-block vertical-align-middle ms-1"
                style={{ transform: 'translateY(1px)' }}
              >
                <span>{firstSource?.publish_time_label || '-'}</span>
              </TooltipCustom>
            </div>
          )
        },
      },
      {
        Header: t('Views'),
        accessor: "views",
        filterable: true,
        sortable: false,
        thWidth: 80,
        thClass: 'align-middle text-end',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-end" style={{ minWidth: '60px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
          </>)
        },
      },
      {
        Header: t('User'),
        accessor: "user",
        filterable: true,
        sortable: false,
        thWidth: 160,
        thClass: 'align-middle text-end',
        description: t('The name of the user who downloaded the news'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '140px' }} className="fs-15 text-end">
              {!!item?.user ? <>{item?.user?.name || '-'} <br />
                <span className="fs-13 text-muted">{item?.user?.email || '-'}</span>
              </> : <span className="fs-13">-</span>}
            </div>
          </>)
        },
      },
      {
        Header: t('Operator'),
        accessor: "admin",
        filterable: true,
        sortable: false,
        thWidth: 160,
        thClass: 'align-middle text-end',
        description: t('The name of the user who published the news'),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '140px' }} className="fs-15 text-end">
              {!!item?.admin ? <>{(item?.admin?.first_name + ' ' + item?.admin?.last_name) || '-'} <br />
                <span className="fs-13 text-muted">{item?.admin?.email || '-'}</span>
              </> : <span className="fs-13">-</span>}
            </div>
          </>)
        },
      },
      {
        Header: t('Created At'),
        accessor: "created_at",
        filterable: true,
        sortable: true,
        thWidth: 120,
        thClass: 'align-middle text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end pe-3" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated At'),
        accessor: "updated_at",
        filterable: true,
        sortable: true,
        thWidth: 120,
        thClass: 'align-middle text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end pe-3" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || '-'}</span> <br /> <span className="text-secondary">{arrDate?.[1] || '-'}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Actions'),
        accessor: "__",
        thClass: 'align-middle text-center',
        thWidth: 120,
        sortable: false,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0" style={{ minWidth: '100px' }}>
              {isHavePermissionRole(ROLES_FOR_APP.BOOSTING_NEWS, userPermissions) &&
                <TooltipCustom
                  title={t('Button View News')}
                  id={`detail-cp-${item?.id}`}
                >
                  <li className=" list-inline-item me-0" id={`detail-us-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-primary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleSetNewsDetail({ ...item, index: cell?.row?.index }) }}
                    >
                      {/* {t('Button View News')} */}
                      <i className="ri-eye-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              }
              {isHavePermissionRole(ROLES_FOR_APP.BOOSTING_NEWS, userPermissions) && (
                <TooltipCustom
                  title={t('Button Delete News')}
                  id={`detail-cp-${item?.id}`}
                >
                  <li className="list-inline-item" id={`detail-cp-${item?.id}`}>
                    <Link className="btn btn-sm btn-soft-danger edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                    >
                      <i className="ri-delete-bin-3-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              )}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, listNews?.list?.length, query, partners, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setListNews((prev: any) => ({
      list: [],
      total: 0,
    }));
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy, page: 1 });
    });
  }, []);


  const handleCallAllOption = async () => {
    try {
      const [res1, res2, res3]: any = await Promise.all([getAllCategory(), getAllImageCategory({}), getAllPartners({})]);
      setListCategory((_prev) => res1?.data || []);
      setImageCategories((_prev: any) => res2?.data);
      setPartners((_prev: any) => res3?.data);
    } catch (error: any) {
      return error;
    }
  };

  const handelChangeCategory = (category_id_search: string) => {
    const queryNew = {
      ...query,
      category_id: category_id_search,
      page: 1,
      time_request: + new Date()
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setListNews((prev: any) => ({
        list: [],
        total: 0,
      }));
    }
    setQuery(queryNew);
  }

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t('Boosting Content')} | ${t('PRESS Ai')}`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);



  const refRight = useRef<any>(null);
  const [position, setPosition] = useState<any>({ left1: 20, left2: 30, top1: 20, top2: 20 });

  useEffect(() => {
    const getPosition = () => {
      if (modalBodyInnerRef.current && refRight.current) {
        const leftRect = modalBodyInnerRef.current.getBoundingClientRect();
        const rightRect = refRight.current.getBoundingClientRect();
        setPosition((_prev: any) => ({ left1: leftRect.left, left2: rightRect.left, top1: leftRect.top, top2: rightRect.top }));
      }
    };

    // Timeout to ensure UI has rendered
    const timeoutId = setTimeout(() => {
      // Get position on mount
      getPosition();
    }, 0);

    // Optionally, track changes on window resize
    window.addEventListener("resize", getPosition);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", getPosition);
    };
  }, [JSON.stringify(newsDetail)]);


  const [status, setStatus] = useState<number>(0); // Tracks current status from API
  const [isRunning, setIsRunning] = useState<boolean>(false); // Tracks if the process is running
  const [buttonText, setButtonText] = useState<string>(''); // Button text to display

  const [idRunning, setIdRunning] = useState<number | string>('');
  const [isStopping, setIsStopping] = useState<boolean>(false);

  const [typeAction, setTypeAction] = useState<string>('');

  // Function to handle the POST request and start the process
  const startProcess = async () => {
    try {
      setIsRunning(true); // Set running state to true
      const response: any = await postGenerateBoostingNews({ type: "news:boosting:generate" });
      setIsRunning(false);
      if (response?.code === 200) {
        setStatus(0);
        setIdRunning((_prev) => response?.data?.id);
        setButtonText(`${String(response?.data?.created_at)?.split(' ')[1].slice(0, 5)}`); // Show `time` on the button
        setTypeAction((_prev) => '');
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  // Function to check the status (GET request)
  const checkStatus = async () => {
    try {
      const response: any = await getGenerateBoostingNews({ type: "news:boosting:generate" });
      if (response?.code === 200) {
        if (response?.data === null) {
          setStatus(1); // Process is complete
          setIdRunning((_prev) => '');
          setButtonText(''); // Reset the button text
        } else {
          setStatus(0); // Process is still running
          setIdRunning((_prev) => response?.data?.id);
          setButtonText(`${String(response?.data?.created_at)?.split(' ')[1].slice(0, 5)}`);
        }
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  // Effect to poll the status every 5 seconds regardless of the state of `isRunning`
  // useEffect(() => {
  //   checkStatus();
  //   const timer = setInterval(() => {
  //     checkStatus(); // Check status every 5 seconds
  //   }, 10000);

  //   return () => clearInterval(timer); // Cleanup the timer on unmount
  // }, []);

  // Function to handle button click
  const handleButtonGenerateClick = async () => {
    if (isRunning) {
      return;
    }
    await startProcess(); // Start the process
  };

  const handleButtonStopClick = async () => {
    try {
      if (!idRunning) {
        return;
      }
      setIsStopping(true); // Set running state to true
      const response: any = await putGenerateBoostingNews(idRunning, { status: 99 });
      setIsStopping(false);
      if (response?.code === 200) {
        setTypeAction((_prev) => '');
        setStatus(1); // Process is complete
        setIdRunning((_prev) => '');
        setButtonText(''); // Reset the button text
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleAction = () => {
    if (typeAction === 'RUNNING') {
      handleButtonGenerateClick();
    }
    if (typeAction === 'STOPPING') {
      handleButtonStopClick();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Boosting Content')} pageTitle={t('Home')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.BOOSTING_NEWS, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                  <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, top: '11px', fontSize: '0.8rem' }}>{t('This data is analyzed every hour')}</div>
                  <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                    <Row className="g-4 align-items-center">
                      <div className="col-12 col-md-12">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            {(isListNewsLoading && query?.page === 1) ? <Spinner size="sm" className="text-primary"></Spinner> : (<CountUp
                              start={0}
                              end={listNews?.total || 0}
                              duration={1}
                              className="text-primary"
                            />)}
                          </h5>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-0 pb-md-4 px-0 px-lg-3 pt-0 pt-lg-3">
                    <NestingFilter>
                      <Row className="g-4 align-items-end mt-0">
                        <Col sm={6} md={6} lg={4} xl={4} xxl={2} className='mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Rank')} isShow={true}>
                            <DropdownStatus
                              name="rank"
                              dataList={RANK_NEWS_OPTIONS_LANG || []}
                              placeholder={`${t("Rank")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={rankSearch || null}
                              onChangeSelect={(e: any) => setRankSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                              colors={COLORS_RANK_NEWS}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4} xxl={2} className='mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Status')} isShow={true}>
                            <DropdownStatus
                              name="status"
                              dataList={STATUS_NEWS_OPTIONS_LANG || []}
                              placeholder={`${t("Status")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={statusSearch || null}
                              onChangeSelect={(e: any) => setStatusSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                              colors={COLORS_STATUS_NEWS}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={4} className='mt-3 mt-xxl-3 order-2 order-sm-3 order-md-3 order-lg-2 order-xxl-0' >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={startDailyVolumeSearch}
                            valueTo={endDailyVolumeSearch}
                            placeholderFrom={`${t('Daily Search Volume (Start)')}...`}
                            placeholderTo={`${t('Daily Search Volume (End)')}...`}
                            labelFrom={`${t('Daily Search Volume (Start)')}`}
                            labelTo={`${t('Daily Search Volume (End)')}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) => setStartDailyVolumeSearch(val)}
                            onChangeTo={(val) => setEndDailyVolumeSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} xxl={4} className='mt-3 mt-xxl-3 order-2 order-sm-2 order-md-2 order-lg-2 order-xxl-0'  >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={startLastPublishedSearch}
                            valueTo={endLastPublishedSearch}
                            placeholderFrom={`${t('Exposure Period (Start)')}...`}
                            placeholderTo={`${t('Exposure Period (End)')}...`}
                            labelFrom={`${t('Exposure Period (Start)')}`}
                            labelTo={`${t('Exposure Period (End)')}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) => setStartLastPublishedSearch(val)}
                            onChangeTo={(val) => setEndLastPublishedSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        {/* <Col sm={6} md={6} lg={4} xl={4} xxl={3} className='mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Sentiment')} isShow={true}>
                            <DropdownStatus
                              name="sentiment"
                              dataList={SENTIMENT_NEWS_OPTIONS_LANG || []}
                              placeholder={`${t("Sentiment")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={sentimentSearch || null}
                              onChangeSelect={(e: any) => setSentimentSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                              colors={COLORS_SENTIMENT_NEWS}
                            />
                          </LabelWrapper>
                        </Col> */}
                        <Col sm={6} md={6} lg={6} xl={3} xxl={2} className='mt-3 mt-xxl-3 order-3 order-sm-3 order-md-3 order-lg-3 order-xxl-0'>
                          <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('Keyword')}...`}
                              value={keywordSearch}
                              onChange={(e) => setKeywordSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} xxl={4} className='mt-3 mt-xxl-3 order-4 order-sm-4 order-md-4 order-lg-4 order-xxl-0'>
                          <LabelWrapper label={t('News Title')} isShow={!!titleSearch}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('News Title')}...`}
                              value={titleSearch}
                              onChange={(e) => setTitleSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4} xxl={3} className='date-picker-wrapper-custom  order-1 order-sm-1 order-md-1 order-lg-1 order-xxl-0 mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                            <DatePickerCustom
                              placeholder={`${t('Start Date')} - ${t('End Date')}`}
                              startDate={startDate || null}
                              endDate={endDate || null}
                              onChangePicker={handleChangePicker}
                              showOptions={[
                                'today', 'yesterday', 'two_day_ago',
                                'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                'this_week', 'last_week',
                                'this_month', 'last_month',
                              ]}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col sm={12} md={12} lg={12} xl={3} xxl={3} className="order-5 order-sm-5 order-md-5 order-lg-5 order-lg-5 order-xxl-0 hstack gap-1 justify-content-center justify-content-sm-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end mt-3 mt-xxl-3">
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-2 me-lg-2"
                              onClick={searchData}
                              disabled={isListNewsLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t('Button Search')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t('Button Reset')}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </NestingFilter>
                  </div>
                  {/* <div className="border border-dashed border-top-0 border-end-0 border-start-0">
                    <Col sm={12} md={12} xl={12} xxl={12} className="px-3 py-3">
                      <div>
                        <div className="d-flex gap-2 flex-wrap">
                          <button type="button" className={`btn px-3 px-md-4 py-btn-section ${String(query?.category_id) === '' ? 'bg-primary text-white' : 'border-gray text-secondary'}`}
                            onClick={(e) => { e.preventDefault(); handelChangeCategory('') }}
                          >
                            {t('All')}
                          </button>
                          {listCategory?.map((item) => (
                            <button type="button" className={`btn px-3 px-md-4 py-btn-section ${String(query?.category_id) === String(item?.id) ? `bg-primary text-white` : `border-gray text-secondary`}`} key={item?.id}
                              onClick={(e) => { e.preventDefault(); handelChangeCategory(`${item?.id}`) }}
                            >
                              {item?.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </div> */}
                  <CardBody className="pt-0 px-0">
                    <div className="mx-3 my-4 mt-3">
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card "
                        tableClass="align-middle sticky-table-ai-news"
                        theadClass="table-light"
                        columns={columns}
                        data={listNews?.list?.length ? listNews?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={listNews?.total}
                        customPageCount={Math.ceil(Number(listNews?.total) / Number(query?.limit || 300))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isListNewsLoading}
                        isShowPagination={true}
                        isShowLoadingBottom={false}
                        isScrollToTop={true}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}</Row>
        </Container>
        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />

        <Modal
          isOpen={!!newsDetail}
          centered={true}
          size="xl"
          className={`border-header-footer modal-fullscreen`}
          scrollable={false}
          toggle={onCloseClick}
          keyboard={true}
          innerRef={modalRef}
        >
          <ModalHeader toggle={onCloseClick}>
            <div className="d-flex flex-column flex-sm-row gap-0 gap-sm-2 fs-18 fs-md-20">
              <div className="flex-grow-1">
                {t('Boosting Content')}: <span style={{ backgroundColor: '#ffff00' }}>{newsDetail?.keyword || ''}</span> {!!newsDetail?.keyword_sub && <> - <span style={{ backgroundColor: '#a8d2fb' }}>{newsDetail?.keyword_sub || ''}</span></>}
                <ButtonLanguage currentLang={currentLang} handleChangeLang={handleChangeLang} />
                <span className={`mx-2 rounded-pill badge bg-${COLORS_STATUS_NEWS[`${newsDetail?.is_used || 0}`] || 'success'}`}>
                  {STATUS_NEWS_OPTIONS_LANG?.find((s) => String(s?.value) === String(newsDetail?.is_used))?.label || ''}
                </span>
                <span key={newsDetail?.section} className={`rounded-pill me-2 badge bg-primary-subtle text-primary`}>
                  {newsDetail?.category?.name || ''}
                </span>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="position-relative">
            <span className="position-absolute text-success fs-13 text-end px-2 d-none d-lg-block" style={{
              top: position?.top1,
              left: position?.left1 + 10,
              transform: `translateY(-${position?.top1 - 10}px)`,
              zIndex: 9,
              backgroundColor: 'var(--vz-secondary-bg)'
            }}>
              {t('Generated News')}
            </span>
            <span className="position-absolute text-primary fs-13 text-end px-2 d-none d-lg-block" style={{
              top: position?.top2,
              left: position?.left2 + 10,
              transform: `translateY(-${position?.top2 - 10}px)`,
              zIndex: 9,
              backgroundColor: 'var(--vz-secondary-bg)'
            }}>
              {typeShowInfoRight === 'SOURCE' ? t('Source News') : t('Google Image')}
            </span>
            <div className="h-100-pc w-100 overflow-x-hidden">
              <Row className="w-100-pc px-0 h-100-pc">
                <Col lg={8} className="h-100-pc mt-2 mt-md-0">
                  <span className="position-absolute text-success fs-13 text-end px-2 d-block d-lg-none" style={{
                    top: 0,
                    left: 5,
                    transform: `translateY(-${10}px)`,
                    zIndex: 9,
                    backgroundColor: 'var(--vz-secondary-bg)'
                  }}>
                    {t('Generated News')}
                  </span>
                  <div ref={modalBodyInnerRef} className="inner-modal-body color-scrollbar-os position-relative p-0 editor-border-radius-0" style={{ border: '1.5px dashed var(--vz-success)' }}>
                    <BoxGeneratedNews ref={refContentNews} newsDetail={newsDetail} imageInsert={imageInsert} isEdit={true} />
                  </div>
                </Col>
                <Col lg={4} className="h-100-pc ms-0 ms-lg-0 pe-3 me-lg-0 mt-4  mt-md-0" >
                  <span className="position-absolute text-primary fs-13 text-end px-2 d-block d-lg-none" style={{
                    top: 0,
                    left: 5,
                    transform: `translateY(-${10}px)`,
                    zIndex: 9,
                    backgroundColor: 'var(--vz-secondary-bg)'
                  }}>
                    {typeShowInfoRight === 'SOURCE' ? t('Source News') : t('Google Image')}
                  </span>
                  {typeShowInfoRight === 'SOURCE' ? <Row className={`h-100-pc align-content-${newsDetail?.news_sources?.length === 2 ? 'start' : 'between'} gap-3 gap-lg-${newsDetail?.news_sources?.length === 2 ? 2 : 0}`}>
                    {newsDetail?.news_sources?.map((item, index) => {
                      if (!modalSourceInnerRefs.current[index]) {
                        modalSourceInnerRefs.current[index] = React.createRef<HTMLDivElement>().current;
                      }
                      return (
                        <div ref={(el) => (modalSourceInnerRefs.current[index] = el)} key={index} className={`color-scrollbar-os position-relative box-source-news chill-${newsDetail?.news_sources?.length}`}>
                          <BoxSourceNews item={item} index={index} keyword={newsDetail?.keyword} keyword_sub={newsDetail?.keyword_sub} />
                        </div>
                      )
                    })}
                  </Row> :
                    <Row className={`h-100-pc align-content-between position-relative gap-3 gap-lg-0 `}>
                      <div className={`box-source-image overflow-y-hidden overflow-x-hidden px-0 `} ref={refRight}>
                        <BoxImageSearch keyword={newsDetail?.keyword || ''} ref={modalBodyInnerRef} news_id={newsDetail?.id} categories={imageCategories} onSetImageInsert={onSetImageInsert} resetCursorToStart={resetCursorToStart} />
                      </div>
                    </Row>}
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter className="position-relative footer-button-new" lang={i18n?.language || 'en'}>
            <BoxFooterNews
              newsDetail={newsDetail}
              isListNewsLoading={isListNewsLoading}
              type_page_no_param={query?.type_page_no_param}
              currentLang={currentLang}
              isSaving={isSaving}
              isShowSave={true}
              handlePreviousNews={handlePreviousNews}
              handleNextNews={handleNextNews}
              handleDownloaded={handleDownloaded}
              handlePublished={handlePublished}
              handleUnPublished={handleUnPublished}
              onCloseClick={onCloseClick}
              onSaveClick={onSaveClick}
              onShowInfoRightClick={onShowInfoRightClick}
              typeShowInfoRight={typeShowInfoRight}
              partners={partners}
            />
          </ModalFooter>
        </Modal>


        <ModalConfirm
          header={t('Button Delete News')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />

        <ModalConfirm
          header={t('Button Generate')}
          textButtonConfirm={t('Button Generate')}
          classButtonConfirm={'btn-success'}
          title={t("Do you want to proceed?")}
          content={t("All articles for the keywords will be generated.")}
          isOpen={typeAction === 'RUNNING'}
          isLoading={isRunning}
          classIconButtonConfirm=''
          isShowIcon={false}
          onClose={() => setTypeAction((_prev: string) => '')}
          onConfirm={handleAction}
        />

        <ModalConfirm
          header={t('Button Stop')}
          textButtonConfirm={t('Button Stop')}
          classButtonConfirm={typeAction === 'RUNNING' ? 'btn-success' : 'btn-danger'}
          title={t("Do you want to proceed?")}
          content={t("All articles for the keywords will be stopped.")}
          isOpen={typeAction === 'STOPPING'}
          isLoading={isStopping}
          classIconButtonConfirm=''
          isShowIcon={false}
          onClose={() => setTypeAction((_prev: string) => '')}
          onConfirm={handleAction}
        />
      </div >
    </React.Fragment >
  );
};

export default BoostingContentList;