import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IJournalist } from "./types/_journalist";
const api = new APIClient();

const path = '/keyword-journalists';
const journalistApi = {
  journalists(params: any): Promise<AxiosResponse<ResponseData<IJournalist[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getJournalist(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IJournalist>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  allJournalists(params: any = {}): Promise<AxiosResponse<ResponseData<IJournalist[]>, any>> {
    const url = `masters/journalists`
    return api.get(url, params)
  },
  postJournalist(dataForm: any): Promise<AxiosResponse<ResponseData<IJournalist>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putJournalist(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IJournalist>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteJournalist(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IJournalist>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
}
export default journalistApi
