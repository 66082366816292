import { createSlice } from "@reduxjs/toolkit";
import { IJournalist } from "api/types/_journalist";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getJournalists } from "./thunk";

export interface IState {
  journalists: ResponseData<IJournalist[]> & PaginationResponse | null,
  isJournalistLoading: boolean,
  isJournalistSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  journalists: null,
  isJournalistLoading: false,
  isJournalistSuccess: false,

  error: {},
};

const JournalistSlice = createSlice({
  name: "Journalist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get journalists
    builder.addCase(getJournalists.pending, (state: IState, action: any) => {
      state.isJournalistLoading = true
    });
    builder.addCase(getJournalists.fulfilled, (state: IState, action: any) => {
      state.journalists = action.payload.data;
      state.isJournalistSuccess = true;
      state.isJournalistLoading = false;
    });
    builder.addCase(getJournalists.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isJournalistSuccess = false;
      state.isJournalistLoading = false;
    });
  },
});

export default JournalistSlice.reducer;