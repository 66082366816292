import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from 'react-select/async';
import { getOperatorsByNormal } from "../../store/thunks";
interface Option {
  label: string;
  value: string;
  isByEnter?: boolean
}
interface Filter {
  page?: number,
  limit?: number,
  name?: string;
}

interface SearchFilterOperatorProps {
  name?: string,
  initialValue?: Option[] | Option | undefined | null;
  disabled?: boolean,
  limitShow?: number,
  onChangeSelect?: (params: any) => void;
  isGetListOptionDefault?: boolean,
  isMulti?: boolean,
  isClearable?: boolean,
  placeholder?: string
}

const SearchFilterOperator = ({
  name = '',
  initialValue,
  isGetListOptionDefault = true,
  disabled = false,
  limitShow = 10,
  onChangeSelect,
  isMulti = false,
  isClearable = false,
  placeholder = '',
}: SearchFilterOperatorProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[] | undefined>([]);

  const setOnChangeOption = (item: any, option: any = null) => {
    onChangeSelect && onChangeSelect(item)
  };
  const FILTER_BY = { page: 1, limit: limitShow, keyword: '' };

  const promiseOptions = (inputValue: string) => {
    return new Promise<Option[]>((resolve) => {
      getOperatorsByNormal({ ...FILTER_BY, keyword: inputValue }).then((response: any) => {
        const result = response?.data?.list?.map((item: any) => ({
          label: item?.first_name + item?.last_name,
          value: item?.id,
        })) || [];
        setOptions((_prev) => result);
        resolve(result);
      });
    });
  };

  const promiseCallOperators = async (params: Filter = {}) => {
    try {
      const response: any = await getOperatorsByNormal({ ...FILTER_BY, ...params });
      const result = response?.data?.list?.map((item: any) => ({
        label: item?.first_name + item?.last_name,
        value: item?.id,
      })) || [];
      setOptions((_prev) => result);
    } catch (error) {
      return error;
    }
  }


  useEffect(() => {
    if (isGetListOptionDefault) {
      promiseCallOperators();
    }
  }, [isGetListOptionDefault]);

  return (
    <React.Fragment>
      <AsyncSelect
        cacheOptions
        defaultOptions={options}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={disabled}
        closeMenuOnSelect={!isMulti}
        name={name}
        value={initialValue}
        loadOptions={promiseOptions}
        onChange={(e: any, op: any) => setOnChangeOption(e, op)}
        placeholder={<div>{placeholder || t('Select User')}...</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        className="search-filter-campaign dropdown-status-rounded"
        classNamePrefix="name-prefix"
      />
    </React.Fragment>
  );
};

export default SearchFilterOperator;
