import { IPartner } from 'api/types/_news';
import DropdownOption from 'components/Common/DropdownOption';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';

interface ModalPartnerProps {
    isOpen: boolean;
    isPublishing: boolean;
    partners?: IPartner[],
    onPublish?: (info: any) => void,
    onCloseClick?: () => void,
}

const ModalPartner: React.FC<ModalPartnerProps> = ({ partners, isOpen, isPublishing, onPublish, onCloseClick }) => {
    const { t } = useTranslation();

    const [options, setOptions] = useState<any>([]);

    const [infoPublish, setInfoPublish] = useState<{ media: any, section: any } | null>(null);

    const handlePublish = async () => {
        if (!infoPublish?.media?.value) {
            return;
        }
        onPublish && onPublish({
            partner_id: infoPublish?.media?.value ? Number(infoPublish?.media?.value) : null,
            partner_code_id: infoPublish?.section?.value ? Number(infoPublish?.section?.value) : null
        });
    };

    const handleCloseClick = () => {
        onCloseClick && onCloseClick();
    }

    useEffect(() => {
        setInfoPublish((_prev: any) => null);
        setOptions((_prev: any) => {
            return partners?.map((item) => ({ label: item?.name || '', value: String(item?.id), sections: item?.partner_codes?.map((d: any) => ({ label: d?.section_title, value: String(d?.id) })) }))
        });
        return () => {
            setInfoPublish((_prev: any) => null);
        }
    }, [partners]);

    return (
        <>
            <Modal
                isOpen={isOpen}
                centered={true}
                size="md"
                scrollable={false}
            >
                <ModalHeader toggle={handleCloseClick}>
                    <>{t("Button Publish News")}</>
                </ModalHeader>
                <ModalBody className="pt-2">
                    <div>
                        <Row>
                            <Col sm={12} md={12} xl={12} className="mt-3">
                                <Label htmlFor="media" className="form-label">{t('Media')}<span className="text-danger"> *</span></Label>
                                <DropdownOption
                                    name="media"
                                    dataList={options || []}
                                    placeholder={`${t("Media")}...`}
                                    className={`dropdown-status-rounded z-hight mb-3 mb-lg-2 mb-xxl-1`}
                                    classNamePrefix="name-prefix"
                                    style={{ width: '100%' }}
                                    initialValue={infoPublish?.media}
                                    onChangeSelect={(e: any) => {
                                        const _sections = options?.find((op: any) => (String(op?.value) === String(e?.value)))?.sections || [];
                                        setInfoPublish((_prev: any) => ({ ..._prev, media: e, section: _sections[0] }));
                                    }}
                                />
                            </Col>
                            <Col sm={12} md={12} xl={12} className="mt-3">
                                <Label htmlFor="section" className="form-label">{t('Section')}<span className="text-danger"> *</span></Label>
                                <DropdownOption
                                    name="section"
                                    dataList={options?.find((op: any) => (String(op?.value) === String(infoPublish?.media?.value)))?.sections || []}
                                    placeholder={`${t("Section")}...`}
                                    className={`dropdown-status-rounded z-hight mb-3 mb-lg-2 mb-xxl-1`}
                                    classNamePrefix="name-prefix"
                                    style={{ width: '100%' }}
                                    initialValue={infoPublish?.section}
                                    onChangeSelect={(e: any) => {
                                        setInfoPublish((_prev: any) => ({ ..._prev, section: e }));
                                    }}
                                />
                            </Col>
                            <Col sm={12} md={12} xl={12} className='mt-4 mb-3 pt-1 text-end'>
                                <button
                                    style={{ width: '120px' }}
                                    type="button"
                                    disabled={isPublishing || !infoPublish?.media?.value || !infoPublish?.section}
                                    onClick={handlePublish}
                                    className="btn btn-primary fs-14 rounded-pill">
                                    {isPublishing ? <Spinner size="sm me-2" ></Spinner> : <></>}
                                    {t('Publish')}
                                </button>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default React.memo(ModalPartner);
