import { createAsyncThunk } from "@reduxjs/toolkit";
import journalistApi from "api/journalistApi";
import { formatQueryParams } from "helpers/format";

export const getJournalists = createAsyncThunk("Journalists", async (params: any = {}) => {
  try {
    const response = await journalistApi.journalists(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getJournalistsByNormal = async (params: any = {}) => {
  try {
    const response = await journalistApi.journalists(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllJournalists = async (params: any = {}) => {
  try {
    const response = await journalistApi.allJournalists(params)
    return response;
  } catch (error) {
    return error;
  }
};

export const getJournalist = async (id: string | number, params: any = {}) => {
  try {
    const response = await journalistApi.getJournalist(id, params)
    return response;
  } catch (error) {
    return error;
  }
};

export const postJournalist = async (data: any = {}) => {
  try {
    const response = await journalistApi.postJournalist(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putJournalist = async (id: string | number, data: any = {}) => {
  try {
    const response = await journalistApi.putJournalist(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteJournalist = async (id: string | number, data: any = {}) => {
  try {
    const response = await journalistApi.deleteJournalist(id, data);
    return response;
  } catch (error) {
    return error;
  }
};